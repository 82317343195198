import Swal from 'sweetalert2';
import { MESSAGES } from './constants-swal.model';

export class ConstantsMaskModel{

    static MASK_CNPJ:string = "99.999.999/9999-99";
    static MASK_CPF:string = "999.999.999-99";
    static MASK_DATE:string = "99/99/9999";
    static MASK_CEP:string = "99999-999";
    static MASK_DIA_MES:string = "99";

    public cpfcnpjmaskReadOnly(cpfCnpjValue) {

        let cpfCnpj = cpfCnpjValue.match(/\d/g);
        let cpfCnpjLength = 0;
        if (cpfCnpj) {
          cpfCnpjLength = cpfCnpj.join('').length;
        }
        if(cpfCnpjLength === 11){
            return [/\d/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
                        
        }else if(cpfCnpjLength > 11 && cpfCnpjLength < 15){
            return [/\d/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
      
        }else{
            return [/\d/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
        }
      }


      public cpfcnpjmask(cpfCnpjValue) {
        let cpfCnpj = cpfCnpjValue.match(/\d/g);
        let cpfCnpjLength = 0;
        if (cpfCnpj) {
          cpfCnpjLength = cpfCnpj.join('').length;
        }
        if (cpfCnpjLength === 11) {
          return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    
        } else if (cpfCnpjLength > 11 && cpfCnpjLength < 15) {
          return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    
        } else {
          return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
        }
      }

      public validarData(dataValue) {
          let data = dataValue.match(/\d/g);
          let dataArray = 0;

           if (data) {
            dataArray = data.join();
           }

          if (dataArray[14] != undefined) {
             let primeiroDiditoDia = dataArray[0];
             let segundoDigitoDia =dataArray[2]
  
             let primeiroDigitoMes = dataArray[4];
             let segundoDigitoMes = dataArray[6];

             let primeiroDigitoAno = dataArray[8];
             let segundoDigitoAno = dataArray[10];
             let terceiroDigitoAno = dataArray[12];
             let quartoDigitoAno = dataArray[14];
          
               let diaSemana = primeiroDiditoDia+segundoDigitoDia;
               let mesAno = primeiroDigitoMes+segundoDigitoMes;
               let ano = primeiroDigitoAno+segundoDigitoAno+terceiroDigitoAno+quartoDigitoAno;
            
              if (diaSemana <= 0) {
                 Swal(MESSAGES.DATA_INVALIDA, "", MESSAGES.TYPE_ERROR);
                   return [/[0-9]/, /[0-9]/, '/',/[0-9]/,/[0-9]/, '/', /[0-9]/, /[0-9]/,  /[0-9]/, /[0-9]/];
              } else if (diaSemana >= 32) {
                 Swal(MESSAGES.DATA_INVALIDA, "", MESSAGES.TYPE_ERROR);
                    return [/[0-9]/, /[0-9]/, '/',/[0-9]/,/[0-9]/, '/', /[0-9]/, /[0-9]/,  /[0-9]/, /[0-9]/];
              }else if (ano < 0) {
                 Swal(MESSAGES.DATA_INVALIDA, "", MESSAGES.TYPE_ERROR);
                   return [/[0-9]/, /[0-9]/, '/',/[0-9]/,/[0-9]/, '/', /[0-9]/, /[0-9]/,  /[0-9]/, /[0-9]/];
              }
              if (mesAno <= 0) {
                Swal(MESSAGES.DATA_INVALIDA, "", MESSAGES.TYPE_ERROR);
                   return [/[0-9]/, /[0-9]/, '/',/[0-9]/,/[0-9]/, '/', /[0-9]/, /[0-9]/,  /[0-9]/, /[0-9]/];
              } else if (mesAno > 12) {
                Swal(MESSAGES.DATA_INVALIDA, "", MESSAGES.TYPE_ERROR);
                   return [/[0-9]/, /[0-9]/, '/',/[0-9]/,/[0-9]/, '/', /[0-9]/, /[0-9]/,  /[0-9]/, /[0-9]/];
              //Validar os dias, caso o mês seja fevereiro:
              } else if (mesAno == "02") {
                var anoo = (ano %4);
                //alert(anoo);
                //Se for ano bissexto, validar o máximo de dias possível para o mês 02:
                if (anoo == 0) {
                  if (diaSemana > 29) {
                    //alert("Dia do nascimento inválido para o mês de fevereiro\nO ano "+anonascimento +" é bissexto!");
                    Swal(MESSAGES.DATA_INVALIDA, "", MESSAGES.TYPE_ERROR);
                    return [/[0-9]/, /[0-9]/, '/',/[0-9]/,/[0-9]/, '/', /[0-9]/, /[0-9]/,  /[0-9]/, /[0-9]/];
                  }
                } else {
                  if (diaSemana > 28) {
                    //alert("Dia do nascimento inválido para o mês de fevereiro\nO ano "+anonascimento+" não é bissexto!");
                    Swal(MESSAGES.DATA_INVALIDA, "", MESSAGES.TYPE_ERROR);
                    return [/[0-9]/, /[0-9]/, '/',/[0-9]/,/[0-9]/, '/', /[0-9]/, /[0-9]/,  /[0-9]/, /[0-9]/];
                  }
                }
              //Se não preencher todos os critérios acima, o mês poderá ser qualquer um entre 01 e 12, exceto mês 02:	
              } else {
                var meses31dias = new Array("01","03","05","07","08","10","12");
                var mes31 = false;
               
                //Varrer as opções para saber se o mês possui 31 dias:
                for (var iLoop = 0; iLoop <= meses31dias.length; iLoop++) {
                  if (mesAno == meses31dias[iLoop]) {
                    mes31 = true;
                  }
                }
               
                if (mes31 == true) {
                  if (diaSemana > 31) {
                    Swal(MESSAGES.DATA_INVALIDA, "", MESSAGES.TYPE_ERROR);
                    return [/[0-9]/, /[0-9]/, '/',/[0-9]/,/[0-9]/, '/', /[0-9]/, /[0-9]/,  /[0-9]/, /[0-9]/];
                  }
                } else {
                  if (diaSemana > 30) {
                    Swal(MESSAGES.DATA_INVALIDA, "", MESSAGES.TYPE_ERROR);
                    return [/[0-9]/, /[0-9]/, '/',/[0-9]/,/[0-9]/, '/', /[0-9]/, /[0-9]/,  /[0-9]/, /[0-9]/];
                  }
                }
              }
              
          return [/[0-9]/, /[0-9]/, '/',/[0-9]/,/[0-9]/, '/', /[0-9]/, /[0-9]/,  /[0-9]/, /[0-9]/];
        } else {
          return [/[0-9]/, /[0-9]/, '/',/[0-9]/,/[0-9]/, '/', /[0-9]/, /[0-9]/,  /[0-9]/, /[0-9]/];
        }
      }
}

