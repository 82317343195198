import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { JWTService } from '../auth/jwt.service';
import { HttpService } from '../shared/http/httpclient.service';

@Injectable()
export class MfaService {

  constructor(private http: HttpService, private jwtService: JWTService) {}

  validarToken(hash: string, token: string) {
    const params = `&hash=${hash}&token=${token}`;
    return this.http.post(`${environment.settings.auth.token}`, params, this.jwtService.getAuthServerHeaders());  }

  reenviarToken(hash: string) {
    return this.http.post(`${environment.settings.auth.mfa_reenviar_uri}/${hash}`, null, this.jwtService.getAuthServerHeaders());
  }

}