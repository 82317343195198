import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { StatusRemessaProcessadaModel } from './status-remessa-processada.model';
import { environment } from '../../../environments/environment';
import { JWTService } from '../../auth/jwt.service';
import { HttpService } from '../../shared/http/httpclient.service';

@Injectable()
export class StatusRemessaProcessadaService {

    constructor(private http: HttpService,
                private jwtService: JWTService) { }

    getRemessas(model: StatusRemessaProcessadaModel): Observable<Number[]> {
        return this.http.postParams(`${environment.settings.api}/remessas/getNumeroRemessa`, {}, this.jwtService.getApiHeaders([]),
            {
                cnpj: model.cnpj,
                area: model.area,
                dataInicio: model.dataIni,
                dataFim: model.dataFim
            }
        );
    }

    getRelatorioRemessas(model: StatusRemessaProcessadaModel): Observable<ArrayBuffer> {
        return this.http.postParamsBuffer(`${environment.settings.api}/remessas/relatorio/estatistica`, {}, this.jwtService.getApiHeaders([]),
            {
                cnpj: model.cnpj,
                area: model.area,
                dataInicio: model.dataIni,
                dataFim: model.dataFim,
                numRemessa: model.numeroRemessa.toString(),
                flagFiltro: model.opt,
                numDocDevedor: model.docDevedor
            }
        );
    }

}