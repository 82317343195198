import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { environment } from '../../../../environments/environment';
import { Contestacao, ContestacaoDetalhe } from './contestacao.model';

import { JWTService } from '../../../auth/jwt.service';
import { AUTHORITIES } from '../../../auth/groups.constants';
import { MotivoContestacaoModel } from '../../../shared/model/motivo-contestacao.model';
import { HttpService } from '../../../shared/http/httpclient.service';

@Injectable()
export class ContestacaoService {

  constructor(
    private http: HttpService,
    private jwtService : JWTService
  ) {}

  listar(cnpjInformante : string): Observable<Contestacao[]> {
    return this.http.post(`${environment.settings.api}/contestacao/${cnpjInformante}`, {}, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_CONTESTACAO]));
  }

  buscar(numContestacao : string): Observable<ContestacaoDetalhe> {
    return this.http.get(`${environment.settings.api}/contestacao/detalhe/${numContestacao}`, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_CONTESTACAO]), null);
  }

  aprovar(numContestacao : string): Observable<Contestacao> {
    return this.http.get(`${environment.settings.api}/contestacao/aprovar/${numContestacao}`, this.jwtService.getApiHeaders([AUTHORITIES.APROVAR_REJEITAR_CONTESTACOES]), null);
  }

  rejeitar(numContestacao : string): Observable<Contestacao> {
    return this.http.get(`${environment.settings.api}/contestacao/rejeitar/${numContestacao}`, this.jwtService.getApiHeaders([AUTHORITIES.APROVAR_REJEITAR_CONTESTACOES]), null);
  }

  detalhes(link : string): Observable<ContestacaoDetalhe> {
    return this.http.get(link, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_CONTESTACAO]), null);
  }

  buscarMotivoContestacao(tipoMotivo: string): Observable<MotivoContestacaoModel[]> {
    return this.http.get(`${environment.settings.api}/contestacao/tipo/${tipoMotivo}`, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_CONTESTACAO]), null);
  }

  aprovarContestacao(numContestacao: number, justificativa: string){
    return this.http.post(`${environment.settings.api}/contestacao/${numContestacao}/tipoBaixa/A?justificativa=${justificativa}`,{}, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_CONTESTACAO]));
  }

  reprovarContestacao(numContestacao: number, justificativa: string, codMotivoBaixa: string){
    return this.http.post(`${environment.settings.api}/contestacao/${numContestacao}/tipoBaixa/R?justificativa=${justificativa}&motivoRejeicao=${codMotivoBaixa}`,{}, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_CONTESTACAO]));
  }

  baixarArquivo(nomeArquivo: string, extensaoArquivo: string){
    return this.http.getBuffer(`${environment.settings.api}/download/contestacao/${nomeArquivo}/extensao/${extensaoArquivo}`, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_CONTESTACAO]), null);
  }

  consultarListaContestacao(cpfCnpjDevedor: string, cnpjInformante: string) {
    let params = {}
    if(cpfCnpjDevedor) {
      params["cpfDevedor"] = cpfCnpjDevedor,
      params["cnpjInformante"] = cnpjInformante
    }
    return this.http.postParams(
      `${environment.settings.api}/contestacao/consultar/lista`,
      {}, 
      this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_CONTESTACAO]),
      params
    );
  }
}
