import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { RemessaModel } from '../../shared/model/remessa.model';
import { RemessaFormModel } from './remessa-form.model';
import { environment } from '../../../environments/environment';
import { JWTService } from '../../auth/jwt.service';
import { AUTHORITIES } from '../../auth/groups.constants';
import { HttpService } from '../../shared/http/httpclient.service';

@Injectable()
export class ConsultarHistoricoRemessaService {

  constructor(private http: HttpService,
              private jwtService: JWTService) { }

  getConsultaHistorico(model: RemessaFormModel): Observable<RemessaModel[]> {
    return this.http.postParams(`${environment.settings.api}/remessas/historico`, {}, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_HISTORICO_PROCESSAMENTO_REMESSA]),
        {
          numDocumento: model.numDocumento,
          tipoRemessa: model.tipoRemessa,
          dataInicio: model.dataInicio,
          dataFinal: model.dataFinal,
          numRemessaInicial: model.numRemessaInicial == null ? '' : model.numRemessaInicial.toString(),
          numRemessaFinal: model.numRemessaFinal == null ? '' : model.numRemessaFinal.toString(),
          status: model.status
        }
      );
  }
}
