import { OnInit, Component } from "@angular/core";
import { SharedService } from "../../../shared/shared.service";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { MyErrorStateMatcher } from "../../../shared/error-matcher-input/error-matcher-input.model";
import { Validator } from "../../../shared/validators/validator";

@Component({
    selector: 'informante-area-dialog',
    templateUrl: 'informante-area-dialog.component.html',
    providers: [SharedService],
})
export class InformanteAreaDialog implements OnInit {

    informanteAreaForm: FormGroup;
    matcher = new MyErrorStateMatcher();

    codAreaControl = new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validator.isNumber()]);
    descAreaControl = new FormControl('', [Validators.required]);

    constructor(public dialogRef: MatDialogRef<InformanteAreaDialog>, private formBuilder: FormBuilder, private sharedService: SharedService) { }

    ngOnInit() {
        this.informanteAreaForm = this.formBuilder.group({
            codArea: this.codAreaControl,
            descArea: this.descAreaControl
        });
    }

    save() {
        this.dialogRef.close(this.informanteAreaForm.value);
    }

    close() {
        this.dialogRef.close();
    }
}