import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../shared/loader.service';
import { ApontamentoHeader, ApontamentoOnline } from '../shared/apontamento.model';
import { ApontamentoService } from '../shared/apontamento.service';
import Swal from 'sweetalert2';
import { MyErrorStateMatcher } from '../../../shared/error-matcher-input/error-matcher-input.model';
import { ConstantsMaskModel } from '../../../shared/constants/constants-mask.model';
import { JWTService } from '../../../auth/jwt.service';
import { Router } from '@angular/router';
import { Validator } from '../../../shared/validators/validator';
import { InformanteModel } from '../../../shared/model/informante.model';
import { UfModel } from '../../../shared/model/uf.model';
import { DominioModel } from '../../../shared/model/dominio.model';
import { ContratoModel } from '../../../shared/model/contrato.model';
import { TelefoneModel } from '../../../shared/model/telefone-model';
import { EmailModel } from '../../../shared/model/email.model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Overlay } from '@angular/cdk/overlay';
import { NegativacaoIdModel } from '../../../shared/model/negativacao-id.model';
import { ConstantsNegativacaoModel } from '../../../shared/constants/constants-negativacao.model';
import { UtilsService } from '../../../shared/utils.service';
import { MESSAGES } from '../../../shared/constants/constants-swal.model';
import { ConstantsAbrev } from '../../../shared/constants/constants-shortening.model';
import { HttpClient } from '@angular/common/http';
import { NegativacaoEnderecoModel } from 'src/app/shared/model/negativacao-endereco.model';
import { ParametrosModel } from 'src/app/shared/model/parametros.model';
import { FlagBooleana } from 'src/app/shared/constants/constants-flagbooleana.model';
import { NaturezaOperacaoModel } from 'src/app/shared/model/natureza-operacao.model';
import { Token } from 'src/app/auth/token.model';
import { AUTHORITIES } from 'src/app/auth/groups.constants';
import { ProdutoModel } from '../shared/produto.model';


@Component({
  selector: 'app-incluir-apontamento',
  templateUrl: './incluir.component.html',
  styleUrls: ['./incluir.component.scss'],
  providers: [ApontamentoService]
})
export class IncluirApontamentoComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private service: ApontamentoService,
    private sharedService: SharedService,
    private loaderService: LoaderService,
    private jwtService: JWTService,
    private router: Router,
    public dialog: MatDialog,
    public overlay: Overlay,
    private utilsService: UtilsService,
    private http: HttpClient
  ) { }

  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();

  incluirApontamentoForm: FormGroup;
  atualizacaoForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  jwt: any;
  isEnabled: boolean = false;
  startDate = new Date();

  header: any;
  ufs: UfModel[] = [];
  dominiosTipoEndereco: DominioModel[] = [];
  naturezasOperacao: NaturezaOperacaoModel[] = [];
  produtos: ProdutoModel[] = [];
  dialogRef: any;
  areas: any[] = [];

  cpfCnpjControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  codAreaControl = new FormControl('', [Validators.required]);
  contratoControl = new FormControl('', [Validators.required]);
  tipoParticipanteControl = new FormControl('', [Validators.required]);
  dadoComplementarControl = new FormControl('', []);
  cpfDevedorControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  natOperacaoControl = new FormControl('', []);
  razaoCredorControl = new FormControl({ value: '', disabled: true }, []);

  // Controls 
  nomeRazaoSocialControl = new FormControl('', [Validators.required]);
  naturezaOperacaoControl = new FormControl('', [Validators.required]);
  datOcorrenciaControl = new FormControl('', [Validators.required]);
  valorDividaControl = new FormControl('', [Validators.required, Validators.min(0.01)]);

  // LogradouroControl
  logradouroControl = new FormControl('', [Validators.required]);
  complementoControl = new FormControl('', []);
  cepControl = new FormControl('', [Validators.required]);
  codBairroControl = new FormControl('', []);
  bairroControl = new FormControl('', [Validators.required]);
  municipioControl = new FormControl('', [Validators.required]);
  ufControl = new FormControl('', [Validators.required]);

  // Endereco Control
  tipoLogradouroControl = new FormControl('', [Validators.required]);
  numLogradouroControl = new FormControl('', [Validators.required, Validators.max(99999)]);
  logradouroDtoControl = new FormGroup({
    logradouro: this.logradouroControl,
    complemento: this.complementoControl,
    cep: this.cepControl,
    codBairro: this.codBairroControl,
    bairro: this.bairroControl,
    municipio: this.municipioControl,
    uf: this.ufControl
  }, [Validators.required]);
  enderecoDTOControl = new FormGroup({
    tipoLogradouro: this.tipoLogradouroControl,
    numLogradouro: this.numLogradouroControl,
    logradouroDto: this.logradouroDtoControl
  });

  cpfControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  moduloControl = new FormControl('', [Validators.required]);
  emailControl = new FormControl('', [Validators.required, Validators.email]);
  emailConfirmarControl = new FormControl('', [Validators.required, Validators.email]);
  apelidoControl = new FormControl('', [Validators.required]);
  numTelefoneControl = new FormControl('', [Validators.required, Validator.isNumber(), Validators.maxLength(9), Validators.minLength(8)]);
  codDddControl = new FormControl('', [Validators.required, Validators.max(99)]);
  tipoUsuarioControl = new FormControl('', [Validators.required]);
  codPerfilControl = new FormControl('', [Validators.required]);
  descAreaControl = new FormControl({ value: '', disabled: true }, []);
  
  contrato: ContratoModel;
  telefones: TelefoneModel[] = [];
  emails: EmailModel[] = [];
  tiposEmail: any[] = [];
  
  maskCpf = ConstantsMaskModel.MASK_CPF;
  maskCep = ConstantsMaskModel.MASK_CEP;
  maskCnpj = ConstantsMaskModel.MASK_CNPJ;
  maskDate = ConstantsMaskModel.MASK_DATE;

  statusInclusaoAvalista: boolean = false;
  exibirArea: boolean = false;
  exibirProduto: boolean = false;
  podeAddCredor: boolean = false;
  exibirDadoComplementar: boolean = false;
  isMultiCobranca: boolean = true;
  // Informante
  informante: InformanteModel = new InformanteModel();

  token: Token;


  ngOnInit() {
    this.incluirApontamentoForm = this.fb.group({
      cpfCnpj: this.cpfCnpjControl,
      contrato: this.contratoControl,
      tipoParticipante: this.tipoParticipanteControl,
      dadoComplementar: this.dadoComplementarControl,
      cpfDevedor: this.cpfDevedorControl,
      codArea: this.codAreaControl,
      natOperacao: this.natOperacaoControl,
      modulo: this.moduloControl

    });

    this.atualizacaoForm = this.fb.group({
      nomeRazaoSocial: this.nomeRazaoSocialControl,
      natOperacao: this.naturezaOperacaoControl,
      datOcorrencia: this.datOcorrenciaControl,
      valor: this.valorDividaControl,
      endereco: this.enderecoDTOControl
    });

    this.token = this.jwtService.getObject('auth_token') as Token;
    this.podeAddCredor = this.sharedService.hasPermission(this.token, [AUTHORITIES.RETAGUARDA_MULTICOBRANCA]);
    this.token = this.jwtService.decode(this.token);

    this.getTipoLogradouro();
    this.getNaturezaOperacaoDesc();
    this.carregarTiposEmail();
    this.getUfs();
    

  }

  avancar() {
    if (this.incluirApontamentoForm.invalid)
      return;
    if (this.natOperacaoControl.value)
      this.naturezaOperacaoControl.setValue(this.natOperacaoControl.value);
    this.verificarSeDividaExiste();
  }

  verificarSeDividaExiste() {
    let id: NegativacaoIdModel = this.newIdNegativacao();
    id.numContrato = id.numContrato.replace("/", "<2f>");
    this.loaderService.display(true);
    this.sharedService.getNegativacaoById(id)
      .subscribe(
        negativacao => {
          this.loaderService.display(false);
          if (negativacao) {
            this.isEnabled = true;
            this.nomeRazaoSocialControl.setValue(negativacao.nomeRazaoSocial);
            this.naturezaOperacaoControl.setValue(negativacao.natOperacao);
            Object.keys(negativacao._links).forEach(key => {
              let url = this.utilsService.converterLinkDto(negativacao._links[key].href);

              switch (key) {
                case "Endereços":
                  this.getEnderecos(url);
                  break;
                case "E-mails":
                  this.getEmails(url);
                  break;
                case "Telefones":
                  this.getTelefones(url);
                  break;
              }

            })
          }
        },
        errors => {
          if (errors.status != 404) {
            Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
            this.loaderService.display(false);
            return;
          }
          this.loaderService.display(false);
          this.consultarNegativacaoParticipantePrincipal();
        }
      );
  }

  zeroAEsquerda() {
    if (!this.cpfCnpjControl.value)
      return;
    this.cpfCnpjControl.setValue(this.utilsService.padLeftWithZeros(this.cpfCnpjControl.value, 14));
  }

  zeroAEsquerdaCpf() {
    const cpf = this.cpfDevedorControl.value;
    if (!cpf)
      return;
    this.cpfDevedorControl.setValue(this.utilsService.padLeftWithZeros(cpf, 11));
  }


  getEmails(link: string) {
    this.http.get(link, {
      headers: this.jwtService.getApiHeaders([])
    }).subscribe((emails: EmailModel[]) => {
      this.emails = emails;
    });
  }

  getTelefones(link: string) {
    this.http.get(link, {
      headers: this.jwtService.getApiHeaders([])
    }).subscribe((telefones: TelefoneModel[]) => {
      this.telefones = telefones;
    });
  }

  getEnderecos(link: string) {
    this.http.get(link,
      {
        headers: this.jwtService.getApiHeaders([])
      }).subscribe((enderecos: NegativacaoEnderecoModel[]) => {
        let endereco: NegativacaoEnderecoModel = enderecos[0];
        this.cepControl.setValue(" ");
        this.cepControl.setValue(this.utilsService.formatarCep(endereco.cep));
        this.ufControl.setValue(endereco.uf);
        this.municipioControl.setValue(endereco.cidade);
        this.logradouroControl.setValue(endereco.logradouro);
        this.numLogradouroControl.setValue(endereco.numero.toString());
        this.bairroControl.setValue(endereco.bairro);
        this.complementoControl.setValue(endereco.complemento);
      },
        errors => {
          console.log(errors);
        }
      );
  }

  newIdNegativacao() {
    return new NegativacaoIdModel(
      this.cpfCnpjControl.value,
      this.contratoControl.value,
      this.tipoParticipanteControl.value,
      this.utilsService.getTipoPessoa(this.cpfDevedorControl.value),
      this.cpfDevedorControl.value,
      this.natOperacaoControl.value != null ? this.natOperacaoControl.value : '',
      this.moduloControl.value != null ? this.moduloControl.value : '',
      this.codAreaControl.value,
      this.dadoComplementarControl.value)
  }

  consultarNegativacaoParticipantePrincipal() {
    let id: NegativacaoIdModel = this.newIdNegativacao();
    id.tipoParticipante = ConstantsNegativacaoModel.PARTICIPANTE_PRINCIPAL;
    this.loaderService.display(true);
    this.sharedService.getNegativacoesByContrato(
      this.cpfCnpjControl.value,
      this.codAreaControl.value,
      this.contratoControl.value.replace("/", "<2f>"),
      ConstantsNegativacaoModel.PARTICIPANTE_PRINCIPAL,
      this.naturezaOperacaoControl.value != null ? this.naturezaOperacaoControl.value : '',
      this.dadoComplementarControl.value != null ? this.dadoComplementarControl.value : '')
      .subscribe(
        negativacoes => {
          this.loaderService.display(false);
          if (this.tipoParticipanteControl.value == ConstantsNegativacaoModel.PARTICIPANTE_PRINCIPAL) {
            if (negativacoes.length == 0) {
              this.isEnabled = true;
              return;
            }
            let negativacao = negativacoes[0];
            if (negativacao.cpfCnpj != this.cpfDevedorControl.value) {
              this.isEnabled = false;
              Swal(MESSAGES.CONTRATO_JA_CADASTRADO, "", MESSAGES.TYPE_ERROR);
            } else {
              this.isEnabled = true;
            }
            return;
          } else if (this.tipoParticipanteControl.value == ConstantsNegativacaoModel.PARTICIPANTE_AVALISTA) {
            if (negativacoes.length > 0) {
              let negativacao = negativacoes[0];
              this.naturezaOperacaoControl.setValue(negativacao.natOperacao);
              this.sharedService.getOcorrencias(negativacao.idNegativacao)
                .subscribe(
                  ocorrencias => {
                    let totalValorDivida: number = 0;
                    let dataInicialDivida: string = "";
                    let ocorrenciaAtiva: boolean = false;
                    ocorrencias.forEach(ocorrencia => {
                      totalValorDivida += ocorrencia.valor;
                      if (dataInicialDivida == "") {
                        dataInicialDivida = ocorrencia.datOcorrencia
                      }
                      if (ocorrencia.sitNegativado == 'A') {
                        ocorrenciaAtiva = true;
                      }
                    });

                    if (!ocorrenciaAtiva) {
                      Swal(MESSAGES.DIVIDA_PARTICIPANTE_PRINCIPAL_INATIVA, "", MESSAGES.TYPE_ERROR);
                      this.statusInclusaoAvalista = false;
                      this.isEnabled = false;
                      return;
                    }

                    this.datOcorrenciaControl.setValue(dataInicialDivida);
                    this.valorDividaControl.setValue(totalValorDivida);
                    this.isEnabled = true;
                    this.statusInclusaoAvalista = true;
                  },
                  errors => {
                    this.loaderService.display(false);
                  }
                )
            } else {
              Swal(MESSAGES.DIVIDA_PARTICIPANTE_PRINCIPAL_NAO_ENCONTRADA, "", MESSAGES.TYPE_ERROR);
              this.statusInclusaoAvalista = false;
              this.isEnabled = false;
            }
          }
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
          this.statusInclusaoAvalista = false;
          this.isEnabled = false;
        }
      );
  }

  avalistaIsOk() {
    if (this.tipoParticipanteControl.value == ConstantsNegativacaoModel.PARTICIPANTE_PRINCIPAL)
      return true;
    else if (this.tipoParticipanteControl.value == ConstantsNegativacaoModel.PARTICIPANTE_AVALISTA && !this.statusInclusaoAvalista)
      return false;
    return true;
  }

  getCredor() {

    this.zeroAEsquerda();
    if (this.cpfCnpjControl.invalid)
      return;
    let numDocumento = this.cpfCnpjControl.value;
    this.sharedService.getInformante(numDocumento)
      .subscribe(
        informante => {
          this.razaoCredorControl.setValue(informante.nomeRazaoSocial);
          this.getAreas(numDocumento.toString());
          this.getProduto(numDocumento.toString());
          this.verificaExibeDadoComplementar(numDocumento.toString())
          this.informante = informante;
          this.utilsService.updateValidatorForNatOperacaoChave(this.incluirApontamentoForm, this.natOperacaoControl, this.informante);
        },
        error => {
          if (error.error.message === "CNPJ da Empresa não encontrado" && this.podeAddCredor) {
            Swal({
              title: MESSAGES.REDIRECIONAMENTO_CADASTRO_CREDOR,
              text: this.sharedService.tratarErro(error),
              type: MESSAGES.TYPE_ERROR,
              showCancelButton: true
            })
              .then((result) => {
                if (result.value) {
                  this.router.navigate(['cadastros/cliente'])
                }
              });

          } else if ((error.status === 0) || (error.statusText === 'Unknown Error')) {
            Swal(MESSAGES.ERRO_DESCONHECIDO, "", MESSAGES.TYPE_ERROR);
          } else {
            Swal(this.sharedService.tratarErro(error), "", MESSAGES.TYPE_ERROR);
            this.limparInformante();
          }
        }
      );
  }

  getRazaoSocialDevedor() {

    if (this.cpfDevedorControl.value.length === 11) {
      this.zeroAEsquerdaCpf();
    }
    if (this.cpfDevedorControl.invalid) {
      Swal(MESSAGES.DADO_INVALIDO, "", MESSAGES.TYPE_ERROR);
      return;
    }
    let cpf = this.cpfDevedorControl.value;
    this.sharedService.getNegativacoesByInformante(cpf.toString(), this.token.numDocumento)
      .subscribe(
        response => {
          if (response.ativas.length == 0) {

            this.sharedService.getNegativacaoByDoc(cpf.toString())
              .subscribe(
                response => {
                  if (response.ativas.length == 0) {
                    this.sharedService.getPessoaByDoc(cpf.toString())
                      .subscribe(
                        response => {
                          if ((response.nomeRazaoSocial != "") &&
                            !response.nomeRazaoSocial.includes("Nome não encontrado"))
                            this.nomeRazaoSocialControl.setValue(response.nomeRazaoSocial);
                        }
                      );
                  } else
                    this.nomeRazaoSocialControl.setValue(response.ativas[0].nomeRazaoSocial);
                }
              );
          } else
            this.nomeRazaoSocialControl.setValue(response.ativas[0].nomeRazaoSocial);
        }
      );
  }

  limparForm() {
    this.cpfCnpjControl.reset();
    this.contratoControl.reset();
    this.cpfDevedorControl.reset();
    this.codAreaControl.reset();
    this.cepControl.reset();
    this.datOcorrenciaControl.reset();

    this.codAreaControl.setValue("0000");
    this.isEnabled = false;
    this.telefones = [];
    this.emails = [];
    this.areas = [];
    this.statusInclusaoAvalista = false;
    this.exibirArea = false;
    this.exibirProduto = false;
    this.informante = new InformanteModel();
    this.razaoCredorControl.reset();
    this.atualizacaoForm.reset();
    this.incluirApontamentoForm.reset();
    this.utilsService.updateValidatorForNatOperacaoChave(
      this.incluirApontamentoForm, this.natOperacaoControl, this.informante);
  }

   limparInformante() {
    this.cpfCnpjControl.reset();
    this.razaoCredorControl.reset();
    this.descAreaControl.reset();
    this.dadoComplementarControl.reset();
    this.areas = [];
    this.isEnabled = false;
    this.informante = new InformanteModel();
    this.exibirDadoComplementar = false;
    this.utilsService.updateValidatorForNatOperacaoChave(this.incluirApontamentoForm, this.natOperacaoControl, this.informante);
  }

  getAreas(numDocumento: string) {
    this.sharedService.getAreas(numDocumento)
      .subscribe(
        informanteArea => {
          this.areas = informanteArea;
          if (this.areas.length > 1) {
            this.exibirArea = true;
        
          } else if (this.areas.length == 1) {
            this.codAreaControl.setValue(this.areas[0].codArea);
            this.exibirArea = true;
          }
        },
        error => {
          Swal(this.sharedService.tratarErro(error), "", MESSAGES.TYPE_ERROR);
          this.exibirArea = false;
        }
      );
  }

  verificaExibeDadoComplementar(numDocumento: string) {
    this.service.verificaExibeDadoComplementar(numDocumento)
      .subscribe(
        (parametroDto: ParametrosModel) => {
          this.loaderService.display(false);

          if (parametroDto.valParametro == FlagBooleana.SIM) {
            this.exibirDadoComplementar = true;
            this.dadoComplementarControl.enable();
          } else {
            this.exibirDadoComplementar = false;
            this.dadoComplementarControl.disable();
            return;
          }
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }


  incluir(model: ApontamentoOnline) {
    this.loaderService.display(true);
    const header = this.incluirApontamentoForm.value as ApontamentoHeader;

    // Substitui dos campos de documento os caracteres (. / -)
    const cpfCnpj = header.cpfCnpj.replace(/[\-/.]/gi, "");
    const cpfDevedor = header.cpfDevedor.replace(/[\-/.]/gi, "");

    const codArea = this.incluirApontamentoForm.value.codArea != null ?
      this.incluirApontamentoForm.value.codArea : header.codArea;

    model.contrato = new ContratoModel(
      cpfCnpj,
      header.contrato.replace("/", "<2f>"),
      cpfCnpj.length <= 11 ? 'F' : 'J',
      codArea,
      header.tipoParticipante,
      cpfDevedor.length <= 11 ? 'F' : 'J',
      cpfDevedor,
      header.natOperacao = header.natOperacao != null && header.natOperacao != '' ? header.natOperacao : this.naturezaOperacaoControl.value,
      header.dadoComplementar = header.dadoComplementar != null && header.dadoComplementar != '' ? header.dadoComplementar : this.dadoComplementarControl.value,
      this.token.numDocumento
    );
    model.telefones = this.telefones;
    model.emails = this.emails;
    model.emissaoBoleto = "N";
    model.tipoComunicado = "F";
    model.datOcorrencia = this.datOcorrenciaControl.value;
    model.modulo = this.moduloControl.value;

    this.service.incluir(model)
      .subscribe(
        (result: any) => {
          this.loaderService.display(false);

          // Identifica a primeira mensagem do retorno do serviço (map de erros)
          var consistencias = Object.keys(result.listaConsistencias).map(function (key) {
            return result.listaConsistencias[key];
          });

          if (consistencias[0] == "Processo Finalizado com Sucesso") {
            this.jwtService.removeObjectLocalStorage('header');
            Swal(MESSAGES.NEGATIVACAO_REALIZADA_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
            this.limparForm();
          } else {
            Swal(MESSAGES.OCORREU_SEGUINTES_ERROS + consistencias[0], "", MESSAGES.TYPE_ERROR);
          }
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  isNatOperacaoChave() {
    return this.utilsService.isNatOperacaoChave(this.informante);
  }

  carregarTiposEmail() {
    this.sharedService.getDominioByCodigo('TIPO_EMAIL')
      .subscribe(
        tiposEmail => {
          this.tiposEmail = tiposEmail
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR)
        }
      )
  }

  getEnderecoByCep(cep: string) {
    if (this.cepControl.invalid) {
      return;
    }
    this.sharedService.getEnderecoByCep(cep)
      .subscribe(
        endereco => {
          if (endereco.logradouro) {
            this.logradouroControl.setValue(this.utilsService.removeTipoLogradouroFromLogradouro(endereco.logradouro));
            let bairro = endereco.bairro;

            if (bairro && bairro.length > 30) {
              let p: string[] = bairro.split(" ");
              let abrev: ConstantsAbrev = new ConstantsAbrev();
              p.forEach(i => {
                if (abrev.SHORTENING[i]) {
                  bairro = bairro.replace(i, abrev.SHORTENING[i]);
                }
              });

              if (bairro.length > 30)
                bairro = bairro.substring(0, 29);
            }

            this.bairroControl.setValue(bairro);
            this.municipioControl.setValue(endereco.municipio);
            this.ufControl.setValue(endereco.uf);
            let tipoLogradouro = this.utilsService.getTipoLogradouroFromLogradouro(endereco.logradouro);
            if(tipoLogradouro || tipoLogradouro === null && this.tipoLogradouroControl.value == '') {
              this.tipoLogradouroControl.setValidators(Validators.required);
              this.tipoLogradouroControl.setErrors({ required: true });
              this.tipoLogradouroControl.markAsDirty();
            }
            this.dominiosTipoEndereco.forEach(dominio => {
              if (tipoLogradouro.toLowerCase() === dominio.descricao.toLowerCase()) {
                this.tipoLogradouroControl.setValue(dominio.descricao);
              }
            })
          }
        }, errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
          this.enderecoDTOControl.reset();
          this.cepControl.setValue(" ");
        }
      );
  }

  getUfs() {
    this.sharedService.getUfs()
      .subscribe(
        ufs => {
          this.ufs = ufs;
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getTipoLogradouro() {
    this.sharedService.getDominioByCodigo('TIPO_LOGRADOURO')
      .subscribe(
        dominios => {
          this.dominiosTipoEndereco = dominios;
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getNaturezaOperacaoDesc() {
    this.sharedService.getNaturezaOperacaoDesc()
      .subscribe(
        naturezasOperacao => {
          this.naturezasOperacao = naturezasOperacao
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR)
        }
      )
  }


   getProduto(numDocumento: string) {
    this.sharedService.getProduto(numDocumento)
      .subscribe(
        produto => {
          this.produtos = produto
          this.exibirProduto = true;
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR)
          this.exibirProduto = false;

        }
      )
  }

  openDialogAdicionarTelefone() {
    this.dialogRef = this.dialog.open(AdicionarTelefoneDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '50%'
    });

    this.dialogRef.afterClosed().subscribe((result: TelefoneModel) => {
      if (result) {
        if (!this.checkTelefoneExists(result)) {
          if (this.checkDDD(result.ddd)) {
            this.telefones.push(result);
            this.telefones = [...this.telefones];
          } else {
            Swal(MESSAGES.DDD_INVALIDO, "", MESSAGES.TYPE_ERROR);
          }
        } else {
          Swal(MESSAGES.TELEFONE_JA_ADICIONADO, "", MESSAGES.TYPE_ERROR);
        }
      }
    });
  }

  checkDDD(ddd: string): boolean {
    return this.utilsService.getListaDDDsValidos().filter(a => a === ddd).length > 0;
  }

  checkTelefoneExists(telefone: TelefoneModel) {
    let check: boolean = false;
    this.telefones.forEach(tel => {
      if (tel.ddd == telefone.ddd && tel.telefone == telefone.telefone) {
        check = true;
        return;
      }
    });
    return check;
  }

  openDialogAdicionarEmail() {
    this.dialogRef = this.dialog.open(AdicionarEmailDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '50%',
      data: this.tiposEmail
    });

    this.dialogRef.afterClosed().subscribe((result: EmailModel) => {
      if (result) {
        if (this.checkEmailExists) {
          this.emails.push(result);
          this.emails = [...this.emails];
        } else {
          Swal(MESSAGES.EMAIL_JA_ADICIONADO, "", MESSAGES.TYPE_ERROR);
        }
      }
    });
  }

  checkEmailExists(email: EmailModel) {
    let check: boolean = false;
    this.emails.forEach(e => {
      if (e.email == email.email) {
        check = true;
        return;
      }
    });
    return check;
  }

  openDialogRemoverTelefone(model: TelefoneModel) {
    this.dialogRef = this.dialog.open(RemoverTelefoneDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '50%',
      data: model
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index: number = this.telefones.indexOf(result);
        if (index !== -1) {
          this.telefones.splice(index, 1);
        }
        this.telefones = [...this.telefones];
      }
    });
  }

  openDialogRemoverEmail(model: EmailModel) {
    this.dialogRef = this.dialog.open(RemoverEmailDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '50%',
      data: model
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index: number = this.emails.indexOf(result);
        if (index !== -1) {
          this.emails.splice(index, 1);

        }
        this.emails = [...this.emails];
      }
    });
  }

  getInformanteArea(cnpj: string, area: string) {
    if (this.codAreaControl.invalid)
      return;
    this.sharedService.getInformanteArea(cnpj, area)
      .subscribe(
        informanteArea => {
          this.descAreaControl.setValue(informanteArea.descArea);
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  deleteAreaDefault() {
    this.areas.forEach((a: any) => {
      if (a.codArea == '0000')
        this.areas.splice(this.areas.indexOf(a), 1);
    }
    );
  }

}



@Component({
  selector: 'adicionar-telefone-dialog',
  templateUrl: '../../../shared/dialogs/adicionar-telefone-dialog.html'
})
export class AdicionarTelefoneDialog implements OnInit {

  matcher = new MyErrorStateMatcher();
  adicionarTelefoneForm: FormGroup;

  dddControl = new FormControl('', [Validators.required, Validators.min(2), Validator.isNumber()]);
  telefoneControl = new FormControl('', [Validators.required, Validators.min(8), Validator.isNumber()]);

  constructor(
    public dialogRef: MatDialogRef<AdicionarTelefoneDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.adicionarTelefoneForm = this.fb.group({
      ddd: this.dddControl,
      telefone: this.telefoneControl
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  adicionar(model: TelefoneModel): void {
    if (this.adicionarTelefoneForm.invalid)
      return;
    this.dialogRef.close(model);
  }

}



@Component({
  selector: 'adicionar-email-dialog',
  templateUrl: '../../../shared/dialogs/adicionar-email-dialog.html'
})
export class AdicionarEmailDialog implements OnInit {

  matcher = new MyErrorStateMatcher();
  adicionarEmailForm: FormGroup;

  tiposEmail: any[] = [];
  tipoControl = new FormControl('', [Validators.required]);
  emailControl = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    public dialogRef: MatDialogRef<AdicionarEmailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service: SharedService
  ) { }

  ngOnInit() {
    this.adicionarEmailForm = this.fb.group({
      tipo: this.tipoControl,
      email: this.emailControl
    });

    this.tiposEmail = [...this.data];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  adicionar(model: EmailModel): void {
    if (this.adicionarEmailForm.invalid)
      return;
    this.dialogRef.close(model);
  }

}



@Component({
  selector: 'remover-telefone-dialog',
  templateUrl: '../../../shared/dialogs/remover-telefone-dialog.html'
})
export class RemoverTelefoneDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RemoverTelefoneDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  remover(): any {
    this.dialogRef.close(this.data);
  }

}



@Component({
  selector: 'remover-email-dialog',
  templateUrl: '../../../shared/dialogs/remover-email-dialog.html'
})
export class RemoverEmailDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RemoverEmailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  remover(): any {
    this.dialogRef.close(this.data);
  }


}