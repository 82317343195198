import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alterar',
  templateUrl: './alterar.component.html',
})
export class AlterarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
