import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ConsultarComunicado } from './shared/consultar-comunicado.model'
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ConsultarComunicadoService} from './shared/consultar-comunicado.service'
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material';
import { SharedService } from 'src/app/shared/shared.service';
import { LoaderService } from 'src/app/shared/loader.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { JWTService } from 'src/app/auth/jwt.service';
import { MyErrorStateMatcher } from 'src/app/shared/error-matcher-input/error-matcher-input.model';
import { ConstantsMaskModel } from 'src/app/shared/constants/constants-mask.model';
import { MESSAGES } from 'src/app/shared/constants/constants-swal.model';
import { AUTHORITIES } from 'src/app/auth/groups.constants';
import { Token } from 'src/app/auth/token.model';

@Component({
  selector: 'app-consultar-comunicados',
  templateUrl: './consultar-comunicados.component.html',
  styleUrls: ['./consultar-comunicados.component.scss'],
  providers: [ConsultarComunicadoService]
})
export class ConsultarComunicadosComponent implements OnInit {
  @ViewChild('cpfCnpjlnput') cpfCnpjlnput: ElementRef;
  @ViewChild('cnpjEmpresa') cnpjEmpresa: ElementRef;
  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    private loaderService: LoaderService,
    private service: ConsultarComunicadoService,
    private utils: UtilsService,
    public dialog: MatDialog,
    public overlay: Overlay,
    private jwtService: JWTService,
    private router: Router,
  ) { }
  jwt: Token;
  matcher = new MyErrorStateMatcher();
  consultarComunicadosForm: FormGroup;
  comunicados: ConsultarComunicado[] = [];
  numComunicadoControl = new FormControl('', []);
  numComunicadoExternoControl = new FormControl('', []);
  cpfCnpjControl =new FormControl('', []);
  nomeRazaoSocialControl = new FormControl({value : '', disabled:true}, []);
  cnpjCredorControl = new FormControl('', []);
  contratoControl = new FormControl('', []);
  nomeCredorControl = new FormControl({value : '', disabled:true}, []);
  maskCpf = ConstantsMaskModel.MASK_CPF;
  maskCnpj = ConstantsMaskModel.MASK_CNPJ;
  maskDate = ConstantsMaskModel.MASK_DATE;
  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();
  userData: Token;

  ngOnInit() {
    this.consultarComunicadosForm = this.fb.group({
      numComunicado: this.numComunicadoControl,
      numComunicadoExterno: this.numComunicadoExternoControl,
      cpfCnpj: this.cpfCnpjControl,
      nomeRazaoSocial: this.nomeRazaoSocialControl,
      cnpjCredor: this.cnpjCredorControl,
      nomeCredor: this.nomeCredorControl,
      contrato: this.contratoControl
    });

    this.jwt = this.jwtService.getObject('auth_token') as Token;
    this.userData = this.jwtService.getCookie('auth_token') as Token;
  }

  buscarNomeDevedor(){
    this.zeroAEsquerda(this.cpfCnpjControl);
    if(this.cpfCnpjControl.value == '' || this.cpfCnpjControl.value == null){
      this.cpfCnpjControl.reset();
      this.nomeRazaoSocialControl.setValue("");
      return;
    }
    let documentoSemMascara = this.cpfCnpjControl.value.replace(/_/g, "");
    
    let nome = 'Nome não encontrado na base';
    this.comunicados = [];
    this.sharedService.getNegativacaoNomeDevedor(documentoSemMascara)
      .subscribe(
        (result) => {
          if (result.nomeRazaoSocial) {
            nome = result.nomeRazaoSocial;
          }
          this.nomeRazaoSocialControl.setValue(nome);
        },
        errors => {
          if(errors.status == 404){
            this.nomeRazaoSocialControl.setValue(nome);
            this.zeroAEsquerda(this.cpfCnpjControl);
            return;
          }
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getCredor() {
    if(this.cnpjCredorControl.value == '' || this.cnpjCredorControl.value == null){
      this.cnpjCredorControl.reset();
      this.nomeCredorControl.setValue("");
      return;
    }
    this.zeroAEsquerda(this.cnpjCredorControl);
    let numDocumento = this.cnpjCredorControl.value;
    this.sharedService.getInformante(numDocumento)
      .subscribe(
        informante => {
          this.nomeCredorControl.setValue(informante.nomeRazaoSocial);        
        },
        error => {
            Swal(this.sharedService.tratarErro(error), "", MESSAGES.TYPE_ERROR);
            this.nomeCredorControl.setValue('');
        }
      );
  }

  listar(model: ConsultarComunicado) {
    if  (model.contrato  ){
      if(!model.cnpjCredor){
        Swal("Para consulta de contrato será necessário preencher CPNJ do Credor.")
        return ;
      }
    }
    if  (model.cnpjCredor  ){
      if(!model.contrato){
        Swal("Para consulta de Credor será necessário preencher contrato.")
        return ;
      }
    }
    if(this.validaCamposRequerido(model) == false){
      Swal("Nenhum campo foi preechido para busca do comunicado")
      return ;
    }
    this.validaCamposNaoObrigatorios(model);
    this.loaderService.display(true);
    this.comunicados = [];
    this.service.listar(model)
      .subscribe(
        (result: any) => {
          this.loaderService.display(false);
          if (result.length == 0) {
            Swal(MESSAGES.NAO_RETORNOU_RESULTADOS, "", MESSAGES.TYPE_ERROR);
          } else {
            this.comunicados = result;      
          }
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  limpar(f: FormGroup) {
  
    this.comunicados = [];
    Object.keys(f.controls).forEach(key => {
      f.get(key).reset();
      f.get(key).patchValue("") ;    
    });
    f.controls['cpfCnpj'].setErrors({'incorrect': true});
  }

  getNumber(cpfCnpjValue) {
    let cpfCnpj = cpfCnpjValue.match(/\d/g);
    let cpfCnpjLength = 0;
    if (cpfCnpj) {
      cpfCnpjLength = cpfCnpj.join('').length;
    }
    return cpfCnpjLength
  }

  zeroAEsquerda(control :FormControl){
    if(!control.value)
      return;
    if(control.value.replace(/_/g, '').length > 11){
      control.setValue(this.utils.padLeftWithZeros(control.value, 14));
    } else {
      control.setValue(this.utils.padLeftWithZeros(control.value, 11));
    }
  }

  canRelatorioSubsidio(){
    return this.sharedService.hasPermission(this.jwt, [AUTHORITIES.CONSULTAR_COMUNICADOS]);
  }

  gerarRelatorioSubsidio(numComunicado: string,isSumula: boolean){
    this.loaderService.display(true);
    this.service.gerarRelatorioSubsidio(numComunicado,isSumula).subscribe(
      pdf => {
        this.loaderService.display(false);
        let file = new Blob([pdf], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      },
      errors => {
        this.loaderService.display(false);
        if (errors.status == 409)
          Swal(MESSAGES.ERRO_GERACAO_RELATORIO, "", MESSAGES.TYPE_ERROR);
        else
          Swal(this.sharedService.tratarErroMediaNotJson(errors), "", MESSAGES.TYPE_ERROR);
      }
    );
  }

  validaCamposNaoObrigatorios(model: ConsultarComunicado): ConsultarComunicado {
    if (!model.valorDebito)
      model.valorDebito = '';
    return model;
  }

  validaCamposRequerido(model: ConsultarComunicado) {
    var validaCampos = false;
    if  (model.cpfCnpj){
      validaCampos = true;
    }
    if  (model.numComunicado){
      validaCampos = true;
    }
    if  (model.numComunicadoExterno){
      validaCampos = true;
    }
    if  (model.contrato && model.cnpjCredor){
      validaCampos = true;
    }   
    return validaCampos;
  }

  exibirApontamento(apontamentoId) {
    this.router.navigateByUrl(`/servicos/apontamento/detalheApontamento/${apontamentoId}?datOcorrencia=null&valor=null&sitNegativado=null`);
  }

  canGerarPDF(){
    return this.sharedService.hasPermission(this.jwt, [AUTHORITIES.CONSULTAR_COMUNICADO_TEMPLATE]);
  }

  gerarPDF(idNegativacao: string, numComunicado: string) {
    this.loaderService.display(true);
    this.service.getPDF(idNegativacao,numComunicado).subscribe(
      pdf => {
        this.loaderService.display(false);
        let file = new Blob([pdf], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      },
      errors => {
        this.loaderService.display(false);
          Swal(this.sharedService.tratarErroMediaNotJson(errors), "", MESSAGES.TYPE_ERROR);
      }
    );
  }
}
