import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-pep',
  templateUrl: './pep.component.html'
  
})
export class PepComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
