import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UtilsService } from '../../../shared/utils.service';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../shared/loader.service';
import { Contestacao } from '../shared/contestacao.model';
import { ContestacaoService } from '../shared/contestacao.service';
import Swal from 'sweetalert2';
import { ConstantsMaskModel } from '../../../shared/constants/constants-mask.model';
import { JWTService } from '../../../auth/jwt.service';
import { Router } from '@angular/router';
import { MESSAGES } from '../../../shared/constants/constants-swal.model';

@Component({
  selector: 'app-consultar-contestacao',
  templateUrl: './consultar-contestacao.component.html',
  providers: [ContestacaoService]
})
export class ConsultarContestacaoComponent implements OnInit {

  constructor(private fb: FormBuilder, 
              private service: ContestacaoService, 
              private sharedService: SharedService,
              private loaderService: LoaderService,
              private jwtService: JWTService,
              private router: Router,
              private utils: UtilsService
            ) { }

  consultarContestacaoForm: FormGroup;
  jwt : any;

  contestacoes: Contestacao[] = [];
  temp: Contestacao[] = [];

  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();
  
  filterDataIni : Date;
  filterDataFim : Date;

  
  cpfDevedorControl = new FormControl('', []);
  cnpjEmpresaControl = new FormControl('', []);
  dataIniControl = new FormControl('', []);
  dataFimControl = new FormControl('', []);
  valorIniControl = new FormControl('', []);
  valorFimControl = new FormControl('', []);
  maskCpf = ConstantsMaskModel.MASK_CPF;
  maskCnpj = ConstantsMaskModel.MASK_CNPJ;
  maskDate = ConstantsMaskModel.MASK_DATE;

  ngOnInit() {
    this.consultarContestacaoForm = this.fb.group({
      cpfDevedor: this.cpfDevedorControl,
      cnpjEmpresa: this.cnpjEmpresaControl,
      dataIni: this.dataIniControl,
      dataFim: this.dataFimControl,
      valorIni: this.valorIniControl,
      valorFim: this.valorFimControl
    });

    this.jwt = this.jwtService.getObject('auth_token');
    this.listar();
  }
 
  listar() {
    this.loaderService.display(true);
    this.service.listar(this.jwt.numDocumento)
      .subscribe(
        (result : Contestacao[]) => {
          this.loaderService.display(false);
          if(result.length == 0) {
            Swal(MESSAGES.NAO_HA_CONTESTACOES, "", MESSAGES.TYPE_SUCCESS);
          } else {
            this.contestacoes = result;
            this.temp = result;
          }
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }
  aprovarOuRejeitar(model : Contestacao) {
    this.router.navigate(['servicos/contestacao/aprovarRejeitarContestacao'], { queryParams: { numContestacao: model.numContestacao } });
  }

  clearFilters(filter : string) {
    if(filter == 'c') {
      this.consultarContestacaoForm.get('dataIni').setValue(' ');
      this.consultarContestacaoForm.get('dataFim').setValue(' ');
    } else {
      this.consultarContestacaoForm.get('cpfDevedor').reset();
      this.consultarContestacaoForm.get('cnpjEmpresa').reset();
    }
  }
  formatToCurrency(value: number): string {
    return this.sharedService.formatToCurrency(value);
  }
  maskACpf(cpf: number) {
    if (!cpf)
      return;
    return this.sharedService.maskACpf(cpf.toString());
  }
  maskACnpj(cnpj: number) {
    if (!cnpj)
      return;
    return this.sharedService.maskACpf(cnpj.toString());
  }

}
