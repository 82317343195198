import { ValidatorFn, AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { KzCpfValidator } from "./cpf.validator";
import { KzCnpjValidator } from "./cnpj.validator";

export class Validator {
    static equalsTo(nameOfControl: string): ValidatorFn {
        return (control: FormControl): { [key: string]: any } | null => {
            let group = control.parent as FormGroup;
            if (!group) {
                return;
            }
            let value = group.get(nameOfControl).value;
            if (value !== control.value) {
                return { valuesNotMatch: true }
            } else {
                return undefined
            }
        }
    }

    static documentIsValid(): ValidatorFn {
        return (control: FormControl): { [key: string]: any } | null => {
            if (this.cpfCnpjValido(control.value)) {
                return undefined;
            }
            return { cpfCnpj: true };
        }
    }

    static isDate(): ValidatorFn {
        return (control: FormControl): { [key: string]: any } | null => {
            if (control.value && control.value.length === 10) {
                var regex_date = /^\d{2}\/\d{1,2}\/\d{4}$/;
                if (!regex_date.test(control.value)) {
                    return { isDate: true };
                }

                let temp: string[] = control.value.split("/");

                let day = parseInt(temp[0], 10);
                let month = parseInt(temp[1], 10);
                let year = parseInt(temp[2], 10);

                if (year < 1000 || year > 3000 || month == 0 || month > 12) {
                    return { isDate: true };
                }

                var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

                if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
                    monthLength[1] = 29;
                }

                if (day > 0 && day <= monthLength[month - 1]) {
                    return null;
                } else {
                    return { isDate: true };
                }
            }

            return { isDate: true };
        }
    }

    static isInteger(): ValidatorFn {
        return (control: FormControl): { [key: string]: any } | null => {
            if (Number.isInteger(control.value)) {
                return null;
            }
            return { isInteger: true };
        }
    }

    static isNumber(): ValidatorFn {
        return (control: FormControl): { [key: string]: any } | null => {
            if (control.value && control.value.match(/\D/g)) {
                return { isNumber: true };
            }
            return null;
        }
    }

    public static cpfCnpjValido(cpfCnpj: any): boolean {
        if (cpfCnpj === '') {
            return true;
        }
        if (!cpfCnpj) {
            return false;
        }
        const cpfCnpjValor = cpfCnpj.replace(/\D/g, '');
        let cpfCnpjValido = false;

        if (cpfCnpjValor.length === 11) {
            cpfCnpjValido = KzCpfValidator.cpfValido(cpfCnpjValor);
        } else if (cpfCnpjValor.length === 14) {
            cpfCnpjValido = KzCnpjValidator.cnpjValido(cpfCnpjValor);
        }

        return cpfCnpjValido;
    }

    static dateIsValid(control: FormControl): { [key: string]: any } {
        if (control.value == '' || control.value == null) {
            return null;
        }

        let brDatePattern = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;

        if (!control.value.match(brDatePattern))
            return { "dateIsValid": true };

        return null;
    }
}