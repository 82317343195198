import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import Swal from 'sweetalert2';

import { Apontamento, ApontamentoOnline } from './apontamento.model';
import { environment } from '../../../../environments/environment';
import { JWTService } from '../../../auth/jwt.service';
import { AUTHORITIES } from '../../../auth/groups.constants';
import { AtualizarOcorrenciaModel } from '../../../shared/model/negativacao-ocorrencias.model';
import { HttpService } from '../../../shared/http/httpclient.service';
import { LoaderService } from '../../../shared/loader.service';
import { SharedService } from '../../../shared/shared.service';
import { MESSAGES } from '../../../shared/constants/constants-swal.model';
import { ParametrosModel } from 'src/app/shared/model/parametros.model';

@Injectable()
export class ApontamentoService {

  constructor( private http: HttpService,
               private jwtService: JWTService,
               private loaderService: LoaderService,
               private sharedService: SharedService ) { }

  listar(params : any): Observable<Apontamento> {
    return this.http.postParams(`${environment.settings.api}/negativacoes`, {}, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_NEGATIVACAO_ATIVAS, 
                                                  AUTHORITIES.CONSULTAR_NEGATIVACAO_SUSPENSA,
                                                  AUTHORITIES.CONSULTAR_HISTORICO_NEGATIVACAO]),
          params
    );
  }

  gerarPDF(apontamentoId: string) {
    this.loaderService.display(true);
    this.getRelatorioComunicado(apontamentoId).subscribe(
      pdf => {
        this.loaderService.display(false);
        let file = new Blob([pdf], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      },
      errors => {
        this.loaderService.display(false);
        if (errors.status == 409)
          Swal(MESSAGES.ERRO_GERACAO_COMUNICADO, "", MESSAGES.TYPE_ERROR);
        else
          Swal(this.sharedService.tratarErroMediaNotJson(errors), "", MESSAGES.TYPE_ERROR);
      }
    );
  }

  gerarInformacoes(numDocumento: string, codArea: string, numContrato: string, datOcorrencia:string, valor:string, natOperacao: string, tipoParticipante : string, cpfCnpj: string, dadoComplementarDivida: string) {
    this.loaderService.display(true);
    this.getInformacoesPdf(numDocumento, codArea, numContrato, datOcorrencia, valor, natOperacao,tipoParticipante,cpfCnpj, dadoComplementarDivida).subscribe(
        pdf => {
          this.loaderService.display(false);
          let file = new Blob([pdf], { type: 'application/pdf' });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getRelatorioComunicado(apontamentoId: string): Observable<ArrayBuffer> {
    return this.http.getBuffer(`${environment.settings.api}/comunicado/relatorio/apontamento`, 
        this.jwtService.getApiHeadersMultypart(),
        {
            idNegativacao: apontamentoId
        }
    );
  }

  getInformacoesPdf(numDocumento: string, codArea: string, numContrato: string, datOcorrencia:string, valorDivida:string, natOperacao: string,tipoParticipante : string,cpfCnpj: string, dadoComplementarDivida: string): Observable<ArrayBuffer> {
    return this.http.postBuffer(`${environment.settings.api}/negativacoes/pdf/detalheNegativacao/numDocumento/${numDocumento}/codArea/${codArea}/numContrato/${numContrato}/dataOcorrencia/${datOcorrencia}/valorDivida/${valorDivida}/natOperacao/${natOperacao}/tipoParticipante/${tipoParticipante}/cpfCnpj/${cpfCnpj}?dadoComplementarDivida=${dadoComplementarDivida}`,
        {}, this.jwtService.getApiHeadersMultypart()
    );
  }

  baixar(params : any) {
    return this.http.postBuffer(`${environment.settings.api}/negativacoes/relatorio/excel`, params, this.jwtService.getApiHeaders([AUTHORITIES.EXPORTAR_CONSULTA_NEGATIVACAO_EXCEL]));
  }
  
  incluir(params : any): Observable<ApontamentoOnline> {
    return this.http.post(`${environment.settings.api}/negativacoesOnline/salvaObrigatorios`, params, this.jwtService.getApiHeaders([AUTHORITIES.INCLUIR_NEGATIVACAO]));
  }
  
  excluir(idNegativacao: string, datOcorrencia: string, sequenciaOcorr: number, motivoBaixa: string, numDocumento: string) {
    return this.http.delete(`${environment.settings.api}/negativacoes/idNegativacao/${idNegativacao}/ocorrencias/datOcorrencia/${datOcorrencia}/sequenciaOcorr/${sequenciaOcorr}/excluir?motivoBaixa=${motivoBaixa}&numDocumento=${numDocumento}`, this.jwtService.getApiHeaders([AUTHORITIES.BAIXAR_NEGATIVACAO]));
  }
  
  excluirAvalista(idNegativacao: string, motivoBaixa: string, numDocumento: number) {
    return this.http.delete(`${environment.settings.api}/negativacoes/idNegativacao/${idNegativacao}/avalista/excluir?motivoBaixa=${motivoBaixa}&numDocumento=${numDocumento}`, this.jwtService.getApiHeaders([AUTHORITIES.BAIXAR_NEGATIVACAO]));
  }

  atualizar(idNegativacao: string, datOcorrencia: string, sequenciaOcorr: number, data: string, valor: string): Observable<AtualizarOcorrenciaModel>  {
    return this.http.post(`${environment.settings.api}/negativacoesOnline/ocorrencia/idNegativacao/${idNegativacao}/datOcorrencia/${datOcorrencia}/sequenciaOcorr/${sequenciaOcorr}/alterar?data=${data}&valor=${valor}`, {}, this.jwtService.getApiHeaders([AUTHORITIES.ALTERAR_NEGATIVACAO]));
  }

  getParametroPermiteInclusaoOcorrencia(numDocumento: string): Observable<ParametrosModel>{
    return this.http.get(`${environment.settings.api}/negativacoes/permiteInclusaoOcorrencia/${numDocumento}`, this.jwtService.getApiHeaders([]), null);
  }

  verificaExibeDadoComplementar(numDocumento: string): Observable<ParametrosModel> {
    return this.http.get(`${environment.settings.api}/negativacoes/verificaExibeDadoComplementar/${numDocumento}`, this.jwtService.getApiHeaders([]), null);
  }

}
