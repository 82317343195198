import { Component, AfterViewInit, AfterViewChecked } from '@angular/core';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { LoaderService } from './shared/loader.service';
import $ from 'jquery';
import { EventEmitterService } from './shared/event-emiter-service.service';
import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import _ from "underscore";
import { ChangeDetectorRef } from '@angular/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [LoaderService]
})
export class AppComponent implements AfterViewInit, AfterViewChecked {
  title = 'app';
  showLoader: boolean;
  onMain: boolean = false;
  switchCase: string = '';

  constructor(private breadcrumbService: BreadcrumbService,
    private loaderService: LoaderService,
    private emitterService: EventEmitterService,
    private router: Router,
    private changeDetector: ChangeDetectorRef) {
    breadcrumbService.hideRoute('/home');
    breadcrumbService.hideRoute('/');
    breadcrumbService.hideRoute('');
    breadcrumbService.hideRoute('/login');
    breadcrumbService.hideRoute('/recuperar-senha');
    breadcrumbService.hideRouteRegex('/validaToken?token=^[a-zA-Z0-9]$');
    breadcrumbService.hideRouteRegex('/nova-senha?token=^[a-zA-Z0-9]$');
    breadcrumbService.hideRoute('/instrucoes-senha');
    breadcrumbService.hideRoute('/reenvio-senha');
    breadcrumbService.hideRoute('/mfa');
    breadcrumbService.hideRoute('/alterar-senha');

    /* Serviços */
    breadcrumbService.hideRoute('/servicos');
    breadcrumbService.hideRoute('/servicos/imagem');
    breadcrumbService.addFriendlyNameForRoute('/servicos/imagem/consultaImagem', 'Serviços / Imagem / Consultar imagem ');
    breadcrumbService.hideRoute('/servicos/oficioJudicial');
    breadcrumbService.addFriendlyNameForRoute('/servicos/oficioJudicial/suspensaoJudicial', 'Serviços / Ofício Judicial / Suspensão Judicial');
    breadcrumbService.addFriendlyNameForRoute('/servicos/oficioJudicial/reinclusaoJudicial', 'Serviços / Ofício Judicial/ Reinclusão Judicial');
    breadcrumbService.hideRoute('/servicos/apontamento');
    breadcrumbService.addFriendlyNameForRoute('/servicos/apontamento/consultarApontamento', 'Serviços / Apontamento / Consultar Apontamento');
    breadcrumbService.addFriendlyNameForRoute('/servicos/apontamento/incluirApontamento', 'Serviços / Apontamento / Incluir Apontamento');
    breadcrumbService.hideRoute('/servicos/contestacao');
    breadcrumbService.addFriendlyNameForRouteRegex('/servicos/contestacao/aprovarRejeitarContestacao', 'Serviços / Contestação / Análise de Contestações');
    breadcrumbService.addFriendlyNameForRouteRegex('/servicos/contestacao/consultarListaContestacao', 'Serviços / Contestação / Consultar Contestação');
    breadcrumbService.hideRoute('/servicos/excecao');
    breadcrumbService.addFriendlyNameForRoute('/servicos/excecao/aprovarRejeitarExcecoes', 'Serviços / Exceções / Análise de Exceção');
    breadcrumbService.hideRoute('/servicos/menor');
    breadcrumbService.addFriendlyNameForRoute('/servicos/menor/aprovarRejeitarMenor', 'Serviços / Menor / Aprovar - Rejeitar');
    breadcrumbService.hideRoute('/servicos/notificacao');
    breadcrumbService.addFriendlyNameForRoute('/servicos/contestacao/consultaContestacao', 'Serviços / Contestação / Análise de Contestações');
    breadcrumbService.addFriendlyNameForRouteRegex('/servicos/apontamento/detalheApontamento/.*', 'Detalhe Apontamento');
    breadcrumbService.hideRoute('/servicos/apontamento/detalheApontamento');

    
    /* Serviços */

    /* Cadastro */
    breadcrumbService.hideRoute('/cadastros');
    breadcrumbService.addFriendlyNameForRoute('/cadastros/cliente', 'Cadastros / Cliente');
    breadcrumbService.addFriendlyNameForRoute('/cadastros/conglomerado', 'Cadastros / Conglomerado');
    breadcrumbService.addFriendlyNameForRoute('/cadastros/produto', 'Cadastros / Produto');
    breadcrumbService.addFriendlyNameForRoute('/cadastros/contrato', 'Cadastros / Contrato');
    breadcrumbService.addFriendlyNameForRoute('/cadastros/naturezaOperacao', 'Cadastros / Natureza da Operação');
    breadcrumbService.addFriendlyNameForRoute('/cadastros/codigoRetorno', 'Cadastros / Código de Retorno');
    breadcrumbService.addFriendlyNameForRoute('/cadastros/modelosCarta', 'Cadastros / Modelos de Carta');
    breadcrumbService.addFriendlyNameForRoute('/cadastros/retornoRemessa', 'Cadastros / Retorno de Remessa Informacional');
    breadcrumbService.addFriendlyNameForRoute('/cadastros/motivosBaixa', 'Cadastros / Motivos de Baixa');
    breadcrumbService.addFriendlyNameForRoute('/cadastros/localidade', 'Cadastros / Manutenção de Localidades');
    breadcrumbService.hideRoute('/cadastros/agendamentoCarteira');
    breadcrumbService.addFriendlyNameForRoute('/cadastros/agendamentoCarteira/consulta', 'Cadastros / Agendamento Carteira');
    breadcrumbService.addFriendlyNameForRouteRegex('/cadastros/agendamentoCarteira/cadastro.*', 'Cadastros / Agendamento Carteira / Cadastro');
    breadcrumbService.addFriendlyNameForRouteRegex('/cadastros/agendamentoCarteira/historico.*', 'Cadastros / Agendamento Carteira / Histórico');

    /* Cadastro */

    /* Configurações */
    breadcrumbService.hideRoute('/configuracoes');
    breadcrumbService.hideRoute('/configuracoes/controleAcesso');
    breadcrumbService.hideRoute('/configuracoes/controleAcesso/solicitacao');
    breadcrumbService.addFriendlyNameForRoute('/configuracoes/controleAcesso/solicitacao/alterarUsuario', 'Configurações / Controle de Acesso / Solicitação / Análise de Solicitação de Alteração de Usuário');
    breadcrumbService.addFriendlyNameForRoute('/configuracoes/controleAcesso/solicitacao/cadastroUsuario', 'Configurações / Controle de Acesso / Solicitação / Solicitação de Cadastro de Usuário');
    breadcrumbService.addFriendlyNameForRoute('/configuracoes/controleAcesso/solicitacao/soliciatacaoCadastramentoUsuario', 'Configurações / Controle de Acesso / Solicitação / Solicitação de Cadastramento Usuário');
    breadcrumbService.addFriendlyNameForRoute('/configuracoes/controleAcesso/solicitacao/aprovarUsuario', 'Configurações / Controle de Acesso / Solicitação / Análise de Solicitações de Usuários');
    breadcrumbService.addFriendlyNameForRoute('/configuracoes/controleAcesso/solicitacao/transferirSolicitacao', 'Configurações / Controle de Acesso / Solicitação / Transferir Solicitação de Aprovação');
    breadcrumbService.addFriendlyNameForRoute('/configuracoes/controleAcesso/usuario/manterUsuarioApi', 'Configurações / Controle de Acesso / Usuário / Manter Usuário API');
    breadcrumbService.addFriendlyNameForRoute('/configuracoes/controleAcesso/usuario/ConsultarUsuarioComponent', 'Configurações / Controle de Acesso / Usuário / Consultar Usuário');
    
    /* Configurações */

    /* Remessas */
    breadcrumbService.hideRoute('/remessas');
    breadcrumbService.addFriendlyNameForRoute('/remessas/historicoRemessa', 'Remessas / Consultar Histórico de Remessa');
    breadcrumbService.addFriendlyNameForRoute('/remessas/statusRemessaProcessada', 'Remessas / Status Remessa Processada');
    /* Remessas */

  }

  ngOnInit() {
    $(".breadcrumb").css({ 'display': 'none' })
    this.loaderService.loaderStatus.subscribe((val: boolean) => {
      this.showLoader = val;
    });

    this.emitterService.hiddenImage.subscribe((val: boolean) => {
      this.onMain = val;
    });
  }

 // ngAfterViewInit() {
   // let ignoredRoutes = ["/", "", "/login","/instrucoes-senha", "/recuperar-senha", "/reenvio-senha"];
    //this.router.events.filter(
     // (event) => event instanceof NavigationEnd
    //).subscribe(
      //(event: any) => {
        //let url = event.url;
        //let contains = null;
        //contains = _.contains(ignoredRoutes, url);
        //if (contains) {
         // this.emitterService.display(false);
        //} else {
          //let strValidaToken = url.indexOf("/validaToken");
          //let strNovaSenha = url.indexOf("/nova-senha");
          //if (strValidaToken >= 0 || strNovaSenha >= 0) {
           // this.emitterService.display(false)
            //this.breadcrumbService.hideRouteRegex('/validaToken?token=^[a-zA-Z0-9]$');
            //this.breadcrumbService.hideRouteRegex('/nova-senha?token=^[a-zA-Z0-9]$');
          //} else {
            //this.emitterService.display(true)
            //$(".breadcrumb").css({ 'display': 'block' })
          //}
        //}
      //}
    //);
  //}

  

  ngAfterViewInit() {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const url = event.url;
        const ignoredRoutes = ["/", "", "/login", "/recuperar-senha", "/reenvio-senha"];
        const hideBreadcrumbRoutes = ["/instrucoes-senha", "/validaToken", "/nova-senha"];
  
        const shouldDisplayBreadcrumb = !ignoredRoutes.includes(url);
        const shouldHideBreadcrumb = hideBreadcrumbRoutes.some(route => url.includes(route));
  
        if (shouldHideBreadcrumb) {
          this.emitterService.display(false);
          $(".breadcrumb").css({ 'display': 'none' });
        } else {
          this.emitterService.display(shouldDisplayBreadcrumb);
          $(".breadcrumb").css({ 'display': 'block' });
        }
      });
  }
  
  ngAfterViewChecked() {
    if ($(".breadcrumb li").length === 0) {
      $(".breadcrumb").css({ 'display': 'none' });
    }
  }


  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

}




/* ngAfterViewChecked() {
    if ($(".breadcrumb li").length > 0) {
      let ignoredRoutes = ["/", "", "/login", "/recuperar-senha", "/reenvio-senha"];
      this.router.events.filter(
        (event) => event instanceof NavigationEnd
      ).subscribe(
        (event: any) => {
          let url = event.url;
          let contains = null;
          contains = _.contains(ignoredRoutes, url);
          if (contains) {
            this.emitterService.display(false);
          } else {
            let strValidaToken = url.indexOf("/validaToken");
            let strNovaSenha = url.indexOf("/nova-senha");
            if (strValidaToken >= 0 || strNovaSenha >= 0) {
              this.emitterService.display(false)
              $(".breadcrumb").css({ 'display': 'none' })
            } else {
              this.emitterService.display(true);
              $(".breadcrumb").css({ 'display': 'block' })
            }
          }
        }
      );
    } else {
      $(".breadcrumb").css({ 'display': 'none' })
    }
  } */