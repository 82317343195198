import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { JWTService } from '../../auth/jwt.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor( private http: HttpClient,
               private jwtService: JWTService ) { }

    /** se estiver logado, verifica se o token está válido e/ou se é necessário refresh */
    authCheck() : boolean {
        if(this.jwtService.loggedIn()){
            if(this.jwtService.checkJWT()) {
                if(this.jwtService.tokenProximoDeExpirar())
                    this.jwtService.refreshToken();
                return true;
            }
            else return false;
        } else return this.jwtService.authCheck(true);
    }

    get(uri : string, headers : HttpHeaders, params: any): Observable<any> {
        if(this.authCheck) 
            return params ? this.http.get<any>(uri, { params, headers }) : this.http.get<any>(uri, { headers });
    }

    getBuffer(uri : string, headers : HttpHeaders, params: any): Observable<any> {
        if(this.authCheck) 
            return params ? this.http.get(uri, { params, headers, responseType : 'arraybuffer' }) : this.http.get(uri, { headers, responseType : 'arraybuffer' });
    }

    post(uri : string,  body : any, headers : HttpHeaders): Observable<any> {
        if(this.authCheck)
            return body ? this.http.post<any>(uri, body, { headers }) : this.http.post<any>(uri, { headers });
    }

    postParams(uri : string,  body : any, headers : HttpHeaders, params: any): Observable<any> {
        if(this.authCheck)
            return body ? this.http.post<any>(uri, body, { params, headers }) : this.http.post<any>(uri, { params, headers });
    }

    postBuffer(uri : string,  body : any, headers : HttpHeaders): Observable<any> {
        if(this.authCheck)
            return body ? this.http.post(uri, body, { headers, responseType : 'arraybuffer' }) : this.http.post<any>(uri, { headers });
    }

    put(uri : string, body : any, headers : HttpHeaders): Observable<any> {
        if(this.authCheck)
            return body ? this.http.put<any>(uri, body, { headers }) : this.http.put<any>(uri, { headers });
    }

    delete(uri : string, headers : HttpHeaders): Observable<any> {
        if(this.authCheck) 
            return this.http.delete<any>(uri, { headers });
    }

    patch(uri : string, body : any, headers : HttpHeaders): Observable<any> {
        if(this.authCheck) 
            return this.http.patch<any>(uri, body, { headers });
    }

    options(uri : string, headers : HttpHeaders): Observable<any> {
        if(this.authCheck) 
            return this.http.options<any>(uri, { headers });
    }

    postParamsBuffer(uri : string,  body : any, headers : HttpHeaders, params: any): Observable<any> {
        if(this.authCheck)
            return body ? this.http.post(uri, body, { params, headers, responseType : 'arraybuffer'}) : this.http.post(uri, { params, headers, responseType : 'buffer' });
    }
}
