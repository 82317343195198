export class InformanteAreaModel {
    constructor(
        public idInformanteArea?: string,
        public idInformante?: string,
        public tipoInformante?: string,
        public tipoPessoaInf?: string,
        public numDocumento?: string,
        public codArea?: string,
        public descArea?: string,
        public numRemessa?: number,
        public statFila?: string,
        public ultComando?: string,
        public statProcessamento?: string,
        public numRemessaForaSeq?: number
    ) {
        this.idInformanteArea = idInformanteArea == null ? '' : idInformante;
            this.idInformante = idInformanteArea == null ? '' : idInformante;
            this.tipoInformante = tipoInformante == null ? '' : tipoInformante;
            this.tipoPessoaInf = tipoPessoaInf == null ? '' : tipoPessoaInf;
            this.numDocumento = numDocumento == null ? '' : numDocumento;
            this.codArea = codArea == null ? '' : codArea;
            this.descArea = descArea == null ? '' : descArea;
            this.numRemessa = numRemessa == null ? null : numRemessa;
            this.statFila = statFila == null ? '' : statFila;
            this.ultComando = ultComando == null ? '' : ultComando;
            this.statProcessamento = statProcessamento == null ? '' : statProcessamento;
            this.numRemessaForaSeq = numRemessaForaSeq == null ? null : numRemessaForaSeq;
    }
}