export const environment = {
  production: false,
  settings: {
    auth: {
      wso2: 'https://api-qa.negativacao.quod.cloud/autg/token',
      token: 'https://api-qa.negativacao.quod.cloud/apig/negativacao/v1/autenticacoes',
      logout: 'https://api-qa.negativacao.quod.cloud/apig/negativacao/v1/autenticacoes/desconectar',
      mfa_validar_uri: 'https://api-qa.negativacao.quod.cloud/apig/negativacao/v1/mfa-validar',
      mfa_reenviar_uri: 'https://api-qa.negativacao.quod.cloud/apig/negativacao/v1/mfa-reenviar',
      timeLimitForRefresh: 1200
    },
    headers: {
      token_type: 'Bearer ',
      content_type_urlencoded: 'application/x-www-form-urlencoded',
      content_type_json: 'application/json;charset=UTF-8'
    },
    captcha: {
      chave: '6LcMcboaAAAAALQ4081FqrAYd-k6713D3G6jKGos'
    },
    api: 'https://api-qa.negativacao.quod.cloud/apig/negativacao/v1',
    api_conversor: 'https://pre-bo.controlcred.com.br/apig/negativacao-conversor/v1',
    portal: 'FO',
    tipoUsuario: 'FO',
    wso2_token_required: false,
    secure_flag: true,
    usuarioRobo: 'RO',
    path: '/',
    quod_uri: 'https://d.atendimento.controlcred.com.br?access_token='
  }
}
