import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ListaDocumentoResult } from './imagem.model';
import { environment } from 'src/environments/environment';
import { JWTService } from 'src/app/auth/jwt.service';
import { HttpService } from '../../../shared/http/httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class ListarImagemService {

  constructor(private http: HttpService,
              private jwtService: JWTService) { }

  listar(body : any): Observable<ListaDocumentoResult> {
    return this.http.post(`${environment.settings.api}/documentos/listar`, body, this.jwtService.getApiHeaders([]));
  }

  buscar(body : any): Observable<any> {
    return this.http.post(`${environment.settings.api}/documentos`, body, this.jwtService.getApiHeaders([]));
  }
}
