
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import { PerfilModel } from '../../shared/perfil.model';
import { environment } from '../../../../../environments/environment';
import { JWTService } from '../../../../auth/jwt.service';
import { AUTHORITIES } from '../../../../auth/groups.constants';
import { HttpService } from '../../../../shared/http/httpclient.service';

@Injectable()
export class PerfilService {

    constructor(private http: HttpService, 
                private jwtService: JWTService) { }
  
    buscarPerfil(codPerfil: number) {
        return this.http.get(`${environment.settings.api}/perfis/${codPerfil}`, this.jwtService.getApiHeaders([]), null);
    }

    alterarPerfil(model: PerfilModel): Observable<PerfilModel>{
        return this.http.put(`${environment.settings.api}/perfis`, model,this.jwtService.getApiHeaders([AUTHORITIES.ASSOCIAR_PERFIL_FUNCIONALIDADE, AUTHORITIES.INCLUSAO_PERFIL, AUTHORITIES.ALTERAR_PERFIL, AUTHORITIES.CONSULTAR_PERFIL]));
    }
    
    getPerfis(): Observable<PerfilModel[]> {
        return this.http.get(`${environment.settings.api}/perfis`, this.jwtService.getApiHeaders([]), null);
    }
    
    getPerfisPorTipo(tipoPerfil: string): Observable<PerfilModel[]> {
        return this.http.get(`${environment.settings.api}/perfis`,
            this.jwtService.getApiHeaders([]),
            { tipoPerfil : tipoPerfil, status : 'A' }
        );
    }

    buscarTodosPerfis(){
        return this.http.get(`${environment.settings.api}/perfis`, this.jwtService.getApiHeaders([]), null);
    }

    incluirPerfil(model: PerfilModel): Observable<PerfilModel> {
        return this.http.post(`${environment.settings.api}/perfis`, model, this.jwtService.getApiHeaders([AUTHORITIES.ASSOCIAR_PERFIL_FUNCIONALIDADE, AUTHORITIES.INCLUSAO_PERFIL, AUTHORITIES.ALTERAR_PERFIL]));
    }

    assocPerfilFuncao(model: PerfilModel): Observable<PerfilModel> {
        return this.http.put(`${environment.settings.api}/perfis/${model.codPerfil}/funcoes`, model.funcoes, this.jwtService.getApiHeaders([AUTHORITIES.ASSOCIAR_PERFIL_FUNCIONALIDADE]));
    }
}
