import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CPFCNPJ'
})
export class CPFCNPJPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (value.toString().length <= 11) {
      value = this.padLeftWithZeros(value, 11)
      return value.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
    } else {
      value = this.padLeftWithZeros(value, 14)
      return value.toString().replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4-\$5');
    }
  }

  padLeftWithZeros(value: string, length: number) {
    let zeros = this.getNumber(value) < 11 ? "00000000000" : "00000000000000";
    return zeros.concat(this.tiraMask(value).toString()).slice(-length);
  }

  getNumber(cpfCnpjValue) {
    let cpfCnpj = cpfCnpjValue.toString().match(/\d/g);
    let cpfCnpjLength = 0;
    if (cpfCnpj) {
      cpfCnpjLength = cpfCnpj.join('').length;
    }
    return cpfCnpjLength
  }

  tiraMask(cpfCnpjValue) {
    let cpfCnpj = cpfCnpjValue.toString().match(/\d/g);
    let cpfCnpjLength = 0;
    if (cpfCnpj) {
      cpfCnpjLength = cpfCnpj.join('');
    }
    return cpfCnpjLength
  }


}
