import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../shared/utils.service';
import $ from "jquery"
import { EventEmitterService } from '../shared/event-emiter-service.service';
import { LoaderService } from '../shared/loader.service';
@Component({
  selector: 'app-instrucoesnovasenha',
  templateUrl: './instrucoesnovasenha.component.html',
  providers: [UtilsService]
})

export class InstrucoesNovaSenhaComponent implements OnInit {
  constructor(
    private loaderService: LoaderService,
    private emitterService: EventEmitterService) { }

  ngOnInit() {
    this.emitterService.display(false);
    this.loaderService.display(false);
    $(".container-fluid").removeClass('wraper')      
  }

  
}
