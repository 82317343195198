import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TipoDocumento, ConsultaDocumentoLista, ListaDocumentoResult, DocumentoResult, DocumentoModel } from './imagem.model';
import { ListarImagemService } from './lista-imagem.service';
import { Validator } from 'src/app/shared/validators/validator';
import { ConstantsMaskModel } from 'src/app/shared/constants/constants-mask.model';
import { LoaderService } from 'src/app/shared/loader.service';
import { SharedService } from 'src/app/shared/shared.service';
import { MESSAGES } from '../../../shared/constants/constants-swal.model';
import { DominioModel } from '../../../shared/model/dominio.model';
import { UtilsService } from '../../../shared/utils.service';
import { MyErrorStateMatcher } from '../../../shared/error-matcher-input/error-matcher-input.model';

@Component({
  selector: 'app-consulta-imagem',
  templateUrl: './consulta-imagem.component.html',
  styleUrls: ['./consulta-imagem.component.scss']
})
export class ConsultaImagemComponent implements OnInit {
  imagens: ListaDocumentoResult;
  possuiImagens = false;
  tipoPessoa : string = '';
  oficioJudicial: boolean = false;
  tiposDoc: DominioModel[] = [];
  tipoDocumentoModal: TipoDocumento[] = [];
  tipoDocumentoSelecionado: TipoDocumento;
  consultarImagens: FormGroup;
  maskDate = ConstantsMaskModel.MASK_DATE;
  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();
  matcher = new MyErrorStateMatcher();

  constructor(private fb: FormBuilder,
              private loaderService: LoaderService,
              private listarImagemService: ListarImagemService,
              private utils : UtilsService,
              private sharedService: SharedService
              ) { }

  ngOnInit() {
    this.consultarImagens = this.fb.group({
      tipo_documento: this.tipoDocumentoControl,
      razaosocial: this.razaosocialControl,
      doc: this.cpfCnpjDevedorControl,
      processo: this.processoControl,
      data_inicio: this.dataIniControl,
      data_fim: this.dataFimControl
    });
  }

  tipoDocumentoControl = new FormControl('', [Validators.required]);
  processoControl = new FormControl('', []);
  cpfCnpjDevedorControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  razaosocialControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  dataIniControl = new FormControl('', [Validators.required, Validator.dateIsValid]);
  dataFimControl = new FormControl('', [Validators.required, Validator.dateIsValid]);

  listar(model: ConsultaDocumentoLista) {
    this.loaderService.display(true);
    this.possuiImagens = false;
    this.listarImagemService.listar(model)
      .subscribe(
        (result: ListaDocumentoResult) => {
          this.loaderService.display(false);
          if (result.documentos && result.documentos.length >= 0) {
            this.imagens = result;
            this.possuiImagens = true;
          } else {
            Swal(MESSAGES.NAO_RETORNOU_RESULTADOS, "", MESSAGES.TYPE_ERROR);
          }
        },
        errors => {
          this.loaderService.display(false);
          Swal(MESSAGES.SERVICO_INDISPONIVEL, "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  buscarImagem(documento: DocumentoModel) {
    if(documento.confidencial == 'S') {
      Swal(MESSAGES.DOCUMENTO_CONFIDENCIAL, "", MESSAGES.TYPE_ERROR);
      return;
    }

    this.loaderService.display(true);
    this.listarImagemService.buscar({ id_documento : documento.id_documento})
      .subscribe(
        (result: DocumentoResult) => {
          this.loaderService.display(false);
          let file = this.dataURItoBlob(result.documento, result.tipo);
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  buscarPessoa(cpfCnpj: string) {
    if(!cpfCnpj)
      return;

    this.tipoPessoa = this.utils.getTipoPessoa(cpfCnpj);
    if(!this.utils.validaDocumento(cpfCnpj, this.tipoPessoa)) {
      Swal((this.tipoPessoa == 'F' ? "CPF " : "CNPJ ").concat("informado não é válido"), "", MESSAGES.TYPE_ERROR);
      return;
    }

    this.sharedService.getPessoa(cpfCnpj, this.tipoPessoa)
      .subscribe(
        result => {
          this.limparForm();
          this.consultarImagens.get('razaosocial').setValue(result.nomeRazaoSocial ? result.nomeRazaoSocial.trim() : result.nomeRazaoSocial)
          this.obterDominio('TIPO_DOCUMENTO_P' + this.tipoPessoa);
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
          this.razaosocialControl.setValue('');
          this.limparForm();
        }
      );
  }

  obterDominioContingencia() {
    if(this.tipoPessoa == 'F')
      this.contingenciaTipoDocPF();
    else
      this.contingenciaTipoDocPJ();  
  }

  obterDominio(cod: any) {
    this.sharedService.getDominioExternoByCodigo(cod)
      .subscribe(
        result => {
          if(result && result.length > 0)
            this.tiposDoc = result;
          else
            this.obterDominioContingencia();
        },
        errors => {
          this.obterDominioContingencia();
        }
      );
  }
  
  verificaTipo(tipo : string) {
    if("OFJ" == tipo) {
      this.oficioJudicial = true;
      this.processoControl.setValidators([Validators.required]);
    } else {
      this.oficioJudicial = false;
      this.processoControl.setValidators([]);
      this.processoControl.reset();
    }
  }

  contingenciaTipoDocPF() {
    this.tiposDoc.push(new DominioModel('TIPO_PESSOA_PF', 'RG', 'RG'));
    this.tiposDoc.push(new DominioModel('TIPO_PESSOA_PF', 'CPF', 'CPF'));
    this.tiposDoc.push(new DominioModel('TIPO_PESSOA_PF', 'CNH', 'CNH'));
    this.tiposDoc.push(new DominioModel('TIPO_PESSOA_PF', 'END', 'Comprovante de Endereço'));
    this.tiposDoc.push(new DominioModel('TIPO_PESSOA_PF', 'OFJ', 'Ofício Judicial'));
    this.tiposDoc.push(new DominioModel('TIPO_PESSOA_PF', 'CIT', 'Citação'));
  }

  contingenciaTipoDocPJ() {
    this.tiposDoc.push(new DominioModel('TIPO_PESSOA_PJ', 'CPF', 'CNPJ'));
    this.tiposDoc.push(new DominioModel('TIPO_PESSOA_PJ', 'DOCSOC', 'Documentos Societários'));
    this.tiposDoc.push(new DominioModel('TIPO_PESSOA_PJ', 'AC', 'Ato Constitutivo'));
    this.tiposDoc.push(new DominioModel('TIPO_PESSOA_PJ', 'CS', 'Contrato Social'));
    this.tiposDoc.push(new DominioModel('TIPO_PESSOA_PJ', 'END', 'Comprovante de Endereço'));
    this.tiposDoc.push(new DominioModel('TIPO_PESSOA_PJ', 'OFJ', 'Ofício Judicial'));
    this.tiposDoc.push(new DominioModel('TIPO_PESSOA_PJ', 'CIT', 'Citação'));
  }

  dataURItoBlob(dataURI, media_type) {
    const byteString = atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: media_type });    
    return blob;
 }

  limparForm() {
    this.tipoDocumentoControl.reset();
    this.processoControl.reset();
    this.dataIniControl.reset();
    this.dataFimControl.reset();
  }

  zeroAEsquerda(){
    if(!this.cpfCnpjDevedorControl.value)
      return;
    if(this.cpfCnpjDevedorControl.value.replace(/_/g, '').length > 11){
      this.cpfCnpjDevedorControl.setValue(this.utils.padLeftWithZeros(this.cpfCnpjDevedorControl.value, 14));
    } else {
      this.cpfCnpjDevedorControl.setValue(this.utils.padLeftWithZeros(this.cpfCnpjDevedorControl.value, 11));
    }
  }
}
