export class EsqueciSenha { 
  constructor( 
    public cpf?: number
  ){} 
}

export class NovaSenha { 
  constructor( 
    public cpf?: number,
    public tipoUsuario?: string,
    public codFuncao?: string,
    public senhaProvisoria?: string,
    public senha?: string,
    public confirmaSenha?: string
  ){} 
}
 
  export class AcessoTemporario {
    constructor( 
      public cpf?: number,
      public tipoUsuario?: string,
      public senhaProvisoria?: string,
      public codOperacaoTemporaria?: string
    ){} 
  
}