import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '../../../../../../node_modules/@angular/forms';
import { ContestacaoService } from '../../shared/contestacao.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../../../node_modules/@angular/material';
import { LoaderService } from '../../../../shared/loader.service';
import swal from 'sweetalert2';
import { SharedService } from '../../../../shared/shared.service';
import { MyErrorStateMatcher } from '../../../../shared/error-matcher-input/error-matcher-input.model';
import { MESSAGES } from '../../../../shared/constants/constants-swal.model';

@Component({
  selector: 'app-aprovar-dialog',
  templateUrl: './aprovar-dialog.component.html',
  styleUrls: ['./aprovar-dialog.component.scss']
})
export class AprovarDialogComponent implements OnInit {

  constructor(private loaderService: LoaderService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<AprovarDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
  private formBuilder: FormBuilder, private service: ContestacaoService) { }

  matcher = new MyErrorStateMatcher();

aprovarForm: FormGroup;
justificativaControl = new FormControl('', [Validators.required]);

ngOnInit() {
  this.aprovarForm = this.formBuilder.group({
    justificativa: this.justificativaControl
  });
}

onNoClick(): void {
  this.dialogRef.close(false);
}

aprovar() {
  if(this.justificativaControl.hasError('required')){
    return;
  }
  this.loaderService.display(true);

  this.service.aprovarContestacao(this.data.numContestacao, this.justificativaControl.value)
  .subscribe(
    resp =>{
      this.loaderService.display(false);
      swal(MESSAGES.CONTESTACAO_APROVADA_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
    },
    errors => { 
    this.loaderService.display(false);
    swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
  })
  this.dialogRef.close(this.aprovarForm.value);
}


}
