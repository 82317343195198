import { Component, OnInit, Inject } from '@angular/core';
import { SolicitacaoService } from '../shared/solicitacao.service';
import { Solicitacao } from '../shared/solicitacao.model';
import Swal from 'sweetalert2';
import { MyErrorStateMatcher } from '../../../../shared/error-matcher-input/error-matcher-input.model';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedService } from '../../../../shared/shared.service';
import { JWTService } from '../../../../auth/jwt.service';
import { Token } from '../../../../auth/token.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoaderService } from '../../../../shared/loader.service';
import { ActivatedRoute } from '@angular/router';
import { Overlay } from '@angular/cdk/overlay';
import { MESSAGES } from '../../../../shared/constants/constants-swal.model';

@Component({
  selector: 'app-aprovar-usuario',
  templateUrl: './aprovar-usuario.component.html',
  styleUrls: ['./aprovar-usuario.component.scss'],
  providers: [MatDialog, SolicitacaoService]
})
export class AprovarUsuarioComponent implements OnInit {

  matcher = new MyErrorStateMatcher();
  solicitacoes: Solicitacao[] = [];
  solicitacoesTeste: Solicitacao[] = [];
  token : Token = {};
  dialogRef: any;
  private params : any = {};
  paginaRedirecionou: string = '';


  constructor( private sharedService : SharedService, 
               private solicitacaoService : SolicitacaoService,
               private jwtService : JWTService,
               public dialog: MatDialog,
               private loaderService : LoaderService,
               private route: ActivatedRoute,
               public overlay: Overlay ) { }

  ngOnInit() {
    this.recuperarParametroTelaHome();
    this.params = this.route.queryParams;
    this.token = this.jwtService.getObject('auth_token');
    if(this.params._value.idSolicitacao){
      this.buscarPorId(this.params._value.idSolicitacao);
    } else {
      this.listar();
    }
  }

  recuperarParametroTelaHome(){
    this.paginaRedirecionou = localStorage.getItem('pagina');
    localStorage.removeItem('pagina');
  }

  listar() {
    this.loaderService.display(true);
    this.solicitacaoService.listar(this.token.cpfCnpj)
      .subscribe(
        result => {
          this.loaderService.display(false);
          this.solicitacoes = result;
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_WARNING);
        }
      );
  }

  buscarPorId(id : string) {
    this.solicitacoes = [];
    this.loaderService.display(true);
    this.solicitacaoService.buscarPorId(id)
      .subscribe(
        (result: Solicitacao) => {
          this.loaderService.display(false);
          this.solicitacoesTeste.push(result);
          this.solicitacoes.push(result);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_WARNING);
        }
      );
  }

  openDialogAprovacao(row : any) : void {
    this.dialogRef = this.dialog.open(AprovarUsuarioDialog, {
      width: '40%',
      height: '40%',
      data: {solicitacao: row},
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });
    this.dialogRef.componentInstance.data = {solicitacao: row};

    this.dialogRef.afterClosed().subscribe(result => {
      if (result != false) {
        this.solicitacaoService.aprovar(result.solicitacao.idSolicitacao, result.solicitacao.operacao)
        .subscribe(
          result => {
            this.listar();
            Swal(MESSAGES.OPERACAO_REALIZADA_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
          },
          errors => {
            Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_WARNING);
          }
        );
      }
    });
  }

  openDialogRejeicao(row : Solicitacao) : void {
    this.dialogRef = this.dialog.open(RejeitarUsuarioDialog, {
      width: '40%',
      height: '40%',
      data: {solicitacao: row},
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });
    this.dialogRef.componentInstance.data = {solicitacao: row};

    this.dialogRef.afterClosed().subscribe(result => {

      // Verifica se o usuário realmente rejeitou a aprovação
      if (result != false) {
        this.solicitacaoService.rejeitar(result.data.solicitacao.idSolicitacao, result.justificativa)
        .subscribe(
          result => {
            this.listar();
            Swal(MESSAGES.OPERACAO_REALIZADA_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
          },
          errors => {
            Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_WARNING);
          }
        );
      }
    });
  }

}

@Component({
  selector: 'aprovar-usuario-dialog',
  templateUrl: 'aprovar-usuario-dialog.component.html',
  providers: [SolicitacaoService, SharedService]
})
export class AprovarUsuarioDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AprovarUsuarioDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private solicitacaoService : SolicitacaoService,
    private sharedService : SharedService
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  aprovar() {
    this.dialogRef.close(this.data);
  }

}

@Component({
  selector: 'rejeitar-usuario-dialog',
  templateUrl: 'rejeitar-usuario-dialog.component.html',
  providers: [SolicitacaoService, SharedService]
})
export class RejeitarUsuarioDialog implements OnInit {
  
  matcher = new MyErrorStateMatcher();
  rejeitarForm: FormGroup;

  justificativaControl = new FormControl('', [Validators.required]);
  
  constructor(
    public dialogRef: MatDialogRef<RejeitarUsuarioDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private solicitacaoService : SolicitacaoService,
    private sharedService : SharedService,
    private fb : FormBuilder,
    private loaderService : LoaderService
  ) { }

  ngOnInit() {
    this.rejeitarForm = this.fb.group({
      justificativa: this.justificativaControl
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  rejeitar(justificativa : string) {

    var retorno = {
      data: this.data,
      justificativa: justificativa
    }

    this.dialogRef.close(retorno);
  }

}
