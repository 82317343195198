import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng5BreadcrumbModule } from 'ng5-breadcrumb';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { MdePopoverModule } from '@material-extended/mde';

import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { SharedModule } from './shared/shared.module';
import { ROUTES } from './app.routes';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';

//CONFIGURACOES COMPONENTS
import { ConfiguracoesComponent } from './configuracoes/configuracoes.component';
import { ControleAcessoComponent } from './configuracoes/controle-acesso/controle-acesso.component';
import { ConfirmarAcaoDialog, ConsultarUsuarioComponent } from './configuracoes/controle-acesso/usuario/consultar/consultar-usuario.component';
import { IncluirUsuarioComponent } from './configuracoes/controle-acesso/usuario/incluir/incluir-usuario.component';
import { ReenviarSenhaComponent } from './configuracoes/controle-acesso/usuario/reenviar-senha/reenviar-senha.component';
import { SolicitacaoComponent } from './configuracoes/controle-acesso/solicitacao/solicitacao.component';
import { AlterarUsuarioComponent } from './configuracoes/controle-acesso/solicitacao/alterar-usuario/alterar-usuario.component';
import { AprovarUsuarioComponent, AprovarUsuarioDialog, RejeitarUsuarioDialog } from './configuracoes/controle-acesso/solicitacao/aprovar-usuario/aprovar-usuario.component';
import { TransferirSolicitacaoComponent, TransferirSolicitacaoDialog } from './configuracoes/controle-acesso/solicitacao/transferir-solicitacao/transferir-solicitacao.component';
import { ParametrosSistemaComponent } from './configuracoes/parametros-sistema/parametros-sistema.component';
import { UsuarioComponent } from './configuracoes/controle-acesso/usuario/usuario.component';
import { PerfilComponent } from './configuracoes/controle-acesso/perfil/perfil.component';
import { MfaComponent } from './mfa/mfa.component';

//CADASTROS COMPONENT
import { CadastrosComponent } from './cadastros/cadastros.component';
import { ClienteComponent } from './cadastros/cliente/cliente.component';


//SERVICOS COMPONENTS
import { ServicosComponent } from './servicos/servicos.component';
import { ApontamentoComponent } from './servicos/apontamento/apontamento.component';
import { AlterarComponent } from './servicos/apontamento/alterar/alterar.component';
import { BaixarComponent } from './servicos/apontamento/baixar/baixar.component';
import { ConsultarApontamentoComponent } from './servicos/apontamento/consultar/consultar-apontamento.component';

import { ContestacaoComponent } from './servicos/contestacao/contestacao.component';
import { ConsultarContestacaoComponent } from './servicos/contestacao/consultar/consultar-contestacao.component';
import { AprovarRejeitarContestacaoComponent, AprovarRejeitarContestacaoDialog } from './servicos/contestacao/aprovar-rejeitar/aprovar-rejeitar-contestacao.component';
import { ConsultarListaContestacaoComponent } from './servicos/contestacao/consultar-lista-contestacao/consultar-lista-contestacao.component';

import { PepComponent } from './servicos/pep/pep.component';
import { AprovarRejeitarPepComponent } from './servicos/pep/aprovar-rejeitar/aprovar-rejeitar-pep.component';
import { MenorComponent } from './servicos/menor/menor.component';
import { AprovarRejeitarMenorComponent } from './servicos/menor/aprovar-rejeitar/aprovar-rejeitar-menor.component';
import { MfaService } from './mfa/mfa.service';

//REMESSAS COMPONENTS

import { SolicitacaoService } from './configuracoes/controle-acesso/solicitacao/shared/solicitacao.service';
//Input Mask
import { TextMaskModule } from 'angular2-text-mask';
import { MaskDirective } from './shared/mask.directive';
import { KzCpfValidatorDirective } from './shared/cpf.mask.directive';
import { KzCnpjValidatorDirective } from './shared/cnpj.mask.directive';
import { KzCpfCnpjValidatorDirective } from './shared/cpfCnpj.mask.directive';
import { NgxPaginationModule } from 'ngx-pagination';

import { LoaderService } from './shared/loader.service';
import { LoginService } from './login/shared/login.service';
import { JWTService } from './auth/jwt.service';
import { LoginComponent } from './login/login.component';
import { CookieModule } from 'ngx-cookie';
import { LocalStorageModule } from 'angular-2-local-storage';
import { EsqueciSenhaComponent } from './senha/esquecisenha.component';
import { NovaSenhaComponent } from './senha/novasenha.component';
import { ValidaSenhaComponent } from './senha/validasenha.component';
import { SolicitacaoCadastroUsuarioComponent } from './configuracoes/controle-acesso/solicitacao/cadastro-usuario/cadastro-usuario.component';
import { MatDialog, MatDialogModule, MatCardModule, MatBadgeModule, MatIconModule, MatMenuModule, MatDatepicker, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { NotificacaoComponent } from './servicos/notificacao/notificacao.component';
import { P401Component } from './auth/error/401.component';
import { P404Component } from './auth/error/404.component';
import { P500Component } from './auth/error/500.component';
import { IncluirApontamentoComponent, AdicionarTelefoneDialog, AdicionarEmailDialog, RemoverTelefoneDialog, RemoverEmailDialog } from './servicos/apontamento/incluir/incluir.component';
import { RejeitarDialogComponent } from './servicos/contestacao/aprovar-rejeitar/Dialog/rejeitar-dialog.component';
import { AprovarDialogComponent } from './servicos/contestacao/aprovar-rejeitar/Dialog/aprovar-dialog.component';
import { ContestacaoService } from './servicos/contestacao/shared/contestacao.service';
import { DetalheComponent } from './servicos/apontamento/detalhe/detalhe.component';
import { AprovarRejeitarPepDialog } from './servicos/pep/aprovar-rejeitar/aprovar-rejeitar-pep-dialog';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID } from '@angular/core';
import { ExcluirNegativacaoDialog } from './servicos/apontamento/detalhe/excluir-negativacao-dialog.component';
import { AtualizarOcorrenciaDialog } from './servicos/apontamento/detalhe/atualizar-ocorrencia-dialog.component';
import { RecaptchaModule } from 'ng-recaptcha';

import { ManterUsuarioApiComponent } from './configuracoes/controle-acesso/usuario/manter-usuario-api/manter-usuario-api.component';
import { AcessoItauComponent } from './acesso-itau/acesso-itau.component';
import { ConsultaImagemComponent } from './servicos/imagem/consulta-imagem/consulta-imagem.component';
import { ImagemComponent } from './servicos/imagem/imagem.component';
import { HealthService } from './auth/health.service';
import { RemessasComponent } from './remessas/remessas.component';
import { ConsultarHistoricoRemessaComponent } from './remessas/consultar-historico-remessa/consultar-historico-remessa.component';
import { StatusRemessaProcessadaComponent } from './remessas/status-remessa-processada/status-remessa-processada.component';
import { StatusRemessaProcessadaService } from './remessas/status-remessa-processada/status-remessa-processada.service';
import { ConsultarHistoricoRemessaService } from './remessas/consultar-historico-remessa/consultar-historico-remessa.service';
import { InstrucoesNovaSenhaComponent } from './senha/instrucoesnovasenha.component';
import { ReenvioSenhaComponent } from './senha/reenviosenha.component';
import { ConfirmarExclusaoOcorrenciaPrincipalDialog } from './servicos/apontamento/detalhe/confirmar-exclusao-ocorrencia-principal-dialog.component';
import { ConfirmarExclusaoOcorrenciaAvalistaDialog } from './servicos/apontamento/detalhe/confirmar-exclusao-ocorrencia-avalista-dialog.component';
import { ExcluirAvalistaDialog } from './servicos/apontamento/detalhe/excluir-avalista-dialog.component';
import { QuestionamentoDialogComponent } from "./servicos/contestacao/consultar-lista-contestacao/questionamento-dialog/questionamento-dialog.component"
import { RejeicaoDialogComponent } from "./servicos/contestacao/consultar-lista-contestacao/rejeicao-dialog/rejeicao-dialog.component"
import { ClienteService } from './cadastros/cliente/cliente.service';
import { ModeloComunicadoDialog } from './cadastros/cliente/cliente-dialog/modelo-comunicado-dialog';
import { InformanteAreaDialog } from './cadastros/cliente/cliente-dialog/informante-area-dialog';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CPFCNPJPipe } from './cpf-cnpj.pipe';
import { ComunicadosComponent } from './servicos/comunicados/comunicados.component';
import { ConsultarComunicadosComponent } from './servicos/comunicados/consultar-comunicados/consultar-comunicados.component';
import { AgendamentoCarteiraComponent } from './cadastros/agendamento-carteira/agendamento-carteira.component';
import { AgendamentoCarteiraConsultaComponent } from './cadastros/agendamento-carteira/consulta/agendamento-carteira-consulta.component';
import { AgendamentoCarteiraCadastroComponent } from './cadastros/agendamento-carteira/cadastro/agendamento-carteira-cadastro.component';
import { AgendamentoCarteiraHistoricoComponent } from './cadastros/agendamento-carteira/historico/agendamento-carteira-historico.component';

registerLocaleData(localePt);

export function configFactory(config: HealthService) {
  return () => config.load();
}

@NgModule({
  declarations: [
    MaskDirective,
    KzCpfValidatorDirective,
    KzCnpjValidatorDirective,
    KzCpfCnpjValidatorDirective,
    EsqueciSenhaComponent,
    ValidaSenhaComponent,
    NovaSenhaComponent,
    AppComponent,
    HomeComponent,
    HeaderComponent,
    SidebarComponent,
    ConfiguracoesComponent,
    ControleAcessoComponent,
    ConsultarUsuarioComponent,
    IncluirUsuarioComponent,
    SolicitacaoComponent,
    AlterarUsuarioComponent,
    AprovarUsuarioComponent,
    SolicitacaoCadastroUsuarioComponent,
    TransferirSolicitacaoComponent,
    PerfilComponent,
    ParametrosSistemaComponent,
    ServicosComponent,
    ApontamentoComponent,
    AlterarComponent,
    BaixarComponent,
    ConsultarApontamentoComponent,
    ContestacaoComponent,
    ConsultarContestacaoComponent,
    AprovarRejeitarContestacaoComponent,
    PepComponent,
    DetalheComponent,
    AprovarRejeitarPepComponent,
    MenorComponent,
    AprovarRejeitarMenorComponent,
    ConsultarUsuarioComponent,
    IncluirUsuarioComponent,
    ReenviarSenhaComponent,
    UsuarioComponent,
    SolicitacaoCadastroUsuarioComponent,
    LoginComponent,
    TransferirSolicitacaoDialog,
    AprovarUsuarioDialog,
    RejeitarUsuarioDialog,
    NotificacaoComponent,
    AprovarRejeitarContestacaoDialog,
    P401Component,
    P404Component,
    P500Component,
    IncluirApontamentoComponent,
    AdicionarTelefoneDialog,
    AdicionarEmailDialog,
    RemoverTelefoneDialog,
    RemoverEmailDialog,
    RejeitarDialogComponent,
    AprovarDialogComponent,
    AprovarRejeitarPepDialog,
    ExcluirNegativacaoDialog,
    AtualizarOcorrenciaDialog,
    InstrucoesNovaSenhaComponent,
    ReenvioSenhaComponent,
    ManterUsuarioApiComponent,
    AcessoItauComponent,
    ReenvioSenhaComponent,
    ConsultaImagemComponent,
    ImagemComponent,
    ConfirmarExclusaoOcorrenciaPrincipalDialog,
    ConfirmarExclusaoOcorrenciaAvalistaDialog,
    RemessasComponent,
    ConsultarHistoricoRemessaComponent,
    StatusRemessaProcessadaComponent,
    ExcluirAvalistaDialog,
    ConsultarListaContestacaoComponent,
    QuestionamentoDialogComponent,
    RejeicaoDialogComponent,
    CadastrosComponent,
    ClienteComponent,
    ModeloComunicadoDialog,
    InformanteAreaDialog,
    DashboardComponent,
    CPFCNPJPipe,
    MfaComponent,
    ComunicadosComponent,
    ConsultarComunicadosComponent,
    AgendamentoCarteiraComponent,
    AgendamentoCarteiraConsultaComponent,
    AgendamentoCarteiraCadastroComponent,
    AgendamentoCarteiraHistoricoComponent,
    ConfirmarAcaoDialog
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    TextMaskModule,
    CookieModule.forRoot(),
    LocalStorageModule.withConfig({
      prefix: 'GIC',
      storageType: 'localStorage'
    }),
    NgbModule.forRoot(),
    SweetAlert2Module.forRoot(),
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    Ng5BreadcrumbModule.forRoot(),
    RouterModule.forRoot(ROUTES),
    MatDialogModule,
    MatIconModule,
    NgxPaginationModule,
    CurrencyMaskModule,
    MdePopoverModule,
    MatCardModule,
    MatBadgeModule,
    MatMenuModule,
    RecaptchaModule.forRoot(),
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule
  ],
  providers: [
    HealthService,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [HealthService],
      multi: true
    },
    LoginService,
    JWTService,
    LoaderService,
    MatDialog,
    ContestacaoService,
    SolicitacaoService,
    StatusRemessaProcessadaService,
    ConsultarHistoricoRemessaService,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    ClienteService,
    MatDatepicker,
    MfaService,
  ],
  entryComponents: [
    TransferirSolicitacaoDialog,
    AprovarUsuarioDialog,
    RejeitarUsuarioDialog,
    AprovarRejeitarContestacaoDialog,
    AdicionarTelefoneDialog,
    AdicionarEmailDialog,
    RemoverTelefoneDialog,
    RemoverEmailDialog,
    RejeitarDialogComponent,
    AprovarDialogComponent,
    AprovarRejeitarPepDialog,
    ExcluirNegativacaoDialog,
    AtualizarOcorrenciaDialog,
    ConfirmarExclusaoOcorrenciaPrincipalDialog,
    ConfirmarExclusaoOcorrenciaAvalistaDialog,
    ExcluirAvalistaDialog,
    QuestionamentoDialogComponent,
    RejeicaoDialogComponent,
    ModeloComunicadoDialog,
    InformanteAreaDialog,
    MfaComponent,
    ConfirmarAcaoDialog
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
