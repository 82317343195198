import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsPessoaModel } from './constants/constants-pessoa.model';
import { JWTService } from '../auth/jwt.service';
import { environment } from '../../environments/environment';
import { ConstantsMessages } from './constants/constants-message.model';
import { MESSAGES } from './constants/constants-swal.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InformanteModel } from './model/informante.model';
import encoding from 'text-encoding';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    constructor(private router: Router,
        private jwtService: JWTService) { }

    allowAccess(gruposUsuario: string[], gruposPermitidos: string[]): boolean {
        return gruposPermitidos.some(function (v) {
            return gruposUsuario.indexOf(v) >= 0;
        });
    }

    capitalize = function (str, lower) {
        return (lower ? str.toLowerCase() : str).replace(/(?:^|\s)\S/g, a => a.toUpperCase());
    }

    tratarErro(errors: any) {
        let msg = '';
        if (errors.error.message != null) {
            if ((errors.error_description && errors.error_description.startsWith("ey")) || errors.error.message.startsWith("ey")) {
                msg = MESSAGES.SESSAO_EXPIRADA;
            } else if (errors.error.message.indexOf("Read timed out") != -1) {
                msg = MESSAGES.SERVICO_INDISPONIVEL;
            } else {
                msg = errors.error.message;
                const messageFilter = ConstantsMessages.BAD_MESSAGES;
                if (messageFilter.length > 0) {
                    messageFilter.filter(m => {
                        if (msg.indexOf(m) != -1) {
                            msg = MESSAGES.ERRO_DESCONHECIDO;
                        }
                    });
                }
            }
        } else if (errors.status === 408) {
            msg = MESSAGES.SERVICO_INDISPONIVEL;
        } else if (errors.error.error_description != null) {
            msg = errors.error.error_description == 'Bad credentials' ?
                MESSAGES.USUARIO_SENHA_INVALIDOS
                : errors.error.error_description;
        } else if (errors.name == 'HttpErrorResponse') {
            msg = MESSAGES.SERVICO_INDISPONIVEL;
        } else {
            msg = MESSAGES.ERRO_DESCONHECIDO;
        }
        return msg;
    }

    tratarErroMediaNotJson(errors: any) {
        let msg: string = '';
        
        const decoder = new encoding.TextDecoder();

        let msgErro = decoder.decode(errors.error);
        let objErro = JSON.parse(msgErro);

        if(objErro.message) {
            msg = objErro.message;
        } else if (objErro.status){
            switch(objErro.status) {
                case 408:
                    msg = MESSAGES.SERVICO_INDISPONIVEL;
                break;
            }
        } else {
            msg = MESSAGES.ERRO_DESCONHECIDO;
        }

        return msg;
    }

    formataData(value: string) {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
            const str = value.split('/');
            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);
            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    formatarCep(value: string): string {
        if ((typeof value === 'string') && (value.length == 8)) {
            let a = value.substring(0, 5);
            let b = value.substring(5, value.length);
            return a + "-" + b;
        }
        return "";
    }

    formatarCepToNumber(value: string): string {
        if ((typeof value === 'string')){
            return value.replace(/\^d/g,'');
        }
        return '';
    }

    navigate(model: any) {
        if (model.codmodulo == 'CO') {
            this.router.navigate(['servicos/contestacao/aprovarRejeitarContestacao'], { queryParams: { numContestacao: model.idModulo } });
        } else if (model.codmodulo == 'CA' || model.codmodulo == 'AU') {
            this.router.navigate(['configuracoes/controleAcesso/solicitacao/aprovarUsuario']
               , { queryParams: { idSolicitacao: model.idModulo } }
            );
        } else if (model.codmodulo == 'EX') { // TODO inserir rota correta
            this.router.navigate(['servicos/excecao/aprovarRejeitarExcecoes']
             , { queryParams: { idSolicitacao: model.idModulo } }
            );
        }
    }

    getTipoPessoa(numDocumento: string) {
        if (numDocumento.length == 11) {
            return ConstantsPessoaModel.TIPO_FISICA
        } else {
            return ConstantsPessoaModel.TIPO_JURIDICA
        }
    }

    getTipoLogradouroFromLogradouro(logradouro: string): string {
        let tiposLogradouros = this.getTipoLogradouros().map(a => '^' + a + '');
        let regex = "(" + tiposLogradouros.join("|") + ")";
        var re = new RegExp(regex, "ig");
        let match = logradouro.toLowerCase().match(re);
        if(match != null && match[0] != null){
          return match[0];
        }else{
            return null;
        }
      
    }

    removeTipoLogradouroFromLogradouro(logradouro: string): string {
        let tiposLogradouros = this.getTipoLogradouros().map(a => '^' + a);
        let regex = tiposLogradouros.join("|");
        var re = new RegExp(regex, "ig");
        return logradouro.replace(re, '').trim();
    }

    getTipoLogradouros(): string[] {
        return [
            'rua',
            'avenida',
            'travessa',
            'alameda',
            'praça',
            'beco',
            'rodovia',
            'largo',
            'vila',
            'quadra',
            'conjunto',
            'condomínio'
        ];
    }

    getListaDDDsValidos() {
        return [
            "11", "12", "13", "14", "15", "16", "17", "18", "19", "21", "22 ", "24", "27",
            "28", "31", "32", "33", "34", "35", "37", "38", "41", "42", "43", "44", "45", "46", "47",
            "48", "49", "51", "53", "54", "55", "61", "62", "63", "64", "65", "66", "67", "68", "69",
            "71", "73", "74", "75", "77", "79", "81", "82", "83", "84", "85", "86", "87", "88", "89",
            "91", "92", "93", "94", "95", "96", "97", "98", "99"
        ];
    }

    formatarDocumento(numDocumento: string, tipPessoa: string) {
        let zero: string = "";
        if (tipPessoa == ConstantsPessoaModel.TIPO_FISICA) {
            zero = "00000000000";
        } else if (tipPessoa == ConstantsPessoaModel.TIPO_JURIDICA) {
            zero = "00000000000000";
        }
        return zero.substring(0, zero.length - numDocumento.length) + numDocumento;
    }

    converterLinkDto(link: string): string {
        try {
            let v: string[] = environment.settings.api.split("/");
            let path = link.split(v[v.length - 1])[1];
            return `${environment.settings.api}${path}`;
        } catch (err) {
            return link;
        }
    }

    validaDocumento(doc, tipPessoa): boolean {
        if (tipPessoa == ConstantsPessoaModel.TIPO_FISICA) {
            return this.validaCPF(doc);
        } else {
            return this.validaCNPJ(doc);
        }
    }

    validaCPF(c) {
        var soma;
        var resto;
        soma = 0;
        if (c == "00000000000") return false;

        for (let i = 1; i <= 9; i++) soma = soma + parseInt(c.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if ((resto == 10) || (resto == 11)) resto = 0;
        if (resto != parseInt(c.substring(9, 10))) return false;

        soma = 0;
        for (let i = 1; i <= 10; i++) soma = soma + parseInt(c.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if ((resto == 10) || (resto == 11)) resto = 0;
        if (resto != parseInt(c.substring(10, 11))) return false;
        return true;
    }

    validaCNPJ(c) {
        var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

        if ((c = c.replace(/[^\d]/g, "")).length != 14)
            return false;

        if (/0{14}/.test(c))
            return false;

        for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
        if (c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
            return false;

        for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
        if (c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
            return false;

        return true;
    }

    getNumber(cpfCnpjValue) {
        let cpfCnpj = cpfCnpjValue.match(/\d/g);
        let cpfCnpjLength = 0;
        if (cpfCnpj) {
            cpfCnpjLength = cpfCnpj.join('').length;
        }
        return cpfCnpjLength
    }
    
    tiraMask(cpfCnpjValue) {
        let cpfCnpj = cpfCnpjValue.match(/\d/g);
        let cpfCnpjLength = 0;
        if (cpfCnpj) {
          cpfCnpjLength = cpfCnpj.join('');
        }
        return cpfCnpjLength
    }

    padLeftWithZeros(value: string, length: number) {
        let zeros = this.getNumber(value) < 11 ? "00000000000" : "00000000000000";
        return zeros.concat(this.tiraMask(value).toString()).slice(-length);
    }

    // usado para atualizar o formulario que precisa ou não de natureza de operação
    updateValidatorForNatOperacaoChave(form: FormGroup, natOperacaoControl: FormControl, informante: InformanteModel) {
        if (this.isNatOperacaoChave(informante)) {
            natOperacaoControl.setValidators([Validators.required]);
            form.updateValueAndValidity();
        } else {
            natOperacaoControl.setValidators([]);
        }
    }

    isNatOperacaoChave(informante: InformanteModel): boolean {
        if (informante.tbParametrosDTO) {
            let parametro = informante.tbParametrosDTO.filter(parametro => parametro.codParametro.trim() == '31')[0];
            if (parametro && parametro.valParametro == 'S') {
                return true;
            } else {
                return false;
            }
        }
    }

    formatToCurrency(value: number): string {
        let numero = value.toFixed(2).split('.');
        numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
    }

    convertToCpfCnpj(num, digits) {
        if (num) {
          num = num.toString();
          num = num.replace(/\D/g, "");
          num = this.padLeftWithZeros(num, digits);
    
          switch (num.length) {
            case 4:
              num = num.replace(/(\d+)(\d{3})/, " $1.$2");
              break;
            case 5:
              num = num.replace(/(\d+)(\d{3})/, " $1.$2");
              break;
            case 6:
              num = num.replace(/(\d+)(\d{3})/, " $1.$2");
              break;
            case 7:
              num = num.replace(/(\d+)(\d{3})(\d{3})/, " $1.$2.$3");
              break;
            case 8:
              num = num.replace(/(\d+)(\d{3})(\d{3})/, " $1.$2.$3");
              break;
            case 9:
              num = num.replace(/(\d+)(\d{3})(\d{3})/, " $1.$2.$3");
              break;
            case 10:
              num = num.replace(/(\d+)(\d{3})(\d{3})(\d{1})/, " $1.$2.$3-$4");
              break;
            case 11:
              num = num.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, " $1.$2.$3-$4");
              break;
            case 12:
              num = num.replace(/(\d+)(\d{3})(\d{3})(\d{4})/, " $1.$2.$3/$4");
              break;
            case 13:
              num = num.replace(/(\d+)(\d{3})(\d{3})(\d{4})(\d{2})/, " $1.$2.$3/$4-$5");
              break;
            case 14:
              num = num.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, " $1.$2.$3/$4-$5");
              break;
          }
        }
        return num;
      }

    gerarHashMD5(texto: string): string {
        const md5 = new Md5();
        return md5.appendStr(texto).end();
    }
}
