import { Component, OnInit, Input } from '@angular/core';
import { Routes, Route, Router } from '@angular/router';

import $ from 'jquery';
import _ from 'underscore';

import {ROUTES} from '../app.routes';

import { JWTService } from 'src/app/auth/jwt.service';
import { Token } from 'src/app/auth/token.model';
import { environment } from '../../environments/environment';

import {SharedService} from '../shared/shared.service';

import { AUTHORITIES } from 'src/app/auth/groups.constants';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  @Input() showMenu: boolean;
  @Input() routeChild: Route;

  @Input() route: Route;
  showChildren: boolean;
  routeChildren: Route;
  subMenuChildren: boolean = false;

  menuInit: boolean = false;
  gruposUsuario: string[];
  allowedRoutes : string[] = [];
  arr: Routes = ROUTES;
  arrChildren: any[] = [];
  eps:any;
  jwt: Token;
  parentRoute: {
    icon:null,
    name:null,
    parent: string
  }

  constructor(private router : Router, private jwtService: JWTService, private sharedService:SharedService) {
    let token = this.jwtService.getObject('auth_token') as Token;
    try{
      token = this.jwtService.decode(token);
      this.gruposUsuario = token.authorities;
      if(this.sharedService.hasPermission(this.jwt, [AUTHORITIES.RETAGUARDA_MULTICOBRANCA])) { // Retira Icone Cadastro - Jira317 (Multicobranca)
        this.arr = this.arr.filter((route) => {
          return route.path != 'cadastros'
        });
      }

    } catch(e){ }
    this.allowAccessShowMenu();
  }

  ngOnInit() {
    this.eps =  this.jwtService.getObject('_EPS');
    this.jwt = this.jwtService.getObject('auth_token') as Token;
  }

  setClickedRoute(route: Route){
    this.route = route;
    if(this.route.path !== 'home' && this.route.path !== 'centralAjuda'){
      this.showSubMenu(this.route);
    }else{
      this.router.navigate([this.route.path]);
      (this.showChildren ? this.showChildren = false : '');
    }
  }

  showSubMenu(route: Route){
    this.arrChildren = [];
    this.showChildren = true;
    if(this.eps != 1) {
      this.showMenu = false;
    }
    var menuOpen = $('.breadcrumb').hasClass('openMenu');
    if(menuOpen){
      $('.breadcrumb').css({'margin-left':'500px', 'width':'62%'})
    }else{
      $('.breadcrumb').css({'margin-left':'350px', 'width':'73%'})
    }
  
    this.parentRoute = {
      icon: this.route.data.icon,
      name: this.route.data.title,
      parent: this.route.path
    };
    this.arrChildren = this.route.children;
  }

  closeSubmenu(){
    this.showChildren = false;
    
    var expd = $('.sidebar').hasClass('sidebar-expand');
    if(expd){
      $('.breadcrumb').css({'margin-left':'200px', 'width':'84%'})
    }else{
      $('.breadcrumb').css({'margin-left':'50px', 'width':'95%'});
    }
  }

  showChildrenChildSubMenu(childSubMenu){
    if(!childSubMenu.show){
      childSubMenu.show = true;
    }else{
      childSubMenu.show = false
    }
  }

  showSubMenuChildren(subMenu){
    if(!subMenu.show){
      subMenu.show = true;
    }else{
      subMenu.show = false
    }
  }
  
  allowMenu(path : string){
    if(this.allowedRoutes.indexOf(path) != -1)
      return true;
    else 
      return false;
  }

  allowAccessShowMenu(){
    this.arr.forEach(menu => {
      const filhos : any[] = menu.children;
      
      /* teste icones locais
      if(this.jwtService.getObject('_EPS') == 1)
      menu.data.icon = this.getLocalIcon(menu.data.icon);
      */

      if(menu.path == 'centralAjuda'){
        if(environment.settings.central_ajuda.enabled) {
          this.allowedRoutes.push(menu.path);
        }
      } else if(filhos && filhos.length > 0){
        let possuiFilhosAtivos = false;
        filhos.forEach(c => {
          const netos : any[] = c.children;

          if(netos && netos.length > 0){
            let possuiNetosAtivos = false;
            netos.forEach(n => {
              const bisnetos : any[] = n.children;
              
              if(bisnetos && bisnetos.length > 0){
                let possuiBisnetosAtivos = false;
                bisnetos.forEach(b => {
                  if(this.checkMenu(b)){
                    possuiBisnetosAtivos = true;
                    this.allowedRoutes.push(b.path);
                  }
                });

                if(possuiBisnetosAtivos){
                  this.allowedRoutes.push(n.path);     
                  possuiNetosAtivos = true;    
                }
              } else {
                if(this.checkMenu(n)){
                  possuiNetosAtivos = true;
                  this.allowedRoutes.push(n.path);
                }
              }
            });

            if(possuiNetosAtivos){
              this.allowedRoutes.push(c.path);  
              possuiFilhosAtivos = true;       
            }
          } else {
            if(this.checkMenu(c)){
              possuiFilhosAtivos = true;
              this.allowedRoutes.push(c.path);
            }
          }
        });

        if(possuiFilhosAtivos){
          this.allowedRoutes.push(menu.path);         
        }
      } else {
        if(this.checkMenu(menu)){
          this.allowedRoutes.push(menu.path);
        }
      }
    });
  }
  
  checkMenu(menu : any){
    try{
      return menu.data.show && this.jwtService.checkGroupPermission("/".concat(menu.data.link), this.gruposUsuario)
    } catch(e){
      return false;
    }
  }

  getLocalIcon(icon : string) : string {
    switch (icon) {
      case 'fas fa-home':
        return 'qi qi-home';
      case 'fas fa-glasses':
        return 'qi qi-glasses';
      case 'fas fa-cogs':
        return 'qi qi-cogs'
      case 'fas fa-question':
        return 'qi qi-question'
      case 'fas fa-newspaper':
        return 'qi qi-newspaper'
      default:
        return '';
    }    
  }

}
