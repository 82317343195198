import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { JWTService } from '../../auth/jwt.service';
import { Token } from '../../auth/token.model';
import { HttpService } from '../../shared/http/httpclient.service';

@Injectable()
export class LoginService {

  constructor(private http: HttpService,
    private jwtService: JWTService) { }

  login(username: string, password: string, provider: string, captcha: string) {
    const params = 'grant_type=password'
          .concat(`&username=${username.concat('|')
          .concat(provider).concat('|')
          .concat(environment.settings.portal)}`)
          .concat(`&password=${password}`)
          .concat(`&response=${captcha}`);
    return this.http.post(`${environment.settings.auth.token}`, params, this.jwtService.getAuthServerHeaders());
  }

  logout(data: Token) {
    return this.http.delete(`${environment.settings.auth.logout}`, this.jwtService.getLogoutHeader());
  }

  loginItau(username: string, password: string, provider: string) {
    const params = 'grant_type=password'.concat(`&username=${username
      .concat('|').concat(provider).concat('|').concat(environment.settings.portal)}`)
      .concat(`&password=${password}`);
    return this.http.post(`${environment.settings.auth.token}/acessoItau`, params, this.jwtService.getAuthServerHeaders());
  }

}
