import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../shared/loader.service';
import { ConsultaApontamento, Apontamento, Negativacao, NegativacaoExcel } from '../shared/apontamento.model';
import { ApontamentoService } from '../shared/apontamento.service';
import Swal from 'sweetalert2';
import { MyErrorStateMatcher } from '../../../shared/error-matcher-input/error-matcher-input.model';
import { ConstantsMaskModel } from '../../../shared/constants/constants-mask.model';
import { JWTService } from '../../../auth/jwt.service';
import * as FileSaver from 'file-saver';
import { Router } from '@angular/router';
import { Validator } from '../../../shared/validators/validator';
import { AUTHORITIES } from '../../../auth/groups.constants';
import { MESSAGES } from '../../../shared/constants/constants-swal.model';
import { Token } from 'src/app/auth/token.model';
import { UtilsService } from '../../../shared/utils.service';
import { Pessoa } from '../../../shared/model/pessoa.model';

@Component({
  selector: 'app-consultar-apontamento',
  templateUrl: './consultar-apontamento.component.html',
  styleUrls: ['./consultar-apontamento.component.scss'],
  providers: [ApontamentoService]
})
export class ConsultarApontamentoComponent implements OnInit {

  @ViewChild('someInput') someInput: ElementRef;
  @ViewChild('someInputCNPJ') someInputCNPJ: ElementRef;
  constructor(private fb: FormBuilder,
    private service: ApontamentoService,
    private sharedService: SharedService,
    private utils: UtilsService,
    private loaderService: LoaderService,
    private jwtService: JWTService,
    private router: Router

  ) { }

  consultarApontamentoForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  possuiNegativacoes: boolean = false;
  jwt: any;
  activeTab: number;
  _blob = Blob;
  _fileSaver = FileSaver;

  todos: Negativacao[] = [];
  apontamentos: Negativacao[] = [];
  historico: Negativacao[] = [];
  suspensas: Negativacao[] = [];
  comunicados: string[] = [];

  tipPessoaControl = new FormControl('', [Validators.required]);
  cpfCnpjControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  cnpjInformanteControl = new FormControl('', [Validator.documentIsValid()]);
  dataIniControl = new FormControl('', [Validator.dateIsValid]);
  dataFimControl = new FormControl('', [Validator.dateIsValid]);
  valorIniControl = new FormControl('', []);
  valorFimControl = new FormControl('', []);
  situacaoControl = new FormControl('', []);
  tipoApontamentoControl = new FormControl('', []);
  nomeDevedorControl = new FormControl({ value: '', disabled: true }, []);
  maskCpf = ConstantsMaskModel.MASK_CPF;
  maskCnpj = ConstantsMaskModel.MASK_CNPJ;
  maskDate = ConstantsMaskModel.MASK_DATE;
  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();
  tamanho: any;
  comZeroAEsquerda: any;
  novoCampoComZeros: any;

  userData: Token;

  //this.cpfCnpjMask.validarCPFCNPJ(teste);

  tabIndex: number = 0;
  ngOnInit() {
    this.consultarApontamentoForm = this.fb.group({
      cpfCnpj: this.cpfCnpjControl,
      dataIni: this.dataIniControl,
      dataFim: this.dataFimControl,
      valorIni: this.valorIniControl,
      valorFim: this.valorFimControl,
      situacao: this.situacaoControl,
      nomeDevedor: this.nomeDevedorControl,
      cnpjInformante: this.cnpjInformanteControl,
      tipoApontamento: this.tipoApontamentoControl,

    });

    this.jwt = this.jwtService.getObject('auth_token') as Token;

    this.userData = this.jwtService.getCookie('auth_token') as Token;
  }

  gerarPDF(apontamentoId: string) {
    this.service.gerarPDF(apontamentoId);
  }

  gerarInformacoes(id: string, datOcorrencia: Date, valorDivida: string, tab: number, natOperacao: string,tipoParticipante: string, cpfCnpj: string, dadoComplementarDivida: string) {
    let negs: Negativacao[];

    switch (tab) {
      case 1:
        negs = this.apontamentos;
        break;
      case 2:
        negs = this.historico;
        break;
      case 3:
        negs = this.suspensas;
        break;
      default:
        return;
    }

    let numDocumento, codArea, numContrato, dataOcorrencia, valorOcorrenciaEnvio;;
    negs.forEach(n => {
      if (n.idNegativacao == id && n.valor == valorDivida && n.datOcorrencia == datOcorrencia) {
        codArea = n.codArea;
        numContrato = n.numContrato;
        numDocumento = n.numDocumento;
        dataOcorrencia = n.datOcorrencia.toString().replace(/\//g, "");
        valorOcorrenciaEnvio = n.valor;
      }
    });

    this.service.gerarInformacoes(numDocumento, codArea, numContrato, dataOcorrencia, valorOcorrenciaEnvio, natOperacao,tipoParticipante, cpfCnpj, dadoComplementarDivida);
  }

  mostrarDetalhe(apontamento:any, sitNegativado:any) {
    let idApontamento = '';

    if(apontamento.tipoParticipante == 'A') {
      let linkNegativacao = apontamento.links.filter(link => link.rel == 'Negativacao')[0];

      idApontamento = this.getIdApontamento(linkNegativacao.href);
    } else {
      idApontamento = apontamento.idNegativacao;
    }

    this.router.navigateByUrl(`/servicos/apontamento/detalheApontamento/${idApontamento}?datOcorrencia=${apontamento.datOcorrencia}&valor=${apontamento.valor}&sitNegativado=${sitNegativado}`);
  }

  formatToCurrency(value: number): string {
    return this.sharedService.formatToCurrency(value);
  }

  getIdApontamento(link: string): string {
    let templink: string[] = link.split('/');
    return templink[templink.length - 1];
  }

  validaCamposNaoObrigatorios(model: ConsultaApontamento): ConsultaApontamento {
    if (!model.valorIni)
      model.valorIni = '';
    if (!model.valorFim)
      model.valorFim = '';
    return model;
  }

  listar(model: ConsultaApontamento) {
    this.validaCamposNaoObrigatorios(model);
    this.loaderService.display(true);
    this.tabIndex = 0;
    this.possuiNegativacoes = false;
    this.apontamentos = [];
    this.historico = [];
    this.suspensas = [];

    // Substitui dos campos de documento os caracteres (. / -)
    model.cpfCnpj = model.cpfCnpj.replace(/[\-/.]/gi, "");

    this.service.listar(model)
      .subscribe(
        (result: Apontamento) => {
          this.loaderService.display(false);
          if ((result.ativas.length == 0) && (result.historico.length == 0) && (result.suspensas.length == 0)) {
            if(result.msgError)
              Swal(result.msgError, "", MESSAGES.TYPE_ERROR);
            else
              Swal(MESSAGES.NAO_RETORNOU_RESULTADOS, "", MESSAGES.TYPE_ERROR);
          } else {
            this.apontamentos = this.filterDataInvalida(result.ativas);
            this.historico = this.filterDataInvalida(result.historico);
            this.suspensas = this.filterDataInvalida(result.suspensas);
            this.possuiNegativacoes = true;
          }
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  filterDataInvalida(negativacoes: Negativacao[]): Negativacao[] {
    return negativacoes.filter(neg => {
      if (neg.datDisponibilidade == "01/01/1900") {
        neg.datDisponibilidade = '';
      }
      return neg;
    });
  }

  
  possuiApontamentos(){
    var h = this.situacaoControl.value;

    if(h == "A" && this.apontamentos.length !== 0){
      return true;
    } if (h == ""){
      return true;
    } else {
      return false;
    }
  }

  possuiHistorico(){
    var h = this.situacaoControl.value;

    if(h == "I" && this.historico.length !== 0){
      return true;
    } if (h == ""){
      return true;
    } else {
      return false;
    }
  }

  possuiSuspensas(){
    var h = this.situacaoControl.value;

    if(h == "I" && this.suspensas.length !== 0){
      return true;
    } if (h == ""){
      return true;
    } else {
      return false;
    }
  }

  baixar(model: ConsultaApontamento) {
    this.loaderService.display(true);
    this.todos = [];
    if (!this.disableNegativacaoAtiva() && this.apontamentos.length !== 0) {
      this.todos = this.todos.concat(this.apontamentos);
    }

    if (!this.disableNegativacaoHistorico() && this.historico.length !== 0) {
      this.todos = this.todos.concat(this.historico);
    }

    if (!this.disableNegativacaoSuspensa() && this.suspensas.length !== 0) {
      this.todos = this.todos.concat(this.suspensas);
    }

    const excel: NegativacaoExcel = new NegativacaoExcel(
      model.cpfCnpj.length == 11 ? 'F' : 'J',
      model.cpfCnpj,
      this.jwt.numDocumento,
      model.dataIni,
      model.dataFim,
      this.tabIndex > 0 ? 'I' : 'A',
      model.valorIni,
      model.valorFim,
      this.todos,
    );

    this.service.baixar(excel)
      .subscribe(
        (res: any) => {
          this.loaderService.display(false);
          const date = new Date();
          const format = date.getFullYear().toString().concat('-')
            .concat((date.getMonth() + 1).toString()).concat('-')
            .concat(date.getDate().toString());
          var blob = new this._blob([res], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation;charset=UTF-8' });
          this._fileSaver.saveAs(blob, `consulta_negativacao_${format}.xlsx`);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  disableNegativacaoAtiva() {
    return !this.sharedService.hasPermission(this.jwt, [AUTHORITIES.CONSULTAR_NEGATIVACAO_ATIVAS]);
  }

  disableNegativacaoSuspensa() {
    return !this.sharedService.hasPermission(this.jwt, [AUTHORITIES.CONSULTAR_NEGATIVACAO_SUSPENSA]);
  }

  disableNegativacaoHistorico() {
    return !this.sharedService.hasPermission(this.jwt, [AUTHORITIES.CONSULTAR_HISTORICO_NEGATIVACAO]);
  }

  canConsultarDetalhe() {
    return this.sharedService.hasPermission(this.jwt, [AUTHORITIES.CONSULTAR_DETALHE_NEGATIVACAO]);
  }

  negativacaoPertenceAoInformante(numDocumento: string): boolean {
    if (numDocumento != this.userData.numDocumento) {
      return false;
    }
    return true;
  }

  canGerarPDF() {
    // return this.sharedService.hasPermission(this.jwt, [AUTHORITIES.GERAR_INFORMACOES_NEGATIVACAO_PDF]);
    return false;
  }

  disableExportarExcel() {
    return !this.sharedService.hasPermission(this.jwt, [AUTHORITIES.EXPORTAR_CONSULTA_NEGATIVACAO_EXCEL]);
  }

  flagComunicado(row: any) : boolean {
    return this.sharedService.hasPermission(this.jwt, [AUTHORITIES.EXPORTAR_CONSULTA_NEGATIVACAO_EXCEL]) && row.statusComunicado != 'N';
  }

  getNumber(cpfCnpjValue) {
    let cpfCnpj = cpfCnpjValue.match(/\d/g);
    let cpfCnpjLength = 0;
    if (cpfCnpj) {
      cpfCnpjLength = cpfCnpj.join('').length;
    }
    return cpfCnpjLength
  }

  tiraMask(cpfCnpjValue) {
    let cpfCnpj = cpfCnpjValue.match(/\d/g);
    let cpfCnpjLength = 0;
    if (cpfCnpj) {
      cpfCnpjLength = cpfCnpj.join('');
    }
    return cpfCnpjLength
  }

  zeroAEsquerda() {
    if(!this.cpfCnpjControl.value)
      return;
    if(this.cpfCnpjControl.value.replace(/_/g, '').length > 11){
      this.cpfCnpjControl.setValue(this.utils.padLeftWithZeros(this.cpfCnpjControl.value, 14));
    } else {
      this.cpfCnpjControl.setValue(this.utils.padLeftWithZeros(this.cpfCnpjControl.value, 11));
    }
  }

  zeroAEsquerdaCNPJ(value: string) {
    if(!this.cnpjInformanteControl.value)
      return;

    this.cnpjInformanteControl.setValue(this.utils.padLeftWithZeros(this.cnpjInformanteControl.value, 14));
  }

  buscarNomeDevedor() {
    this.zeroAEsquerda();
    if (this.cpfCnpjControl.value == 0) {
      this.nomeDevedorControl.setValue("");
      return;
    }
    let documentoSemMascara = this.cpfCnpjControl.value.replace(/_/g, "");
    this.sharedService.getNegativacaoByDoc(documentoSemMascara)
      .subscribe(
        (result) => {
          let nome = '';
          if(result.ativas.length != 0) {
            for(let key in result.ativas){
              nome = result.ativas[key].nomeRazaoSocial;
            }
          } else if (result.historico.length != 0) {
            for(let key in result.historico){
              nome = result.historico[key].nomeRazaoSocial;
            }
          } else if(result.suspensas.length != 0) {
            for(let key in result.suspensas){
              nome = result.suspensas[key].nomeRazaoSocial;
            }
          }
          this.nomeDevedorControl.setValue(nome);
        },
        errors => {
          if (errors.status == 404) {
            this.nomeDevedorControl.setValue('Nome não encontrado na base');
            return;
          }
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }
}
