import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConstantsMaskModel } from 'src/app/shared/constants/constants-mask.model';
import 'rxjs/add/observable/of';
import { SharedService } from 'src/app/shared/shared.service';
import { LoaderService } from 'src/app/shared/loader.service';
import { MESSAGES } from 'src/app/shared/constants/constants-swal.model';
import Swal from 'sweetalert2';
import { JWTService } from 'src/app/auth/jwt.service';
import { Token } from 'src/app/auth/token.model'; 
import { InformanteModel } from 'src/app/shared/model/informante.model';
import { AgendamentoCarteiraService } from '../agendamento-carteira.service';
import { Router } from '@angular/router';
import { MyErrorStateMatcher } from 'src/app/shared/error-matcher-input/error-matcher-input.model';

@Component({
  selector: 'app-agendamento-carteira',
  styleUrls: ['./agendamento-carteira-consulta.component.scss'],
  templateUrl: './agendamento-carteira-consulta.component.html',
})
export class AgendamentoCarteiraConsultaComponent implements OnInit {

  constructor(private sharedServices: SharedService,
    private jwtService: JWTService,
    private loaderService: LoaderService,
    private router: Router,
    private agendamentoCarteiraService: AgendamentoCarteiraService
  ) {}

  exibeConsulta = false;

  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();

  matcher = new MyErrorStateMatcher();

  userData = this.jwtService.getCookie('auth_token') as Token;

  informantes: InformanteModel[] = [];
  agendamentos = [];

  formAgendamentoCarteira: FormGroup;
  cpfConglomeradoConsultaControl = new FormControl('', []);
  razaoSocialConsultaControl = new FormControl({value : '', disabled:true});  

  ngOnInit() {
    this.formAgendamentoCarteira = new FormGroup({
      cpfConglomeradoConsulta: this.cpfConglomeradoConsultaControl,
      razaoSocialConsulta: this.razaoSocialConsultaControl,
    });
    this.buscarListaConglomerado();

  }

  carregarInformanteLogado(informantes: InformanteModel[]) {
    informantes.forEach(informante => {
      if (informante.numDocumento === this.userData.numDocumento) {
        this.cpfConglomeradoConsultaControl.setValue(informante);
        this.carregarNomeRazaoSocial(informante);
      }
    });
  }

  buscarListaConglomerado() {
    this.loaderService.display(true);
    this.sharedServices.getConglomerado(this.userData.numDocumento)
      .subscribe(conglomerado => {
        this.informantes = conglomerado;
        this.buscarListaEmpresas();
        this.carregarInformanteLogado(this.informantes);
        this.loaderService.display(false);
      },
        error => {
          if (error.status === 400) {
            Swal("A requisição está incorreta!", "", MESSAGES.TYPE_ERROR);
          } else if (error.status === 401) {
            Swal(MESSAGES.NAO_POSSUI_ACESSO_FUNCIONALIDADE, "", MESSAGES.TYPE_ERROR);
          } else {
            Swal("Ocorreu um erro inesperado ao consultar!", "", MESSAGES.TYPE_ERROR);
          }
        });
  }

  buscarListaEmpresas() {
    this.loaderService.display(true);
    this.sharedServices.getEmpresasUsuario()
      .subscribe(empresas => {
        empresas.forEach(item => {
          this.informantes.push(item);
        });
        this.loaderService.display(false);
      },
      error => {
        this.loaderService.display(false);
        if (error.status === 400) {
          Swal("A requisição está incorreta!", "", MESSAGES.TYPE_ERROR);
        } else if (error.status === 401) {
          Swal(MESSAGES.NAO_POSSUI_ACESSO_FUNCIONALIDADE, "", MESSAGES.TYPE_ERROR);
        } else {
          Swal(error.error.descricao, "", MESSAGES.TYPE_ERROR);
        }
      });
  }

  carregarNomeRazaoSocial(informanteSelecionado: InformanteModel) {
    this.exibeConsulta = false;
    this.agendamentos = [];
    this.razaoSocialConsultaControl.setValue(informanteSelecionado.nomeRazaoSocial);
  }

  consultar() {
    this.loaderService.display(true);
    const informante = this.cpfConglomeradoConsultaControl.value;
    this.agendamentoCarteiraService.getAgendamentos(informante.numDocumento)
    .subscribe(agendamentos => {
        this.agendamentos = agendamentos;
        this.loaderService.display(false);
      },
      error => {
        if (error.status === 400) {
          Swal("A requisição está incorreta!", "", MESSAGES.TYPE_ERROR);
        } else if (error.status === 401) {
          Swal(MESSAGES.NAO_POSSUI_ACESSO_FUNCIONALIDADE, "", MESSAGES.TYPE_ERROR);
        } else {
          Swal(error.error.message, "", MESSAGES.TYPE_ERROR);
        }
        this.loaderService.display(false);
      });
      this.exibeConsulta = true;
  }
  
  formataValorMoeda(value: number): string {
    return this.sharedServices.formatToCurrency(value);
  }

  formataCnpj(value: number) : string {
    return this.sharedServices.maskACnpj(value.toString());
  }

  novo() {
    this.router.navigateByUrl(`/cadastros/agendamentoCarteira/cadastro?id=null`);
  }

  editar(id: number) {
    this.router.navigateByUrl(`/cadastros/agendamentoCarteira/cadastro?id=${id}&operacao=E`);
  }

  clonar(id: number) {
    this.router.navigateByUrl(`/cadastros/agendamentoCarteira/cadastro?id=${id}&operacao=C`);
  }

  historico(id: number) {
    this.router.navigateByUrl(`/cadastros/agendamentoCarteira/historico?id=${id}`);
  }

  habilitar(agendamento, habilita: boolean) {
    agendamento.status = (habilita ? 'A' : 'I');
    
    this.loaderService.display(true);
    this.agendamentoCarteiraService.salvar(agendamento)
      .subscribe(
        () => {
          this.loaderService.display(false);
          Swal("Agendamento atualizado com sucesso!", "", "success");
          this.router.navigateByUrl(`/cadastros/agendamentoCarteira/consulta`);
        },
        error => {
          this.loaderService.display(false);
          agendamento.status = (habilita ? 'I' : 'A');
          Swal(error.error.message, "", "error")
        }
      );
  }
}