import { ContestacaoSituacaoMotivosModel } from "./contestacao-situacao-motivos.model";
import { LinkModel } from "./link.model"

export class ContestacaoModel {
    constructor(
        public cnpjEmpresa?: string,
        public cpf?: string,
        public datDivida?: string,
        public numContestacao?: string,
        public nomeDevedor?: string,
        public numContrato?: string,
        public razaoSocial?: string,
        public valorDivida?: string,
        public contestacaoSituacaoMotivos?: ContestacaoSituacaoMotivosModel,
        public links?: LinkModel[]
    ){}
}
