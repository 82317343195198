import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ContestacaoSituacaoMotivosModel } from "../../../../shared/model/contestacao-situacao-motivos.model"
import { ContestacaoModel } from "../../../../shared/model/contestacao.model"

@Component({
  selector: 'app-rejeicao-dialog',
  templateUrl: './rejeicao-dialog.component.html',
  styleUrls: ['./rejeicao-dialog.component.scss']
})
export class RejeicaoDialogComponent implements OnInit {

  contestacaoSituacaoMotivos: ContestacaoSituacaoMotivosModel;

  constructor(
    public dialogRef: MatDialogRef<RejeicaoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContestacaoModel,
  ) { }

  ngOnInit() {
    this.contestacaoSituacaoMotivos = this.data.contestacaoSituacaoMotivos
  }

  close(): void {
    this.dialogRef.close();
  }
}
