export class NegativacaoOcorrenciasModel {
    constructor(
        public idNegativacao: string,
        public datOcorrencia: string,
        public sequenciaOcorr: number,
        public valor: number,
        public datBaixaAnt: string,
        public motBaixaAnt: string,
        public sitNegativadoAnt: string,
        public datBaixa: string,
        public motBaixa: string,
        public sitNegativado: string,
        public datProcessamento: string,
        public descBaixa?:string
    ) { 
        this.descBaixa = descBaixa == null ? "":descBaixa;
    }
}

export class AtualizarOcorrenciaModel {
    constructor(
        public ocorrenciaOriginal: NegativacaoOcorrenciasModel,
        public datOcorrencia: string,
        public datOcorrenciaModificada: string,
        public valor: string,
        public valorModificado: string
    ) { }
}