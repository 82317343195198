import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

import { JWTService } from '../../auth/jwt.service';
import { AUTHORITIES } from '../../auth/groups.constants';
import { InformanteModel } from "../../shared/model/informante.model";
import { HttpService } from "../../shared/http/httpclient.service";

@Injectable()
export class ClienteService {
    constructor(private http: HttpService,
                private jwtService : JWTService) { }

    salvarInformante(model: InformanteModel): Observable<InformanteModel> {
        return this.http.post(`${environment.settings.api}/informantes/`, model, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_INFORMACOES_FONTE]));
    }

    atualizarInformante(model: InformanteModel): Observable<InformanteModel> {
        return this.http.put(`${environment.settings.api}/informantes/`, model, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_INFORMACOES_FONTE]));
    }
}