import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { InformanteModel } from '../../../shared/model/informante.model';
import { UsuarioModel } from '../../../shared/model/usuario.model';
import { environment } from '../../../../environments/environment';
import { AUTHORITIES } from '../../../auth/groups.constants';
import { JWTService } from '../../../auth/jwt.service';
import { HttpService } from '../../../shared/http/httpclient.service';
import { ManterusuarioModel } from './manter-usuario-api/manter-usuario.model';


@Injectable()
export class UsuarioService {
  constructor(private http: HttpService,
              private jwtService: JWTService) { }
  @Injectable({
    providedIn: 'root'
  })

  salvarUsuario(model): Observable<UsuarioModel> {
    return this.http.post(`${environment.settings.api}/usuarios`, model, this.jwtService.getApiHeaders([AUTHORITIES.INCLUSAO_USUARIO]));
  }

  editarUsuario(model): Observable<UsuarioModel> {
    return this.http.put(`${environment.settings.api}/usuarios`, model, this.jwtService.getApiHeaders([AUTHORITIES.ALTERACAO_USUARIO]));
  }

  getUsuario(cpf, tipoUsuario, status, possuiFuncao?: String): Observable<UsuarioModel> {
    return this.http.postParams(`${environment.settings.api}/usuarios/cpf/${cpf}/tipoUsuario/${tipoUsuario}/status/${status}`, {},
      this.jwtService.getApiHeaders([AUTHORITIES.CONSULTA_USUARIO]),
      { possuiFuncao: possuiFuncao != null ? possuiFuncao.toString() : '' }
    );
  }

  getAprovadores(tipoUsuario, status, possuiFuncao?: String): Observable<UsuarioModel[]> {
    return this.http.postParams(`${environment.settings.api}/usuarios/tipoUsuario/${tipoUsuario}/status/${status}`, {}, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTA_USUARIO]), { possuiFuncao: possuiFuncao != null ? possuiFuncao.toString() : ''} );
  }

  getUsuarioNonConsumidor(cpf, status): Observable<UsuarioModel> {
    return this.http.post(`${environment.settings.api}/usuarios/cpf/${cpf}/status/${status}`, {}, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTA_USUARIO]));
  }

  getConglomerado(numDocumento: String): Observable<InformanteModel[]> {
    return this.http.post(`${environment.settings.api}/${numDocumento}/conglomerados`, {}, this.jwtService.getApiHeaders([]));
  }
  
  salvarManterUsuario(model: ManterusuarioModel): Observable<ManterusuarioModel> {
    return this.http.post(`${environment.settings.api}/usuarios/api/`, model, this.jwtService.getApiHeaders([AUTHORITIES.INCLUSAO_USUARIO]));
  }

  updateManterUsuario(model: ManterusuarioModel): Observable<ManterusuarioModel> {
    return this.http.put(`${environment.settings.api}/usuarios/api/`, model, this.jwtService.getApiHeaders([AUTHORITIES.INCLUSAO_USUARIO]));
  }

  consultaListaUsuariosPorInformantePaiFilho(usuario: UsuarioModel): Observable<UsuarioModel[]> {    
    return this.http.post(`${environment.settings.api}/usuarios/consultaListaUsuariosPorInformantePaiFilho`, usuario , this.jwtService.getApiHeaders([AUTHORITIES.CONSULTA_USUARIO]));
  }

  inativaUsuario(usuario: UsuarioModel) : Observable<any> {
    return this.http.post(`${environment.settings.api}/usuarios/inativa/id/${usuario.idLogin}`,{},this.jwtService.getApiHeaders([AUTHORITIES.ALTERACAO_USUARIO]));
  }

  exportarExcel(usuario: UsuarioModel[]) {
    return this.http.postBuffer(`${environment.settings.api}/usuarios/relatorio/excel`, usuario, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTA_USUARIO]));
  }
}
