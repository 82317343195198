import { Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';

import { ConfiguracoesComponent } from './configuracoes/configuracoes.component';
import { ControleAcessoComponent } from './configuracoes/controle-acesso/controle-acesso.component';
import { SolicitacaoComponent } from './configuracoes/controle-acesso/solicitacao/solicitacao.component';
import { AlterarUsuarioComponent } from './configuracoes/controle-acesso/solicitacao/alterar-usuario/alterar-usuario.component';
import { AprovarUsuarioComponent } from './configuracoes/controle-acesso/solicitacao/aprovar-usuario/aprovar-usuario.component';
import { SolicitacaoCadastroUsuarioComponent } from './configuracoes/controle-acesso/solicitacao/cadastro-usuario/cadastro-usuario.component';
import { TransferirSolicitacaoComponent } from './configuracoes/controle-acesso/solicitacao/transferir-solicitacao/transferir-solicitacao.component';
import { EsqueciSenhaComponent } from './senha/esquecisenha.component';
import { NovaSenhaComponent } from './senha/novasenha.component';
import { ManterUsuarioApiComponent } from './configuracoes/controle-acesso/usuario/manter-usuario-api/manter-usuario-api.component';
import { ServicosComponent } from './servicos/servicos.component';
import { ApontamentoComponent } from './servicos/apontamento/apontamento.component';
import { AlterarComponent } from './servicos/apontamento/alterar/alterar.component';
import { BaixarComponent } from './servicos/apontamento/baixar/baixar.component';
import { IncluirApontamentoComponent } from './servicos/apontamento/incluir/incluir.component';
import { ContestacaoComponent } from './servicos/contestacao/contestacao.component';
import { ConsultarContestacaoComponent } from './servicos/contestacao/consultar/consultar-contestacao.component';
import { AprovarRejeitarContestacaoComponent } from './servicos/contestacao/aprovar-rejeitar/aprovar-rejeitar-contestacao.component';
import { PepComponent } from './servicos/pep/pep.component';
import { AprovarRejeitarPepComponent } from './servicos/pep/aprovar-rejeitar/aprovar-rejeitar-pep.component';
import { MenorComponent } from './servicos/menor/menor.component';
import { AprovarRejeitarMenorComponent } from './servicos/menor/aprovar-rejeitar/aprovar-rejeitar-menor.component';
import { UsuarioComponent } from './configuracoes/controle-acesso/usuario/usuario.component';
import { LoginComponent } from './login/login.component';
import { ValidaSenhaComponent } from './senha/validasenha.component';
import { NotificacaoComponent } from './servicos/notificacao/notificacao.component';
import { JWTService } from './auth/jwt.service';
import { P401Component } from './auth/error/401.component';
import { P404Component } from './auth/error/404.component';
import { P500Component } from './auth/error/500.component';
import { ConsultarApontamentoComponent } from './servicos/apontamento/consultar/consultar-apontamento.component';
import { DetalheComponent } from './servicos/apontamento/detalhe/detalhe.component';
import { AcessoItauComponent } from './acesso-itau/acesso-itau.component';
import { ConsultaImagemComponent } from './servicos/imagem/consulta-imagem/consulta-imagem.component';
import { ImagemComponent } from './servicos/imagem/imagem.component';
import { StatusRemessaProcessadaComponent } from './remessas/status-remessa-processada/status-remessa-processada.component';
import { ConsultarHistoricoRemessaComponent } from './remessas/consultar-historico-remessa/consultar-historico-remessa.component';
import { RemessasComponent } from './remessas/remessas.component';
import { InstrucoesNovaSenhaComponent } from './senha/instrucoesnovasenha.component';
import { ReenvioSenhaComponent } from './senha/reenviosenha.component';
import { ConsultarListaContestacaoComponent } from './servicos/contestacao/consultar-lista-contestacao/consultar-lista-contestacao.component';
import { CadastrosComponent } from './cadastros/cadastros.component';
import { ClienteComponent } from './cadastros/cliente/cliente.component';
import { MfaComponent } from './mfa/mfa.component';
import { ConsultarUsuarioComponent } from './configuracoes/controle-acesso/usuario/consultar/consultar-usuario.component';
import { ComunicadosComponent } from './servicos/comunicados/comunicados.component';
import { ConsultarComunicadosComponent } from './servicos/comunicados/consultar-comunicados/consultar-comunicados.component';
import { AgendamentoCarteiraConsultaComponent } from './cadastros/agendamento-carteira/consulta/agendamento-carteira-consulta.component';
import { AgendamentoCarteiraCadastroComponent } from './cadastros/agendamento-carteira/cadastro/agendamento-carteira-cadastro.component';
import { AgendamentoCarteiraHistoricoComponent } from './cadastros/agendamento-carteira/historico/agendamento-carteira-historico.component';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    data: {
      title: 'Home',
      icon: 'fas fa-home',
      show: false
    }
  },
  {
    path: 'acessoItau',
    component: AcessoItauComponent,
    data: {
      title: 'Home',
      icon: 'fas fa-home',
      show: false
    }
  },
  {
    path: 'mfa', component: MfaComponent,
    data: {
      title: 'Home',
      icon: 'fas fa-home',
      show: false
    }
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [JWTService],
    data: {
      title: 'Home',
      icon: 'fas fa-home',
      show: true
    }, children: [
      {
        path: 'Home',
        component: HomeComponent,
        data: {
          link: '/home',
          icon: 'fas fa-home',
          title: 'Home',
          show: true
        },
        children: []
      }]
  },
  {
    path: 'login', component: LoginComponent,
    data: {
      title: 'Home',
      icon: 'fas fa-home',
      show: false
    },
  },
  {
    path: 'recuperar-senha', component: EsqueciSenhaComponent,
    data: {
      title: 'Home',
      icon: 'fas fa-home',
      show: false
    }
  },
  {
    path: 'validaToken', component: ValidaSenhaComponent,
    data: {
      title: 'Home',
      icon: 'fas fa-home',
      show: false
    }
  },
  {
    path: 'nova-senha', component: NovaSenhaComponent,
    data: {
      title: 'Home',
      icon: 'fas fa-home',
      show: false
    }
  },
  {
    path: 'instrucoes-senha', component: InstrucoesNovaSenhaComponent,
    data: {
      title: 'Home',
      icon: 'fas fa-home',
      show: false
    }
  },
  {
    path: 'reenvio-senha', component: ReenvioSenhaComponent,
    data: {
      title: 'Home',
      icon: 'fas fa-home',
      show: false
    }
  },
  {
    path: 'error', component: P401Component,
    data: {
      title: 'Erro de Autenticacao',
      icon: 'fas fa-error',
      show: false
    }
  },
  {
    path: '404', component: P404Component,
    data: {
      title: 'Pagina não encontrada',
      icon: 'fas fa-error',
      show: false
    }
  },
  {
    path: '500', component: P500Component,
    data: {
      title: 'Erro interno',
      icon: 'fas fa-error',
      show: false
    }
  },
  {
    path: 'servicos',
    component: ServicosComponent,
    children: [
      {
        path: 'imagem',
        component: ImagemComponent,
        canActivate: [JWTService],
        data: {
          link: 'servicos/imagem/',
          icon: 'fas fa-glasses',
          title: 'Imagem',
          show: true
        },
        children: [     {
          path: 'consultaImagem',
          component: ConsultaImagemComponent,
          canActivate: [JWTService],
          data: {
            link: 'servicos/imagem/consultaImagem',
            title: 'Consultar Imagem',
            show: true
          },
          children: []
        }]
      },
      {
        path: 'apontamento',
        component: ApontamentoComponent,
        canActivate: [JWTService],
        data: {
          link: 'servicos/apontamento',
          icon: 'fas fa-glasses',
          title: 'Apontamento',
          show: true
        },
        children: [
          {
            path: 'consultarApontamento',
            component: ConsultarApontamentoComponent,
            canActivate: [JWTService],
            data: {
              link: 'servicos/apontamento/consultarApontamento',
              title: 'Consultar Apontamento',
              show: true
            },
            children: []
          },
          {
            path: 'incluirApontamento',
            component: IncluirApontamentoComponent,
            canActivate: [JWTService],
            data: {
              link: 'servicos/apontamento/incluirApontamento',
              title: 'Incluir Apontamento',
              show: true
            },
            children: []
          },
          {
            path: 'alterarApontamento',
            component: AlterarComponent,
            canActivate: [JWTService],
            data: {
              link: 'servicos/apontamento/alterarApontamento',
              title: 'Alterar Apontamento',
              show: false
            },
            children: []
           },
           {
            path: 'detalheApontamento/:id',
           component: DetalheComponent,
             canActivate: [JWTService],
             data: {
               link: 'servicos/apontamento/detalheApontamento/:id',
               title: 'Detalhe Apontamento',
               show: false
             },
             children: []
           },
           {
              path: 'baixarApontamento',
              component: BaixarComponent,
              canActivate: [JWTService],
              data: {
                link: 'servicos/apontamento/baixarApontamento',
                title: 'Baixar Apontamento',
                show: false
              },
              children: []
            }
        ]
      },
      {
        path: 'comunicados',
        component: ComunicadosComponent,
        canActivate: [JWTService],
        data: {
          link: 'servicos/comunicados',
          title: 'Comunicados',
          show: true
        },
        children: [
          {
            path: 'consultarComunicados',
            component: ConsultarComunicadosComponent,
            canActivate: [JWTService],
            data: {
              link: 'servicos/comunicados/consultarComunicados',
              title: 'Consultar Comunicados',
              show: true
            },
            children: []
          },
        ]
      },
      {
        path: 'contestacao',
        component: ContestacaoComponent,
        canActivate: [JWTService],
        data: {
          link: 'servicos/contestacao',
          icon: 'fas fa-glasses',
          title: 'Contestação',
          show: false
        },
        children: [
          {
            path: 'consultaContestacao',
            component: ConsultarContestacaoComponent,
            canActivate: [JWTService],
            data: {
              link: 'servicos/contestacao/consultaContestacao',
              title: 'Análise de Contestações',
              show: false
            },
            children: []
          },
          {
            path: 'aprovarRejeitarContestacao',
            component: AprovarRejeitarContestacaoComponent,
            canActivate: [JWTService],
            data: {
              link: 'servicos/contestacao/aprovarRejeitarContestacao',
              show: false
            },
            children: []
          },
          {
            path: 'consultarListaContestacao',
            component: ConsultarListaContestacaoComponent,
            canActivate: [JWTService],
            data: {
              link: 'servicos/contestacao/consultarListaContestacao',
              title: 'Consultar',
              show: false
            },
            children: []
          }
        ]
      },
      {
        path: 'excecao',
        component: PepComponent,
        canActivate: [JWTService],
        data: {
          link: 'servicos/excecoes',
          icon: 'fas fa-glasses',
          title: 'Exceções',
          show: false
        },
        children: [
          {
            path: 'aprovarRejeitarExcecoes',
            component: AprovarRejeitarPepComponent,
            canActivate: [JWTService],
            data: {
              link: 'servicos/excecao/aprovarRejeitarExcecoes',
              title: 'Análise de Exceção',
              show: false
            },
            children: []
          }
        ]
      },
      {
        path: 'menor',
        component: MenorComponent,
        data: {
          link: 'servicos/menor',
          icon: 'fas fa-glasses',
          title: 'Menor',
          show: false
        },
        children: [
          {
            path: 'aprovarRejeitarMenor',
            component: AprovarRejeitarMenorComponent,
            canActivate: [JWTService],
            data: {
              link: 'servicos/menor/aprovarRejeitarMenor',
              title: 'Aprovar / Rejeitar',
              show: false
            },
            children: []
          }
        ]
      },
      {
         path: 'notificacao',
         component: NotificacaoComponent,
         canActivate: [JWTService],
         data: {
           link: 'servicos/notificacao',
           //title: 'Notificações',
           show: false
         },
         children: []
       }
    ],
    data: {
      title: 'Serviços',
      icon: 'fas fa-glasses',
      show: true
    }
  },
  {
    path: 'cadastros',
    component: CadastrosComponent,
    children: [
      {
        path: 'cliente',
        component: ClienteComponent,
        canActivate: [JWTService],
        data: {
          link: 'cadastros/cliente',
          icon: 'fas fa-plus-circle',
          title: 'Cliente',
          show: false
        },
        children: []
      },
      {
        path: 'agendamentoCarteira/consulta',
        component: AgendamentoCarteiraConsultaComponent,
        canActivate: [JWTService],
        data: {
          link: 'cadastros/agendamentoCarteira/consulta',
          title: 'Agendamento Carteira',
          show: true
        },
        children: []
      },
      {
        path: 'agendamentoCarteira/cadastro',
        component: AgendamentoCarteiraCadastroComponent,
        canActivate: [JWTService],
        data: {
          link: 'cadastros/agendamentoCarteira/cadastro',
          title: 'Cadastrar agendamento carteira',
          show: false
        },
        children: []
      },
      {
        path: 'agendamentoCarteira/historico',
        component: AgendamentoCarteiraHistoricoComponent,
        canActivate: [JWTService],
        data: {
          link: 'cadastros/agendamentoCarteira/historico',
          title: 'Histórico Agendamento Carteira',
          show: false
        },
        children: []
      },
    ],
    data: {
      title: 'Cadastros',
      icon: 'fas fa-plus-circle',
      show: true
    }
  },
  {
    path: 'configuracoes',
    component: ConfiguracoesComponent,
    children: [
      {
        path: 'controleAcesso',
        component: ControleAcessoComponent,
        canActivate: [JWTService],
        data: {
          link: 'configuracoes/controleAcesso',
          icon: 'fas fa-cogs',
          title: 'Controle de Acesso',
          show: true
        },
        children: [
        {
          path: 'usuario',
          component: UsuarioComponent,
          canActivate: [JWTService],
          data: {
            link: 'configuracoes/controleAcesso/usuario',
            title: 'Usuário',
            show: true
          },
          children: [
            {
              path: 'manterUsuarioApi',
              component: ManterUsuarioApiComponent,
              canActivate: [JWTService],
              data: {
                link: 'configuracoes/controleAcesso/usuario/manterUsuarioApi',
                title: 'Manter Usuário API',
                show: true
              },
              children: []
            },
            {
              path: 'ConsultarUsuarioComponent',
              component: ConsultarUsuarioComponent,
              canActivate: [JWTService],
              data: {
                link: 'configuracoes/controleAcesso/usuario/ConsultarUsuarioComponent',
                title: 'Consultar usuário',
                show: true
              },
              children: []
            }
          ]
        },
          {
            path: 'solicitacao',
            component: SolicitacaoComponent,
            canActivate: [JWTService],
            data: {
              link: 'configuracoes/controleAcesso/solicitacao',
              title: 'Solicitação',
              show: true
            },
            children: [
              {
                path: 'cadastroUsuario',
                component: SolicitacaoCadastroUsuarioComponent,
                canActivate: [JWTService],
                data: {
                  link: 'configuracoes/controleAcesso/solicitacao/cadastroUsuario',
                  title: 'Solicitação de Cadastro de Usuário',
                  show: true
                },
                children: []
              },
              {
                path: 'alterarUsuario',
                component: AlterarUsuarioComponent,
                canActivate: [JWTService],
                data: {
                  link: 'configuracoes/controleAcesso/solicitacao/alterarUsuario',
                  title: 'Análise de Solicitação de Alteração de Usuário',
                  show: true
                },
                children: []
              },
              {
                path: 'aprovarUsuario',
                component: AprovarUsuarioComponent,
                canActivate: [JWTService],
                data: {
                  link: 'configuracoes/controleAcesso/solicitacao/aprovarUsuario',
                  title: 'Análise de Solicitações de Usuários',
                  show: true
                },
                children: []
              },
              {
                path: 'transferirSolicitacao',
                component: TransferirSolicitacaoComponent,
                canActivate: [JWTService],
                data: {
                  link: 'configuracoes/controleAcesso/solicitacao/transferirSolicitacao',
                  title: 'Transferir Solicitação de Aprovação',
                  show: true
                },
                children: []
              }
            ]
          }
        ]
      },
    ],
    data: {
      title: 'Configurações',
      icon: 'fas fa-cogs',
      show: true
    }
  },
  {
    path: 'remessas',
    component: RemessasComponent,
    children: [
      {
        path: 'historicoRemessa',
        component: ConsultarHistoricoRemessaComponent,
        canActivate: [JWTService],
        data: {
          link: 'remessas/historicoRemessa',
          icon: 'fas fa-newspaper',
          title: 'Consultar Histórico de Remessa',
          show: true
        },
        children: []
      },
      {
        path: 'statusRemessaProcessada',
        component: StatusRemessaProcessadaComponent,
        canActivate: [JWTService],
        data: {
          link: 'remessas/statusRemessaProcessada',
          icon: 'fas fa-newspaper',
          title: 'Consultar Status Remessa Processada',
          show: true
        },
        children: []
      }
    ],
    data: {
      title: 'Remessas',
      icon: 'fas fa-newspaper',
      show: true
    }
  },
  {
    path: '**',
    redirectTo: '404',
    data: {
      show: false
    }
  }
]
