import { Injectable } from "@angular/core";
import { FuncaoModel } from "../shared/funcao.model";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";

import { JWTService } from "../../../auth/jwt.service";
import { AUTHORITIES } from "../../../auth/groups.constants";
import { HttpService } from "../../../shared/http/httpclient.service";

@Injectable()
export class FuncionalidadeService {
    constructor(private http: HttpService,
                private jwtService: JWTService) { }

    salvarFuncao(model: FuncaoModel): Observable<FuncaoModel>{
        return this.http.put(`${environment.settings.api}/funcoes`, model, this.jwtService.getApiHeaders([AUTHORITIES.ALTERAR_FUNCIONALIDADE]));
    }
}