import { CookieService } from 'ngx-cookie';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Token } from './token.model';
import { WSO2 } from './wso2.model';
import Swal from 'sweetalert2';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AUTH } from './routes.constants';
import { Observable } from 'rxjs';
import { MESSAGES } from '../shared/constants/constants-swal.model';
import { KEYS } from '../shared/constants/constants-keys.model';

@Injectable()
export class JWTService {

  helper: JwtHelperService = new JwtHelperService();

  constructor(private http: HttpClient,
    private _cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) { }

  check() : Observable<any> {
    return this.http.get(`${environment.settings.api}/check`, { headers : this.getApiHeaders([]) });
  }

  loggedIn() {
    return !(this.isExpired('jwt'));
  }

  getAccessToken() {
    const params = 'grant_type=client_credentials';

    return this.http.post(`${environment.settings.auth.wso2}`, params,
      {
        headers: this.getWSO2Headers()
      });
  }

  getJwt(token) {
    const jwt = JSON.stringify(this._cookieService.getObject(token));
    return jwt == null ? null : this.decode(jwt);
  }

  putObjectLocalStorage(id, value) {
    this.localStorageService.set(id, value);
  }

  getObjectLocalStorage(id): any {
    return this.localStorageService.get(`${id}`);
  }

  removeObjectLocalStorage(id) {
    return this.localStorageService.remove(`${id}`);
  }

  getCookie(key) {
    return this.decodeJWT(this._cookieService.get(key));
  }

  getObject(key) : any {
    const jwt = this._cookieService.getObject(key);
    return jwt;
  }

  putObject(key, object, expiredDate) {
    return this._cookieService.putObject(key, object, { httpOnly: true, secure: environment.settings.secure_flag, expires: expiredDate });
  }

  removeCookie(key) {
    return this._cookieService.remove(key);
  }

  removeAllCookies() {
    return this._cookieService.removeAll();
  }

  decodeJWT(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  getTokenExpirationDate(token) {
    return this.helper.getTokenExpirationDate(token);
  }

  isExpired(type) {
    var token: any = {};

    if (type === 'wso2')
      token = this.getObject('wso2_access') as WSO2;
    else if (type === 'jwt')
      token = this.getObject('auth_token') as Token;
    else return true;

    if (token) {
      var dt = new Date();

      if (dt.getTime() > Date.parse(token.expires_in))
        return true;
      else return false;

    } else return true;

  }

  decode(token) {
    const decoded = this.helper.decodeToken(token.access_token ? token.access_token : token);
    return decoded;
  }

  getWSO2Headers(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': environment.settings.headers.content_type_urlencoded,
      'Authorization': KEYS.WSO2
    });
  }

  getSignInHeaders(): HttpHeaders {
    this.authCheck(true);
    const wso2 = this.getWSO2();

    let headers = new HttpHeaders({
      'Content-Type': environment.settings.headers.content_type_json
    });

    headers = this.appendSecurityHeaders(headers);

    if (environment.settings.wso2_token_required) {
      headers = headers.append('X-Authorization', environment.settings.headers.token_type.concat(wso2.access_token));
    }

    return headers;
  }

  getAuthServerHeaders(): HttpHeaders {
    this.authCheck(true);
    const wso2 = this.getWSO2();

    let headers = new HttpHeaders({
      'Content-Type': environment.settings.headers.content_type_urlencoded
    });

    headers = this.appendSecurityHeaders(headers);

    if (environment.settings.wso2_token_required) {
      headers = headers.append('X-Authorization', environment.settings.headers.token_type.concat(wso2.access_token));
    }

    return headers;
  }

  getLogoutHeader(): HttpHeaders {
    const wso2 = this.getWSO2();
    const token = this.getObject('auth_token') as Token;

    let headers = new HttpHeaders({
      'Content-Type': environment.settings.headers.content_type_urlencoded,
      'Authorization': 'Bearer '.concat(token.access_token)
    });

    headers = this.appendSecurityHeaders(headers);

    if (environment.settings.wso2_token_required)
      headers = headers.append('X-Authorization', environment.settings.headers.token_type.concat(wso2.access_token));

    return headers;
  }

  getApiHeaders(gruposPermitidos: string[]): HttpHeaders {
    this.checkJWT();
    const wso2 = this.getWSO2();
    const token = this.getObject('auth_token') as Token;
    const gruposUsuario: string[] = this.decode(token.access_token).authorities;

    if(this.tokenProximoDeExpirar()){
      this.refreshToken();
    }

    let headers = new HttpHeaders({
      'Content-Type': environment.settings.headers.content_type_json,
      'Authorization': 'Bearer '.concat(token.access_token)
    });

    headers = this.appendSecurityHeaders(headers);

    if (this.allowAccess(gruposUsuario, gruposPermitidos)) {
      if (environment.settings.wso2_token_required) {
        headers = headers.append('X-Authorization', environment.settings.headers.token_type.concat(wso2.access_token));
      }
    } else {
      if (gruposPermitidos.indexOf('90') !== 0) { 
 //       Swal(MESSAGES.NAO_POSSUI_ACESSO, "", MESSAGES.TYPE_ERROR);
      }
      return null;
    }
    return headers;
  }

  getApiHeadersMultypart() : HttpHeaders {
    try {
      this.checkJWT();
      const wso2 = this.getWSO2();
      const token = this.getObject('auth_token');

      let headers = new HttpHeaders({
        'Authorization': 'Bearer '.concat(token.access_token)
      });

      if (environment.settings.wso2_token_required) {
        headers = headers.append('X-Authorization', environment.settings.headers.token_type.concat(wso2.access_token));
      }

      return headers;
      
    } catch(e) {
      Swal(MESSAGES.ERRO_DESCONHECIDO, "", MESSAGES.TYPE_ERROR)
      this.reiniciarSessao();
    }
  }

  appendSecurityHeaders(header : HttpHeaders) : HttpHeaders {
    header = header.append('X-XSS-Protection', '1; mode=block');
    header = header.append('Content-Security-Policy', 'script-src \'self\'');
    header = header.append('Strict-Transport-Security', 'max-age=31536000; includeSubDomains');
    header = header.append('Cache-Control', 'no-cache');
    header = header.append('Pragma', 'no-cache');
    header = header.append('Set-Cookie', `HttpOnly; Secure; SameSite=strict; Path=${environment.settings.path};`);
    header = header.append('portalOrigem', `${environment.settings.portal}`);
    return header;
  }

  refreshTokenRequest(token: string): Observable<Token> {
    let params = `grant_type=refresh_token&refresh_token=${token}`;
    return this.http.post<Token>(`${environment.settings.auth.token}`, params, {
        headers: this.getAuthServerHeaders()
    });
  }

  refreshToken(){
    this.refreshTokenRequest(this.getObject('auth_token').refresh_token)
      .subscribe(
        refreshToken => {
          let dt = new Date();
          dt.setSeconds(dt.getSeconds() + refreshToken.expires_in);
          refreshToken.expires_in = dt;
          this.putObject('auth_token', refreshToken, dt);
        },
        errors => { }
      );
  }

  tokenProximoDeExpirar(): boolean{
    let tokenParaRefresh = this.getCookie('auth_token') as Token;
    if(!tokenParaRefresh || !tokenParaRefresh.expires_in)
      return;
    let dataAtual = new Date(); 
    let dataToken = new Date(tokenParaRefresh.expires_in);
    let timeDiff = dataToken.getTime() - dataAtual.getTime();
    let diffSeconds = Math.ceil(timeDiff / 1000); 
    if(diffSeconds <= environment.settings.auth.timeLimitForRefresh)
      return true;
    return false;
  }

  
  checkJWT() : boolean {
    return this.authCheck(false);
  }

  getWSO2() : any {
    const token = this.getObject('wso2_access') as WSO2;
    if (environment.settings.wso2_token_required) {
      if(!token) {
        this.getAccessToken()
            .subscribe(
              (wso2: any) => {
                var dt = new Date();
                dt.setSeconds(dt.getSeconds() + wso2.expires_in);
                wso2.expires_in = dt;
                this.putObject('wso2_access', wso2, dt);
                return wso2;
              }
            );
      } else return token;
    } else return null;
  }

  authCheck(ignoreJwt) : boolean {
    if (environment.settings.wso2_token_required) {
      if (this.isExpired('wso2')) {
        this.getAccessToken()
          .subscribe(
            (jwt: any) => {
              var dt = new Date();
              dt.setSeconds(dt.getSeconds() + jwt.expires_in);
              jwt.expires_in = dt;
              this.putObject('wso2_access', jwt, dt);
              return true;
            }
          );
      } else return true;
    }

    if (!ignoreJwt && this.isExpired('jwt')) {
      this.putObjectLocalStorage('route', this.router.url);
      Swal(MESSAGES.SESSAO_EXPIRADA, "", MESSAGES.TYPE_ERROR);
      this.router.navigate(['login']);
      return false;
    } else return true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = this.getObject('auth_token') as any;
    if (!token) {
      this.router.navigate(['/login']);
      return false;
    }
    if (this.loggedIn()) {
      if (this.checkGroupPermission(state.url, this.decode(token).authorities)) {
        return true;
      }
    } else {
      this.router.navigate(['error']);
      return false;
    }

    this.router.navigate(['error']);
    return false;
  }

  checkGroupPermission(url: string, grupos: string[]): boolean {
    switch (url) {
      case AUTH.BAIXAR_REGISTRO_PASSAGEM['path']:
        return this.allowAccess(grupos, AUTH.BAIXAR_REGISTRO_PASSAGEM['groups']);
      case AUTH.INCLUIR_USUARIO['path']:
        return this.allowAccess(grupos, AUTH.INCLUIR_USUARIO['groups']);
      case AUTH.ALTERAR_USUARIO['path']:
        return this.allowAccess(grupos, AUTH.ALTERAR_USUARIO['groups']);
      case AUTH.CONSULTA_USUARIO['path']:
        return this.allowAccess(grupos, AUTH.CONSULTA_USUARIO['groups']);
      case AUTH.INCLUIR_PERFIL['path']:
        return this.allowAccess(grupos, AUTH.INCLUIR_PERFIL['groups']);
      case AUTH.CONSULTAR_PERFIL['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_PERFIL['groups']);
      case AUTH.ALTERAR_PERFIL['path']:
        return this.allowAccess(grupos, AUTH.ALTERAR_PERFIL['groups']);
      case AUTH.CONSULTAR_FUNCIONALIDADE['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_FUNCIONALIDADE['groups']);
      case AUTH.ALTERAR_FUNCIONALIDADE['path']:
        return this.allowAccess(grupos, AUTH.ALTERAR_FUNCIONALIDADE['groups']);
      case AUTH.ASSOCIAR_PERFIL_FUNCIONALIDADE['path']:
        return this.allowAccess(grupos, AUTH.ASSOCIAR_PERFIL_FUNCIONALIDADE['groups']);
      case AUTH.SOLICITAR_CADASTRAMENTO_USUARIO['path']:
        return this.allowAccess(grupos, AUTH.SOLICITAR_CADASTRAMENTO_USUARIO['groups']);
      case AUTH.SOLICITAR_ALTERACAO_USUARIO['path']:
        return this.allowAccess(grupos, AUTH.SOLICITAR_ALTERACAO_USUARIO['groups']);
      case AUTH.CONSULTAR_SOLICITACAO['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_SOLICITACAO['groups']);
      case AUTH.APROVAR_REJEITAR_SOLICITACAO['path']:
        return this.allowAccess(grupos, AUTH.APROVAR_REJEITAR_SOLICITACAO['groups']);
      case AUTH.TRANSFERIR_SOLICITACAO['path']:
        return this.allowAccess(grupos, AUTH.TRANSFERIR_SOLICITACAO['groups']);
      case AUTH.CONSULTAR_NATUREZA_OPERACAO['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_NATUREZA_OPERACAO['groups']);
      case AUTH.INCLUIR_NATUREZA_OPERACAO['path']:
        return this.allowAccess(grupos, AUTH.INCLUIR_NATUREZA_OPERACAO['groups']);
      case AUTH.ALTERAR_NATUREZA_OPERACAO['path']:
        return this.allowAccess(grupos, AUTH.ALTERAR_NATUREZA_OPERACAO['groups']);
      case AUTH.EXCLUIR_NATUREZA_OPERACAO['path']:
        return this.allowAccess(grupos, AUTH.EXCLUIR_NATUREZA_OPERACAO['groups']);
      case AUTH.CONSULTAR_CODIGO_RETORNO['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_CODIGO_RETORNO['groups']);
      case AUTH.INCLUIR_CODIGO_RETORNO['path']:
        return this.allowAccess(grupos, AUTH.INCLUIR_CODIGO_RETORNO['groups']);
      case AUTH.ALTERAR_CODIGO_RETORNO['path']:
        return this.allowAccess(grupos, AUTH.ALTERAR_CODIGO_RETORNO['groups']);
      case AUTH.EXCLUIR_CODIGO_RETORNO['path']:
        return this.allowAccess(grupos, AUTH.EXCLUIR_CODIGO_RETORNO['groups']);
      case AUTH.CONSULTAR_MOTIVOS_BAIXA['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_MOTIVOS_BAIXA['groups']);
      case AUTH.INCLUIR_MOTIVOS_BAIXA['path']:
        return this.allowAccess(grupos, AUTH.INCLUIR_MOTIVOS_BAIXA['groups']);
      case AUTH.ALTERAR_MOTIVOS_BAIXA['path']:
        return this.allowAccess(grupos, AUTH.ALTERAR_MOTIVOS_BAIXA['groups']);
      case AUTH.EXCLUIR_MOTIVOS_BAIXA['path']:
        return this.allowAccess(grupos, AUTH.EXCLUIR_MOTIVOS_BAIXA['groups']);
      case AUTH.INCLUIR_MODELO_CARTA_FONTE['path']:
        return this.allowAccess(grupos, AUTH.INCLUIR_MODELO_CARTA_FONTE['groups']);
      case AUTH.ALTERAR_MODELO_CARTA_FONTE['path']:
        return this.allowAccess(grupos, AUTH.ALTERAR_MODELO_CARTA_FONTE['groups']);
      case AUTH.EXCLUIR_MODELO_CARTA_FONTE['path']:
        return this.allowAccess(grupos, AUTH.EXCLUIR_MODELO_CARTA_FONTE['groups']);
      case AUTH.CONSULTAR_CODIGO_REMESSA['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_CODIGO_REMESSA['groups']);
      case AUTH.INCLUIR_CODIGO_REMESSA['path']:
        return this.allowAccess(grupos, AUTH.INCLUIR_CODIGO_REMESSA['groups']);
      case AUTH.ALTERAR_CODIGO_REMESSA['path']:
        return this.allowAccess(grupos, AUTH.ALTERAR_CODIGO_REMESSA['groups']);
      case AUTH.EXCLUIR_CODIGO_REMESSA['path']:
        return this.allowAccess(grupos, AUTH.EXCLUIR_CODIGO_REMESSA['groups']);
      case AUTH.CONSULTAR_INFORMACAO_FONTE['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_INFORMACAO_FONTE['groups']);
      case AUTH.CONSULTAR_PRODUTO['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_PRODUTO['groups']);
      case AUTH.CONSULTAR_CONTRATO['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_CONTRATO['groups']);
      case AUTH.CONSULTAR_REGRAS_FONTE['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_REGRAS_FONTE['groups']);
      case AUTH.MANUTENCAO_PARAMETRO_LOCALIDADE['path']:
        return this.allowAccess(grupos, AUTH.MANUTENCAO_PARAMETRO_LOCALIDADE['groups']);
      case AUTH.INCLUIR_NEGATIVACAO['path']:
        return this.allowAccess(grupos, AUTH.INCLUIR_NEGATIVACAO['groups']);
      case AUTH.ALTERAR_NEGATIVACAO['path']:
        return this.allowAccess(grupos, AUTH.ALTERAR_NEGATIVACAO['groups']);
      case AUTH.BAIXAR_NEGATIVACAO['path']:
        return this.allowAccess(grupos, AUTH.BAIXAR_NEGATIVACAO['groups']);
      case AUTH.CONSULTAR_APONTAMENTO['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_APONTAMENTO['groups']);
      case AUTH.VISUALIZAR_CARTA['path']:
        return this.allowAccess(grupos, AUTH.VISUALIZAR_CARTA['groups']);
      case AUTH.SOLICITAR_CONTESTACAO['path']:
        return this.allowAccess(grupos, AUTH.SOLICITAR_CONTESTACAO['groups']);
      case AUTH.APROVAR_REJEITAR_CONTESTACAO['path']:
        return this.allowAccess(grupos, AUTH.APROVAR_REJEITAR_CONTESTACAO['groups']);
      case AUTH.APROVAR_REJEITAR_PEP['path']:
        return this.allowAccess(grupos, AUTH.APROVAR_REJEITAR_PEP['groups']);
      case AUTH.APROVAR_REJEITAR_MENOR['path']:
        return this.allowAccess(grupos, AUTH.APROVAR_REJEITAR_MENOR['groups']);
      case AUTH.SUSPENDER_OFICIO_JUDICIAL['path']:
        return this.allowAccess(grupos, AUTH.SUSPENDER_OFICIO_JUDICIAL['groups']);
      case AUTH.REINCLUIR_OFICIO_JUDICIAL['path']:
        return this.allowAccess(grupos, AUTH.REINCLUIR_OFICIO_JUDICIAL['groups']);
      case AUTH.SOLICITACAO_CARTA_AVULSA['path']:
        return this.allowAccess(grupos, AUTH.SOLICITACAO_CARTA_AVULSA['groups']);
      case AUTH.MONITORAMENTO_REMESSA['path']:
        return this.allowAccess(grupos, AUTH.MONITORAMENTO_REMESSA['groups']);
      case AUTH.CONSULTAR_HISTORICO_REMESSA['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_HISTORICO_REMESSA['groups']);
      case AUTH.CONSULTAR_HISTORICO_REMESSA_INFORMACIONAL['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_HISTORICO_REMESSA_INFORMACIONAL['groups']);
      case AUTH.CONSULTAR_HISTORICO_ATUALIZACAO_ONLINE['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_HISTORICO_ATUALIZACAO_ONLINE['groups']);
      case AUTH.CONSULTA_USUARIO['path']:
        return this.allowAccess(grupos, AUTH.CONSULTA_USUARIO['groups']);
      case AUTH.ALTERACAO_USUARIO['path']:
        return this.allowAccess(grupos, AUTH.ALTERACAO_USUARIO.groups);
      case AUTH.INCLUSAO_USUARIO['path']:
        return this.allowAccess(grupos, AUTH.INCLUSAO_USUARIO['groups']);
      case AUTH.AGENDAMENTO_BASES['path']:
        return this.allowAccess(grupos, AUTH.AGENDAMENTO_BASES['groups']);
      case AUTH.CONSULTAR_CONTESTACAO['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_CONTESTACAO['groups']);
      case AUTH.CONSULTAR_IMAGEM['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_IMAGEM['groups']);
      case AUTH.USUARIO_API['path']:
        return this.allowAccess(grupos, AUTH.USUARIO_API['groups']);
      case AUTH.CONSULTAR_LISTA_CONTESTACAO['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_LISTA_CONTESTACAO['groups']);
      case AUTH.CONSULTAR_DASHBOARD['path']:
        return this.allowAccess(grupos, AUTH.CONSULTAR_DASHBOARD['groups']);
      case AUTH.AGENDAMENTO_CARTEIRA['path']:
        return this.allowAccess(grupos, AUTH.AGENDAMENTO_CARTEIRA['groups']);
      default:
        return true;
    }
  }

  allowAccess(gruposUsuario: string[], gruposPermitidos: string[]): boolean {
    if (gruposPermitidos.length == 0) {
     return true;
    } else {
     return gruposPermitidos.some(function (v) {
       return gruposUsuario.indexOf(v) >= 0;
     });
    }
  }

  reiniciarSessao() {
    this.removeAllCookies();
    this.router.navigate(['/login']);
  }
}