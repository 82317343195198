import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConstantsMaskModel } from 'src/app/shared/constants/constants-mask.model';
import 'rxjs/add/observable/of';
import { SharedService } from 'src/app/shared/shared.service';
import { LoaderService } from 'src/app/shared/loader.service';
import { AgendamentoCarteiraService } from '../agendamento-carteira.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MESSAGES } from 'src/app/shared/constants/constants-swal.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agendamento-carteira-historico',
  styleUrls: ['./agendamento-carteira-historico.component.scss'],
  templateUrl: './agendamento-carteira-historico.component.html',
})
export class AgendamentoCarteiraHistoricoComponent implements OnInit {

  constructor(private sharedServices: SharedService,
    private loaderService: LoaderService,
    private agendamentoCarteiraService: AgendamentoCarteiraService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  idAgendamento: number;
  envios = [];

  formHistorico: FormGroup;
  
  idControl = new FormControl('', []);
  cnpjControl = new FormControl('', []);
  razaoSocialControl = new FormControl('', []);
  codAreaControl = new FormControl('', []);
  areaControl = new FormControl('', []);
  dividaDeControl = new FormControl('', []);
  dividaAteControl = new FormControl('', []);
  tipoPessoaControl = new FormControl('', []);

  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();

  ngOnInit() {
    this.formHistorico = new FormGroup({
      id: this.idControl,
      cnpj: this.cnpjControl,
      razaoSocial: this.razaoSocialControl,
      codArea: this.codAreaControl,
      area: this.areaControl,
      dividaDe: this.dividaDeControl,
      dividaAte: this.dividaAteControl,
      tipoPessoa: this.tipoPessoaControl
    });

    this.route.queryParams.subscribe(params => {
      this.idAgendamento = params['id'];
    });

    this.pesquisaEnvios();
  }

  pesquisaEnvios() {
    this.loaderService.display(true);
    this.agendamentoCarteiraService.getHistoricoEnvios(this.idAgendamento)
      .subscribe(historico => {
        this.idControl.setValue(this.idAgendamento);
        this.cnpjControl.setValue(this.formataCnpj(historico.numDocumento));
        this.razaoSocialControl.setValue(historico.nomeRazaoSocial);
        this.codAreaControl.setValue(historico.codArea);
        this.areaControl.setValue(historico.descArea);
        this.dividaDeControl.setValue(historico.valorInicial);
        this.dividaAteControl.setValue(historico.valorFinal);
        this.tipoPessoaControl.setValue(historico.tipoPessoa);
        this.envios = historico.envios;
        this.loaderService.display(false);
      },
        error => {
          if (error.status === 400) {
            Swal("A requisição está incorreta!", "", MESSAGES.TYPE_ERROR);
          } else if (error.status === 401) {
            Swal(MESSAGES.NAO_POSSUI_ACESSO_FUNCIONALIDADE, "", MESSAGES.TYPE_ERROR);
          } else {
            Swal("Ocorreu um erro inesperado ao consultar!", "", MESSAGES.TYPE_ERROR);
          }
    });
  }

  formataValorMoeda(value: number): string {
    return this.sharedServices.formatToCurrency(value);
  }

  formataCnpj(value: number) : string {
    return this.sharedServices.maskACnpj(value.toString());
  }

  voltar() {
    this.router.navigateByUrl(`/cadastros/agendamentoCarteira/consulta`);
  }

  solicitarDownload(row) {
    const params = {
      idAgendamento: this.idAgendamento,
      dataAgendamento: row.dataAgendamento
    };

    this.loaderService.display(true);
    this.agendamentoCarteiraService.solicitarDownload(params)
      .subscribe(() => {
        Swal("Requisição solicitada com sucesso. O arquivo está sendo processado.", "", MESSAGES.TYPE_SUCCESS);
        row.arquivoStatus = 'EM_PROCESSAMENTO';
        this.loaderService.display(false);
      },
        error => {
          this.loaderService.display(false);
          if (error.status === 400) {
            Swal("A requisição está incorreta!", "", MESSAGES.TYPE_ERROR);
          } else if (error.status === 401) {
            Swal(MESSAGES.NAO_POSSUI_ACESSO_FUNCIONALIDADE, "", MESSAGES.TYPE_ERROR);
          } else {
            Swal("Ocorreu um erro inesperado ao consultar!", "", MESSAGES.TYPE_ERROR);
          }
    });
  }

  relizarDownload(row) {
    const params = {
      idAgendamento: this.idAgendamento,
      dataAgendamento: row.dataAgendamento
    };

    this.loaderService.display(true);
    this.agendamentoCarteiraService.realizarDownload(params)
      .subscribe(zip => {
        this.loaderService.display(false);
        let blob = new Blob([zip], { type: 'application/zip' });
        
        const a: any = document.createElement('a');
        document.body.appendChild(a);

        const url = window.URL.createObjectURL(blob);
        a.style = 'display: none';
        a.href = url;
        a.download = params.idAgendamento + '_' + params.dataAgendamento.toString().replaceAll('/', '') + '.zip';
        a.click();
        window.URL.revokeObjectURL(url);
      },
      error => {
        console.log(error);
        this.loaderService.display(false);
        if (error.status === 400) {
          Swal("A requisição está incorreta!", "", MESSAGES.TYPE_ERROR);
        } else if (error.status === 401) {
          Swal(MESSAGES.NAO_POSSUI_ACESSO_FUNCIONALIDADE, "", MESSAGES.TYPE_ERROR);
        } else {
          Swal("Ocorreu um erro inesperado ao consultar!", "", MESSAGES.TYPE_ERROR);
        }
    });
  }
}