export class NegativacaoIdModel{
    constructor(
        public numDocumentoCredor:number,
        public numContrato:string,
        public tipoParticipante:string,
        public tipoPessoa:string,
        public numDocumentoNegativado:number,
        public natOperacao:string,
        public produto:string,
        public codArea?:string,
        public dadoComplementar?:string
    ){
        this.codArea = this.codArea != null && this.codArea != "" ? this.codArea:"0000" 
    }
}