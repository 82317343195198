import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { InformanteModel } from './model/informante.model';

import { InformanteAreaModel } from './model/informante-area.model';
import { UfModel } from './model/uf.model';
import { NegativacaoModel } from './model/negativacao.model';
import { DominioModel } from './model/dominio.model';
import { FuncaoModel } from '../configuracoes/controle-acesso/shared/funcao.model';
import { NegativacaoOcorrenciasModel } from './model/negativacao-ocorrencias.model';
import { Pessoa } from './model/pessoa.model';
import { LogradouroModel } from './model/logradouro.model';
import { environment } from '../../environments/environment';
import { Notificacao } from './model/notificacao.model';

import { JWTService } from '../auth/jwt.service';
import { AUTHORITIES } from '../auth/groups.constants';
import { NegativacaoIdModel } from './model/negativacao-id.model';
import { Token } from '../auth/token.model';
import { HttpService } from './http/httpclient.service';
import { UtilsService } from './utils.service';
import { MotivosBaixaModel } from '../cadastros/motivos-baixa/motivos-baixa.model';
import { HttpParams } from '@angular/common/http';
import { ManterusuarioModel } from '../configuracoes/controle-acesso/usuario/manter-usuario-api/manter-usuario.model';
import { NaturezaOperacaoModel } from './model/natureza-operacao.model';
import { UsuarioModel } from './model/usuario.model';
import { ProdutoModel } from '../servicos/apontamento/shared/produto.model';
import { AgendamentoCarteiraDominioModel } from './model/agendamento-carteira-dominio';

@Injectable()
export class SharedService {

    constructor(private http: HttpService,
        private jwtService: JWTService,
        private utils: UtilsService) { }

    getNaturezaOperacao(codNatOper: string): Observable<NaturezaOperacaoModel> {
        return this.http.get(`${environment.settings.api}/naturezasOperacao/${codNatOper}`, this.jwtService.getApiHeaders([]), null);
    }

    getAreas(numDocumento: string): Observable<InformanteAreaModel[]> {
        return this.http.post(`${environment.settings.api}/informantes/${numDocumento}/areas`, {}, this.jwtService.getApiHeaders([]));
    }

    getUfs(): Observable<UfModel[]> {
        return this.http.get(`${environment.settings.api}/estados/todos`, this.jwtService.getApiHeaders([]), null);
    }
    getInformanteArea(numDocumento: string, codArea: string): Observable<InformanteAreaModel> {
        return this.http.post(`${environment.settings.api}/informantes/${numDocumento}/areas/${codArea}`, {}, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_INFORMACOES_FONTE]));
    }

    getInformanteAreaByIdNegativacao(numDocumento: string, codArea: string, idNegativacao: string): Observable<InformanteAreaModel> {
        return this.http.post(`${environment.settings.api}/informantes/${numDocumento}/areas/${codArea}/idNegativacao/${idNegativacao}`, {}, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_INFORMACOES_FONTE]));
    }

    getRazaoSocial(contrato: string, cnpjCredor: number, docDevedor: string, natOperacao: string): Observable<NegativacaoModel> {
        return this.http.post(`${environment.settings.api}/contrato/${contrato}/cnpjCredor/${cnpjCredor}/docDevedor/${docDevedor}/natOperacao/${natOperacao}/razaoSocial`, {}, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_CONTRATO]));
    }

    getDominioByCodigo(codigo: string): Observable<DominioModel[]> {
        return this.http.get(`${environment.settings.api}/dominio/${codigo}`, this.jwtService.getApiHeaders([]), null);
    }

    getDominioByCodDominioAndCodigo(codDominio: string, codigo: string): Observable<DominioModel> {
        return this.http.get(`${environment.settings.api}/dominio/${codDominio}/${codigo}`, this.jwtService.getSignInHeaders(), null);
    }

    getDominioExternoByCodigo(codigo: string): Observable<DominioModel[]> {
        return this.http.get(`${environment.settings.api}/dominio/externo/${codigo}`, this.jwtService.getApiHeaders([]), null);
    }

    getInformante(numDocumento: number): Observable<InformanteModel> {
        return this.http.post(`${environment.settings.api}/informantes/${numDocumento}`, {}, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_INFORMACOES_FONTE]));
    }

    getFuncoes(): Observable<FuncaoModel[]> {
        return this.http.get(`${environment.settings.api}/funcoes/todos`, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_FUNCIONALIDADE]), null);
    }

    getNegativacao(id: string): Observable<NegativacaoModel> {
        return this.http.get(`${environment.settings.api}/negativacoes/idNegativacao/${id}`, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_DETALHE_NEGATIVACAO]), null);
    }

    getNegativacaoByDoc(cpfCnpj: string): any {
        const params = {
            cpfCnpj
        }
        return this.http.postParams(`${environment.settings.api}/negativacoes`, {},
         this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_DETALHE_NEGATIVACAO]), params);
    }

    getNegativacoesByInformante(cpfCnpj: string, cnpjInformante: string): any {
        const params = {
            cpfCnpj,
            cnpjInformante
        }
        return this.http.postParams(`${environment.settings.api}/negativacoes`, {},
         this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_DETALHE_NEGATIVACAO]), params);
    }

    getNegativacaoPrincipalDivida(id: string): Observable<NegativacaoModel> {
        return this.http.get(`${environment.settings.api}/negativacoes/principalDivida/idNegativacao/${id}`, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_DETALHE_NEGATIVACAO]), null);
    }

    getNegativacaoById(id: NegativacaoIdModel): Observable<NegativacaoModel> {
        if(id.dadoComplementar){
            return this.http.post(`${environment.settings.api}/negativacoes/numDocumento/${id.numDocumentoCredor}/codArea/${id.codArea}/numContrato/${id.numContrato}/tipoParticipante/${id.tipoParticipante}/cpfCnpj/${id.numDocumentoNegativado}/dadoComplementarDivida/${id.dadoComplementar}`, {}, this.jwtService.getApiHeaders([]));
         }else{
             return this.http.post(`${environment.settings.api}/negativacoes/numDocumento/${id.numDocumentoCredor}/codArea/${id.codArea}/numContrato/${id.numContrato}/tipoParticipante/${id.tipoParticipante}/cpfCnpj/${id.numDocumentoNegativado}/natOperacao/${id.natOperacao}`, {}, this.jwtService.getApiHeaders([]));
         }
    }

    getNegativacoesByContrato(numDocumento: string, codArea: string, numContrato: string, tipoParticipante: string, natOperacao: string, dadoComplementar: string): Observable<NegativacaoModel[]> {
        if(dadoComplementar && dadoComplementar != ''){
            return this.http.post(`${environment.settings.api}/negativacoes/dadoComplementar/numDocumento/${numDocumento}/codArea/${codArea}/numContrato/${numContrato}/tipoParticipante/${tipoParticipante}/dadoComplementar/${dadoComplementar}`, {}, this.jwtService.getApiHeaders([]));
        }else{
            return this.http.post(`${environment.settings.api}/negativacoes/numDocumento/${numDocumento}/codArea/${codArea}/numContrato/${numContrato}/tipoParticipante/${tipoParticipante}/natOperacao/${natOperacao}`, {}, this.jwtService.getApiHeaders([]));
        }
    }
    getOcorrencias(idNegativacao: string): Observable<NegativacaoOcorrenciasModel[]> {
        return this.http.get(`${environment.settings.api}/negativacoes/idNegativacao/${idNegativacao.trim()}/ocorrencias`, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_DETALHE_NEGATIVACAO]), null);
    }

    getOcorrencia(idNegativacao: string, dataOcorrencia: string, sequenciaOcorr: number): Observable<NegativacaoOcorrenciasModel> {
        dataOcorrencia = dataOcorrencia.replace(/\//g, '');
        return this.http.get(`${environment.settings.api}/negativacoes/idnegativacao/${idNegativacao}/ocorrencias/datOcorrencia/${dataOcorrencia}/sequenciaOcorr/${sequenciaOcorr}`, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_DETALHE_NEGATIVACAO]), null);
    }

    getOcorrenciasByContrato(contrato: string, numDocumentoInf: string, numDocumento: string, natOperacao?: string, codArea?: string, reinclusao?: boolean): Observable<NegativacaoOcorrenciasModel[]> {
        let params: any = {};
        if (reinclusao) {
            params['reinclusao'] = reinclusao;
        }
        if (codArea) {
            params['codArea'] = codArea;
        }
        if (natOperacao) {
            params['natOperacao'] = natOperacao;
        }

        return this.http.postParams(
            `${environment.settings.api}/negativacoes/contrato/${contrato}/cnpjCredor/${numDocumentoInf}/docDevedor/${numDocumento}/ocorrencias`,
            {},
            this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_NEGATIVACAO_ATIVAS]),
            params);
    }

    getEnderecoByCep(cep: string): Observable<LogradouroModel> {
        return this.http.get(`${environment.settings.api}/logradouros/${cep}`, this.jwtService.getApiHeaders([]), null);
    }

    getPessoa(documento: string, tipoPessoa: string): Observable<Pessoa> {
        return this.http.post(`${environment.settings.api}/pessoas/tipoPessoa/${tipoPessoa}/cpfCnpj/${documento}`, {}, this.jwtService.getApiHeaders([]));
    }

    getPessoaByDoc(documento: string): Observable<Pessoa> {
        return this.http.post(`${environment.settings.api}/pessoas/cpfCnpj/${documento}`, {}, this.jwtService.getApiHeaders([]));
    }

    getNaturezaOperacaoDesc() {
        return this.http.get(`${environment.settings.api}/naturezasOperacao/todosDecrescente`, this.jwtService.getApiHeaders([]), null);
    }

    getProduto( numDocumento : string) : Observable<ProdutoModel[]>{
        return this.http.post(`${environment.settings.api}/produtos/numDocumento/${numDocumento}`, {}, this.jwtService.getApiHeaders([]));
    }

    allowAccess(gruposUsuario: string[], gruposPermitidos: string[]): boolean {
        return gruposPermitidos.some(function (v) {
            return gruposUsuario.indexOf(v) >= 0;
        });
    }

    capitalize = function (str, lower) {
        return (lower ? str.toLowerCase() : str).replace(/(?:^|\s)\S/g, a => a.toUpperCase());
    }

    tratarErro(errors: any) {
        return this.utils.tratarErro(errors);
    }

    tratarErroMediaNotJson(errors: any) {  
        return this.utils.tratarErroMediaNotJson(errors);  
    }  

    formatToCurrency(value: number): string {
        let numero = value.toFixed(2).split('.');
        numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
    }

    maskACpf(cpf: string): string {
        cpf = cpf.replace(/\D/g, "");
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        return cpf;
    }

    maskACnpj(cnpj: string): string {
        if(cnpj.length < 14){
            cnpj = ("00000000000000" + cnpj).slice(-14);
        }
        cnpj = cnpj.replace(/\D/g, "");
        cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
        return cnpj;
    }

    getConglomerado(numDocumento: String): Observable<InformanteModel[]> {
        return this.http.post(`${environment.settings.api}/informantes/${numDocumento}/conglomerados`, {}, this.jwtService.getApiHeaders([]));
    }

    getEmpresasUsuario(): Observable<InformanteModel[]> {
        return this.http.get(`${environment.settings.api}/informantes/empresas`, this.jwtService.getApiHeaders([]), null);
    }

    getNotificacoes(idLogin: string): Observable<Notificacao[]> {
        return this.http.post(`${environment.settings.api}/notificacao/${idLogin}`, {}, this.jwtService.getApiHeaders([]));
    }

    getConglomeradoFilhos(numDocumento: string, situacao?: string): Observable<InformanteModel[]> {
        return this.http.postParams(`${environment.settings.api}/informantes/${numDocumento}/filhos`, {}, this.jwtService.getApiHeaders([]), { situacao: situacao });
    }

    getConglomeradoPai(numDocumento: string): Observable<InformanteModel> {
        return this.http.post(`${environment.settings.api}/informantes/${numDocumento}/pai`, {}, this.jwtService.getApiHeaders([]));
    }

    hasPermission(jwtToken: Token, gruposPermitidos: string[]) {
        return this.allowAccess(this.jwtService.decodeJWT(jwtToken.access_token).authorities, gruposPermitidos);
    }

    getUsuario(cnpj: string): Observable<ManterusuarioModel> {
        return this.http.post(`${environment.settings.api}/usuarios/api?cnpj=${cnpj}`, {}, this.jwtService.getApiHeaders([]));
    }

    getMotivosBaixa(): Observable<MotivosBaixaModel[]> {
        return this.http.get(`${environment.settings.api}/motivosBaixa/todos`, this.jwtService.getApiHeaders([]), null);
    }

    formatToNumber(value: number): string {
        let numero = value.toFixed(2).split('.');
        numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
    }

    getNegativacaoNomeDevedor(cpfCnpj: string): any {
        return this.http.get(
            `${environment.settings.api}/negativacoes/${cpfCnpj}/nomeRazaoSocial`,
            this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_DETALHE_NEGATIVACAO]),
            null
        );
    }

    getProdutosByNumContrato(numContrato: string): any {
        return this.http.get(
            `${environment.settings.api}/produto-erp/contrato/${numContrato}`,
            this.jwtService.getApiHeaders([]),
            null
        );
    }

    getBradescoAgencias(): Observable<AgendamentoCarteiraDominioModel[]> {
        return this.http.get(`${environment.settings.api}/agendamento-carteira/dominio/AGENCIA_BRADESCO_NEXT`, this.jwtService.getApiHeaders([]), null);
    }

    getBradescoCNPJs(): Observable<AgendamentoCarteiraDominioModel[]> {
        return this.http.get(`${environment.settings.api}/agendamento-carteira/dominio/CNPJ_BRADESCO`, this.jwtService.getApiHeaders([]), null);
    }
}