import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../shared/loader.service';
import { ContestacaoDetalhe, Documento } from '../shared/contestacao.model';
import { ContestacaoService } from '../shared/contestacao.service';
import Swal from 'sweetalert2';
import { ConstantsMaskModel } from '../../../shared/constants/constants-mask.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { RejeitarDialogComponent } from './Dialog/rejeitar-dialog.component';
import { Overlay } from '../../../../../node_modules/@angular/cdk/overlay';
import { AprovarDialogComponent } from './Dialog/aprovar-dialog.component';
import { MESSAGES } from '../../../shared/constants/constants-swal.model';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-aprovar-rejeitar-contestacao',
  templateUrl: './aprovar-rejeitar-contestacao.component.html',
  styleUrls: ['./aprovar-rejeitar-contestacao.component.scss'],
  providers: [ContestacaoService]
})
export class AprovarRejeitarContestacaoComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private service: ContestacaoService,
    private sharedService: SharedService,
    private router: Router,
    public dialog: MatDialog,
    public overlay: Overlay
  ) { }

  private params: any = {};

  detalhes: ContestacaoDetalhe;
  documentos: Documento[] = [];
  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();

  detalhesContestacaoForm: FormGroup;

  cpfDevedorControl = new FormControl('', []);
  nomeDevedorControl = new FormControl('', []);
  cnpjEmpresaControl = new FormControl('', []);
  nomeRazaoSocialControl = new FormControl('', []);
  numContratoControl = new FormControl('', []);
  motivoControl = new FormControl('', []);
  justificativaControl = new FormControl('', []);
  valorDividaControl = new FormControl('', []);
  datDividaControl = new FormControl('', []);
  maskCpf = ConstantsMaskModel.MASK_CPF;
  maskCnpj = ConstantsMaskModel.MASK_CNPJ;
  maskDate = ConstantsMaskModel.MASK_DATE;
  contestacaoEncontrada: boolean = false;
  displayLoad: boolean = true;

  _blob = Blob;
  _fileSaver = FileSaver;

  ngOnInit() {
    this.detalhesContestacaoForm = this.fb.group({
      cpfDevedor: this.cpfDevedorControl,
      nomeDevedor: this.nomeDevedorControl,
      cnpjEmpresa: this.cnpjEmpresaControl,
      nomeRazaoSocial: this.nomeRazaoSocialControl,
      numContrato: this.numContratoControl,
      motivo: this.motivoControl,
      justificativa: this.justificativaControl,
      valorDivida: this.valorDividaControl,
      datDivida: this.datDividaControl
    });

    this.params = this.route.queryParams;
    this.exibirDetalhes();
  }

  exibirDetalhes() {
    if (!this.params._value.numContestacao){
      this.displayLoad = false;
      return;
    }
    this.loaderService.display(true);
    this.service.buscar(this.params._value.numContestacao)
      .subscribe(
        (resp: ContestacaoDetalhe) => {
          this.loaderService.display(false);
          this.detalhesContestacaoForm.get('cpfDevedor').setValue(this.sharedService.maskACpf(resp.headerContestacao.cpf.toString()));
          this.detalhesContestacaoForm.get('nomeDevedor').setValue(resp.headerContestacao.nomeDevedor);
          this.detalhesContestacaoForm.get('cnpjEmpresa').setValue(this.sharedService.maskACnpj(resp.headerContestacao.cnpjEmpresa.toString()));
          this.detalhesContestacaoForm.get('datDivida').setValue(resp.headerContestacao.datDivida);
          this.detalhesContestacaoForm.get('nomeRazaoSocial').setValue(resp.headerContestacao.razaoSocial);
          this.detalhesContestacaoForm.get('numContrato').setValue(resp.headerContestacao.numContrato);
          this.detalhesContestacaoForm.get('valorDivida').setValue(resp.headerContestacao.valorDivida);
          this.detalhesContestacaoForm.get('motivo').setValue(resp.motivoContestacao);
          this.detalhesContestacaoForm.get('justificativa').setValue(resp.justifContestacao);

          if (resp.localDocumentos) {
            if (resp.documento1) {
              this.documentos.push(new Documento(resp.localDocumentos, resp.documento1))
            }
            if (resp.documento2) {
              this.documentos.push(new Documento(resp.localDocumentos, resp.documento2))
            }
          }
          this.displayLoad = false;
          this.contestacaoEncontrada = true;
        },
        errors => {
          this.contestacaoEncontrada = false;
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  aprovar() {
    let config = new MatDialogConfig();
    config.width = '42%';
    config.height = '50%';
    config.scrollStrategy = this.overlay.scrollStrategies.close();
    config.data = { numContestacao: this.params._value.numContestacao }
    this.dialog.open(AprovarDialogComponent, config).afterClosed()
      .subscribe(result => {
        if(result){
          this.router.navigate(['/servicos/contestacao/consultaContestacao']);
        }
    });
  }

  rejeitar() {
    let config = new MatDialogConfig();
    config.width = '42%';
    config.height = '60%';
    config.scrollStrategy = this.overlay.scrollStrategies.close();
    config.data = { numContestacao: this.params._value.numContestacao }
    this.dialog.open(RejeitarDialogComponent, config).afterClosed()
      .subscribe(result => {
        if(result){
          this.router.navigate(['/servicos/contestacao/consultaContestacao']);
        }
    });

  }

  getNomeArquivo(nome: string) {
    return nome.substring(0, nome.length - 4);
  }

  getExtensaoArquivo(nome: string) {
    return nome.substring(nome.length - 3, nome.length);
  }

  visualizar(doc: Documento) {
    const nomeArquivo = this.getNomeArquivo(doc.nome);
    const extensaoArquivo = this.getExtensaoArquivo(doc.nome);

    this.service.baixarArquivo(nomeArquivo, extensaoArquivo)
      .subscribe(
        (res: any) => {
          this.loaderService.display(false);
          this.displayLoad = false;
          var blob = new Blob([res], { type: 'image/png' });
          this._fileSaver.saveAs(blob, `${doc.nome}`);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  openDialog(data): void {
    let dialogRef = this.dialog.open(AprovarRejeitarContestacaoDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: { url: data }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

@Component({
  selector: 'aprovar-rejeitar-contestacao-dialog',
  templateUrl: 'aprovar-rejeitar-contestacao-dialog.component.html',
  providers: []
})
export class AprovarRejeitarContestacaoDialog {
  constructor(
    public dialogRef: MatDialogRef<AprovarRejeitarContestacaoDialog>,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  maskACnpj(cnpj: number) {
    if (!cnpj)
      return;
    return this.sharedService.maskACnpj(cnpj.toString());
  }

  formatToCurrency(value: string): string {
    return this.sharedService.maskACpf(value);
  }
}
