import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import 'rxjs/add/observable/of';
import { MyErrorStateMatcher } from '../../../../shared/error-matcher-input/error-matcher-input.model'
import { SharedService } from '../../../../shared/shared.service'
import Swal from 'sweetalert2'
import { PerfilService } from '../../perfil/shared/perfil.service';
import { PerfilModel } from '../../shared/perfil.model';
import { UsuarioModel } from '../../../../shared/model/usuario.model';
import { ConstantsMaskModel } from '../../../../shared/constants/constants-mask.model';
import { LoaderService } from '../../../../shared/loader.service';
import { Token } from '../../../../auth/token.model';
import { JWTService } from 'src/app/auth/jwt.service';
import { ManterusuarioModel } from './manter-usuario.model';
import { environment } from 'src/environments/environment';
import { MESSAGES } from '../../../../shared/constants/constants-swal.model';
import { Validator } from 'src/app/shared/validators/validator';
import { ManterUsuarioService } from './manter-usuario.service';
import { FuncaoModel } from '../../shared/funcao.model';

declare var $: any;
@Component({
  selector: 'app-manter-usuario-api',
  templateUrl: './manter-usuario-api.component.html',
})
export class ManterUsuarioApiComponent implements OnInit {


  constructor(private sharedServices: SharedService,
    private formBuilder: FormBuilder,
    private perfilService: PerfilService,
    private loaderService: LoaderService,
    private jwtService: JWTService,
    private manterUsuarioService: ManterUsuarioService) {
  }

  matcher = new MyErrorStateMatcher();
  usuarioConsultaForm: FormGroup;
  isSelecionado: boolean = false;
  numDocumentoLogado: String;
  hide = true;
  confHide = true;
  existe: boolean = false;


  // Control
  loginUsuarioControl = new FormControl('', [Validators.required]);
  cnpjControl = new FormControl('', [Validators.required]);
  nomeEmpresaControl = new FormControl('', [Validators.required]);
  senhaControl = new FormControl('', [Validators.required]);
  confSenhaControl = new FormControl('', [Validators.required, Validator.equalsTo('senha')]);
  codPerfilControl = new FormControl('', [Validators.required]);
  codAreaControl = new FormControl('0000', [Validators.required]);

  //Model
  usuario: UsuarioModel;
  perfisModel: PerfilModel[] = [];
  perfilSelecionado: PerfilModel = new PerfilModel(null, null, null, null, null, []);

  // Mask
  maskCnpj = ConstantsMaskModel.MASK_CNPJ;
  maskCpf = ConstantsMaskModel.MASK_CPF;

  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();
  cpfCnpj: string = "";
  numDocumento: string = "";
  nomeEmpresa: string = "";
  loginUsuario: string = "";
  usuarioModel: ManterusuarioModel;
  usuarioRobo: ManterusuarioModel;
  teste: string;
  funcoes: FuncaoModel[] = [];

  ngOnInit() {
    this.usuarioConsultaForm = this.formBuilder.group({
      numDocumento: this.loginUsuarioControl,
      nomeEmpresa: this.nomeEmpresaControl,
      senha: this.senhaControl,
      confSenha: this.confSenhaControl,
      codPerfil: this.codPerfilControl,
      cnpj: this.cnpjControl,
      codArea: this.codAreaControl
    });

    const userData = this.jwtService.getCookie('auth_token') as Token;

    this.usuario = new UsuarioModel('', '', null, null, null, null, '', '', null, '', null, null, '', '');

    this.numDocumentoLogado = userData.numDocumento;
    this.cpfCnpj = userData.cpfCnpj;
    this.numDocumento = userData.numDocumento;

    this.loginUsuarioControl.setValue(this.numDocumento);
    this.cnpjControl.setValue(this.numDocumento);

    this.getinformante(this.numDocumento);
    this.getPerfis(userData.numDocumento);
  }

  markConfirmarAsTouch() {
    if (this.confSenhaControl.invalid)
      return;
    this.confSenhaControl.markAsTouched();
    this.confSenhaControl.updateValueAndValidity();
  }

  salvarUsuario() {
    if (this.usuarioConsultaForm.invalid) {
      this.usuarioConsultaForm.markAsTouched();
      return;
    }
    this.loaderService.display(true);
    if (!this.existe) {
      this.manterUsuarioService.salvarUsuarios(this.usuarioConsultaForm.value)
        .subscribe(
          usuario => {
            this.loaderService.display(false);
            Swal(MESSAGES.USUARIO_CADASTRADO_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
            this.limparForm();
          },
          error => {
            this.loaderService.display(false);
            Swal(this.sharedServices.tratarErro(error), "", MESSAGES.TYPE_ERROR);
          }
        );
    } else {
      this.manterUsuarioService.alterarUsuarios(this.usuarioConsultaForm.value)
        .subscribe(
          usuario => {
            this.loaderService.display(false);
            Swal(MESSAGES.USUARIO_CADASTRADO_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
            this.limparForm();
          },
          error => {
            this.loaderService.display(false);
            Swal(this.sharedServices.tratarErro(error), "", MESSAGES.TYPE_ERROR);
          }
        );
    }
  }

  getUsuario(cnpj: string) {
    this.manterUsuarioService.consultarUsuario(cnpj)
      .subscribe(
        usuario => {
          this.loginUsuarioControl.setValue(this.numDocumento);
          this.cnpjControl.setValue(this.numDocumento);
          this.codPerfilControl.setValue(usuario.codPerfil);
          this.existe = true;
          this.senhaControl.setValue('000000');
          this.confSenhaControl.setValue('000000');
          this.perfilDetalhe(this.codPerfilControl.value);
          this.existe = true;
        },
        errors => {
          this.existe = false;
        }
      );
  }

  getinformante(numDocumento: any) {
    this.sharedServices.getInformante(numDocumento)
      .subscribe(
        informante => { this.nomeEmpresa = informante.nomeRazaoSocial; this.nomeEmpresaControl.setValue(informante.nomeRazaoSocial) },
        error => {
          Swal(this.sharedServices.tratarErro(error), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getPerfis(cnpj: string) {
    this.perfilService.buscarTodosPerfis()
      .subscribe(
        perfil => {
          this.perfisModel = perfil;
          this.perfisModel = this.perfisModel.filter(item => item.tipoPerfil == environment.settings.usuarioRobo);
          this.getUsuario(cnpj);
        },
        error => {
          Swal(this.sharedServices.tratarErro(error), "", MESSAGES.TYPE_ERROR);
        }
      )
  }

  perfilDetalhe(codPerfil: number) {
    if (codPerfil) {
      this.isSelecionado = true;
    }
    let perfilFiltro = this.perfisModel.find(item => item.codPerfil === codPerfil);
    this.perfilSelecionado = perfilFiltro ? perfilFiltro:this.perfilSelecionado;
    this.perfilSelecionado.funcoes = this.perfilSelecionado.funcoes.filter(item => item.selecionado);
  }

  limparForm() {
    this.senhaControl.setValue("");
    this.confSenhaControl.setValue("");
  }
  static equalControlValue(targetKey: string, toMatchKey: string): ValidatorFn {

    return (group: FormGroup): { [key: string]: any } => {

      const target = group.controls[targetKey];
      const toMatch = group.controls[toMatchKey];
      if (target.touched && toMatch.touched) {

        const isMatch = target.value === toMatch.value;

        // set equal value error on dirty controls
        if (!isMatch && target.valid && toMatch.valid) {
          toMatch.setErrors({ equalValue: targetKey });
          const message = targetKey + ' diferente de ' + toMatchKey;
          return { 'equalValue': message };
        }

        if (isMatch && toMatch.hasError('equalControlValue')) {
          toMatch.setErrors(null);
        }
      }

      return null;
    };
  }

}
