import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import 'rxjs/add/observable/of';
import { MyErrorStateMatcher } from '../../../../shared/error-matcher-input/error-matcher-input.model'
import { SharedService } from '../../../../shared/shared.service';
import { UsuarioService } from '../usuario.service';
import { UsuarioModel } from '../../../../shared/model/usuario.model';
import { Validator } from '../../../../shared/validators/validator';
import { ConstantsMaskModel } from '../../../../shared/constants/constants-mask.model';
import { LoaderService } from '../../../../shared/loader.service';
import { UtilsService } from '../../../../shared/utils.service';
import { MESSAGES } from 'src/app/shared/constants/constants-swal.model';
import Swal from 'sweetalert2';
import { InformanteModel } from 'src/app/shared/model/informante.model';
import { JWTService } from 'src/app/auth/jwt.service';
import { Token } from '../../../../auth/token.model';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Overlay } from '@angular/cdk/overlay';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'confirmar-acao-dialog',
  templateUrl: './confirmar-acao-dialog.component.html'
})
export class ConfirmarAcaoDialog implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmarAcaoDialog>){
  }

  ngOnInit(){
  }
}

@Component({
  selector: 'app-consultar-usuario',
  templateUrl: './consultar-usuario.component.html',
})
export class ConsultarUsuarioComponent implements OnInit {

  constructor(private sharedServices: SharedService, private jwtService: JWTService,
    private usuarioService: UsuarioService, private loaderService: LoaderService, private utils: UtilsService, public dialog: MatDialog, public overlay: Overlay,     private sharedService: SharedService,
    ) { }

  matcher = new MyErrorStateMatcher();
  usuarioConsultarForm: FormGroup;
  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();
  userData = this.jwtService.getCookie('auth_token') as Token;
  _blob = Blob;
  _fileSaver = FileSaver;


  // Control
  cpfConglomeradoConsultaControl = new FormControl('', []);
  cpfCnpjConsultaControl = new FormControl('', []);
  situacaoConsultaControl = new FormControl('', []);
  nomeConsultaControl = new FormControl('', []);
  razaoSocialConsultaControl = new FormControl({value : '', disabled:true});  
  emailConsultaControl = new FormControl('', []);
  isLoading = false;
  

  //Model
  usuarios: UsuarioModel[] = [];
  informantes: InformanteModel[] = [];
  informante: InformanteModel;
  formUsuario: FormGroup;

  // Mask


  pesquisou: boolean;



  ngOnInit() {
    this.formUsuario = new FormGroup({
      cpfConglomeradoConsulta: this.cpfConglomeradoConsultaControl,
      razaoSocialConsulta: this.razaoSocialConsultaControl,
      cpfCnpjConsulta: this.cpfCnpjConsultaControl,
      nomeConsulta: this.nomeConsultaControl,
      emailConsulta: this.emailConsultaControl,
      situacaoConsulta: this.situacaoConsultaControl,
    });
    this.situacaoConsultaControl.setValue('T');
    this.buscarListaConglomerado();
  }

  buscarListaConglomerado() {
    this.loaderService.display(true);
    this.sharedServices.getConglomerado(this.userData.numDocumento)
      .subscribe(conglomerado => {
        this.informantes = conglomerado;
        this.carregarInformanteLogado(this.informantes);
        this.loaderService.display(false);
      },
        error => {
          if (error.status === 400) {
            Swal("A requisição está incorreta!", "", MESSAGES.TYPE_ERROR);
          } else if (error.status === 401) {
            Swal(MESSAGES.NAO_POSSUI_ACESSO_FUNCIONALIDADE, "", MESSAGES.TYPE_ERROR);
          } else {
            Swal("Ocorreu um erro inesperado ao consultar!", "", MESSAGES.TYPE_ERROR);
          }
        });
  }

  private carregarInformanteLogado(informantes: InformanteModel[]) {
    informantes.forEach(informante => {
      if (informante.numDocumento === this.userData.numDocumento) {
        this.cpfConglomeradoConsultaControl.setValue(informante);
        this.carregarNomeRazaoSocial(informante);
      }
    });
  }

  carregarNomeRazaoSocial(informanteSelecionado: InformanteModel) {
    this.razaoSocialConsultaControl.setValue(informanteSelecionado.nomeRazaoSocial);
  }


  buscarUsuario() { 
    this.loaderService.display(true);
    let informanteSelecionando: InformanteModel = this.cpfConglomeradoConsultaControl.value;
    var filtro = new UsuarioModel();
    filtro.nomeFantasia = this.razaoSocialConsultaControl.value;
    filtro.cpf = this.cpfCnpjConsultaControl.value;
    filtro.email = this.emailConsultaControl.value;
    filtro.nome = this.nomeConsultaControl.value;
    filtro.status = this.situacaoConsultaControl.value;
    filtro.numDocumento = Number(informanteSelecionando.numDocumento);

    this.usuarioService.consultaListaUsuariosPorInformantePaiFilho(filtro)
      .subscribe(usuarios => {
        this.usuarios = usuarios;
        this.loaderService.display(false);
        this.pesquisou = true;
      },
        error => {
          if (error.status === 400) {
            Swal("A requisição está incorreta!", "", MESSAGES.TYPE_ERROR);
          } else if (error.status === 401) {
            Swal(MESSAGES.NAO_POSSUI_ACESSO_FUNCIONALIDADE, "", MESSAGES.TYPE_ERROR);
          } else {
            Swal("Ocorreu um erro inesperado ao consultar!", "", MESSAGES.TYPE_ERROR);
          }
        });
  }

  exportarExcel(usuarios: UsuarioModel[]){
    this.usuarioService.exportarExcel(usuarios)
    .subscribe(
      (res: any) => {
        this.loaderService.display(false);
        const date = new Date();
        const format = date.getFullYear().toString().concat('-')
          .concat((date.getMonth() + 1).toString()).concat('-')
          .concat(date.getDate().toString());
        var blob = new this._blob([res], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation;charset=UTF-8' });
        this._fileSaver.saveAs(blob, `consulta_usuarios_${format}.xlsx`);
      },
      errors => {
        this.loaderService.display(false);
        Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
      }
    );

  }



  zeroAEsquerda() {
    if (!this.cpfCnpjConsultaControl.value)
      return;
    if (this.cpfCnpjConsultaControl.value.replace(/_/g, '').length > 11) {
      this.cpfCnpjConsultaControl.setValue(this.utils.padLeftWithZeros(this.cpfCnpjConsultaControl.value, 14));
    } else {
      this.cpfCnpjConsultaControl.setValue(this.utils.padLeftWithZeros(this.cpfCnpjConsultaControl.value, 11));
    }
  }

  limpar() {
    this.pesquisou = false;
    this.usuarios = [];
    this.situacaoConsultaControl.setValue('T');
    this.carregarInformanteLogado(this.informantes);
    this.cpfCnpjConsultaControl = new FormControl('', []);
    this.nomeConsultaControl = new FormControl('', []);
    this.emailConsultaControl = new FormControl('', []);
  }


  inativarUsuario(usuarioSelecionado: UsuarioModel) {
    var dialogRef = this.dialog.open(ConfirmarAcaoDialog, {
      width: '50%',
      height: '50%',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result)
        return;

        this.loaderService.display(true);

      this.usuarioService.inativaUsuario(usuarioSelecionado)
        .subscribe(() => {
          this.buscarUsuario();
          this.loaderService.display(false);
          Swal("A inativação do usuário foi efetuado com sucesso!", "", MESSAGES.TYPE_SUCCESS);
        },
          error => {
            this.loaderService.display(false);
            if (error.status === 401) {
              Swal(MESSAGES.NAO_POSSUI_ACESSO_FUNCIONALIDADE, "", MESSAGES.TYPE_ERROR);
            } else {
              Swal("Ocorreu um erro durante a inativação do usuário!", "", MESSAGES.TYPE_ERROR);
            }
          });

    });
  }

  podeInativarUsuario(usuarioSelecionado: UsuarioModel){
    if(usuarioSelecionado.status ==='A'){
        return true;
    }
    return false;
  }
}
