import { OnInit, Component, Inject } from "@angular/core";
import { SharedService } from "../../../shared/shared.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder } from "@angular/forms";
import { NegativacaoPendenteAprovacaoModel } from "../../../shared/model/negativacao-pendente-aprovacao.model";
import { ConstantsNegativacaoPendenteAprovacaoModel } from "../../../shared/constants/constants-negativacao-pendente-aprovacao.model";
import { AprovarRejeitarPepService } from "./aprovar-rejeitar-pep.service";
import { LoaderService } from "../../../shared/loader.service";

@Component({
    selector: 'aprovar-rejeitar-pep-dialog',
    templateUrl: 'aprovar-rejeitar-pep-dialog.component.html',
    providers: [SharedService]
})
export class AprovarRejeitarPepDialog implements OnInit {

    texto: string = '';
    negPendentAProvacao: NegativacaoPendenteAprovacaoModel;
    action: string = '';

    constructor(
        public dialogRef: MatDialogRef<AprovarRejeitarPepDialog>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private formBuilder: FormBuilder,
        private sharedService: SharedService,
        private service: AprovarRejeitarPepService,
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        this.texto = this.data.texto;
        this.negPendentAProvacao = Object.assign({}, this.data.negPendentAProvacao); // Copiando Negativacao Pendente Aprovação
        this.adaptarObjeto(this.data.action);
    
    }

    adaptarObjeto(action: string) {
        delete this.negPendentAProvacao.negativacao;
        switch (action) {
            case 'aprovar':
                this.negPendentAProvacao.statPendencia = ConstantsNegativacaoPendenteAprovacaoModel.CONS_STATUS_APROVADA;
                break;
            case 'rejeitar':
                this.negPendentAProvacao.statPendencia = ConstantsNegativacaoPendenteAprovacaoModel.CONS_STATUS_REJEITADA;
                break;
        }
    }

    confirmar() {
        this.loaderService.display(true);
        this.service.alterar(this.negPendentAProvacao)
        .subscribe(
            ok => {
                this.loaderService.display(false);
                this.dialogRef.close({status: "ok"});
            },
            errors => {
                this.loaderService.display(false);
                this.dialogRef.close({
                    status: "error",
                    errors: errors
                });
            }
        )
        
    }

    cancelar() {
        this.dialogRef.close();
    }


}