import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-controle-acesso',
  templateUrl: './controle-acesso.component.html',
})
export class ControleAcessoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
