import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { SolicitarCadastroUsuarioModel } from '../solicitacao-usuario.model';
import { environment } from '../../../../../environments/environment';
import { JWTService } from '../../../../auth/jwt.service';


@Injectable()
export class SocilicitacaoCadastroUsuarioService {

  constructor(private http: HttpClient,
              private jwtService: JWTService) { }

  getUsuario(model):Observable<SolicitarCadastroUsuarioModel>{
    return this.http.post<SolicitarCadastroUsuarioModel>(`${environment.settings.api}/solicitacoes/usuarios`, model,
      {
        headers : this.jwtService.getApiHeaders([])
      });
  }

  
  salvarUsuario(model):Observable<SolicitarCadastroUsuarioModel>{
    return this.http.post<SolicitarCadastroUsuarioModel>(`${environment.settings.api}/solicitacoes/usuarios`, model,
      {
        headers : this.jwtService.getApiHeaders([])
      });
  }
}
