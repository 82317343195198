export class DashBoardIndicadorModel{
    constructor(   
         public valor:number,
         public name:string,
         public descricao: string,
         public periodicidade: string,
         public icon: string
    ){}
}

export class ConsultaDashBoard{
    constructor(   
        public numDocumento:string,
        public dataInicio:string,
        public dataFim: string,
        public periodicidade: string,
      
   ){}
}

export class DashBoardResult{
    constructor(   
        public dataInicio:string,
        public dataFim: string,
        public indicadores: DashBoardIndicadorModel[],
      
   ){}
}