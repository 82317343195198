import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solicitacao',
  templateUrl: './solicitacao.component.html',
})
export class SolicitacaoComponent implements OnInit {

  constructor( ) { }

  ngOnInit() {
  }
  
}
