import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { LoaderService } from '../../shared/loader.service';
import { Notificacao } from '../../shared/model/notificacao.model';
import { JWTService } from '../../auth/jwt.service';
import { UtilsService } from '../../shared/utils.service';
import 'rxjs/add/observable/interval';
import { Observable } from '../../../../node_modules/rxjs';

@Component({
  selector: 'app-notificacao',
  templateUrl: './notificacao.component.html',
  styleUrls: ['./notificacao.component.scss']
})
export class NotificacaoComponent implements OnInit {

  constructor( private sharedService: SharedService,
               private loaderService: LoaderService,
               private jwtService: JWTService,
               private utilsService: UtilsService ) { }

  private params : any = {};
  private jwt : any;
  private firstLoading : boolean = true;

  notificacoes: Notificacao[] = [];
  notificacoesCO: Notificacao[] = [];
  notificacoesAU: Notificacao[] = [];
  notificacoesEX: Notificacao[] = [];

  ngOnInit() {
    this.jwt = this.jwtService.getObject('auth_token');
    this.listar();
    this.listarTime();
  }

  listarTime(){
    Observable.interval(1000 * 20).subscribe(x => {
      this.listar();
    });
  }

  listar() {
    if(this.firstLoading){
      this.loaderService.display(true);
      this.firstLoading = false;
    }
    this.notificacoesCO = [];
    this.notificacoesAU = [];
    this.notificacoesEX = [];
    this.sharedService.getNotificacoes(this.jwt.idLogin)
      .subscribe(
        (resp : Notificacao[]) => {
          this.loaderService.display(false);
          this.notificacoes = resp;
          this.notificacoes.forEach(notificacao=> {
            if(notificacao.codmodulo === 'CO'){
              this.notificacoesCO.push(notificacao);
            } else if(notificacao.codmodulo === 'AU' || notificacao.codmodulo === 'CA'){
              this.notificacoesAU.push(notificacao);
            }else if(notificacao.codmodulo === 'EX'){
              this.notificacoesEX.push(notificacao);
            }
          });
       }, 
        errors => {
          this.loaderService.display(false);
        }
      );              
  }

  navigate(model: any){
    this.utilsService.navigate(model);
  }

  

}
