import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Inject, Component, OnInit } from "@angular/core";
import { MotivosBaixaModel } from "../../../cadastros/motivos-baixa/motivos-baixa.model";
import { MotivosBaixaService } from "../../../cadastros/motivos-baixa/motivos-baixa.service";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { MyErrorStateMatcher } from "../../../shared/error-matcher-input/error-matcher-input.model";
import Swal from 'sweetalert2'

  @Component({
    selector: 'excluir-negativacao-dialog',
    templateUrl: './excluir-negativacao-dialog.component.html'
  })
  export class ExcluirNegativacaoDialog implements OnInit {
    
    constructor(
      public dialogRef: MatDialogRef<ExcluirNegativacaoDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private motivosBaixaService: MotivosBaixaService, 
      private formBuilder: FormBuilder
    ) { }

    motivosBaixa: MotivosBaixaModel[] = [];
    modelMotivosBaixa: MotivosBaixaModel;
    motivosBaixaForm: FormGroup;
    matcher = new MyErrorStateMatcher();
    
    codMotivoBaixaControl = new FormControl('', [Validators.required]);

    ngOnInit() {
      this.motivosBaixaForm = this.formBuilder.group({
        codMotivoBaixa: this.codMotivoBaixaControl,
      });
      this.getMotivosBaixaAsc();
    }
  
   
    getMotivosBaixaAsc() {
      this.motivosBaixaService.getMotivosBaixaAsc()
          .subscribe(
            motivosBaixa => {
                  this.motivosBaixa = motivosBaixa.sort((a, b) => a.codMotivoBaixa - b.codMotivoBaixa);
            },
            errors => {
              Swal(errors.data.message, "", "error")
            }
          )
    }
  

    onNoClick(): void {
      this.dialogRef.close();
    }
  
    excluir(model: MotivosBaixaModel): any {
      this.dialogRef.close(model);
    }
  
  }