import { OnInit, Component } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { MyErrorStateMatcher } from "../../../shared/error-matcher-input/error-matcher-input.model";
import { SharedService } from "../../../shared/shared.service";
import { MatDialogRef } from '@angular/material';
import { Validator } from "../../../shared/validators/validator";

@Component({
    selector: 'modelo-comunicado-dialog',
    templateUrl: 'modelo-comunicado-dialog.component.html',
    providers: [SharedService],
})
export class ModeloComunicadoDialog implements OnInit {

    clienteModalForm: FormGroup;
    matcher = new MyErrorStateMatcher();

    // Control
    codComunicadoControl = new FormControl('', [Validators.required, Validators.maxLength(5), Validator.isNumber()]);
    descModeloComunicadoControl = new FormControl('', [Validators.required]);

    constructor(public dialogRef: MatDialogRef<ModeloComunicadoDialog>, private formBuilder: FormBuilder, private sharedService: SharedService) { }

    ngOnInit() {
        this.clienteModalForm = this.formBuilder.group({
            codComunicado: this.codComunicadoControl,
            descModeloComunicado: this.descModeloComunicadoControl,
        });
    }

    save(): void {
        this.dialogRef.close(this.clienteModalForm.value);
    }

    close() {
        this.dialogRef.close();
    }
}