import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ConsultarHistoricoRemessaService } from './consultar-historico-remessa.service';
import { SharedService } from '../../shared/shared.service';
import { RemessaFormModel } from './remessa-form.model';
import { RemessaModel } from '../../shared/model/remessa.model';
import Swal from 'sweetalert2';
import { MyErrorStateMatcher } from '../../shared/error-matcher-input/error-matcher-input.model';
import { EstatisticaDecursadaModel } from '../../shared/model/estatistica-decursada.model';
import { EstatisticaBloqueioJudicialModel } from '../../shared/model/estatistica-bloqueio-judicial.model';
import { EstatisticaDevolucaoCorreioModel } from '../../shared/model/estatistica-devolucao-correios.model';
import { EstatisticaContestadoModel } from '../../shared/model/estatistica-contestado.model';
import { ConstantsMaskModel } from '../../shared/constants/constants-mask.model';
import { DominioModel } from '../../shared/model/dominio.model';
import { Validator } from '../../shared/validators/validator';
import { LoaderService } from '../../shared/loader.service';
import * as moment from 'moment';
import { MESSAGES } from '../../shared/constants/constants-swal.model';
import { UtilsService } from '../../shared/utils.service';

@Component({
  selector: 'app-consultar-historico-remessa',
  templateUrl: './consultar-historico-remessa.component.html',
})
export class ConsultarHistoricoRemessaComponent implements OnInit {

  constructor(private fb: FormBuilder, 
              private service: ConsultarHistoricoRemessaService, 
              private sharedService: SharedService,
              private loaderService: LoaderService,
              private utils: UtilsService) { }

  consultarHistoricoRemessaForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  remessasModel: RemessaModel[] = [];
  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();

  numDocumentoControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  tipoRemessaControl = new FormControl('', []);
  dataInicioControl = new FormControl('', [Validator.dateIsValid]);
  dataFinalControl = new FormControl('', [Validator.dateIsValid]);
  numRemessaInicialControl = new FormControl('', [Validators.min(1)]);
  numRemessaFinalControl = new FormControl('', [Validators.min(1)]);
  statusControl = new FormControl('', []);
  maskCnpj = ConstantsMaskModel.MASK_CNPJ;
  maskDate = ConstantsMaskModel.MASK_DATE;
  tipoRemessaDominios: DominioModel[] = [];
  statusDominios: DominioModel[] = [];

  ngOnInit() {
    this.consultarHistoricoRemessaForm = this.fb.group({
      numDocumento: this.numDocumentoControl,
      tipoRemessa: this.tipoRemessaControl,
      dataInicio: this.dataInicioControl,
      dataFinal: this.dataFinalControl,
      numRemessaInicial: this.numRemessaInicialControl,
      numRemessaFinal: this.numRemessaFinalControl,
      status: this.statusControl
    });

    this.getDominioTipoRemessa("TIPO_REMESSA");
    this.getDominioStatus("REMESSA_STATUS");
  }

  getDominioTipoRemessa(codigo: string) {
    this.sharedService.getDominioByCodigo(codigo)
      .subscribe(
        dominios => {
          this.tipoRemessaDominios = dominios
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getDominioStatus(codigo: string) {
    this.sharedService.getDominioByCodigo(codigo)
      .subscribe(
        dominios => {
          this.statusDominios = dominios
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getConsultaHistorico(model: RemessaFormModel) {
    if(!this.validaSequencias(model.numRemessaInicial, model.numRemessaFinal)) {
      return;
    }

    this.loaderService.display(true);
    this.service.getConsultaHistorico(model)
      .subscribe(
        remessas => {
          this.loaderService.display(false);
          if (remessas.length == 0) {
            Swal(MESSAGES.NENHUMA_REMESSA_ENCONTRADA, "", MESSAGES.TYPE_ERROR);
          }
          this.remessasModel = remessas;
          this.remessasModel.forEach((remessa, i) => {
            this.remessasModel[i].estatisticaDescursada = new EstatisticaDecursadaModel(0);
            this.remessasModel[i].estatisticaBloqueioJudicial = new EstatisticaBloqueioJudicialModel(0);
            this.remessasModel[i].estatisticaDevolucaoCorreios = new EstatisticaDevolucaoCorreioModel(0);
            this.remessasModel[i].estatisticaConstestado = new EstatisticaContestadoModel(0);
            if (remessa.tbRemessaEstatisticaDTOs.length) {
              remessa.tbRemessaEstatisticaDTOs.forEach((estatistica) => {
                switch (estatistica.codEstatistica) {
                  case "001":
                    this.remessasModel[i].estatisticaDescursada = new EstatisticaDecursadaModel(estatistica.valEstatistica);
                    break;
                  case "002":
                    this.remessasModel[i].estatisticaBloqueioJudicial = new EstatisticaBloqueioJudicialModel(estatistica.valEstatistica);
                    break;
                  case "003":
                    this.remessasModel[i].estatisticaDevolucaoCorreios = new EstatisticaDevolucaoCorreioModel(estatistica.valEstatistica);
                    break;
                  case "004":
                    this.remessasModel[i].estatisticaConstestado = new EstatisticaContestadoModel(estatistica.valEstatistica);
                    break;
                  default:
                    break;
                }
              });
            }
          });
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  validaDataInicial() {
    var dataInicio = this.consultarHistoricoRemessaForm.get('dataInicio');
    var dataFinal   = this.consultarHistoricoRemessaForm.get('dataFinal');

    var checkAfterFinal = moment(dataInicio.value, "DD/MM/YYYY").isAfter(dataFinal.value);

    if (checkAfterFinal) {
      Swal(MESSAGES.DATA_INICIAL_MAIOR_FINAL, "", MESSAGES.TYPE_ERROR);
      this.consultarHistoricoRemessaForm.controls['dataInicio'].setErrors({'incorrect': true});
      return false;
    }
  }

  validaDataFinal() {
    var dataInicio = this.consultarHistoricoRemessaForm.get('dataInicio');
    var dataFinal   = this.consultarHistoricoRemessaForm.get('dataFinal');

    var checkBeforeInicial = moment(dataFinal.value, "DD/MM/YYYY").isBefore(dataInicio.value);
    var checkAfterToday    = moment(dataFinal.value, "DD/MM/YYYY").isAfter(new Date());

    if (checkBeforeInicial || checkAfterToday) {
      if (checkBeforeInicial)
        Swal(MESSAGES.DATA_FINAL_MENOR_INICIAL, "", MESSAGES.TYPE_ERROR);
      else
        Swal(MESSAGES.DATA_FINAL_NAO_PODE_SER_MAIOR_HOJE, "", MESSAGES.TYPE_ERROR);

      this.consultarHistoricoRemessaForm.controls['dataFinal'].setErrors({'incorrect': true});
      return false;
    }
  }

  validaSequenciaInicial() {
    var seqInicial = this.consultarHistoricoRemessaForm.get('numRemessaInicial');
    var seqFinal = this.consultarHistoricoRemessaForm.get('numRemessaFinal');

    if (seqInicial.value != null) {
      if (seqFinal.value != null && seqFinal.value != '' && seqInicial.value > seqFinal.value) {
        Swal(MESSAGES.SEQUENCIA_INICIAL_MAIOR_FINAL, "", MESSAGES.TYPE_ERROR);
        this.consultarHistoricoRemessaForm.controls['numRemessaInicial'].setErrors({'incorrect': true});
        return false;
      }
    }
  }

  validaSequenciaFinal() {
    var seqInicial = this.consultarHistoricoRemessaForm.get('numRemessaInicial');
    var seqFinal = this.consultarHistoricoRemessaForm.get('numRemessaFinal');

    var seqFinalMenor = seqFinal.value < seqInicial.value;

    if (seqFinal.value != null) {
      if (seqFinalMenor) {
        Swal(MESSAGES.SEQUENCIA_FINAL_MENOR_INICIAL, "", MESSAGES.TYPE_ERROR);
        this.consultarHistoricoRemessaForm.controls['numRemessaFinal'].setErrors({'incorrect': true});
        return false;
      }
    }
  }

  validaSequencias(numRemessaInicial, numRemessaFinal) {
    if((numRemessaInicial != null && numRemessaFinal == null) ||
       (numRemessaInicial != '' && numRemessaFinal == '')){
      Swal(MESSAGES.SEQUENCIA_FINAL_NAO_PODE_SER_NULA, "", MESSAGES.TYPE_ERROR);
      return false;
    }
       
    if ((numRemessaInicial == null && numRemessaFinal != null) ||
       (numRemessaInicial == '' && numRemessaFinal != '')) {
      Swal(MESSAGES.SEQUENCIA_INICIAL_NAO_PODE_SER_NULA, "", MESSAGES.TYPE_ERROR);
      return false;
    }

    return true;
  }
  
  zeroAEsquerdaCNPJ(){
    if(!this.numDocumentoControl.value)
      return;

    this.numDocumentoControl.setValue(this.utils.padLeftWithZeros(this.numDocumentoControl.value, 14));
  }

}
