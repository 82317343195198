import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatTableModule } from '@angular/material/table'
import { SharedService } from './shared.service';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { LaInputMaskModule } from 'la-input-mask';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule, MatCardModule, MatToolbarModule } from '@angular/material';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatExpansionModule } from '@angular/material/expansion';

/* SERVIÇOS APP - INICIO  */
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { UsuarioService } from '../configuracoes/controle-acesso/usuario/usuario.service';
import { PerfilService } from '../configuracoes/controle-acesso/perfil/shared/perfil.service';
import { SocilicitacaoCadastroUsuarioService } from '../configuracoes/controle-acesso/solicitacao/cadastro-usuario/cadastro-usuario.service';
import { EventEmitterService } from './event-emiter-service.service';
import { AlterarUsuarioService } from '../configuracoes/controle-acesso/solicitacao/alterar-usuario/alterar-usuario.service';
import { FuncionalidadeService } from '../configuracoes/controle-acesso/funcionalidade/funcionalidade.service';
import { MatTabsModule } from '@angular/material';
import { MatStepperModule } from '@angular/material/stepper';
import { AprovarRejeitarPepService } from '../servicos/pep/aprovar-rejeitar/aprovar-rejeitar-pep.service';
import { MotivosBaixaService } from '../cadastros/motivos-baixa/motivos-baixa.service';
import { AgendamentoCarteiraService } from '../cadastros/agendamento-carteira/agendamento-carteira.service';
/* SERVIÇOS APP - FIM  */


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    NgxDatatableModule,
    MatSelectModule,
    MatRadioModule,
    LaInputMaskModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatMenuModule,
    MatButtonModule, 
    MatCardModule,
    MatToolbarModule,
    MatListModule,
    OverlayModule,
    MatExpansionModule,
    MatStepperModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    NgxDatatableModule,
    MatSelectModule,
    MatRadioModule,
    LaInputMaskModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatTabsModule,
    MatMenuModule,
    MatButtonModule, 
    MatCardModule,
    MatToolbarModule,
    MatListModule,
    OverlayModule,
    MatExpansionModule,
    MatStepperModule
  ],
  providers: [SharedService],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
        UsuarioService,
        PerfilService,
        SocilicitacaoCadastroUsuarioService,
        EventEmitterService,
        AlterarUsuarioService,
        FuncionalidadeService,
        AlterarUsuarioService,
        AprovarRejeitarPepService,
        MotivosBaixaService,
        AgendamentoCarteiraService,
      ]
    }
  }
}
