import { environment as localEnvironment } from './environment.local';
import { environment as devEnvironment } from './environment.dev';
import { environment as qaEnvironment } from './environment.qa';
import { environment as uatEnvironment } from './environment.uat';
import { environment as pceEnvironment } from './environment.pce';
import { environment as prdEnvironment } from './environment.prd';

export const environmentLoader = new Promise<any>((resolve, reject) => {

  var xmlhttp = new XMLHttpRequest(),
    method = 'GET',
    url = './assets/environments/environment.json';

  xmlhttp.open(method, url, true);

  xmlhttp.onload = function() {
    if (xmlhttp.status === 200) {
      var ambiente = JSON.parse(xmlhttp.responseText);
      if (ambiente && ambiente.environment) {
        if (ambiente.environment === 'DEV') {
          resolve(devEnvironment);
        } else if (ambiente.environment === 'QA') {
          resolve(qaEnvironment);
        } else if (ambiente.environment === 'UAT') {
          resolve(uatEnvironment);
        } else if (ambiente.environment === 'PCE') {
          resolve(pceEnvironment);
        } else if (ambiente.environment === 'PRD') {
          resolve(prdEnvironment);
        } else {
          resolve(localEnvironment);
        }
      } else {
        resolve(localEnvironment);
      }
    } else {
      resolve(localEnvironment);
    }
  };

  xmlhttp.onerror = function() {
    resolve(localEnvironment);
  };

  xmlhttp.send();
});
