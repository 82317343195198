import { Injectable, Injector } from "@angular/core";
import { JWTService } from "./jwt.service";
import { MESSAGES } from "../shared/constants/constants-swal.model";

import swal from "sweetalert2";

@Injectable()
export class HealthService {
    
    constructor( private inj: Injector ) { }
    
    // valida saúde do serviço e autenticidade da sessão na inicialização     
    load(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let jwtService: JWTService = this.inj.get(JWTService);
            
            try {    
                let block : boolean = false;
                if(this.isExternalRoute(window.location.pathname))
                    return resolve();
    
                if(jwtService.loggedIn()){
                    if(jwtService.checkJWT()) {
                        if(jwtService.tokenProximoDeExpirar())
                            jwtService.refreshToken();
                        
                        jwtService.check()
                            .subscribe(
                                ok => {
                                    return resolve();
                                },
                                errors => {
                                    swal(MESSAGES.SESSAO_EXPIRADA, "", MESSAGES.TYPE_ERROR);
                                    return jwtService.reiniciarSessao();
                                }
                            )
                    } else block = true;
                } else block = true;
    
                if(block) {
                    swal(MESSAGES.SESSAO_EXPIRADA, "", MESSAGES.TYPE_ERROR);
                    return jwtService.reiniciarSessao();
                }    
            } catch (e) {
                swal(MESSAGES.SESSAO_EXPIRADA, "", MESSAGES.TYPE_ERROR);
                return jwtService.reiniciarSessao();
            }
            
        });
    }

    isExternalRoute(route : string) : boolean {
        switch(route) {
            case "/":
            case "/login":
            case "/acessoItau":
            case "/recuperar-senha":
            case "/mfa":
            case "/reenvio-senha":
            case "/nova-senha":
            case "/instrucoes-senha":
            case "/validaToken":
            case "/cadastro/conta-criada":
                return true;            
            default:
                return false;
        }
    }

}
