import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ContestacaoService } from '../../shared/contestacao.service';
import { LoaderService } from 'src/app/shared/loader.service';
import { SharedService } from "../../../../shared/shared.service";
import Swal from 'sweetalert2';
import { MESSAGES } from '../../../../shared/constants/constants-swal.model';
import { ContestacaoSituacaoMotivosModel } from "../../../../shared/model/contestacao-situacao-motivos.model"
import { ContestacaoModel } from "../../../../shared/model/contestacao.model"

@Component({
  selector: 'app-questionamento-dialog',
  templateUrl: './questionamento-dialog.component.html',
  styleUrls: ['./questionamento-dialog.component.scss']
})
export class QuestionamentoDialogComponent implements OnInit {
  contestacaoSituacaoMotivos: ContestacaoSituacaoMotivosModel;

  constructor(
    public dialogRef: MatDialogRef<QuestionamentoDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: ContestacaoModel,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private service: ContestacaoService,
  ) { }

  ngOnInit() {
    this.contestacaoSituacaoMotivos = this.data.contestacaoSituacaoMotivos
  }

  close(): void {
    this.dialogRef.close();
  }
 
  getNomeArquivo(nome: string) {
    return nome.substring(0, nome.length - 4);
  }

  getExtensaoArquivo(nome: string) {
    return nome.substring(nome.length - 3, nome.length);
  }
  
  getDownloads(arquivo) {
    this.loaderService.display(true);

    const nomeArquivo = this.getNomeArquivo(arquivo);
    const extensaoArquivo = this.getExtensaoArquivo(arquivo);

    this.service.baixarArquivo(nomeArquivo, extensaoArquivo).subscribe(
      (success) => {
        this.loaderService.display(false);
        let file = this.gerarArquivoParaVisualizacao(arquivo, success);
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      },
      (errors) => {
        this.loaderService.display(false);
        Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
      }
    )
  }

  gerarArquivoParaVisualizacao(arquivo, success): Blob {
    let extensao: string = arquivo.split(".")[1];
    if(extensao.toLowerCase() === "png") {
      return new Blob([success], { type: 'image/png' });
    }
    else {
      return new Blob([success], { type: 'application/pdf' });
    }
  }
}
