import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apontamento',
  templateUrl: './apontamento.component.html',
})
export class ApontamentoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
