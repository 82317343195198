import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MyErrorStateMatcher } from '../../shared/error-matcher-input/error-matcher-input.model';
import { SharedService } from '../../shared/shared.service';
import { ConstantsMaskModel } from '../../shared/constants/constants-mask.model';
import Swal from 'sweetalert2';
import { DominioModel } from '../../shared/model/dominio.model';
import { UfModel } from '../../shared/model/uf.model';
import { MatDialog, MatStepperNext, MatStepper } from '@angular/material';
import { ModeloComunicadoDialog } from './cliente-dialog/modelo-comunicado-dialog';
import { ModeloCartaComunicadoModel } from '../../shared/model/modelo-carta-comunicado.model';
import { InformanteAreaModel } from '../../shared/model/informante-area.model';
import { InformanteAreaDialog } from './cliente-dialog/informante-area-dialog';
import { ClienteService } from './cliente.service';
import { Overlay } from '@angular/cdk/overlay';
import { Validator } from '../../shared/validators/validator';
import { LoaderService } from '../../shared/loader.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ParametrosModel } from '../../shared/model/parametros.model';
import { InformanteModel } from '../../shared/model/informante.model';
import { Router } from '@angular/router';
import { MESSAGES } from '../../shared/constants/constants-swal.model';
import { Token } from 'src/app/auth/token.model';
import { JWTService } from 'src/app/auth/jwt.service';
import { EmailModel } from 'src/app/shared/model/email.model';
import { TelefoneModel } from 'src/app/shared/model/telefone-model';
import { AUTHORITIES } from 'src/app/auth/groups.constants';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss']
})
export class ClienteComponent implements OnInit {

  @ViewChild(MatStepperNext) stepNext: MatStepperNext;

  model: InformanteModel;
  clienteForm: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  matcher = new MyErrorStateMatcher();
  disableStep = true;
  consultouInf: boolean = false;
  exists: boolean = false;
  isMultiCobranca: boolean = false;

  token: Token;

  // DadosCadastraisControls
  numDocumentoControl = new FormControl('', [Validators.required, Validator.documentIsValid(), Validator.isNumber()]);
  nomeRazaoSocialControl = new FormControl('', [Validators.required]);
  // LogradouroControl
  logradouroControl = new FormControl('', [Validators.required]);
  complementoControl = new FormControl('', []);
  cepControl = new FormControl('', [Validators.required]);
  codBairroControl = new FormControl('', []);
  bairroControl = new FormControl('', [Validators.required]);
  municipioControl = new FormControl('', [Validators.required]);
  ufControl = new FormControl('', [Validators.required]);
  // Endereco Control
  tipoLogradouroControl = new FormControl('', [Validators.required]);
  numLogradouroControl = new FormControl('', [Validators.required, Validator.isNumber(), Validators.max(99999)]);
  sequenciaControl = new FormControl('', []);
  // Dados Complementares Control
  nomeFantasiaControl       = new FormControl('', []);
  numTelefoneCapitalControl = new FormControl('', [Validators.min(1000000000), Validators.max(99999999999)]);
  numTelefoneDemaisControl  = new FormControl('', [Validators.min(1000000000),  Validators.max(99999999999)]);
  horarioAtendimentoControl = new FormControl('', []);
  emailControl              = new FormControl('', [Validators.email]);
  siteControl               = new FormControl('', []);

  cnpjInformanteControl = new FormControl('', []);

  logradouroDtoControl = new FormGroup({
    logradouro: this.logradouroControl,
    complemento: this.complementoControl,
    cep: this.cepControl,
    codBairro: this.codBairroControl,
    bairro: this.bairroControl,
    municipio: this.municipioControl,
    uf: this.ufControl,
    sequencia: this.sequenciaControl
  }, [Validators.required]);

  enderecoDTOControl = new FormGroup({
    tipoLogradouro: this.tipoLogradouroControl,
    numLogradouro: this.numLogradouroControl,
    logradouroDto: this.logradouroDtoControl
  });

  infoDadosComplDTOControl = new FormGroup({
    nomeFantasia      : this.nomeFantasiaControl,
    numTelefoneCapital: this.numTelefoneCapitalControl,
    numTelefoneDemais : this.numTelefoneDemaisControl,
    horarioAtendimento: this.horarioAtendimentoControl,
    email             : this.emailControl,
    site              : this.siteControl
  });

  tbParametrosDTOControl = new FormArray([
    new FormGroup({
      codParametro: new FormControl(5, [Validators.required]), // Valor mínimo de negativação 0
      valParametro: new FormControl(0, [Validators.required])
    }),
    new FormGroup({
      codParametro: new FormControl(6, [Validators.required]), // Valor maximo de negativação 1
      valParametro: new FormControl('', [])
    }),
    new FormGroup({
      codParametro: new FormControl(4, [Validators.required]), // Valor mínimo de negativação 2
      valParametro: new FormControl(0, [Validators.required])
    }),
    new FormGroup({
      codParametro: new FormControl(19, [Validators.required]), // Valor maximo de negativação 3
      valParametro: new FormControl('', [])
    }),
    new FormGroup({
      codParametro: new FormControl(2, [Validators.required]), // Negativar PEP 4
      valParametro: new FormControl(0, [Validators.required])
    }),
    new FormGroup({
      codParametro: new FormControl(1, [Validators.required]), // Emitir comunicado carga inicial 5
      valParametro: new FormControl(0, [Validators.required])
    }),
    new FormGroup({
      codParametro: new FormControl(3, [Validators.required]), // Negativar menor de idade 6
      valParametro: new FormControl(0, [Validators.required])
    }),
    new FormGroup({
      codParametro: new FormControl(17, [Validators.required]), // Indica se quantidade de dias para análise da contestação
      valParametro: new FormControl(0, [Validators.required])
    }),
    new FormGroup({
      codParametro: new FormControl(16, [Validators.required]), // Indica se a negativação será liberada em confiança após contestação
      valParametro: new FormControl('N', [Validators.required])
    }),
    new FormGroup({
      codParametro: new FormControl(18, [Validators.required]), // Súmula 385
      valParametro: new FormControl('N', [Validators.required])
    })
  ]);

  modeloComunicadoDTOControl = new FormArray([]);
  informanteAreasDTOControl = new FormArray([]);

  // Model
  dominiosTipoEndereco: DominioModel[] = [];
  ufs: UfModel[] = [];
  requestLoad: boolean = false;

  // Tab
  selectedTab: number = 0;

  // Mask
  maskCnpj = ConstantsMaskModel.MASK_CNPJ;
  maskCep = ConstantsMaskModel.MASK_CEP;
  specialCharMask: ConstantsMaskModel = new ConstantsMaskModel();

  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();
  dialogRef: any;
  telefones: TelefoneModel[] = [];
  emails: EmailModel[] = [];
  tiposEmail: any[] = [];
  jwt: Token;
  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    public dialog: MatDialog,
    public service: ClienteService,
    private jwtService: JWTService,
    public overlay: Overlay,
    private loaderService: LoaderService,
    private utilsService: UtilsService,
    private router: Router) { }

  ngOnInit() {
    this.clienteForm = this.formBuilder.group({
      numDocumento: this.numDocumentoControl,
      nomeRazaoSocial: this.nomeRazaoSocialControl,
      enderecoDTO: this.enderecoDTOControl
    });
    this.firstFormGroup = this.formBuilder.group({
      numDocumento: this.numDocumentoControl,
      nomeRazaoSocial: this.nomeRazaoSocialControl
    })
    this.secondFormGroup = this.formBuilder.group({
      enderecoDTO: this.enderecoDTOControl
    })
    this.thirdFormGroup = this.formBuilder.group({
      infoDadosComplDTO: this.infoDadosComplDTOControl
    })
    
    this.jwt = this.jwtService.getObject('auth_token') as Token;

    this.getTipoLogradouro();
    this.getUfs();
    this.informanteAreasDTOControl.push(this.criarFormGroupInformanteAreaDefault());

    this.token = this.jwtService.getObject('auth_token') as Token;
    this.token = this.jwtService.decode(this.token);

    this.cnpjInformanteControl.setValue(this.token.numDocumento);
    this.isMultiCobranca = this.checkMultiCobranca(this.token);
    if (this.isMultiCobranca) {
      this.getInformante(this.token.numDocumento);
      this.clienteForm.addControl('infoDadosComplDTO', this.infoDadosComplDTOControl);
    } else {
      this.clienteForm.addControl('tbParametrosDTO', this.tbParametrosDTOControl);
      this.clienteForm.addControl('modeloComunicadoDTO', this.modeloComunicadoDTOControl);
      this.clienteForm.addControl('informanteAreasDTO', this.informanteAreasDTOControl);
    }
  }
  /*
  * Checa se a relação Informmante x Credor é MultiCobranca
  * através do perfil_usuario
  */
  checkMultiCobranca(token: Token): boolean {
    return this.sharedService.hasPermission(this.jwt, [AUTHORITIES.RETAGUARDA_MULTICOBRANCA]);
  }

  criarFormGroupInformanteAreaDefault(): FormGroup {
    return new FormGroup({
      codArea: new FormControl("0000", []),
      descArea: new FormControl("", []),
      numDocumento: new FormControl("", [])
    });
  }

  loadDocAndDescForAreaDefault() {
    if (!this.exists && this.informanteAreasDTOControl.controls.length > 0) {
      this.informanteAreasDTOControl.controls[0].get('descArea').setValue(this.nomeRazaoSocialControl.value);
      this.informanteAreasDTOControl.controls[0].get('numDocumento').setValue(this.numDocumentoControl.value);
    }
  }

  markAsTouch(form: FormGroup): void {
    Object.keys(form.controls).forEach(key => {
      form.get(key).markAsTouched();
    });

    this.loadDocAndDescForAreaDefault();
  }

  markAsEnderecoAsTouch() {
    Object.keys(this.enderecoDTOControl.controls).forEach(key => {
      this.enderecoDTOControl.get(key).markAsTouched();
    });
    Object.keys(this.logradouroDtoControl.controls).forEach(key => {
      this.logradouroDtoControl.get(key).markAsTouched();
    });
  }

  salvarCredor(stepper: MatStepper) {
    this.clienteForm.updateValueAndValidity();
    if (!this.formIsValid()) {
      Swal(MESSAGES.DADO_INVALIDO, "", MESSAGES.TYPE_ERROR);
      return;
    }
    this.requestLoad = true;
    this.setNumDocumentoForInformanteArea();
    this.model = this.setModel(this.clienteForm);

    this.loaderService.display(true);
    if (this.exists) {
      this.service.atualizarInformante(this.model)
        .subscribe(
          informante => {
            this.loaderService.display(false);
            Swal(MESSAGES.CLIENTE_ATUALIZADO_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
            this.limparForm();
            this.resetStepper(stepper);
            this.requestLoad = false;
            if (this.isMultiCobranca)
              this.router.navigate(['servicos/apontamento/incluirApontamento'])
            else
              this.router.navigate(['cadastros/cliente']);
          },
          errors => {
            this.showErrors(errors);
          }
        );
    } else {
      this.model.enderecoDTO.sequencia = 0;
      this.service.salvarInformante(this.model)
        .subscribe(
          informante => {
            this.loaderService.display(false);
            Swal(MESSAGES.CLIENTE_SALVO_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
            this.limparForm();
            this.resetStepper(stepper);
            this.requestLoad = false;
            if (this.isMultiCobranca)
              this.router.navigate(['servicos/apontamento/incluirApontamento'])
            else
              this.router.navigate(['cadastros/cliente']);
          },
          errors => {
            this.showErrors(errors);
          }
        );
    }
  }

  /*
  * Configura o InformanteModel para envio
  * param FormGroup
  * return InformanteModel
  */
  setModel(form: FormGroup): InformanteModel {
    let model: InformanteModel = form.value;

    model.cnpjInformante = this.token.numDocumento;
    if (this.isMultiCobranca) {
      model.informanteAreasDTO = this.model.informanteAreasDTO;
      model.modeloComunicadoDTO = this.model.modeloComunicadoDTO;
      model.tbParametrosDTO = this.model.tbParametrosDTO;
    }
    return model;
  }

  showErrors(errors: any): any {
    this.loaderService.display(false);
    this.requestLoad = false;

    if (errors.status === 0)
      Swal(MESSAGES.ERRO_DESCONHECIDO, "", MESSAGES.TYPE_ERROR);
    else
      Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
  }



  setNumDocumentoForInformanteArea() {
    const numDocumento = this.numDocumentoControl.value;
    this.informanteAreasDTOControl.controls.forEach((informante, i) => {
      this.informanteAreasDTOControl.controls[i].value.numDocumento = numDocumento;
    })
  }

  formIsValid(): boolean {
    if (!this.isMultiCobranca) {
      if (this.modeloComunicadoDTOControl.controls.length <= 0) {
        Swal(MESSAGES.NECESSARIO_UM_MODELO_COMUNICADO, "", MESSAGES.TYPE_ERROR);
        return false;
      }
      if (this.informanteAreasDTOControl.controls.length <= 0) {
        Swal(MESSAGES.NECESSARIO_UMA_AREA, "", MESSAGES.TYPE_ERROR);
        return false;
      }
    }

    if (this.clienteForm.invalid) {
      this.clienteForm.markAsTouched();
      return false;
    }
    return true;
  }

  getEnderecoByCep(cep: string) {

    this.sharedService.getEnderecoByCep(cep)
      .subscribe(
        endereco => {
          if (endereco.logradouro) {
            this.logradouroControl.setValue(this.utilsService.removeTipoLogradouroFromLogradouro(endereco.logradouro));
            this.bairroControl.setValue(endereco.bairro);
            this.municipioControl.setValue(endereco.municipio);
            this.ufControl.setValue(endereco.uf);
            let tipoLogradouro = this.utilsService.getTipoLogradouroFromLogradouro(endereco.logradouro);
            this.dominiosTipoEndereco.forEach(dominio => {
              if (tipoLogradouro.toLowerCase() === dominio.descricao.toLowerCase()) {
                this.tipoLogradouroControl.setValue(dominio.descricao);
              }
            })
          }
        },
        errors => {
          this.limpaCep();
        }
      );
  }

  limpaCep() {
    this.logradouroControl.reset();
    this.bairroControl.setValue('');
    this.municipioControl.setValue('');
    this.ufControl.setValue('');

  }

  removerFormGroupInformanteArea(index: number) {
    this.informanteAreasDTOControl.removeAt(index);
  }

  criarFormGroupInformanteArea(informanteArea: InformanteAreaModel): FormGroup {
    return new FormGroup({
      codArea: new FormControl(informanteArea.codArea, []),
      descArea: new FormControl(informanteArea.descArea, []),
      numDocumento: new FormControl('', [])
    });
  }

  removerFormGroupModeloCarta(index: number) {
    let flagPrincipal: boolean = false;
    if (this.modeloComunicadoDTOControl.controls[index].get('principal').value == '1') {
      flagPrincipal = true;
    }
    this.modeloComunicadoDTOControl.removeAt(index);
    if (this.modeloComunicadoDTOControl.controls.length > 0 && flagPrincipal) {
      this.selecionarPrincipal(0);
    }
  }

  criarFormGroupModeloCarta(modeloComunicado: ModeloCartaComunicadoModel): FormGroup {
    return new FormGroup({
      codComunicado: new FormControl(modeloComunicado.codComunicado, [Validators.required]),
      descModeloComunicado: new FormControl(modeloComunicado.descModeloComunicado, [Validators.required]),
      principal: new FormControl(this.modeloComunicadoDTOControl.controls.length == 0 ? '1' : '0', [])
    })
  }

  selecionarPrincipal(index: number): void {
    this.modeloComunicadoDTOControl.controls.forEach((modelo, i) => {
      this.modeloComunicadoDTOControl.controls[i].get('principal').setValue('0');
    });
    this.modeloComunicadoDTOControl.controls[index].get('principal').setValue('1');
  }

  openDialogInformanteArea() {
    const dialogRef = this.dialog.open(InformanteAreaDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '50%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let flag: boolean = false;
        this.informanteAreasDTOControl.controls.forEach((area, i) => {
          if (area.get('codArea').value === result.codArea) {
            Swal(MESSAGES.EXISTE_UMA_AREA_CODIGO, "", MESSAGES.TYPE_ERROR);
            flag = true;
            return;
          }
        })
        if (flag)
          return;
        this.informanteAreasDTOControl.push(this.criarFormGroupInformanteArea(result));
      }
    });
  }

  openDialogModeloComunicado() {
    const dialogRef = this.dialog.open(ModeloComunicadoDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '50%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.modeloComunicadoDTOControl.controls.length > 0) {
          let flag: boolean = false;
          this.modeloComunicadoDTOControl.controls.forEach((modelo, i) => {
            if (modelo.get('codComunicado').value === result.codComunicado) {
              Swal(MESSAGES.JA_EXISTE_MODELO_COMUNICADO, "", MESSAGES.TYPE_ERROR);
              flag = true;
              return;
            }
          });
          if (flag)
            return;
        }
        this.modeloComunicadoDTOControl.push(this.criarFormGroupModeloCarta(result));
      }
    });
  }

  tabFoward() {
    if (this.selectedTab >= 2) {
      return;
    }
    this.selectedTab++;
  }

  tabBackward() {
    if (this.selectedTab == 0) {
      return;
    }
    this.selectedTab--;
  }


  getUfs() {
    this.sharedService.getUfs()
      .subscribe(
        ufs => {
          this.ufs = ufs;
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getTipoLogradouro() {
    this.sharedService.getDominioByCodigo('TIPO_LOGRADOURO')
      .subscribe(
        dominios => {
          this.dominiosTipoEndereco = dominios;
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  validaRazaoSocial() {
    if (this.nomeRazaoSocialControl.value.match(/[^a-zA-Z0-9-. ]/)) {
      this.clienteForm.controls['nomeRazaoSocial'].setErrors({ 'specialchar': true });
    }
  }

  limparForm() {
    this.clienteForm.reset();
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
    this.thirdFormGroup.reset();
    this.numDocumentoControl.setValue(' ');
    this.cepControl.setValue(' ');
    this.exists = false;
    this.consultouInf = false;

    while (this.modeloComunicadoDTOControl.length !== 0) {
      this.modeloComunicadoDTOControl.removeAt(0);
    }
    while (this.informanteAreasDTOControl.length !== 0) {
      this.informanteAreasDTOControl.removeAt(0);
    }
    this.resetParametros();

    this.informanteAreasDTOControl.push(this.criarFormGroupInformanteAreaDefault());
    this.stepNext._stepper.reset();
  }

  resetStepper(stepper: MatStepper) {
    stepper.reset();
  }

  resetParametros() {
    this.tbParametrosDTOControl.controls[0].get("codParametro").setValue(5);
    this.tbParametrosDTOControl.controls[0].get("valParametro").setValue(0);

    this.tbParametrosDTOControl.controls[1].get("codParametro").setValue(6);
    this.tbParametrosDTOControl.controls[1].get("valParametro").setValue('');

    this.tbParametrosDTOControl.controls[2].get("codParametro").setValue(4);
    this.tbParametrosDTOControl.controls[2].get("valParametro").setValue(0);

    this.tbParametrosDTOControl.controls[3].get("codParametro").setValue(19);
    this.tbParametrosDTOControl.controls[3].get("valParametro").setValue('');

    this.tbParametrosDTOControl.controls[4].get("codParametro").setValue(2);
    this.tbParametrosDTOControl.controls[4].get("valParametro").setValue(0);

    this.tbParametrosDTOControl.controls[5].get("codParametro").setValue(1);
    this.tbParametrosDTOControl.controls[5].get("valParametro").setValue(0);

    this.tbParametrosDTOControl.controls[6].get("codParametro").setValue(3);
    this.tbParametrosDTOControl.controls[6].get("valParametro").setValue(0);

    this.tbParametrosDTOControl.controls[7].get("codParametro").setValue(17);
    this.tbParametrosDTOControl.controls[7].get("valParametro").setValue(0);

    this.tbParametrosDTOControl.controls[8].get("codParametro").setValue(16);
    this.tbParametrosDTOControl.controls[8].get("valParametro").setValue('N');

    this.tbParametrosDTOControl.controls[9].get("codParametro").setValue(18);
    this.tbParametrosDTOControl.controls[9].get("valParametro").setValue('N');
  }

  getCredor(numDocumento: string) {
    this.consultouInf = false;
    numDocumento = this.zeroAEsquerda(numDocumento.toString());
    this.numDocumentoControl.setValue(numDocumento);
    if (this.numDocumentoControl.invalid)
      return;
    this.sharedService.getInformante(Number.parseInt(numDocumento))
      .subscribe(
        (credor: InformanteModel) => {
          while (this.modeloComunicadoDTOControl.length !== 0) {
            this.modeloComunicadoDTOControl.removeAt(0);
          }
          while (this.informanteAreasDTOControl.length !== 0) {
            this.informanteAreasDTOControl.removeAt(0);
          }

          if (credor.tbParametrosDTO && credor.tbParametrosDTO.length == 0) {
            this.criarFormGroupInformanteAreaDefault();
            this.loadDocAndDescForAreaDefault();
          }

          this.consultouInf = true;
          this.exists = true;
          this.nomeRazaoSocialControl.setValue(credor.nomeRazaoSocial);
          this.logradouroControl.setValue(credor.enderecoDTO.logradouroDto.logradouro);
          this.complementoControl.setValue(credor.enderecoDTO.logradouroDto.complemento);
          this.cepControl.setValue(credor.enderecoDTO.logradouroDto.cep);
          this.codBairroControl.setValue(credor.enderecoDTO.logradouroDto.codBairro);
          this.bairroControl.setValue(credor.enderecoDTO.logradouroDto.bairro);
          this.municipioControl.setValue(credor.enderecoDTO.logradouroDto.municipio);
          this.ufControl.setValue(credor.enderecoDTO.logradouroDto.uf);
          this.tipoLogradouroControl.setValue(credor.enderecoDTO.tipoLogradouro);
          this.numLogradouroControl.setValue(credor.enderecoDTO.numLogradouro.toString().trim());
          credor.tbParametrosDTO.forEach(p => {
            if (p.codParametro) {
              p.codParametro = p.codParametro.trim();
              this.setParametro(p);
            }
          });
          credor.modeloComunicadoDTO.forEach(e => {
            if (e.codComunicado) {
              e.codComunicado = e.codComunicado.trim();
              this.modeloComunicadoDTOControl.push(this.criarFormGroupModeloCarta(e));
            }
          });
          credor.informanteAreasDTO.forEach(e => {
            if (e.codArea) {
              e.codArea = e.codArea.trim();
              this.informanteAreasDTOControl.push(this.criarFormGroupInformanteArea(e));
            }
          });
        },
        error => {
          if (this.exists) {
            this.nomeRazaoSocialControl.setValue('');
            this.secondFormGroup.reset();
            this.cepControl.setValue(' ');
            this.resetParametros();
            this.exists = false;

            while (this.modeloComunicadoDTOControl.length !== 0) {
              this.modeloComunicadoDTOControl.removeAt(0);
            }
            while (this.informanteAreasDTOControl.length !== 0) {
              this.informanteAreasDTOControl.removeAt(0);
            }

            this.informanteAreasDTOControl.push(this.criarFormGroupInformanteAreaDefault());
          }

          else if ((error.status === 0) || (error.statusText === 'Unknown Error')) {
            Swal(MESSAGES.ERRO_DESCONHECIDO, "", MESSAGES.TYPE_ERROR);
          } else if (error.error.message != "CNPJ da Empresa não encontrado") {
            Swal(this.sharedService.tratarErro(error), "", MESSAGES.TYPE_ERROR);
          }
          this.consultouInf = true;
        }
      );
  }


  getInformante(numDocumento: string) {
    numDocumento = this.zeroAEsquerda(numDocumento.toString());
    this.sharedService.getInformante(Number.parseInt(numDocumento))
      .subscribe(
        (informante: InformanteModel) => {
          this.model = informante;
        },
        errors => {
          console.log(errors);
        }
      );
  }


  zeroAEsquerda(numDocumento: string): string {
    if (!numDocumento)
      return;
    return this.utilsService.padLeftWithZeros(numDocumento, 14);
  }

  setParametro(parametros: ParametrosModel) {
    let key = 0;
    try {
      switch (parametros.codParametro.trim()) {
        case "5":
          key = 0;
          break;
        case "6":
          key = 1;
          break;
        case "4":
          key = 2;
          break;
        case "19":
          key = 3;
          break;
        case "2":
          key = 4;
          break;
        case "1":
          key = 5;
          break;
        case "3":
          key = 6;
          break;
        case "17":
          key = 7;
          break;
        case "16":
          key = 8;
          break;
        case "18":
          key = 9;
          break;
        default:
          return;
      }

      this.tbParametrosDTOControl.controls[key].get("codParametro").setValue(parametros.codParametro);
      this.tbParametrosDTOControl.controls[key].get("valParametro").setValue(parametros.valParametro);
    } catch {
      return;
    }
  }
  
  checkDDD(ddd: string): boolean {
    return this.utilsService.getListaDDDsValidos().filter(a => a === ddd).length > 0;
  }
}
