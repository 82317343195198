import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AUTHORITIES } from '../../auth/groups.constants';
import { JWTService } from '../../auth/jwt.service';
import { HttpService } from '../../shared/http/httpclient.service';
import { Observable } from 'rxjs';

@Injectable()
export class AgendamentoCarteiraService {
  constructor(private http: HttpService,
    private jwtService: JWTService
  ) {}

  getAgendamentos(numDocumento: string) {
    return this.http.get(`${environment.settings.api}/agendamento-carteira/informante/${numDocumento}`, this.jwtService.getApiHeaders([AUTHORITIES.AGENDAMENTO_CARTEIRA]), null);
  }

  getAgendamento(id: number) {
    return this.http.get(`${environment.settings.api}/agendamento-carteira/${id}`, this.jwtService.getApiHeaders([AUTHORITIES.AGENDAMENTO_CARTEIRA]), null);
  }

  salvar(body) {
    return this.http.post(`${environment.settings.api}/agendamento-carteira`, body, this.jwtService.getApiHeaders([AUTHORITIES.AGENDAMENTO_CARTEIRA]));
  }

  executarPrevia(idAgendamento: number) {
    return this.http.put(`${environment.settings.api}/agendamento-carteira/previa/${idAgendamento}`, null, this.jwtService.getApiHeaders([AUTHORITIES.AGENDAMENTO_CARTEIRA]));
  }

  getHistoricoEnvios(idAgendamento: number) {
    return this.http.get(`${environment.settings.api}/agendamento-carteira/historico/${idAgendamento}`, this.jwtService.getApiHeaders([AUTHORITIES.AGENDAMENTO_CARTEIRA]), null);
  }

  solicitarDownload(body) {
    return this.http.post(`${environment.settings.api}/agendamento-carteira/historico/solicitar-download`, body, this.jwtService.getApiHeaders([AUTHORITIES.AGENDAMENTO_CARTEIRA]));
  }

  realizarDownload(body): Observable<ArrayBuffer> {
    return this.http.postBuffer(`${environment.settings.api}/agendamento-carteira/historico/realizar-download`, body, this.jwtService.getApiHeaders([AUTHORITIES.AGENDAMENTO_CARTEIRA]));
  }

  getParametrosNejInformante(numDocumento: string) {
    return this.http.get(`${environment.settings.api}/agendamento-carteira/parametros/${numDocumento}`, this.jwtService.getApiHeaders([AUTHORITIES.AGENDAMENTO_CARTEIRA]), null);
  }
}
