import { Component, OnInit, OnDestroy } from '@angular/core';
import $ from 'jquery';

@Component({
  templateUrl: '404.component.html'
})
export class P404Component implements OnInit, OnDestroy{

  constructor() { }

  ngOnInit(){
    $(".menu-bar").css("display","none");
    $(".testeMenu").css("display","none");
    $("breadcrumb").css("display","none");
  }

  ngOnDestroy(){
    $(".menu-bar").css("display","");
    $(".testeMenu").css("display","");
    $("breadcrumb").css("display","");
  }
}
