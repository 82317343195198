import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MotivoContestacaoModel } from '../../../../shared/model/motivo-contestacao.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ContestacaoService } from '../../shared/contestacao.service';
import { SharedService } from '../../../../shared/shared.service';
import swal from 'sweetalert2';
import { LoaderService } from '../../../../shared/loader.service';
import { MyErrorStateMatcher } from '../../../../shared/error-matcher-input/error-matcher-input.model';
import { MESSAGES } from '../../../../shared/constants/constants-swal.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rejeitar-dialog',
  templateUrl: './rejeitar-dialog.component.html',
  styleUrls: ['./rejeitar-dialog.component.scss']
})
export class RejeitarDialogComponent implements OnInit {

  constructor(private loaderService: LoaderService, public dialogRef: MatDialogRef<RejeitarDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder, private service: ContestacaoService,
    private sharedService: SharedService) { }

  matcher = new MyErrorStateMatcher();

  rejeitarForm: FormGroup;
  dadosForm: any;
  motivoContestacaoControl = new FormControl('', [Validators.required]);
  justificativaControl = new FormControl('', [Validators.required]);
  motivosContestacao: MotivoContestacaoModel[] = [];

  ngOnInit() {
    this.rejeitarForm = this.formBuilder.group({
      motivoContestacao: this.motivoContestacaoControl,
      justificativa: this.justificativaControl
    });
    this.getMotivosQuestionamento();
  }

  getMotivosQuestionamento() {
    this.service.buscarMotivoContestacao("R").subscribe(
      motivo => {
        this.motivosContestacao = motivo;
      },
      errors => {
        swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
      }
    )
  }

  rejeitar() {
    if (this.motivoContestacaoControl.hasError('required')) {
      return;
    }

    if (this.motivoContestacaoControl.value == '998' && this.justificativaControl.hasError('required')) {
      Swal("Quando motivo for outros justificativa é obrigatória", "", MESSAGES.TYPE_ERROR);
      return;
    }

    this.loaderService.display(true);

    this.service.reprovarContestacao(this.data.numContestacao, this.justificativaControl.value, this.motivoContestacaoControl.value)
      .subscribe(
        resp => {
          this.loaderService.display(false);
          swal(MESSAGES.CONTESTACAO_REJEITADA_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
        },
        errors => {
          this.loaderService.display(false);
          swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        })
    this.dialogRef.close(this.rejeitarForm.value);
  }

}
