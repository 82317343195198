import { Directive } from '@angular/core';
import {
    AbstractControl, NG_VALIDATORS, Validator
} from '@angular/forms';

import { KzCnpjValidator } from './validators/cnpj.validator';

@Directive({
    selector: '[kz-cnpj]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: KzCnpjValidatorDirective,
        multi: true
    }]
})
export class KzCnpjValidatorDirective implements Validator {
    /**
     * Valida os dados.
     *
     * @param AbstractControl control
     * @return object ou null caso válido
     */
    validate(control: AbstractControl): { [key: string]: any } {
        return KzCnpjValidator.validate(control);
    }
}