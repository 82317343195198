import { InformanteAreaModel } from './informante-area.model';
import { ModeloCartaComunicadoModel } from './modelo-carta-comunicado.model';
import { ParametrosModel } from './parametros.model';
import { EnderecoModel } from './endereco.model';
import { InformanteDadosComplModel } from './informanteDadosCompl.model';
import { ContratoGicModel } from './contrato-gic.model';

export class InformanteModel {
    constructor(
        public idInformante?: string,
        public numDocumento?: string,
        public nomeRazaoSocial?: string,
        public enderecoDTO?: EnderecoModel,
        public informanteAreasDTO?: InformanteAreaModel[],
        public modeloComunicadoDTO?: ModeloCartaComunicadoModel[],
        public tbParametrosDTO?: ParametrosModel[],
        public cnpjInformante?: string,
        public infoDadosComplDto?: InformanteDadosComplModel,
        public tbContratoDTO?: ContratoGicModel[],

    ) {
        this.idInformante = idInformante == null ? '' : idInformante;
        this.numDocumento = numDocumento == null ? '' : numDocumento;
        this.nomeRazaoSocial = nomeRazaoSocial == null ? '' : nomeRazaoSocial;
        this.informanteAreasDTO = informanteAreasDTO == null ? [] : informanteAreasDTO;
        this.modeloComunicadoDTO = modeloComunicadoDTO == null ? [] : modeloComunicadoDTO;
        this.tbParametrosDTO = tbParametrosDTO == null ? [] : tbParametrosDTO;
        this.enderecoDTO = enderecoDTO == null ? null : enderecoDTO;
        this.cnpjInformante = cnpjInformante == null ? '' : cnpjInformante;
        this.tbContratoDTO = tbContratoDTO == null ? [] : tbContratoDTO;
    }
}