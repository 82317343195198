export class Solicitacao {
    constructor(
        public idLogin : number,
        public idSolicitacao : number,
        public numDocumento : string,
        public codArea : string,
        public cpf : string,
        public email : string,
        public emailConfirmar : string,
        public nome : string,
        public apelido : string,
        public numTelefone : string,
        public codDdd : string,
        public tipoUsuario : string,
        public codPerfil : number,
        public nomePerfil : string,
        public status : string,
        public cpfAprovador : string,
        public operacao : string,
        public informanteNome?: string,
        public areaDesc?: string
    ){

    }
}

export class SolicitacaoTransferencia {
    constructor(
        public listaSolicitacoes : Solicitacao[],
        public cpfNovoAprovador : string
    ){

    }
}