import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, FormBuilder, Validators} from '@angular/forms';
import { SenhaService } from './shared/senha.service';
import { NovaSenha, AcessoTemporario } from './shared/senha.model';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import {UtilsService} from '../shared/utils.service';
import { LoaderService } from '../shared/loader.service';
import $ from "jquery"
import { EventEmitterService } from '../shared/event-emiter-service.service';
import { Validator } from '../shared/validators/validator';
import { ConstantsMaskModel } from '../shared/constants/constants-mask.model';
import { MESSAGES } from '../shared/constants/constants-swal.model';
import { MyErrorStateMatcher } from '../shared/error-matcher-input/error-matcher-input.model';

@Component({
  selector: 'app-novasenha',
  styleUrls: ['./novasenha.component.scss'],
  templateUrl: './novasenha.component.html',
  providers: [SenhaService]
})
export class NovaSenhaComponent implements OnInit {

  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel()

  maiusculas: boolean = false;
  minusculas: boolean = false;
  numeros: boolean = false;
  especiais: boolean = false;
  tamanho: boolean = false;

  visibleSenhaProvisoria: boolean = false;
  visibleSenha: boolean = false;
  visibleConfirmaSenha: boolean = false;
  changetypeSenhaProvisoria: boolean = true;
  changetypeSenha: boolean = true;
  changetypeConfirmaSenha: boolean = true;

  constructor( private formBuilder: FormBuilder, 
               private senhaService : SenhaService, 
               private router: Router,
               private route: ActivatedRoute,
               private utils : UtilsService,
               private loaderService: LoaderService,
               private emitterService: EventEmitterService ) { }
  
  senhaForm: FormGroup;
  senha: NovaSenha; 
  nomePagina: string;
  acessoTemporario: AcessoTemporario;
  cpfControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  senhaControl = new FormControl('', [Validators.required]);
  confirmaSenhaControl = new FormControl('', [Validators.required]);
  matcher = new MyErrorStateMatcher();

  ngOnInit() {
    window.scrollTo(0, 0);    
    this.emitterService.display(false);
    this.loaderService.display(true);
    this.senhaForm = this.formBuilder.group({
      cpf: this.cpfControl,
      tipoUsuario: "FO",
      senhaProvisoria: this.formBuilder.control('', [Validators.required, Validators.maxLength(8), Validators.minLength(8)]),
      senha: this.senhaControl,
      confirmaSenha: this.confirmaSenhaControl,
    });
    
    if (this.route.snapshot.queryParamMap.get("cpf") !== null) {
      this.senhaForm.get("cpf").setValue(this.route.snapshot.queryParamMap.get("cpf"));
    }

    this.acessoTemporario = new AcessoTemporario();
    this.loaderService.display(false);

    $(".breadcrumb").css({'display': 'none'})  
    $('.content-wraper').addClass('col-6')
    $('.content-wraper').addClass('float-right')
    $('.content-wraper').addClass('pl-3')
    $('.content-wraper').addClass('ml-0')
    $(".container-fluid").removeClass('wraper')      
    $(".content-wraper").removeClass('ml-5')  
  }

  viewpassSenhaProvisoria(){
    this.visibleSenhaProvisoria = !this.visibleSenhaProvisoria
    this.changetypeSenhaProvisoria = !this.changetypeSenhaProvisoria
  }
  viewpassSenha(){
    this.visibleSenha = !this.visibleSenha
    this.changetypeSenha = !this.changetypeSenha
  }
  viewpassConfirmaSenha(){
    this.visibleConfirmaSenha = !this.visibleConfirmaSenha
    this.changetypeConfirmaSenha = !this.changetypeConfirmaSenha
  }

  atualizarSenha(model) {
    this.loaderService.display(true);
    this.emitterService.display(false);
    this.senhaService.atualizarSenha(model)
      .subscribe(
        () => {
          this.loaderService.display(false);
          Swal(MESSAGES.SENHA_ATUALIZADA_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
          this.router.navigate(['/login']);
        }, 
        errors => { 
          this.loaderService.display(false);
          if(errors.status === 418){
            this.router.navigate(["reenvio-senha"]);
            return;
          }
          Swal(this.utils.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );        
  }

  recuperarSenha(){
    this.router.navigate(['/recuperar-senha']);
  }

  zeroAEsquerda(){
    if(!this.cpfControl.value)
      return;
    if(this.cpfControl.value.replace(/_/g, '').length > 11){
      this.cpfControl.setValue(this.utils.padLeftWithZeros(this.cpfControl.value, 14));
    } else {
      this.cpfControl.setValue(this.utils.padLeftWithZeros(this.cpfControl.value, 11));
    }
  }

  contadorCaracteres(): any {
    const senha = this.senhaControl.value;
    var numerosPattern = /\d+/g;
    var letrasMaiusculasPattern = /[A-Z]+/g;
    var letrasMinusculasPattern = /[a-z]+/g;

    let letrasMaiusculas = 0, letrasMinusculas = 0, numeros = 0, caracteresEspeciais = 0;

    if (senha.match(letrasMaiusculasPattern) != null) {
      senha.match(letrasMaiusculasPattern).forEach(element => {
        letrasMaiusculas += element.length;
      });
    }
    if (senha.match(letrasMinusculasPattern) != null) {
      senha.match(letrasMinusculasPattern).forEach(element => {
        letrasMinusculas += element.length;
      });
    }
    if (senha.match(numerosPattern) != null) {
      senha.match(numerosPattern).forEach(element => {
        numeros += element.length;
      });
    }
    caracteresEspeciais = senha.length - letrasMaiusculas - letrasMinusculas - numeros;

    return {
      maiusculas: letrasMaiusculas,
      minusculas: letrasMinusculas,
      numeros: numeros,
      especiais: caracteresEspeciais,
      tamanho: senha.length
    }
  }

  validarNovaSenha() {
    const contador = this.contadorCaracteres();
    this.minusculas = (contador.minusculas > 0);
    this.maiusculas = (contador.maiusculas > 0);
    this.numeros = (contador.numeros > 0);
    this.especiais = (contador.especiais > 0);
    this.tamanho = (contador.tamanho > 8);

    if (this.maiusculas && this.minusculas && this.numeros && this.especiais && this.tamanho) {
      this.senhaControl.setErrors({ 'invalid': null });
      this.senhaControl.updateValueAndValidity();
    } else {
      this.senhaControl.setErrors({ 'invalid': true });
      return;
    }

    if (this.senhaControl.value === this.confirmaSenhaControl.value) {
      this.senhaControl.setErrors({ 'password-match': null });
      this.confirmaSenhaControl.setErrors({ 'password-match': null });
      this.senhaControl.updateValueAndValidity();
      this.confirmaSenhaControl.updateValueAndValidity();
    } else {
      this.senhaControl.setErrors({ 'password-match': true });
      this.confirmaSenhaControl.setErrors({ 'password-match': true });
    }
  }

}
