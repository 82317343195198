import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from '../../shared/error-matcher-input/error-matcher-input.model';
import { SharedService } from '../../shared/shared.service';
import Swal from 'sweetalert2';
import 'rxjs/add/operator/finally';
import { InformanteAreaModel } from '../../shared/model/informante-area.model';
import { StatusRemessaProcessadaModel } from './status-remessa-processada.model';
import { StatusRemessaProcessadaService } from './status-remessa-processada.service';
import { ConstantsMaskModel } from '../../shared/constants/constants-mask.model';
import { Validator } from '../../shared/validators/validator';
import * as moment from 'moment'
import { LoaderService } from '../../shared/loader.service';
import { MESSAGES } from '../../shared/constants/constants-swal.model';
import { UtilsService } from '../../shared/utils.service';

@Component({
  selector: 'app-status-remessa-processada',
  templateUrl: './status-remessa-processada.component.html',
})
export class StatusRemessaProcessadaComponent implements OnInit {

  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();
  statusRemessaProcessadaForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  informanteAreasModel: InformanteAreaModel[] = [];
  informanteAreaModel: InformanteAreaModel;
  numerosRemessaModel: Number[] = [];
  optModel: Object[] = [];
  maskCnpj = ConstantsMaskModel.MASK_CNPJ;
  maskCpf = ConstantsMaskModel.MASK_CPF;
  maskDate = ConstantsMaskModel.MASK_DATE;
  disabledButton: boolean = false;

  cnpjControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  areaControl = new FormControl('', [Validators.required]);
  dataIniControl = new FormControl('', [Validators.required, Validator.dateIsValid]);
  dataFimControl = new FormControl('', [Validators.required, Validator.dateIsValid]);
  numeroRemessaControl = new FormControl('', [Validators.required]);
  optControl = new FormControl('', [Validators.required]);
  tipoPessoaDevedorControl = new FormControl('', []);
  docDevedorControl = new FormControl('', [Validator.documentIsValid()]);

  exibirArea: boolean = false;

  constructor(private fb: FormBuilder, 
    private sharedService: SharedService, 
    private service: StatusRemessaProcessadaService,
    private loaderService: LoaderService,
    private utils: UtilsService) {
      this.optModel = this.getOptions();
  }

  ngOnInit() {
    this.statusRemessaProcessadaForm = this.fb.group({
      cnpj: this.cnpjControl,
      area: this.areaControl,
      dataIni: this.dataIniControl,
      dataFim: this.dataFimControl,
      numeroRemessa: this.numeroRemessaControl,
      opt: this.optControl,
      tipoPessoaDevedor: this.tipoPessoaDevedorControl,
      docDevedor: this.docDevedorControl
    });
  }

  getOptions():Array<Object>{
    return [
      {
        name: "Informar Totais",
        value: 0
      },
      {
        name: "Informar Todos Rejeitados",
        value: 1
      },
      {
        name: "Informar Principais Rejeitados",
        value: 2
      },
      {
        name: "Informar Coobrigados Rejeitados",
        value: 3
      },
      {
        name: "Informar Registros Opcionais Rejeitados",
        value: 4
      },
      {
        name: "Informar Rejeitados Por CPF ou CNPJ",
        value: 5
      }
    ];
  }

  getAreas(numDocumento: string) {
    if (this.cnpjControl.invalid){
      this.areaControl.reset();
      return;
    }
    this.sharedService.getAreas(numDocumento)
      .subscribe(
        informanteAreas => {
          this.informanteAreasModel = informanteAreas;
          if (informanteAreas.length == 1) {
            this.areaControl.setValue(informanteAreas[0].codArea);
         } else if (informanteAreas.length > 1) {
            this.exibirArea = true;
            this.deleteAreaDefault();
          }        
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", "error");
        }
      );
  }

  getRemessas(model: StatusRemessaProcessadaModel) {
    if (this.cnpjControl.invalid
      || this.areaControl.invalid
      || this.dataIniControl.invalid
      || this.dataFimControl.invalid)
      return;
    this.loaderService.display(true);
    this.service.getRemessas(model)
      .subscribe(
        remessas => {
          this.loaderService.display(false);
          if(remessas.length > 0){
           this.numerosRemessaModel = remessas;
          } else {
            Swal(MESSAGES.NENHUMA_REMESSA_ENCONTRADA, "", MESSAGES.TYPE_ERROR);  
            this.numerosRemessaModel = [];
          }
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
          this.numerosRemessaModel = [];
        }
      );
  }

  getRelatorioRemessas(model: StatusRemessaProcessadaModel) {
    if (this.statusRemessaProcessadaForm.invalid)
      return;
    
    this.loaderService.display(true);
    this.service.getRelatorioRemessas(model)
      .subscribe(
        pdf => {
          this.loaderService.display(false);
          let file = new Blob([pdf], { type: 'application/pdf' });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        },
        errors => {
          this.loaderService.display(false);
          Swal(MESSAGES.RELATORIO_NAO_GERADO, "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  addDocRequired() {
    this.docDevedorControl.setValue("");
    if (this.optControl.value == 5) {
      this.statusRemessaProcessadaForm.controls['tipoPessoaDevedor'].setValidators([Validators.required]);
      this.statusRemessaProcessadaForm.controls['docDevedor'].setValidators([Validators.required, Validator.documentIsValid()]);
    } else {
      this.statusRemessaProcessadaForm.controls['tipoPessoaDevedor'].setValidators(null);
      this.statusRemessaProcessadaForm.controls['docDevedor'].setValidators(null);
    }
    this.statusRemessaProcessadaForm.valueChanges.subscribe(
      result => {
        this.statusRemessaProcessadaForm.patchValue(result, {emitEvent: false});
      }
    )
    this.statusRemessaProcessadaForm.updateValueAndValidity()
  }

  validaDataInicial() {
    var dataInicial = this.statusRemessaProcessadaForm.get('dataIni');
    var dataFinal   = this.statusRemessaProcessadaForm.get('dataFim');

    var checkAfterFinal = moment(dataInicial.value, "DD/MM/YYYY").isAfter(moment(dataFinal.value, "DD/MM/YYYY"));

    if (checkAfterFinal) {
      Swal(MESSAGES.DATA_INICIAL_MAIOR_FINAL, "", MESSAGES.TYPE_ERROR);
      this.statusRemessaProcessadaForm.controls['dataIni'].setErrors({'incorrect': true});
      return false;
    }
  }  

  validaDataFinal() {
    var dataInicial = this.statusRemessaProcessadaForm.get('dataIni');
    var dataFinal   = this.statusRemessaProcessadaForm.get('dataFim');

    if(dataInicial != null || dataFinal != null) {
      var checkBeforeInicial = moment(dataFinal.value, "DD/MM/YYYY").isBefore(moment(dataInicial.value, "DD/MM/YYYY"));
      var checkAfterToday    = moment(dataFinal.value, "DD/MM/YYYY").isAfter(new Date());

      if (checkBeforeInicial || checkAfterToday) {
        if (checkBeforeInicial)
          Swal(MESSAGES.DATA_FINAL_MENOR_INICIAL, "", MESSAGES.TYPE_ERROR);
        else
          Swal(MESSAGES.DATA_FINAL_NAO_PODE_SER_MAIOR_HOJE, "", MESSAGES.TYPE_ERROR);

        this.statusRemessaProcessadaForm.controls['dataFim'].setErrors({'incorrect': true});
        return false;
      }
    }
  }  

  deleteAreaDefault(){
    this.informanteAreasModel.forEach((a : any) => {
    if(a.codArea =='0000')
    this.informanteAreasModel.splice(this.informanteAreasModel.indexOf(a),1);
    }
  );
 }
  zeroAEsquerdaCNPJ(){
    if(!this.cnpjControl.value)
      return;

    this.cnpjControl.setValue(this.utils.padLeftWithZeros(this.cnpjControl.value, 14));
  }
}
