import { TelefoneModel } from "../../../shared/model/telefone-model";
import { EmailModel } from "../../../shared/model/email.model";
import { EnderecoModel } from "../../../shared/model/endereco.model";
import { ContratoModel } from "../../../shared/model/contrato.model";

export class ConsultaApontamento {
    constructor(
        public tipPessoa: string,
        public cpfCnpj: string,
        public cnpjCredor: string,
        public dataIni: Date,
        public dataFim: Date,
        public valorIni: string,
        public valorFim: string,
        public situacao: string,
        public numDocumentoInformanteOrigem: string,
        public tipoApontamento: string
    ){
    }
}

export class Apontamento {
    constructor(
        public ativas : Negativacao[],
        public historico : Negativacao[],
        public suspensas : Negativacao[],
        public msgError: string
    ){
    }
}


export class ApontamentoOnline {
    constructor(
		public nomeRazaoSocial: string,
        public natOperacao: string,
        public modulo: string,
		public datOcorrencia: string,
        public datOcorrenciaModificada: Date,
        public datNascimento: Date,
		public valor: string,
        public valorModificado: string,
        public tipoComunicado: string,
        public emissaoBoleto: string,
        public telefones: TelefoneModel[],
        public emails: EmailModel[],
        public endereco: EnderecoModel,
        public contrato: ContratoModel,
        public dadoComplementar: string
    ){
    }
}

export class ApontamentoHeader {
    constructor(
		public cpfCnpj: string,
        public contrato: string,
        public tipoParticipante: string,
        public cpfDevedor: string,
        public codArea?: string,
        public natOperacao? :string,
        public produto? :string,
        public dadoComplementar? :string
    ){
    }
}

export class NegativacaoExcel {
    constructor(
		public tipPessoa: string,
		public cpfCnpj: string,
		public numDocumento: string,
		public datOcorrenciaDe: Date,
		public datOcorrenciaAte: Date,
		public sitNegativado: string,
		public valorDe: string,
		public valorAte: string,
		public negativacaoDTO : Negativacao[]
    ){
    }
}

export class Negativacao {
    constructor(
        public idNegativacao: string,
		public datOcorrencia: Date,
		public datDisponibilidade: any,
		public sequenciaOcorr: number,
		public valor: string,
		public numDocumento: string,
		public numContrato: string,
		public codArea: string,
		public tipoParticipante: string,
		public tipPessoa: string,
		public cpfCnpj: string,
		public nomeRazaoSocial: string,
		public datBaixa: Date,
		public datBaixaAnt: Date,
		public motBaixa: string,
		public motBaixaAnt: string,
		public sitNegativado: string,
		public sitNegativadoAnt: string,
        public natOperacao: string,
        public produto: string,
        public datProcessamento: Date,
        public flagRetornoComunicado: string,
        public sumula: string,
        public statusComunicado: string
    ){
    }
}