import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import 'rxjs/add/observable/of';
import { MyErrorStateMatcher } from '../../../../shared/error-matcher-input/error-matcher-input.model'
import { DominioModel } from '../../../../shared/model/dominio.model'
import { SharedService } from '../../../../shared/shared.service'
import { UsuarioService } from '../usuario.service'
import Swal from 'sweetalert2'
import { InformanteAreaModel } from '../../../../shared/model/informante-area.model';
import { InformanteModel } from '../../../../shared/model/informante.model';
import { PerfilModel } from '../../shared/perfil.model';
import { UsuarioModel } from '../../../../shared/model/usuario.model';
import { ConstantsMaskModel } from '../../../../shared/constants/constants-mask.model';
import { Validator } from '../../../../shared/validators/validator';
import { ConstantsUsuarioModel } from '../../../../shared/constants/constants-usuario.model';
import { LoaderService } from '../../../../shared/loader.service';
import { MESSAGES } from '../../../../shared/constants/constants-swal.model';
import { UtilsService } from '../../../../shared/utils.service';

@Component({
  selector: 'app-incluir-usuario',
  templateUrl: './incluir-usuario.component.html',
})
export class IncluirUsuarioComponent implements OnInit {

  constructor(private sharedServices: SharedService, private formBuilder: FormBuilder,
    private usuarioService: UsuarioService, private loaderService: LoaderService, private utils: UtilsService) { }

  matcher = new MyErrorStateMatcher();
  usuarioConsultaForm: FormGroup;
  isSelecionado : boolean = false;
  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();

  // Control
  numDocumentoControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  codAreaControl = new FormControl('', [Validators.required]);
  cpfControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  emailControl = new FormControl('', [Validators.required, Validators.email]);
  emailConfirmarControl = new FormControl('', [Validators.required, Validators.email, Validator.equalsTo('email')]);
  nomeControl = new FormControl('', [Validators.required]);
  apelidoControl = new FormControl('', [Validators.required]);
  numTelefoneControl = new FormControl('', [Validators.required, Validator.isNumber(), Validators.maxLength(9), Validators.minLength(8)]);
  codDddControl = new FormControl('', [Validators.required, Validators.max(99)]);
  tipoUsuarioControl = new FormControl('', [Validators.required]);
  codPerfilControl = new FormControl('', [Validators.required]);
  descAreaControl = new FormControl({ value: '', disabled: true }, []);
  descInformanteControl = new FormControl({ value: '', disabled: true }, []);
  tipoTelefoneControl = new FormControl(ConstantsUsuarioModel.TIPO_TELEFONE_COMERCIAL, [Validators.required]);
  socialProviderControl = new FormControl(ConstantsUsuarioModel.PROVIDER_LOCAL, [Validators.required]);

  //Model
  usuario: UsuarioModel;
  informanteAreasModel: InformanteAreaModel[] = [];
  informanteModel: InformanteModel;
  perfisModel: PerfilModel[] = [];
  perfilSelecionado: PerfilModel;
  listaTipoUsuario: DominioModel[] = [];
  telefoneComercialCompleto: String;
  requestLoad: boolean = false;

  // Mask
  maskCnpj = ConstantsMaskModel.MASK_CNPJ;
  maskCpf = ConstantsMaskModel.MASK_CPF;


  ngOnInit() {
    this.usuarioConsultaForm = this.formBuilder.group({
      numDocumento: this.numDocumentoControl,
      codArea: this.codAreaControl,
      cpf: this.cpfControl,
      email: this.emailControl,
      emailConfirmar: this.emailConfirmarControl,
      nome: this.nomeControl,
      apelido: this.apelidoControl,
      numTelefone: this.numTelefoneControl,
      codDdd: this.codDddControl,
      tipoUsuario: this.tipoUsuarioControl,
      codPerfil: this.codPerfilControl,
      descArea: this.descAreaControl,
      descInformante: this.descInformanteControl,
      tipoTelefone: this.tipoTelefoneControl,
      socialProvider: this.socialProviderControl
    });


    this.getTipoUsuario();
    this.usuario = new UsuarioModel('', '', null, null, null, null,'', '', null, '', null, null, '', '');
    this.informanteModel = new InformanteModel('', '');
    this.telefoneComercialCompleto = new String('');
    this.perfisModel = [];
    this.informanteAreasModel = [];
    this.perfilSelecionado = new PerfilModel(null, null, null, null, null);
  }

  salvarUsuario() {
    if (this.usuarioConsultaForm.invalid) {
      this.usuarioConsultaForm.markAsTouched();
      return;
    }
    this.requestLoad = true;
    this.loaderService.display(true);
    this.usuarioService.salvarUsuario(this.usuarioConsultaForm.value)
      .subscribe(
        usuario => {
          this.loaderService.display(false);
          this.requestLoad = false;
          Swal('Usuário cadastrado com sucesso!<br>Foi enviado um e-mail para ' + usuario.email + ' com o link para o cadastro da senha e ativação da conta', "", MESSAGES.TYPE_SUCCESS);
          this.limparForm();
        },
        error => {
          this.loaderService.display(false);
          this.requestLoad = false;
          Swal(this.sharedServices.tratarErro(error), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  markConfirmarAsTouch(){
    if(this.emailControl.invalid)
      return;
    this.emailConfirmarControl.markAsTouched();
    this.emailConfirmarControl.updateValueAndValidity();
  }

  getPessoa(cpf: string) {
    this.sharedServices.getPessoa(cpf, 'F')
      .subscribe(
        pessoa => {
          this.nomeControl.setValue(pessoa.nomeRazaoSocial);
          this.apelidoControl.setValue(pessoa.nomeRazaoSocial.split(' ')[0] ? pessoa.nomeRazaoSocial.split(' ')[0] : pessoa.nomeRazaoSocial);
        }
      );
  }

  getTipoUsuario() {
    this.sharedServices.getDominioByCodigo('USUARIO_TIPO')
      .subscribe(
        dominio => {
          this.listaTipoUsuario = dominio.filter(item => item.codigo !== 'CO');
        },
        error => {
          Swal(this.sharedServices.tratarErro(error), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getAreas(numDocumento: string) {
    this.sharedServices.getAreas(numDocumento)
      .subscribe(
        informanteArea => {
          this.informanteAreasModel = informanteArea;
        },
        error => {
          Swal(this.sharedServices.tratarErro(error), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getInformanteArea(cnpj: string, area: string) {
    if (this.codAreaControl.invalid)
      return;
    this.sharedServices.getInformanteArea(cnpj, area)
      .subscribe(
        informanteArea => {
          this.descAreaControl.setValue(informanteArea.descArea);
        },
        errors => {
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getInformante(numDocumento: number) {
    if (this.numDocumentoControl.invalid)
      return;
    this.sharedServices.getInformante(numDocumento)
      .subscribe(
        informante => {
          this.descInformanteControl.setValue(informante.nomeRazaoSocial);
          this.getAreas(numDocumento.toString());
        },
      error => {
        Swal(this.sharedServices.tratarErro(error), "", MESSAGES.TYPE_ERROR);
      }
    );
  }


  perfilDetalhe(codPerfil: number) {
    if(codPerfil) {
      this.isSelecionado = true;
    }
    this.perfilSelecionado = this.perfisModel.find(item => item.codPerfil === codPerfil);
    this.perfilSelecionado.funcoes = this.perfilSelecionado.funcoes.filter(item => item.selecionado);
  }

  limparForm() {
    this.isSelecionado = false;
    this.informanteAreasModel = [];
    this.numDocumentoControl.setValue(' ');
    this.cpfControl.setValue(' ');
    this.codDddControl.setValue(' ');
    this.tipoUsuarioControl.setValue(' ');
    this.numTelefoneControl.setValue(' ');
    this.descInformanteControl.setValue('');
    this.descAreaControl.setValue('');
    this.codPerfilControl.reset();
    this.perfilSelecionado = new PerfilModel();
    this.usuarioConsultaForm.reset();
  }

  zeroAEsquerda(){
    if(!this.cpfControl.value)
      return;
    if(this.cpfControl.value.replace(/_/g, '').length > 11){
      this.cpfControl.setValue(this.utils.padLeftWithZeros(this.cpfControl.value, 14));
    } else {
      this.cpfControl.setValue(this.utils.padLeftWithZeros(this.cpfControl.value, 11));
    }
  }

}
