import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Inject, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { MyErrorStateMatcher } from "../../../shared/error-matcher-input/error-matcher-input.model";
import Swal from 'sweetalert2'
import { NegativacaoOcorrenciasModel } from "../../../shared/model/negativacao-ocorrencias.model";
import { ConstantsMaskModel } from "../../../shared/constants/constants-mask.model";
import { MESSAGES } from "../../../shared/constants/constants-swal.model";

@Component({
  selector: 'atualizar-ocorrencia-dialog',
  templateUrl: './atualizar-ocorrencia-dialog.component.html'
})
export class AtualizarOcorrenciaDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AtualizarOcorrenciaDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) { }

  atualizarOcorrenciaForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  maskDate = ConstantsMaskModel.MASK_DATE;

  datOcorrenciaControl = new FormControl('', [Validators.required]);
  valorControl = new FormControl('', [Validators.required]);

  ngOnInit() {
    this.atualizarOcorrenciaForm = this.formBuilder.group({
      datOcorrencia: this.datOcorrenciaControl,
      valor: this.valorControl,
    });
    this.datOcorrenciaControl.setValue(this.data.datOcorrencia);
    this.valorControl.setValue(this.data.valor);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  atualizar(model: NegativacaoOcorrenciasModel): any {
    let tempData: string[] = model.datOcorrencia ? model.datOcorrencia.split("/") : [];
    if (tempData.length == 0) {
      Swal(MESSAGES.NOVA_DATA_DIVIDA_INVALIDA, "", MESSAGES.TYPE_ERROR);
      return;
    }

    let novaDataOcorrencia = new Date(parseInt(tempData[2]), parseInt(tempData[1]) - 1, parseInt(tempData[0]));
    let dataAtual = new Date();
    if (novaDataOcorrencia > dataAtual) {
      Swal(MESSAGES.NOVA_DATA_DIVIDA_NAO_PODE_SER_MAIOR, "", MESSAGES.TYPE_ERROR);
      return;
    }

    this.dialogRef.close(model);
  }

}