export class ContratoModel {
    constructor(
        public numDocumentoInf?: string,
        public numContrato?: string,
        public tipoPessoaInf?: string,
        public codArea?: string,
        public tipoParticipante?: string,
        public tipPessoa?: string,
        public cpfCnpj?: string,
        public natOperacao?: string,
        public dadoComplementar?: string,
        public numDocumentoInformanteOrigem?: string
    ) { }
}
