import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comunicados',
  templateUrl: './comunicados.component.html',
})
export class ComunicadosComponent implements OnInit {

  constructor() {
    // construtor
  }

  ngOnInit() {
    // inicializa componente
  }

}
