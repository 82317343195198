import { Component, OnInit, Inject } from '@angular/core';
import { SolicitacaoService } from '../shared/solicitacao.service';
import { Solicitacao, SolicitacaoTransferencia } from '../shared/solicitacao.model';
import Swal from 'sweetalert2';
import { MyErrorStateMatcher } from '../../../../shared/error-matcher-input/error-matcher-input.model';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ConstantsMaskModel } from '../../../../shared/constants/constants-mask.model';
import { SharedService } from '../../../../shared/shared.service';
import { Pessoa } from '../../../../shared/model/pessoa.model';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoaderService } from '../../../../shared/loader.service';
import { Overlay } from '@angular/cdk/overlay';
import { Validator } from '../../../../shared/validators/validator';
import { MESSAGES } from '../../../../shared/constants/constants-swal.model';
import { UtilsService } from '../../../../shared/utils.service';
import { environment } from '../../../../../environments/environment';
import { AUTHORITIES } from '../../../../auth/groups.constants';
import { UsuarioModel } from '../../../../shared/model/usuario.model';
import { UsuarioService } from '../../usuario/usuario.service';

@Component({
  selector: 'app-transferir-solicitacao',
  templateUrl: './transferir-solicitacao.component.html',
  providers: [MatDialog, SolicitacaoService]
})
export class TransferirSolicitacaoComponent implements OnInit {
  
  matcher = new MyErrorStateMatcher();
  solicitacoes: Solicitacao[] = [];
  selected : Solicitacao[] = [];
  aprovadores: UsuarioModel[] = [];

  solicitacoesForm: FormGroup;
  dialogRef: any;

  cpfAprovadorControl = new FormControl('', [Validators.required]);
  nomeControl = new FormControl({ value: '', disabled: true }, []);
  maskCpf = ConstantsMaskModel.MASK_CPF;
  unlockButton: boolean = false;
  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();
  
  constructor( private fb: FormBuilder,
               private sharedService : SharedService, 
               private solicitacaoService : SolicitacaoService,
               private router: Router,
               public dialog: MatDialog,
               private loaderService: LoaderService,
               public overlay: Overlay,
               private utils: UtilsService,
               private usuarioService: UsuarioService ) { }
               
  ngOnInit() {
    this.solicitacoesForm = this.fb.group({
      cpfAprovador: this.cpfAprovadorControl,
      nome: this.nomeControl
    });

    this.buscarAprovadores();
  }

  openDialog() : void {
    if(this.selected.length == 0) {
      Swal(MESSAGES.NECESSARIO_SELECIONAR_SOLICITACAO, "", MESSAGES.TYPE_ERROR);
      return;
    }

    this.dialogRef = this.dialog.open(TransferirSolicitacaoDialog, {
      width: '50%',
      height: '50%',
      data: {solicitacoes: this.selected},
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });
    this.dialogRef.componentInstance.data = {solicitacoes: this.selected};

    this.dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.clear();
      }
    });
  }

  obterAprovador(cpf: string) {
    this.solicitacaoService.obterAprovador(cpf)
      .subscribe(
        result => {
          this.solicitacoesForm.get('nome').setValue(result.nomeRazaoSocial)
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  buscarAprovadores() {
    let tipousuario = environment.settings.tipoUsuario;
    let status = "A";

    this.loaderService.display(true);
    this.usuarioService.getAprovadores(tipousuario, status, AUTHORITIES.APROVAR_REJEITAR_SOLICITACAO_USUARIO)
      .subscribe(
        aprovadores => {
          this.loaderService.display(false);
          this.aprovadores = aprovadores;
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  listar(model: Solicitacao) {
    this.loaderService.display(true);
    this.obterAprovador(model.cpfAprovador);
    this.solicitacaoService.listar(model.cpfAprovador)
      .subscribe(
        result => {
          this.loaderService.display(false);
          if(!result || result.length == 0) 
            Swal(MESSAGES.NAO_HA_SOLICITACOES, "", MESSAGES.TYPE_SUCCESS);
          else 
            this.solicitacoes = result;
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );  
  }

  cancelar() {
    this.router.navigate(['configuracoes/controleAcesso']);
  }

  clear() {
    this.selected = [];
    this.solicitacoes = [];
    this.solicitacoesForm.get('cpfAprovador').setValue(' ');
    this.solicitacoesForm.get('nome').setValue('');
  }

  zeroAEsquerda(){
    if(!this.cpfAprovadorControl.value)
      return;
    if(this.cpfAprovadorControl.value.replace(/_/g, '').length > 11){
      this.cpfAprovadorControl.setValue(this.utils.padLeftWithZeros(this.cpfAprovadorControl.value, 14));
    } else {
      this.cpfAprovadorControl.setValue(this.utils.padLeftWithZeros(this.cpfAprovadorControl.value, 11));
    }
  }

}

@Component({
  selector: 'transferir-solicitacao-dialog',
  templateUrl: 'transferir-solicitacao-dialog.component.html',
  providers: [SolicitacaoService, SharedService]
})
export class TransferirSolicitacaoDialog implements OnInit {

  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();
  maskCpf = ConstantsMaskModel.MASK_CPF;
  cpfAprovador : string;
  aprovadores : UsuarioModel[] = [];
  matcher = new MyErrorStateMatcher();
  transferirForm: FormGroup;
  usuarioAprovador: string;

  cpfAprovadorControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  nomeControl = new FormControl({ value: '', disabled: false }, []);

  constructor(
    public dialogRef: MatDialogRef<TransferirSolicitacaoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private solicitacaoService : SolicitacaoService,
    private sharedService : SharedService,
    private fb : FormBuilder,
    private loaderService : LoaderService,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit() {
    this.transferirForm = this.fb.group({
      cpfAprovador: this.cpfAprovadorControl,
      nome: this.nomeControl
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  transferir(model: Solicitacao): any {
    this.loaderService.display(true);
    let transferencia = new SolicitacaoTransferencia(this.data.solicitacoes, model.cpfAprovador);
    this.solicitacaoService.transferir(transferencia)
      .subscribe(
        result => {    
          this.loaderService.display(false);
          Swal(MESSAGES.OPERACAO_REALIZADA_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  buscaNome() {
    const doc = this.cpfAprovadorControl.value;
    if(doc.length != 11) {
      return;
    } else {
      this.sharedService.getPessoa(doc, 'F')
        .subscribe(
          (result : Pessoa) => {
            this.transferirForm.get('nome').setValue(result.nomeRazaoSocial)
          },
          errors => {
            Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
          }
        );
    }
  }

  buscarAprovadores() {
    let tipousuario = environment.settings.tipoUsuario;
    let status = "A";

    this.usuarioService.getAprovadores(tipousuario, status, AUTHORITIES.APROVAR_REJEITAR_SOLICITACAO_USUARIO)
      .subscribe(
        aprovadores => {
          this.aprovadores = aprovadores;
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  buscarUsuarioAprovador(cpf) {
    this.cpfAprovadorControl.setValue(cpf);
    let tipousuario = environment.settings.tipoUsuario;
    let status = "A";

    this.usuarioService.getUsuario(cpf, tipousuario, status, AUTHORITIES.APROVAR_REJEITAR_SOLICITACAO_USUARIO)
      .subscribe(
        usuario => {
          this.nomeControl.setValue(usuario.nome);
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

}
