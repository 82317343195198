import { Injectable } from '@angular/core';
import { Manterusuario } from './manter-usuario';
import { environment } from 'src/environments/environment';
import { JWTService } from 'src/app/auth/jwt.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManterUsuarioService {

  constructor(private http: HttpClient,
    private jwtService: JWTService) { }

  salvarUsuarios(model: Manterusuario): Observable<Manterusuario> {
    return this.http.post<Manterusuario>(`${environment.settings.api}/usuarios/api`, model,
      {
        headers: this.jwtService.getApiHeaders([])
      });
  }

  consultarUsuario(documento: string): Observable<Manterusuario> {
    return this.http.get<Manterusuario>(`${environment.settings.api}/usuarios/api`,
      {
        headers: this.jwtService.getApiHeaders([]),
        params: {cnpj: documento}
      }
    );
  }

  alterarUsuarios(model: Manterusuario): Observable<Manterusuario> {
    return this.http.put<Manterusuario>(`${environment.settings.api}/usuarios/api`, model,
      {
        headers: this.jwtService.getApiHeaders([])
      });
  }
}
