import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/http/httpclient.service';
import { JWTService } from '../../auth/jwt.service';
import { environment } from '../../../environments/environment';
import { AUTHORITIES } from '../../auth/groups.constants';
import { Observable } from 'rxjs/Observable';
import { DashBoardResult , ConsultaDashBoard} from '../shared/dashboard-indicador-model'
@Injectable()
export class DashboardService {

  constructor( private http: HttpService,
    private jwtService: JWTService, ) { }


    listarIndicadoresByDocumentoAndDatas(model : ConsultaDashBoard): Observable<DashBoardResult> {
      return this.http.post(`${environment.settings.api}/dashboardInformante`, model,this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_DASHBOARD]));
    }

}
