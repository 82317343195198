export class Contestacao {
    constructor(
		public numContestacao: string,
		public cpf: string,
		public cnpjEmpresa: string,
		public nomeDevedor: string,
		public razaoSocial: string,
		public numContrato : string,
		public datDivida: string,
		public valorDivida: string
    ){
    }
}

export class ContestacaoDetalhe {
    constructor(
		public headerContestacao: Contestacao,
        public mensagemRetorno: string,
		public situacao: string,
		public motivoContestacao: string,
		public justifContestacao: string,
		public motivoConclusao: string,
		public justifConclusao: string,
		public localDocumentos: string,
		public documento1: string,
		public documento2: string
    ){
    }
}

export class Documento {
	constructor(
		public local: string,
		public nome: string
	){
	}
}