import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SenhaService } from './shared/senha.service';
import { JWTService } from '../auth/jwt.service';
import {UtilsService} from '../shared/utils.service';
import { EsqueciSenha } from './shared/senha.model';
import Swal from 'sweetalert2';
import { LoaderService } from '../shared/loader.service';
import $ from "jquery"
import { EventEmitterService } from '../shared/event-emiter-service.service';
import { Router } from '../../../node_modules/@angular/router';
import { Validator } from '../shared/validators/validator';
import { MyErrorStateMatcher } from '../shared/error-matcher-input/error-matcher-input.model';
import { ConstantsMaskModel } from '../shared/constants/constants-mask.model';
import { MESSAGES } from '../shared/constants/constants-swal.model';

@Component({
  selector: 'app-esquecisenha',
  templateUrl: './esquecisenha.component.html',
  providers: [JWTService, SenhaService, UtilsService]
})
export class EsqueciSenhaComponent implements OnInit {
  
  senhaForm: FormGroup;
  senha: EsqueciSenha; 
  cpfControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  matcher = new MyErrorStateMatcher();
  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();

  constructor( private formBuilder: FormBuilder, 
               private senhaService : SenhaService, 
               private router: Router,
               private jwtService : JWTService,
               private utils: UtilsService,
               private loaderService: LoaderService,
               private emitterService: EventEmitterService  ) { }

  ngOnInit() {
    this.jwtService.authCheck(true);
    this.senhaForm = this.formBuilder.group({
      cpf: this.cpfControl
    });
    this.emitterService.display(false);
    $(".breadcrumb").css({'display': 'none'})   
    $('.content-wraper').addClass('col-6')
    $('.content-wraper').addClass('float-right')
    $('.content-wraper').addClass('pl-3')
    $('.content-wraper').addClass('ml-0')
    $(".container-fluid").removeClass('wraper')  
    $(".content-wraper").removeClass('ml-5')      
  }

  recuperarSenha(model) {
    if(this.senhaForm.invalid)
      return;
    this.loaderService.display(true);
    this.senhaService.recuperarSenha(model)
      .subscribe( 
        () => {
          this.loaderService.display(false);
          this.navigate();
        }, 
        errors => { 
          this.loaderService.display(false);
          Swal(this.utils.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        } 
      );              
  }

  navigate(){
    this.router.navigate(["instrucoes-senha"]);
  }

  limparForm(){
    this.cpfControl.setValue(' ');
    this.senhaForm.reset();
  }

  cadastraNovaSenha() {
    this.router.navigate(['/nova-senha']);
  }

  zeroAEsquerda(){
    if(!this.cpfControl.value)
      return;
    if(this.cpfControl.value.replace(/_/g, '').length > 11){
      this.cpfControl.setValue(this.utils.padLeftWithZeros(this.cpfControl.value, 14));
    } else {
      this.cpfControl.setValue(this.utils.padLeftWithZeros(this.cpfControl.value, 11));
    }
  }

}
