import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-cadastros',
  templateUrl: './cadastros.component.html',
})
export class CadastrosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
