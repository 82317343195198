export class NegativacaoOpcionalModel {
    constructor(
        public nomePai?: string,
        public nomeMae?: string,
        public estadoCivil?: string,
        public nomeConjuge?: string,
        public rg?: string,
        public ufRg?: string,
        public naturalidade?: string,
        public ufNaturalidade?: string,
        public codUfNat?: number,
        public codUfRg?: number
    ) {
        this.nomePai = nomePai == null ? '' : nomePai;
        this.nomeMae = nomeMae == null ? '' : nomeMae;
        this.estadoCivil = estadoCivil == null ? '' : estadoCivil;
        this.nomeConjuge = nomeConjuge == null ? '' : nomeConjuge;
        this.rg = rg == null ? '' : rg;
        this.ufRg = ufRg == null ? '' : ufRg;
        this.naturalidade = naturalidade == null ? '' : naturalidade;
        this.ufNaturalidade = ufNaturalidade == null ? '' : ufNaturalidade;
        this.codUfNat = codUfNat == null ? null : codUfNat;
        this.codUfRg = codUfRg == null ? null : codUfRg;
    }
}