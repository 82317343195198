import { FuncaoModel } from './funcao.model';

export class PerfilModel {
  constructor(
    public codPerfil?: number,
    public nomePerfil?: String,
    public descPerfil?: String,
    public statPerfil?: String,
    public tipoPerfil?: String,
    public funcoes?: FuncaoModel[]
  ) {

    this.funcoes = funcoes == null ? [] : funcoes;
  }
}
