import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import 'rxjs/add/observable/of';
import { MyErrorStateMatcher } from '../../../shared/error-matcher-input/error-matcher-input.model'
import { SharedService } from '../../../shared/shared.service';
import { ConstantsMaskModel } from '../../../shared/constants/constants-mask.model';
import { LoaderService } from '../../../shared/loader.service';
import { UtilsService } from '../../../shared/utils.service';
import { MESSAGES } from 'src/app/shared/constants/constants-swal.model';
import Swal from 'sweetalert2';
import { JWTService } from 'src/app/auth/jwt.service';
import { Token } from '../../../auth/token.model';
import { InformanteModel } from 'src/app/shared/model/informante.model';
import { InformanteAreaModel } from 'src/app/shared/model/informante-area.model';
import { NaturezaOperacaoModel } from 'src/app/shared/model/natureza-operacao.model';
import { ProdutoGicModel } from 'src/app/shared/model/produto-gic.model';
import { Validator } from 'src/app/shared/validators/validator';
import { ContratoGicModel } from 'src/app/shared/model/contrato-gic.model';
import { AgendamentoCarteiraService } from '../agendamento-carteira.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AgendamentoCarteiraDominioModel } from 'src/app/shared/model/agendamento-carteira-dominio';

@Component({
  selector: 'app-agendamento-carteira',
  styleUrls: ['./agendamento-carteira-cadastro.component.scss'],
  templateUrl: './agendamento-carteira-cadastro.component.html',
})
export class AgendamentoCarteiraCadastroComponent implements OnInit {

  constructor(private sharedServices: SharedService,
    private jwtService: JWTService,
    private loaderService: LoaderService,
    private utils: UtilsService,
    private agendamentoCarteiraService: AgendamentoCarteiraService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  idAgendamento: number;
  operacao: string;
  dataCadastroAgendamento: Date;
  executado: boolean = false;

  matcher = new MyErrorStateMatcher();
  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();
  maskDate = ConstantsMaskModel.MASK_DATE;
  userData = this.jwtService.getCookie('auth_token') as Token;

  isBradesco: boolean = false;
  habilitaAgencia: boolean = false;
  habilitaNatOperacao: boolean = false;

  bradescoAgencias: AgendamentoCarteiraDominioModel[] = [];
  bradescoCnpjs: AgendamentoCarteiraDominioModel[] = [];

  areasInformante: InformanteAreaModel[] = [];
  areasSelecionadas: InformanteAreaModel[] = [];
  naturezasOperacao: NaturezaOperacaoModel[] = [];
  natOperacoesSelecionadas: NaturezaOperacaoModel[] = [];
  produtos: ProdutoGicModel[] = [];
  produtosSelecionados: ProdutoGicModel[] = [];
  contratos: ContratoGicModel[] = [];
  agenciasSelecionadas: string[] = [];
  removerAgencia: boolean;

  informantes: InformanteModel[] = [];
  informante: InformanteModel;
  formAgendamentoCarteira: FormGroup;

  areaControl = new FormControl('', []);
  natOperacaoControl = new FormControl('', []);
  cpfConglomeradoConsultaControl = new FormControl('', []);
  valorDebitoFinalControl = new FormControl('', [Validators.required, Validators.min(0.01)]);
  valorDebitoInicialControl = new FormControl('', [Validators.required, Validators.min(0)]);
  dataInicialControl = new FormControl('', [Validators.required, Validator.dateIsValid]);
  dataFinalControl = new FormControl('', [Validators.required, Validator.dateIsValid]);
  situacaoDebitoControl = new FormControl('', [Validators.required]);
  removerAgenciaControl = new FormControl('', []);

  produtoControl = new FormControl('', []);
  contratoControl = new FormControl('', []);
  prazoVigenciaControl = new FormControl('', []);
  statusContratoControl = new FormControl('', []);

  debitoExpostoControl = new FormControl('', []);
  tipoPessoaControl = new FormControl('', [Validators.required]);
  diasAtrasoInicialControl = new FormControl('', [Validators.min(0), Validator.isNumber()]);
  diasAtrasoFinalControl = new FormControl('', [Validators.max(1825), Validator.isNumber()]);
  cpf6DigitoInicialControl = new FormControl('', [ Validators.min(0), Validators.max(9), Validator.isNumber() ]);
  cpf6DigitoFinalControl = new FormControl('', [ Validators.min(0), Validators.max(9), Validator.isNumber() ]);
  cpf7DigitoInicialControl = new FormControl('', [ Validators.min(0), Validators.max(9), Validator.isNumber() ]);
  cpf7DigitoFinalControl = new FormControl('', [ Validators.min(0), Validators.max(9), Validator.isNumber() ]);

  periodoAgendamentoControl = new FormControl('', [Validators.required]);
  statusAgendamentoControl = new FormControl('', [Validators.required]);

  cpfCnpjConsultaControl = new FormControl('', []);
  razaoSocialConsultaControl = new FormControl({value : '', disabled:true});  

  agenciaControl = new FormControl('', []);

  ngOnInit() {
    this.formAgendamentoCarteira = new FormGroup({
      cpfConglomeradoConsulta: this.cpfConglomeradoConsultaControl,
      razaoSocialConsulta: this.razaoSocialConsultaControl,
      cpfCnpjConsulta: this.cpfCnpjConsultaControl,
      valorDebitoInicial: this.valorDebitoInicialControl,
      valorDebitoFinal: this.valorDebitoFinalControl,
      situacaoDebito: this.situacaoDebitoControl,
      debitoExposto: this.debitoExpostoControl,
      dataInicial: this.dataInicialControl,
      dataFinal: this.dataFinalControl,
      cpf6DigitoInicial: this.cpf6DigitoInicialControl,
      cpf6DigitoFinal: this.cpf6DigitoFinalControl,
      cpf7DigitoInicial: this.cpf7DigitoInicialControl,
      cpf7DigitoFinal: this.cpf7DigitoFinalControl,
      diasAtrasoInicial: this.diasAtrasoInicialControl,
      diasAtrasoFinal: this.diasAtrasoFinalControl,
      tipoPessoa: this.tipoPessoaControl,
      area: this.areaControl,
      natOperacao: this.natOperacaoControl,
      produto: this.produtoControl,
      contrato: this.contratoControl,
      prazoVigencia: this.prazoVigenciaControl,
      statusContrato: this.statusContratoControl,
      periodoAgendamento: this.periodoAgendamentoControl,
      statusAgendamento: this.statusAgendamentoControl,
      agencia: this.agenciaControl,
      removerAgencia: this.removerAgenciaControl,
    });

    this.markAsTouch(this.formAgendamentoCarteira);

    this.dataCadastroAgendamento = new Date();
    this.dataCadastroAgendamento.setHours(0, 0, 0, 0);

    this.route.queryParams.subscribe(params => {
      this.idAgendamento = params['id'];
      this.operacao = params['operacao'];
    });

    this.getNaturezaOperacao();
    this.buscarListaConglomerado();
    this.getDominiosBradesco();

    if (isNaN(this.idAgendamento)) {
      this.situacaoDebitoControl.setValue('A');
      this.debitoExpostoControl.setValue('S');
      this.valorDebitoInicialControl.setValue(0);
      this.valorDebitoFinalControl.setValue(0);
      this.statusAgendamentoControl.setValue('A');
    }
  }

  carregaDadosDoAgendamento(informantes: InformanteModel[]) {
    this.loaderService.display(true);
    this.agendamentoCarteiraService.getAgendamento(this.idAgendamento)
      .subscribe(
        agendamento => {
          this.carregarInformanteByIdInformante(informantes, agendamento.idInformante);
          if (!(agendamento.cpf6DigitoInicial == null) && !(agendamento.cpf6DigitoFinal == null)) {
            this.cpf6DigitoInicialControl.setValue(agendamento.cpf6DigitoInicial.toString());
            this.cpf6DigitoFinalControl.setValue(agendamento.cpf6DigitoFinal.toString());
          }
          if (!(agendamento.cpf7DigitoInicial == null) && !(agendamento.cpf7DigitoFinal == null)) {
            this.cpf7DigitoInicialControl.setValue(agendamento.cpf7DigitoInicial.toString());
            this.cpf7DigitoFinalControl.setValue(agendamento.cpf7DigitoFinal.toString());
          }
          if (!(agendamento.diasAtrasoInicial == null) && !(agendamento.diasAtrasoFinal == null)) {
            this.diasAtrasoInicialControl.setValue(agendamento.diasAtrasoInicial.toString());
            this.diasAtrasoFinalControl.setValue(agendamento.diasAtrasoFinal.toString());
          }
          if (isNaN(agendamento.valorDebitoInicial)) {
            this.valorDebitoInicialControl.setValue("0");
          } else {
            this.valorDebitoInicialControl.setValue(agendamento.valorDebitoInicial.toString());
          }
          if (isNaN(agendamento.valorDebitoFinal)) {
            this.valorDebitoFinalControl.setValue("0");
          } else {
            this.valorDebitoFinalControl.setValue(agendamento.valorDebitoFinal.toString());
          }
          if (agendamento.removerAgencia) {
            this.removerAgenciaControl.setValue(agendamento.removerAgencia);
          }

          if (agendamento.debitoExposto) this.debitoExpostoControl.setValue(agendamento.debitoExposto);
          if (agendamento.situacaoDebito) this.situacaoDebitoControl.setValue(agendamento.situacaoDebito);
          if (agendamento.tipoPessoa) this.tipoPessoaControl.setValue(agendamento.tipoPessoa);

          if (agendamento.periodo) this.periodoAgendamentoControl.setValue(agendamento.periodo);
          if (agendamento.status) this.statusAgendamentoControl.setValue(agendamento.status);
          if (agendamento.dataInicial && agendamento.dataFinal) {
            this.dataCadastroAgendamento = moment(agendamento.dataInicial).toDate();
            this.dataInicialControl.setValue(agendamento.dataInicial);
            this.dataFinalControl.setValue(agendamento.dataFinal);
          }

          if (this.operacao === 'E') {
            this.executado = agendamento.executado;
          }
          this.carregarAreas(agendamento.numDocumento, agendamento.areas);
          this.carregarAgencias(agendamento.agencias);
          this.carregarNaturezaDasOperacoes(agendamento.naturezaDasOperacoes);
          this.statusAgendamentoControl.setValue(this.operacao == 'C' ? 'A' : agendamento.status);
          this.loaderService.display(false);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR)
        }
      );
    this.idAgendamento = (this.operacao == 'C' ? null : this.idAgendamento);
  }

  carregarAgencias(agencias: string[]) {
    if (agencias) {
      agencias.forEach(agencia => {
        this.selecionaAgencia(agencia);
      });
    }
  }

  carregarProdutos(numContrato: string, produtos: number[]) {
    this.getContratos(this.cpfConglomeradoConsultaControl.value, numContrato, produtos);
  }

  carregarNaturezaDasOperacoes(naturezaDasOperacoes: string[]) {
    naturezaDasOperacoes.forEach(codNatOp => {
      this.selecionaNatOperacao(codNatOp);
    });
  }

  selecionaArea(codArea: string) {
    if (this.areasSelecionadas.length > 0) {
      Swal("Atenção! Só é possível selecionar uma área por agendamento!", "", MESSAGES.TYPE_ERROR);
      return;
    }
    
    if (codArea) {
      const areaSelecionada = this.areasInformante.find(item => item.codArea === codArea);
      if (areaSelecionada) {
        if (this.areasSelecionadas.indexOf(areaSelecionada) == -1) {
          this.areasSelecionadas.push(areaSelecionada);
        }
        this.areaControl.reset();
      }
    }
    this.validaArea();
  }

  limparAgencias() {
    this.agenciasSelecionadas = [];
  }

  limparAreas() {
    this.areasSelecionadas = [];
    this.areasInformante = [];
    this.validaArea();
  }

  limparNatOperacao() {
    this.natOperacoesSelecionadas = [];
  }

  limparContratos() {
    this.contratos = [];
    this.statusContratoControl.setValue('');
    this.prazoVigenciaControl.setValue('');
  }

  limparProdutos() {
    this.produtos = [];
    this.produtosSelecionados = [];
    // this.validaProduto();
  }

  limparDadosDoDebito() {
    this.situacaoDebitoControl.setValue('');
    this.tipoPessoaControl.setValue('');
    this.debitoExpostoControl.setValue('');
    this.valorDebitoInicialControl.setValue(0);
    this.valorDebitoFinalControl.setValue(0);
    this.cpf6DigitoInicialControl.setValue('');
    this.cpf6DigitoFinalControl.setValue('');
    this.cpf7DigitoInicialControl.setValue('');
    this.cpf7DigitoFinalControl.setValue('');
    this.diasAtrasoInicialControl.setValue('');
    this.diasAtrasoFinalControl.setValue('');
  }

  limparDadosDoAgendamento() {
    this.periodoAgendamentoControl.setValue('');
    this.dataInicialControl.setValue(null);
    this.dataFinalControl.setValue(null);
    this.dataFinalControl.reset();
    this.statusAgendamentoControl.setValue('A');
  }

  limpar() {
    this.formAgendamentoCarteira.reset();
    this.carregarInformanteLogado(this.informantes);
    this.limparDadosDoDebito();
    this.limparDadosDoAgendamento();
  }

  removeArea(codArea: string) {
    const areaSelecionada = this.areasInformante.find(item => item.codArea === codArea);
    if (areaSelecionada) {
      const index = this.areasSelecionadas.indexOf(areaSelecionada);
      if (index != -1) {
        this.areasSelecionadas.splice(index, 1);
      }
    }
    this.validaArea();
  }

  selecionaNatOperacao(codNatOp: string) {
    if (codNatOp) {
      const natOperacaoSelecionada = this.naturezasOperacao.find(item => item.codNatOp.trim() === codNatOp.trim());
      if (natOperacaoSelecionada) {
        if (this.natOperacoesSelecionadas.indexOf(natOperacaoSelecionada) == -1) {
          this.natOperacoesSelecionadas.push(natOperacaoSelecionada);
        }
        this.natOperacaoControl.reset();
      }
    }
  }

  selecionaAgencia(agencia: string) {
    if (agencia && this.agenciasSelecionadas.indexOf(agencia) == -1) {
      this.agenciasSelecionadas.push(agencia);
    }
  }

  removeNatOperacao(codNatOp: string) {
    const natOperacaoSelecionada = this.naturezasOperacao.find(item => item.codNatOp.trim() === codNatOp.trim());
    if (natOperacaoSelecionada) {
      const index = this.natOperacoesSelecionadas.indexOf(natOperacaoSelecionada);
      if (index != -1) {
        this.natOperacoesSelecionadas.splice(index, 1);
      }
    }
  }

  selecionaProduto(produto: ProdutoGicModel) {
    const codProdutoERP = produto.codProdutoERP;
    if (codProdutoERP) {
      const produtoSelecionado = this.produtos.find(item => item.codProdutoERP === codProdutoERP);
      if (produtoSelecionado) {
        if (this.produtosSelecionados.indexOf(produtoSelecionado) == -1) {
          this.produtosSelecionados.push(produtoSelecionado);
        }
        this.produtoControl.reset();
      }
    }
  }

  removeProduto(codProdutoERP: number) {
    const produtoSelecionado = this.produtos.find(item => item.codProdutoERP === codProdutoERP);
    if (produtoSelecionado) {
      const index = this.produtosSelecionados.indexOf(produtoSelecionado);
      if (index != -1) {
        this.produtosSelecionados.splice(index, 1);
      }
    }
  }

  getDominiosBradesco() {
    this.getBradescoAgencias();
    this.getBradescoCnpjs();
  }

  getParametrosNejInformante(informante: InformanteModel) {
    this.loaderService.display(true);
    this.agendamentoCarteiraService.getParametrosNejInformante(informante.numDocumento)
      .subscribe(
        params => {
          this.habilitaAgencia = params.habilitaAgencia;
          this.habilitaNatOperacao = params.habilitaNatOperacao;
          this.loaderService.display(false);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );    
  }

  getBradescoAgencias() {
    this.loaderService.display(true);
    this.sharedServices.getBradescoAgencias()
      .subscribe(
        agencias => {
          this.bradescoAgencias = agencias;
          this.bradescoAgencias.sort((a, b) => {
            if (a.valor < b.valor) return -1;
            if (a.valor > b.valor) return 1;
            return 0;
          });
          this.loaderService.display(false);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getBradescoCnpjs() {
    this.loaderService.display(true);
    this.sharedServices.getBradescoCNPJs()
      .subscribe(
        cnpjs => {
          this.bradescoCnpjs = cnpjs;
          this.bradescoCnpjs.sort((a, b) => {
            if (a.valor < b.valor) return -1;
            if (a.valor > b.valor) return 1;
            return 0;
          });
          this.loaderService.display(false);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getNaturezaOperacao() {
    this.loaderService.display(true);
    this.sharedServices.getNaturezaOperacaoDesc()
      .subscribe(
        naturezasOperacao => {
          this.naturezasOperacao = naturezasOperacao;
          this.naturezasOperacao.sort((a, b) => {
            if (a.codNatOp < b.codNatOp) return -1;
            if (a.codNatOp > b.codNatOp) return 1;
            return 0;
          });
          this.loaderService.display(false);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getProdutos(numContrato: string, produtosSelecionados: number[]) {
    this.loaderService.display(true);
    this.sharedServices.getProdutosByNumContrato(numContrato)
      .subscribe(
        produtos => {
          this.produtos = produtos;
          this.produtos.sort((a, b) => {
            if (a.descricao < b.descricao) return -1;
            if (a.descricao > b.descricao) return 1;
            return 0;
          });
          if (produtosSelecionados && produtosSelecionados.length != 0) {
            produtosSelecionados.forEach(produtoGicErpId => {
              const produto = produtos.find(item => item.produtoGicErpId === produtoGicErpId);
              if (produto) {
                this.selecionaProduto(produto);
              }
            });
          }
          this.loaderService.display(false);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR)
        }
      )
  }

  buscarListaConglomerado() {
    this.loaderService.display(true);
    this.sharedServices.getConglomerado(this.userData.numDocumento)
      .subscribe(conglomerado => {
        this.informantes = conglomerado;
        this.buscarListaEmpresas();

        if (isNaN(this.idAgendamento)) {
          this.carregarInformanteLogado(this.informantes);
        } else {
          this.carregaDadosDoAgendamento(this.informantes);
        }
        this.loaderService.display(false);
      },
      error => {
        this.loaderService.display(false);
        if (error.status === 400) {
          Swal("A requisição está incorreta!", "", MESSAGES.TYPE_ERROR);
        } else if (error.status === 401) {
          Swal(MESSAGES.NAO_POSSUI_ACESSO_FUNCIONALIDADE, "", MESSAGES.TYPE_ERROR);
        } else {
          Swal(error.error.descricao, "", MESSAGES.TYPE_ERROR);
        }
      });
  }

  buscarListaEmpresas() {
    this.loaderService.display(true);
    this.sharedServices.getEmpresasUsuario()
      .subscribe(empresas => {
        empresas.forEach(item => {
          this.informantes.push(item);
        });
        this.loaderService.display(false);
      },
      error => {
        this.loaderService.display(false);
        if (error.status === 400) {
          Swal("A requisição está incorreta!", "", MESSAGES.TYPE_ERROR);
        } else if (error.status === 401) {
          Swal(MESSAGES.NAO_POSSUI_ACESSO_FUNCIONALIDADE, "", MESSAGES.TYPE_ERROR);
        } else {
          Swal(error.error.descricao, "", MESSAGES.TYPE_ERROR);
        }
      });
  }

  getContratos(informante: InformanteModel, numContrato: string, produtos: number[]) {
    this.contratos = [];
    informante.tbContratoDTO.forEach(item => {
      if (!this.contratos.find(contrato => contrato.numContrato === item.numContrato)) {
        this.contratos.push(item);
      }
    });
    if (isNaN(this.idAgendamento)) {
      if (this.contratos && this.contratos.length === 1) {
        this.carregaDadosDoContrato(this.contratos[0], produtos);
      }
    }
    if (numContrato) {
      const contrato = this.contratos.find(contrato => contrato.numContrato.trim() === numContrato.trim());
      if (contrato) {
        this.carregaDadosDoContrato(contrato, produtos);
      }
    }
  }

  carregaDadosDoContrato(contrato: ContratoGicModel, produtos: number[]) {
    this.limparProdutos();
    this.contratoControl.setValue(contrato);
    this.statusContratoControl.setValue(contrato.sitContrato == '2' ? 'Ativo' : 'Inativo');
    this.prazoVigenciaControl.setValue(contrato.datTermino);
    this.getProdutos(contrato.numContrato, produtos);
  }

  carregarInformanteLogado(informantes: InformanteModel[]) {
    informantes.forEach(informante => {
      if (informante.numDocumento === this.userData.numDocumento) {
        this.cpfConglomeradoConsultaControl.setValue(informante);
        this.carregarDadosDoInformante(informante);
        this.carregarAreas(informante.numDocumento, []);
        this.getContratos(informante, '', []);
      }
    });
  }

  carregarInformante(informante: InformanteModel) {
    this.carregarDadosDoInformante(informante);
    this.carregarAreas(informante.numDocumento, []);
    this.getContratos(informante, '', []);
    this.getParametrosNejInformante(informante);
  }

  carregarInformanteByIdInformante(informantes: InformanteModel[], idInformante: string) {
    informantes.forEach(informante => {
      if (informante.idInformante === idInformante) {
        this.cpfConglomeradoConsultaControl.setValue(informante);
        this.carregarDadosDoInformante(informante);
      }
    });
  }

  carregarDadosDoInformante(informanteSelecionado: InformanteModel) {
    this.razaoSocialConsultaControl.setValue(informanteSelecionado.nomeRazaoSocial);
    this.verificaClienteBradesco();
    if (!this.isBradesco) {
      this.agenciasSelecionadas = [];
      this.removerAgenciaControl.setValue('');
    }
    this.getParametrosNejInformante(informanteSelecionado);

    this.limparAgencias();
    this.limparAreas();
    this.limparContratos();
    this.limparProdutos();
    this.limparNatOperacao();
  }

  markAsTouch(form: FormGroup): void {
    Object.keys(form.controls).forEach(key => {
      form.get(key).markAsTouched();
    });
  }

  carregarAreas(numDocumento: string, areasSelecionadas: string[]) {
    this.areasSelecionadas = [];
    this.loaderService.display(true);
    this.sharedServices.getAreas(numDocumento)
      .subscribe(
        informanteArea => {
          this.areasInformante = informanteArea;
          if (isNaN(this.idAgendamento)) {
            if (informanteArea && informanteArea.length === 1) {
              this.selecionaArea(informanteArea[0].codArea);
            }
          } else {
            if (areasSelecionadas.length != 0) {
              areasSelecionadas.forEach(codArea => {
                this.selecionaArea(codArea);
              });
            }
          }
          if (this.operacao == 'C' && this.areasSelecionadas.length > 0) {
            this.areasSelecionadas.pop();
            this.validaArea();
          }
          this.loaderService.display(false);
        },
        error => {
          this.loaderService.display(false);
          Swal(this.sharedServices.tratarErro(error), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  zeroAEsquerda() {
    if (!this.cpfCnpjConsultaControl.value)
      return;
    if (this.cpfCnpjConsultaControl.value.replace(/_/g, '').length > 11) {
      this.cpfCnpjConsultaControl.setValue(this.utils.padLeftWithZeros(this.cpfCnpjConsultaControl.value, 14));
    } else {
      this.cpfCnpjConsultaControl.setValue(this.utils.padLeftWithZeros(this.cpfCnpjConsultaControl.value, 11));
    }
  }

  validaArea() {
    if (this.areasSelecionadas.length == 0) {
      this.areaControl.setErrors({ 'required': true });
      this.areaControl.markAsTouched();
    } else {
      this.areaControl.setErrors({ 'required': null });
      this.areaControl.updateValueAndValidity();
    }
  }

  validaValorDebito() {
    const valorInicial = Number(this.valorDebitoInicialControl.value);
    const valorFinal = Number(this.valorDebitoFinalControl.value);
    if (valorFinal < valorInicial) {
      this.valorDebitoInicialControl.setErrors({ 'range-invalid': true });
      this.valorDebitoFinalControl.setErrors({ 'range-invalid': true });
    } else {
      if (this.valorDebitoInicialControl.hasError('range-invalid') || this.valorDebitoFinalControl.hasError('range-invalid')) {
        this.valorDebitoInicialControl.setErrors({ 'range-invalid': null });
        this.valorDebitoFinalControl.setErrors({ 'range-invalid': null });
        this.valorDebitoInicialControl.updateValueAndValidity();
        this.valorDebitoFinalControl.updateValueAndValidity();
      }
    }
  }

  validaAgencia() {
    if (!this.removerAgenciaControl.value) {
      this.agenciasSelecionadas = [];
    }
    if (this.removerAgenciaControl.value && this.agenciasSelecionadas.length == 0) {
      this.agenciaControl.setErrors({ 'required': true });
      this.agenciaControl.markAsTouched();
    } else {
      this.agenciaControl.setErrors({ 'required': null });
      this.agenciaControl.updateValueAndValidity();
    }
  }

  validaDigitoCpfObrigatorio6() : boolean {
    let validacao = true;
    if (String(this.cpf6DigitoFinalControl.value) != '' && String(this.cpf6DigitoInicialControl.value) == '') {
      validacao = false;
      this.cpf6DigitoInicialControl.setErrors({ 'required': true });
      this.cpf6DigitoInicialControl.markAsTouched();
    } else {
      this.cpf6DigitoInicialControl.setErrors({ 'required': null });
      this.cpf6DigitoInicialControl.updateValueAndValidity();
    }

    if (String(this.cpf6DigitoInicialControl.value) != '' && String(this.cpf6DigitoFinalControl.value) == '') {
      validacao = false;
      this.cpf6DigitoFinalControl.setErrors({ 'required': true });
      this.cpf6DigitoFinalControl.markAsTouched();
    } else {
      this.cpf6DigitoFinalControl.setErrors({ 'required': null });
      this.cpf6DigitoFinalControl.updateValueAndValidity();
    }

    return validacao;
  }

  validaDigitoCpfObrigatorio7() : boolean {
    let validacao = true;
    if (String(this.cpf7DigitoFinalControl.value) != '' && String(this.cpf7DigitoInicialControl.value) == '') {
      validacao = false;
      this.cpf7DigitoInicialControl.setErrors({ 'required': true });
      this.cpf7DigitoInicialControl.markAsTouched();
    } else {
      this.cpf7DigitoInicialControl.setErrors({ 'required': null });
      this.cpf7DigitoInicialControl.updateValueAndValidity();
    }

    if (String(this.cpf7DigitoInicialControl.value) != '' && String(this.cpf7DigitoFinalControl.value) == '') {
      validacao = false;
      this.cpf7DigitoFinalControl.setErrors({ 'required': true });
      this.cpf7DigitoFinalControl.markAsTouched();
    } else {
      this.cpf7DigitoFinalControl.setErrors({ 'required': null });
      this.cpf7DigitoFinalControl.updateValueAndValidity();
    }
    return validacao;
  }

  validaDigitoCpfFaixa6() : boolean {
    let validacao = true;
    if (String(this.cpf6DigitoFinalControl.value) != '' && String(this.cpf6DigitoInicialControl.value) != '') {
      const valorInicial = Number(this.cpf6DigitoInicialControl.value);
      const valorFinal = Number(this.cpf6DigitoFinalControl.value);
      if (valorFinal < valorInicial) {
        validacao = false;
      }
    }

    if (validacao) {
      this.cpf6DigitoInicialControl.setErrors({ 'range-invalid': null });
      this.cpf6DigitoFinalControl.setErrors({ 'range-invalid': null });
      this.cpf6DigitoInicialControl.updateValueAndValidity();
      this.cpf6DigitoFinalControl.updateValueAndValidity();
    } else {
      this.cpf6DigitoInicialControl.setErrors({ 'range-invalid': true });
      this.cpf6DigitoFinalControl.setErrors({ 'range-invalid': true });
      this.cpf6DigitoInicialControl.markAsTouched();
      this.cpf6DigitoFinalControl.markAsTouched();
    }

    return validacao;
  }

  validaDigitoCpfFaixa7() : boolean {
    let validacao = true;
    if (String(this.cpf7DigitoFinalControl.value) != '' && String(this.cpf7DigitoInicialControl.value) != '') {
      const valorInicial = Number(this.cpf7DigitoInicialControl.value);
      const valorFinal = Number(this.cpf7DigitoFinalControl.value);
      if (valorFinal < valorInicial) {
        validacao = false;
      }
    }

    if (validacao) {
      this.cpf7DigitoInicialControl.setErrors({ 'range-invalid': null });
      this.cpf7DigitoFinalControl.setErrors({ 'range-invalid': null });
      this.cpf7DigitoInicialControl.updateValueAndValidity();
      this.cpf7DigitoFinalControl.updateValueAndValidity();
    } else {
      this.cpf7DigitoInicialControl.setErrors({ 'range-invalid': true });
      this.cpf7DigitoFinalControl.setErrors({ 'range-invalid': true });
      this.cpf7DigitoInicialControl.markAsTouched();
      this.cpf7DigitoFinalControl.markAsTouched();
    }

    return validacao;
  }

  validaDigitoCpf6() {
    if (this.validaDigitoCpfObrigatorio6())
      this.validaDigitoCpfFaixa6();
  }

  validaDigitoCpf7() {
    if (this.validaDigitoCpfObrigatorio7())
      this.validaDigitoCpfFaixa7();
  }

  validaDiasAtrasoObrigatorio() : boolean {
    let validacao = true;

    if (String(this.diasAtrasoFinalControl.value) != '' && String(this.diasAtrasoInicialControl.value) == '') {
      validacao = false;
      this.diasAtrasoInicialControl.setErrors({ 'required': true });
      this.diasAtrasoInicialControl.markAsTouched();
    } else {
      this.diasAtrasoInicialControl.setErrors({ 'required': null });
      this.diasAtrasoInicialControl.updateValueAndValidity();
    }

    if (String(this.diasAtrasoInicialControl.value) != '' && String(this.diasAtrasoFinalControl.value) == '') {
      validacao = false;
      this.diasAtrasoFinalControl.setErrors({ 'required': true });
      this.diasAtrasoFinalControl.markAsTouched();
    } else {
      this.diasAtrasoFinalControl.setErrors({ 'required': null });
      this.diasAtrasoFinalControl.updateValueAndValidity();
    }

    return validacao;
  }

  validaDiasAtrasoFaixa() : boolean {
    let validacao = true;
    if (String(this.diasAtrasoFinalControl.value) != '' && String(this.diasAtrasoInicialControl.value) != '') {
      const valorInicial = Number(this.diasAtrasoInicialControl.value);
      const valorFinal = Number(this.diasAtrasoFinalControl.value);
      if (valorFinal < valorInicial) {
        validacao = false;
      }
    }

    if (validacao) {
      this.diasAtrasoInicialControl.setErrors({ 'range-invalid': null });
      this.diasAtrasoFinalControl.setErrors({ 'range-invalid': null });
      this.diasAtrasoInicialControl.updateValueAndValidity();
      this.diasAtrasoFinalControl.updateValueAndValidity();
    } else {
      this.diasAtrasoInicialControl.setErrors({ 'range-invalid': true });
      this.diasAtrasoFinalControl.setErrors({ 'range-invalid': true });
    }

    return validacao;
  }

  validaDiasAtraso() {
    if (this.validaDiasAtrasoObrigatorio()) {
      this.validaDiasAtrasoFaixa();
    }
  }

  validaDataInicial() {
    let validacao = true;

    const dataCadastro = moment(this.dataCadastroAgendamento, "DD/MM/YYYY");
    const dataInicial = moment(this.dataInicialControl.value, "DD/MM/YYYY");
    if (dataInicial.isValid() && dataCadastro.isValid()) {
      if (dataCadastro !== dataInicial && dataInicial.isBefore(dataCadastro)) {
        validacao = false;
      }
    }

    if (validacao) {
      this.dataInicialControl.setErrors({ 'date-initial-invalid': null });
      this.dataInicialControl.updateValueAndValidity();
    }
    else {
      this.dataInicialControl.setErrors({ 'date-initial-invalid': true });
    }

    return validacao;
  }

  validaPeriodoAgendamento() : boolean {
    let validacao = true;

    const dataInicial = moment(this.dataInicialControl.value, "DD/MM/YYYY");
    const dataFinal = moment(this.dataFinalControl.value, "DD/MM/YYYY");
    if (dataInicial.isValid() && dataFinal.isValid()) {
      if (dataInicial.isAfter(dataFinal)) {
        validacao = false;
      }
    }

    if (validacao) {
      this.dataInicialControl.setErrors({ 'range-invalid': null });
      this.dataInicialControl.updateValueAndValidity();
      this.dataFinalControl.setErrors({ 'range-invalid': null });
      this.dataFinalControl.updateValueAndValidity();
    }
    else {
      this.dataInicialControl.setErrors({ 'range-invalid': true });
      this.dataFinalControl.setErrors({ 'range-invalid': true });
    }

    return validacao;
  }

  validaDatasPeriodoAgendamento() {
    if (!this.validaPeriodoAgendamento()) {
      return;
    } else if (!this.validaDataInicial()) {
      return;
    }
  }

  getBody(model) : any {
    return {
      idAgendamento: this.idAgendamento,
      idInformante: model.cpfConglomeradoConsulta.idInformante,
      numContrato: null,
      areas: this.areasSelecionadas.length == 0 ? null : this.areasSelecionadas.map(item => item.codArea),
      naturezaDasOperacoes: this.natOperacoesSelecionadas.length == 0 ? null : this.natOperacoesSelecionadas.map(item => item.codNatOp),     
      produtos: this.produtosSelecionados.length == 0 ? null : this.produtosSelecionados.map(item => item.produtoGicErpId),
      agencias: this.agenciasSelecionadas.length == 0 ? null : this.agenciasSelecionadas,
      removerAgencia: model.removerAgencia ? model.removerAgencia : null,
      situacaoDebito: model.situacaoDebito,
      tipoPessoa: model.tipoPessoa,
      debitoExposto: model.debitoExposto,

      valorDebitoInicial: model.valorDebitoInicial,
      valorDebitoFinal: model.valorDebitoFinal,
      cpf6DigitoInicial: (model.tipoPessoa != 'J' ? model.cpf6DigitoInicial : null),
      cpf6DigitoFinal: (model.tipoPessoa != 'J' ? model.cpf6DigitoFinal : null),
      cpf7DigitoInicial: (model.tipoPessoa != 'J' ? model.cpf7DigitoInicial : null),
      cpf7DigitoFinal: (model.tipoPessoa != 'J' ? model.cpf7DigitoFinal : null),
      diasAtrasoInicial: model.diasAtrasoInicial,
      diasAtrasoFinal: model.diasAtrasoFinal,
      
      periodo: model.periodoAgendamento,
      dataInicial: model.dataInicial,
      dataFinal: model.dataFinal,
      status: model.statusAgendamento,
    };
  }

  salvar(model) {
    const body = this.getBody(model);

    this.loaderService.display(true);
    this.agendamentoCarteiraService.salvar(body)
      .subscribe(
        agendamento => {
          this.idAgendamento = agendamento.idAgendamento;
       //   this.executarPrevia(agendamento.idAgendamento);
          this.loaderService.display(false);
          Swal("O agendamento foi efetuado com sucesso. Consulte a prévia do volume de débitos que serão acionado. Caso não queira seguir com os disparos, clique no botão 'INATIVAR'.", "", "success");
          this.router.navigateByUrl(`/cadastros/agendamentoCarteira/consulta`);
        },
        error => {
          this.loaderService.display(false);
          Swal(error.error.message, "", "error")
        }
      );
  }

  cancelar() {
    this.router.navigateByUrl(`/cadastros/agendamentoCarteira/consulta`);
  }

  //executarPrevia(idAgendamento: number) {
    //this.agendamentoCarteiraService.executarPrevia(idAgendamento)
     //.subscribe(
      //() => {
        //this.loaderService.display(false);
       //},
       //error => {
        //this.loaderService.display(false);
        //Swal(error.error.message, "", "error")
      // }
    //  );
  //}


  formataAgenciaComZerosEsquerda(agencia: string): string {
    return this.utils.padLeftWithZeros(agencia, 4)   
  }

  adicionaAgencia(agencia: string) {
    agencia = this.formataAgenciaComZerosEsquerda(agencia);
    const index = this.agenciasSelecionadas.indexOf(agencia);
    if (index == -1) {
      this.agenciasSelecionadas.push(agencia);
    }
    this.agenciaControl.setValue('');
  }

  removeAgencia(agencia: string) {
    const index = this.agenciasSelecionadas.indexOf(agencia);
    if (index != -1) {
      this.agenciasSelecionadas.splice(index, 1);
    }
  }

  selecionarTodasNaturezas() {
    this.natOperacoesSelecionadas = [...this.naturezasOperacao];
  }
  
  removerTodasNaturezas() {
    this.natOperacoesSelecionadas = [];
  }

  verificaClienteBradesco() {
    this.isBradesco = false;
    if (!this.cpfConglomeradoConsultaControl.value) {
      return;
    }

    const cnpj = Number(this.cpfConglomeradoConsultaControl.value.numDocumento);
    this.bradescoCnpjs.forEach(item => {
      if (Number(item.valor) === cnpj) {
        this.isBradesco = true;
      }
    });
  }

  selecionarTodasAgenciasNext() {
    this.removerTodasAgencias();
    this.removerAgenciaControl.setValue('N');
    this.bradescoAgencias.forEach(item => {
      this.adicionaAgencia(item.valor);
    });
  }

  removerTodasAgencias() {
    this.agenciasSelecionadas = [];
  }

}