import { Component, OnInit } from '@angular/core';
import { SenhaService } from './shared/senha.service';
import { JWTService } from '../auth/jwt.service';
import {UtilsService} from '../shared/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoaderService } from '../shared/loader.service';
import { EventEmitterService } from '../shared/event-emiter-service.service';
import { MESSAGES } from '../shared/constants/constants-swal.model';

@Component({
  selector: 'app-validaToken',
  template: '',
  providers: [JWTService, SenhaService, UtilsService]
})
export class ValidaSenhaComponent implements OnInit {

  constructor( private route: ActivatedRoute,
               private senhaService : SenhaService, 
               private jwtService : JWTService,
               private router: Router,
               private utils: UtilsService,
               private loaderService: LoaderService,
               private emitterService: EventEmitterService ) { }

  private params : any = {};

  ngOnInit() {
    this.params = this.route.queryParams;
    this.validaToken();
    this.emitterService.display(false);
  }

  validaToken() {
    this.loaderService.display(true);
    this.senhaService.validaToken(this.params._value.token)
      .subscribe(
        resp => {
          this.jwtService.putObjectLocalStorage('validacao', resp);
          this.loaderService.display(false);
          this.router.navigate(['/nova-senha'], { queryParams: { token: this.params._value.token } });
        }, 
        errors => { 
          Swal(this.utils.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
          this.loaderService.display(false);
          this.router.navigate(['/recuperar-senha']);
        }
      );              
  }

}
