import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import 'rxjs/add/observable/of';
import { MyErrorStateMatcher } from '../../../../shared/error-matcher-input/error-matcher-input.model'
import { DominioModel } from '../../../../shared/model/dominio.model';
import { SharedService } from '../../../../shared/shared.service';
import { UsuarioService } from '../../usuario/usuario.service';
import Swal from 'sweetalert2';
import { InformanteAreaModel } from '../../../../shared/model/informante-area.model';
import { InformanteModel } from '../../../../shared/model/informante.model';
import { PerfilService } from '../../perfil/shared/perfil.service';
import { PerfilModel } from '../../shared/perfil.model';
import { environment } from '../../../../../environments/environment';
import { UsuarioModel } from '../../../../shared/model/usuario.model';
import { ConstantsMaskModel } from '../../../../shared/constants/constants-mask.model';
import { FuncaoModel } from '../../shared/funcao.model';
import { AlterarUsuarioService } from './alterar-usuario.service';
import { SolicitarCadastroUsuarioModel } from '../solicitacao-usuario.model';
import { Validator } from '../../../../shared/validators/validator';
import { LoaderService } from '../../../../shared/loader.service';
import { MESSAGES } from '../../../../shared/constants/constants-swal.model';
import { AUTHORITIES } from '../../../../auth/groups.constants';
import { UtilsService } from '../../../../shared/utils.service';
import { JWTService } from 'src/app/auth/jwt.service';
import { Token } from 'src/app/auth/token.model';

@Component({
  selector: 'app-alterar-usuario',
  templateUrl: './alterar-usuario.component.html',
})
export class AlterarUsuarioComponent implements OnInit {

  matcher = new MyErrorStateMatcher();
  usuarioAlterarForm: FormGroup;
  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();

  cpfConsultaControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  apelidoControl = new FormControl('', [Validators.required]);
  areaControl = new FormControl('', []);
  cpfAprovadorControl = new FormControl('', [Validators.required]);
  nomeControl = new FormControl('', []);
  telefoneComercialCompletoControl = new FormControl('', []);
  emailControl = new FormControl('', [Validators.required, Validators.email]);
  dddControl = new FormControl('', [Validators.required, Validators.minLength(2), Validator.isNumber()]);
  telefoneControl = new FormControl('', [Validators.required, Validators.minLength(8), Validator.isNumber()]);
  confirmacaoEmailControl = new FormControl('', [Validators.required, Validator.equalsTo('email')]);
  perfilControl = new FormControl({}, [Validators.required]);
  statusControl = new FormControl('', [Validators.required]);

  usuario: UsuarioModel = new UsuarioModel();
  usuarioAprovador: UsuarioModel = new UsuarioModel();
  aprovadores: UsuarioModel[] = [];
  informanteAreaModel: InformanteAreaModel = new InformanteAreaModel();
  informanteModel: InformanteModel = new InformanteModel();
  perfilModel: PerfilModel = new PerfilModel();
  listaTipoUsuario: DominioModel[] = [];
  telefoneComercialCompleto: string;
  perfilSelecionado: PerfilModel;
  perfisModel: PerfilModel[] = [];
  funcoes: FuncaoModel[] = [];
  maskCpf = ConstantsMaskModel.MASK_CPF;
  flagAlterar: boolean = false;
  requestLoad: boolean = false;

  userData = this.jwtService.getCookie('auth_token') as Token;

  constructor(private sharedServices: SharedService, private formBuilder: FormBuilder,
    private usuarioService: UsuarioService, private perfilService: PerfilService, private service: AlterarUsuarioService,
    private loaderService: LoaderService, private utils: UtilsService, private jwtService: JWTService,) { }

  ngOnInit() {
    this.usuarioAlterarForm = this.formBuilder.group({
      cpfConsulta: this.cpfConsultaControl,
      cpfAprovador: this.cpfAprovadorControl,
      nome: this.nomeControl,
      email: this.emailControl,
      confirmacaoEmail: this.confirmacaoEmailControl,
      ddd: this.dddControl,
      telefone: this.telefoneControl,
      area: this.areaControl,
      perfil: this.perfilControl,
      apelido: this.apelidoControl,
      status: this.statusControl,
     
    });

    this.bloquearForm();

    this.getPerfis();
    this.buscarAprovadores();
  }

  criarSolicitacao() {
    if (!this.flagAlterar) {
      this.flagAlterar = true;
      this.liberarForm();
      return;
    }
    if (this.usuarioAlterarForm.invalid) {
      Swal(MESSAGES.EXISTEM_CAMPOS_FALTANDO, "", MESSAGES.TYPE_ERROR);
      this.usuarioAlterarForm.markAsTouched();
      this.cpfAprovadorControl.markAsTouched();
      return;
    }
    this.requestLoad = true;
    this.usuarioAlterarForm.markAsTouched();
    let model: SolicitarCadastroUsuarioModel = new SolicitarCadastroUsuarioModel();
    model.codArea = this.informanteAreaModel.codArea;
    model.tipoUsuario = environment.settings.tipoUsuario;
    model.operacao = 'A';
    model.apelido = this.usuarioAlterarForm.get('apelido').value;
    model.numTelefone = this.usuarioAlterarForm.get('telefone').value;
    model.codDdd = this.usuarioAlterarForm.get('ddd').value;
    model.codPerfil = this.usuarioAlterarForm.get('perfil').value.codPerfil;
    model.cpf = this.usuarioAlterarForm.get('cpfConsulta').value;
    model.cpfAprovador = this.usuarioAlterarForm.get('cpfAprovador').value;
    model.email = this.usuarioAlterarForm.get('email').value;
    model.emailConfirmar = this.usuarioAlterarForm.get('confirmacaoEmail').value;
    model.idLogin = this.usuario.idLogin;
    model.nome = this.usuario.nome;
    model.status = this.usuarioAlterarForm.get('status').value;
    model.numDocumento = this.usuario.numDocumento;
    model.cpfAprovador = this.usuarioAlterarForm.get('cpfAprovador').value;
    
    this.loaderService.display(true);
    this.service.alterarUsuario(model)
      .subscribe(
        solicitacao => {
          this.loaderService.display(false);
          Swal(MESSAGES.SOLICITACAO_CRIADA_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
          this.limparForm();
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
          this.requestLoad = false;
        }
      );
  }

  liberarForm() {
    this.cpfAprovadorControl.enable();
    this.apelidoControl.enable();
    this.emailControl.enable();
    this.confirmacaoEmailControl.enable();
    this.telefoneControl.enable();
    this.dddControl.enable();
    this.perfilControl.enable();
    this.statusControl.enable();
  }

  bloquearForm() {
    this.cpfAprovadorControl.disable();
    this.apelidoControl.disable();
    this.emailControl.disable();
    this.confirmacaoEmailControl.disable();
    this.telefoneControl.disable();
    this.dddControl.disable();
    this.perfilControl.disable();
    this.statusControl.disable();
  }

  buscarUsuarioAprovador(cpf) {
    this.cpfAprovadorControl.setValue(cpf);
    let tipousuario = environment.settings.tipoUsuario;
    let status = "A";

    this.usuarioService.getUsuario(cpf, tipousuario, status, AUTHORITIES.APROVAR_REJEITAR_SOLICITACAO_USUARIO)
      .subscribe(
        usuario => {
          this.usuarioAprovador = usuario;
        },
        errors => {
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  buscarAprovadores() {
    let tipousuario = environment.settings.tipoUsuario;
    let status = "A";

    this.loaderService.display(true);
    this.usuarioService.getAprovadores(tipousuario, status, AUTHORITIES.APROVAR_REJEITAR_SOLICITACAO_USUARIO)
      .subscribe(
        aprovadores => {
          this.aprovadores = aprovadores;
          this.loaderService.display(false);
        },
        errors => {
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
          this.loaderService.display(false);
        }
      );
  }


  buscarNomeUsuario(cpf: number) {
    this.usuario.nome = " ";
    if(!cpf && cpf.toString().length != 11){
      return;
    }
    this.loaderService.display(true);
    let tipousuario = environment.settings.tipoUsuario;
    this.usuarioService.getUsuario(cpf, tipousuario, 'T')
      .subscribe(
        usuario => {
          if(usuario.numDocumento !== Number(this.userData.numDocumento)){
            Swal("Não é possível alterar esse usuário", "", MESSAGES.TYPE_ERROR);
          } else{
          this.usuario.nome = usuario.nome;
          }
          this.loaderService.display(false);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        })
  }

  buscarUsuario(cpf: number) {
    if(!cpf && cpf.toString().length != 11){
      return;
    }
    let tipousuario = environment.settings.tipoUsuario;

    this.loaderService.display(true);
    this.usuarioService.getUsuario(cpf, tipousuario, 'T')
      .subscribe(
        usuario => {
          if(usuario.numDocumento !== Number(this.userData.numDocumento)){
            Swal("Não é possível alterar esse usuário", "", MESSAGES.TYPE_ERROR);
            this.loaderService.display(false);
            return;
          }        
          this.liberarForm();
          this.usuario = usuario;
          if (this.usuario.tipoUsuario === 'FO') {
            this.usuario.tipoUsuario = 'Fonte';
          } else {
            this.usuario.tipoUsuario = 'Back Office';
          }
          if (this.usuario.status === 'Ativo' || this.usuario.status === 'A') {
            this.usuario.status = 'A';
          } else {
            this.usuario.status = 'I';
          }
          this.statusControl.setValue(this.usuario.status);

          this.getArea(this.usuario.numDocumento.toString(), this.usuario.codArea);
          this.getInformante(this.usuario.numDocumento);
          this.perfisModel.forEach(p => {
            if (p.codPerfil == this.usuario.codPerfil) {
              this.perfilControl.setValue(p);
              this.changeFuncoes(this.perfilControl.value.funcoes);
              return;
            }
          });
          this.emailControl.setValue(usuario.email);
          this.confirmacaoEmailControl.setValue(usuario.email);
          this.apelidoControl.setValue(usuario.apelido);
          this.dddControl.setValue(usuario.codDdd);
          this.telefoneControl.setValue(usuario.numTelefone);
          this.loaderService.display(false);
        },
        errors => {
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
          this.loaderService.display(false);
        }
      );

  }

  changeFuncoes(funcoes: FuncaoModel[]) {
    this.funcoes = funcoes.filter(f => f.selecionado);
  }

  getPerfis() {
    this.loaderService.display(true);
    this.perfilService.getPerfisPorTipo(environment.settings.portal)
      .subscribe(
        perfis => {
          this.perfisModel = perfis;
          this.loaderService.display(false);
        },
        errors => {
          Swal(this.sharedServices.tratarErro(errors), '', MESSAGES.TYPE_ERROR);
          this.loaderService.display(false);
        }
      );
  }

  getArea(numDocumento: string, codArea: string) {
    this.loaderService.display(true);
    this.sharedServices.getInformanteArea(numDocumento, codArea)
      .subscribe(
        informanteArea => {
          this.informanteAreaModel = informanteArea;
          this.loaderService.display(false);
        },
        error => {
          this.loaderService.display(false);
          Swal(this.sharedServices.tratarErro(error), '', MESSAGES.TYPE_ERROR);
        }
      );
  }

  getInformante(numDocumento: number) {
    this.loaderService.display(true);
    this.sharedServices.getInformante(numDocumento)
      .subscribe(
        informante => {
          this.informanteModel = informante;
          this.loaderService.display(false);
        },
        error => {
          this.loaderService.display(false);
          Swal(this.sharedServices.tratarErro(error), '', MESSAGES.TYPE_ERROR);
        }
      );
  }

  limparForm() {
    this.cpfAprovadorControl.setValue(' ');
    this.cpfConsultaControl.setValue(' ');
    this.usuarioAlterarForm.reset();
    this.usuario = new UsuarioModel();
    this.informanteAreaModel = new InformanteAreaModel();
    this.informanteModel = new InformanteModel();
    this.funcoes = [];
    this.bloquearForm();
    this.usuarioAprovador = new UsuarioModel();
    this.flagAlterar = false;
    this.requestLoad = false;
  }
}
