import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-remessas',
  templateUrl: './remessas.component.html',
})
export class RemessasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
