import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ConsultarComunicado } from './consultar-comunicado.model';
import { environment } from '../../../../../environments/environment';
import { JWTService } from '../../../../auth/jwt.service';
import { AUTHORITIES } from '../../../../auth/groups.constants';
import { HttpService } from '../../../../shared/http/httpclient.service';

@Injectable()
export class ConsultarComunicadoService {

  constructor(private http: HttpService, private jwtService: JWTService) {}

  listar(params: any): Observable<ConsultarComunicado> {
    const url = `${environment.settings.api}/comunicado/consultarComunicados`;
    return this.http.postParams(url, {}, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_COMUNICADOS]), params);
  }

  getPDF(idNegativacao, numComunicado): Observable<ArrayBuffer> {
    const params = {
      idNegativacao: idNegativacao,
      numComunicado: numComunicado,
    };
    const url = `${environment.settings.api}/comunicado/relatorio/consultaComunicado`;
    return this.http.getBuffer(url, this.jwtService.getApiHeadersMultypart(), params);
  }

  gerarRelatorioSubsidio(numComunicado: any, isSumula: boolean): Observable<ArrayBuffer> {
    const url = `${environment.settings.api}/negativacoes/relatorio/consultarRelatorioSubsidio/numComunicado/${numComunicado}/isSumula/${isSumula}`;
    return this.http.postBuffer(url, {}, this.jwtService.getApiHeadersMultypart());
  }

  getDetalheComunicado(numComunicado: string): Observable<ConsultarComunicado> {
    const url = `${environment.settings.api}/comunicado/detalheComunicado/${numComunicado}`;
    return this.http.get(url, this.jwtService.getApiHeaders([]), null);
  }
}
