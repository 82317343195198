import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { SharedService } from '../../../../shared/shared.service';
import { PerfilService } from '../../perfil/shared/perfil.service';
import { DominioModel } from '../../../../shared/model/dominio.model';
import Swal from 'sweetalert2'
import { InformanteAreaModel } from '../../../../shared/model/informante-area.model';
import { InformanteModel } from '../../../../shared/model/informante.model';
import { PerfilModel } from '../../shared/perfil.model'
import { MyErrorStateMatcher } from '../../../../shared/error-matcher-input/error-matcher-input.model';
import { SolicitarCadastroUsuarioModel } from '../solicitacao-usuario.model';
import { SocilicitacaoCadastroUsuarioService } from './cadastro-usuario.service';
import { JWTService } from '../../../../auth/jwt.service';
import { Token } from '../../../../auth/token.model';
import { environment } from '../../../../../environments/environment';
import { UsuarioService } from '../../usuario/usuario.service';
import { ConstantsMaskModel } from '../../../../shared/constants/constants-mask.model';
import { Validator } from '../../../../shared/validators/validator';
import { LoaderService } from '../../../../shared/loader.service';
import { MESSAGES } from '../../../../shared/constants/constants-swal.model';
import { AUTHORITIES } from '../../../../auth/groups.constants';
import { UtilsService } from '../../../../shared/utils.service';
import { UsuarioModel } from '../../../../shared/model/usuario.model';

@Component({
  selector: 'app-solicitacao-cadastro-usuario',
  templateUrl: './cadastro-usuario.component.html',
})
export class SolicitacaoCadastroUsuarioComponent implements OnInit {

  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();

  constructor(private sharedServices: SharedService,
    private formBuilder: FormBuilder,
    private solicitacaoService: SocilicitacaoCadastroUsuarioService,
    private perfilService: PerfilService,
    private jwtService: JWTService,
    private usuarioService: UsuarioService,
    private loaderService: LoaderService,
    private utils: UtilsService) { }

  usuarioSolicitacaoCadastroForm: FormGroup;
  conglomeradoControl = new FormControl('', [Validators.required]);
  tipoUsuarioConsultaControl = new FormControl('', [Validators.required]);
  cpfUsuarioControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  areaControl = new FormControl('', [Validators.required]);
  perfilControl = new FormControl('', []);
  perfilDescControl = new FormControl('', []);
  nomeEmpresaControl = new FormControl('', []);
  descricaoAreaControl = new FormControl('', []);
  emailUsuarioControl = new FormControl('', [Validators.required, Validators.email]);
  confirmeEmailUsuarioControl = new FormControl('', [Validators.required, Validator.equalsTo('emailUsuario')]);
  nomeCompletoControl = new FormControl('', [Validators.required]);
  primeiroNomeControl = new FormControl('', [Validators.required]);
  dddControl = new FormControl('', [Validators.required, Validators.minLength(2), Validator.isNumber()]);
  telefoneControl = new FormControl('', [Validators.required, Validators.minLength(8), Validator.isNumber()]);
  cpfAprovadorControl = new FormControl('', [Validators.required]);
  nomeAprovadorControl = new FormControl('', [Validators.required]);

  matcher = new MyErrorStateMatcher();

  //Iniciando models e vaiaveis
  informanteAreasModel: InformanteAreaModel[] = [];
  informanteModel: InformanteModel;
  perfisModel: PerfilModel[] = [];
  perfilSelecionado: PerfilModel;
  listaTipoUsuario: DominioModel[] = [];
  listaUsuario: SolicitarCadastroUsuarioModel[] = [];
  informanteAreaModel: InformanteAreaModel;
  listaConglomerado: InformanteModel[] = [];
  numDocumentoLogado: String;
  idLogin: Number;
  nomeUsuarioLogado: String;
  maskCpf = ConstantsMaskModel.MASK_CPF;
  aprovadores: UsuarioModel[] = [];
  CNPJ_BANCO_ITAU = '60701190000104';
  AREA_PRINCIPAL_ITAU = '0017';


  ngOnInit() {
    this.usuarioSolicitacaoCadastroForm = this.formBuilder.group({
      conglomeradoConsulta: this.conglomeradoControl,
      tipoUsuarioConsulta: this.tipoUsuarioConsultaControl,
      cpfUsuario: this.cpfUsuarioControl,
      area: this.areaControl,
      perfilCod: this.perfilControl,
      perfilDesc: this.perfilDescControl,
      nomeEmpresa: this.nomeEmpresaControl,
      descricaoArea: this.descricaoAreaControl,
      emailUsuario: this.emailUsuarioControl,
      confirmeEmailUsuario: this.confirmeEmailUsuarioControl,
      nomeCompleto: this.nomeCompletoControl,
      primeiroNome: this.primeiroNomeControl,
      ddd: this.dddControl,
      telefone: this.telefoneControl,
      cpfAprovador: this.cpfAprovadorControl,
      nomeAprovador: this.nomeAprovadorControl
    });

    const userData = this.jwtService.getObject('auth_token') as Token;
    this.numDocumentoLogado = userData.numDocumento;

    this.getConglomerado(this.numDocumentoLogado);
    this.getTipoUsuario();
    this.getPerfis();
    this.buscarAprovadores();
    this.informanteAreasModel = [];
    this.informanteAreaModel = new InformanteAreaModel();
    this.informanteModel = new InformanteModel('', '');
    this.perfisModel = [];
    this.perfilSelecionado = new PerfilModel();

    this.nomeAprovadorControl.disable();
    this.nomeEmpresaControl.disable();
    this.descricaoAreaControl.disable();
  }

  carregarInformanteLogado(informantes: InformanteModel[]) {
    informantes.forEach(informante => {
      if (Number(informante.numDocumento) === Number(this.numDocumentoLogado)) {
        this.conglomeradoControl.setValue(informante.numDocumento);
        this.alterarConglomerado(informante.numDocumento);
      }
    });
  }

  buscarListaEmpresas() {
    this.loaderService.display(true);
    this.sharedServices.getEmpresasUsuario()
      .subscribe(empresas => {
        empresas.forEach(item => {
          this.listaConglomerado.push(item);
        });
        this.loaderService.display(false);
      },
      error => {
        this.loaderService.display(false);
        if (error.status === 400) {
          Swal("A requisição está incorreta!", "", MESSAGES.TYPE_ERROR);
        } else if (error.status === 401) {
          Swal(MESSAGES.NAO_POSSUI_ACESSO_FUNCIONALIDADE, "", MESSAGES.TYPE_ERROR);
        } else {
          Swal(error.error.descricao, "", MESSAGES.TYPE_ERROR);
        }
      });
  }

  getConglomerado(numDocument: String) {
    this.loaderService.display(true);
    this.sharedServices.getConglomerado(numDocument)
      .subscribe(
        conglomerados => {
          this.loaderService.display(false);
          this.listaConglomerado = conglomerados;
          this.buscarListaEmpresas();
          this.carregarInformanteLogado(conglomerados);
        },
        error => {
          Swal(this.sharedServices.tratarErro(error), '', MESSAGES.TYPE_ERROR);
        }
      );
  }

  alterarConglomerado(documentoConglomerado) {
    this.usuarioSolicitacaoCadastroForm.get("nomeEmpresa").setValue(this.listaConglomerado.filter((item) => item.numDocumento === documentoConglomerado).map(((item) => item.nomeRazaoSocial))[0]);
    this.usuarioSolicitacaoCadastroForm.get("nomeEmpresa").disable();
    this.getAreas(documentoConglomerado);
  }

  alterarDescricaoArea(codArea) {
    this.usuarioSolicitacaoCadastroForm.get("descricaoArea").setValue(this.informanteAreasModel.filter((item) => item.codArea === codArea).map(((item) => item.descArea))[0]);
    this.usuarioSolicitacaoCadastroForm.get("descricaoArea").disable();
  }

  alterarDetalhePerfil(codPerfil: number) {
    this.perfilSelecionado = this.perfisModel.find(item => item.codPerfil === codPerfil);
    if (this.perfilSelecionado) {
      this.perfilDescControl.setValue(this.perfilSelecionado.descPerfil);
    } else {
      this.perfilDescControl.setValue('');
    }
  }

  getTipoUsuario() {
    this.sharedServices.getDominioByCodigo('USUARIO_TIPO')
      .subscribe(
        dominio => {
          this.listaTipoUsuario = dominio.filter(item => item.codigo == environment.settings.portal);
        },
        error => {
          Swal(this.sharedServices.tratarErro(error), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getAreas(numDocumento: string) {
    this.loaderService.display(true);
    this.sharedServices.getAreas(numDocumento)
      .subscribe(
        informanteArea => {
          this.loaderService.display(false);
          this.limpezaListaInformanteDescricao();
          if(numDocumento == this.CNPJ_BANCO_ITAU){
            this.informanteAreasModel = informanteArea;
             this.carregarPrincipalAreaBancoItau();
          }else{
            this.informanteAreasModel = informanteArea;
          }
        },
        error => {
          Swal(this.sharedServices.tratarErro(error), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  private limpezaListaInformanteDescricao() {
    this.informanteAreasModel = [];
    this.areaControl.setValue('');
    this.descricaoAreaControl.setValue('');
  }

  carregarPrincipalAreaBancoItau() {
      this.areaControl.setValue(this.AREA_PRINCIPAL_ITAU);
      this.alterarDescricaoArea(this.AREA_PRINCIPAL_ITAU);
  }

  getInformanteArea(cnpj: string, area: string) {
    if (this.usuarioSolicitacaoCadastroForm.invalid)
      return;
    this.sharedServices.getInformanteArea(cnpj, area)
      .subscribe(
        informanteArea => {
          this.informanteAreaModel = informanteArea;

        },
        errors => {
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getPerfis() {
    this.perfilService.getPerfisPorTipo(environment.settings.portal)
      .subscribe(
        perfil => {
          this.perfisModel = perfil;
          this.perfisModel = this.perfisModel.filter(item => item.tipoPerfil !== 'C');//removendo perfil consumidor
        },
        error => {
          Swal(this.sharedServices.tratarErro(error), "", MESSAGES.TYPE_ERROR);
        }
      )
  }

  equalsTo(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
      let formGroup = this.usuarioSolicitacaoCadastroForm;
      if (formGroup) {
        let email = formGroup.value.emailUsuario;
        let emailConfirmation = control.value;
        if (email !== emailConfirmation) {
          return { emailsNotMatch: true }
        } else {
          return undefined
        }
      }
    }
  }

  buscarUsuarioAprovador(cpf: number) {
    if (cpf.toString().length < 11) {
      this.nomeAprovadorControl.setValue('');
      return;
    }

    let tipousuario = environment.settings.tipoUsuario;
    let status = "A";

    this.usuarioService.getUsuario(cpf, tipousuario, status, AUTHORITIES.APROVAR_REJEITAR_SOLICITACAO_USUARIO)
      .subscribe(
        usuario => {
          this.nomeAprovadorControl.setValue(usuario.nome);
        },
        errors => {
          this.nomeAprovadorControl.setValue('');
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  buscarAprovadores() {
    let tipousuario = environment.settings.tipoUsuario;
    let status = "A";

    this.usuarioService.getAprovadores(tipousuario, status, AUTHORITIES.APROVAR_REJEITAR_SOLICITACAO_USUARIO)
      .subscribe(
        aprovadores => {
          this.aprovadores = aprovadores.filter( aprovador => aprovador.codPerfil === 20) ;
        },
        errors => {
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  limparForm(){
    this.cpfAprovadorControl.setValue(" ");
    this.cpfUsuarioControl.setValue(" ");
    this.telefoneControl.setValue(" ");
    this.dddControl.setValue(" ");
    this.usuarioSolicitacaoCadastroForm.reset();
  }

  criarSolicitacao() {
    if (!this.usuarioSolicitacaoCadastroForm.valid) {
      this.usuarioSolicitacaoCadastroForm.markAsTouched();
      return;
    }
    let model: SolicitarCadastroUsuarioModel = new SolicitarCadastroUsuarioModel();
    model.codArea = this.usuarioSolicitacaoCadastroForm.get('area').value;
    model.tipoUsuario = this.usuarioSolicitacaoCadastroForm.get('tipoUsuarioConsulta').value;
    model.operacao = 'I'; // I de inclusao
    model.apelido = this.usuarioSolicitacaoCadastroForm.get('primeiroNome').value;
    model.numTelefone = this.usuarioSolicitacaoCadastroForm.get('telefone').value;
    model.codDdd = this.usuarioSolicitacaoCadastroForm.get('ddd').value;
    model.codPerfil = this.usuarioSolicitacaoCadastroForm.get('perfilCod').value;
    model.cpf = this.usuarioSolicitacaoCadastroForm.get('cpfUsuario').value;
    model.cpfAprovador = this.usuarioSolicitacaoCadastroForm.get('cpfAprovador').value;
    model.email = this.usuarioSolicitacaoCadastroForm.get('emailUsuario').value;
    model.emailConfirmar = this.usuarioSolicitacaoCadastroForm.get('confirmeEmailUsuario').value;
    model.idLogin = null;
    model.nome = this.usuarioSolicitacaoCadastroForm.get('nomeCompleto').value;
    model.status = 'I';
    model.numDocumento = Number(this.numDocumentoLogado);
    this.loaderService.display(true);
    this.solicitacaoService.salvarUsuario(model)
      .subscribe(
        solicitacao => {
          this.loaderService.display(false);
          this.limparForm();
          Swal(MESSAGES.SOLICITACAO_CRIADA_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedServices.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  zeroAEsquerdaUsuario(){
    if(!this.cpfUsuarioControl.value)
      return;
    if(this.cpfUsuarioControl.value.replace(/_/g, '').length > 11){
      this.cpfUsuarioControl.setValue(this.utils.padLeftWithZeros(this.cpfUsuarioControl.value, 14));
    } else {
      this.cpfUsuarioControl.setValue(this.utils.padLeftWithZeros(this.cpfUsuarioControl.value, 11));
    }
  }

  zeroAEsquerdaAprovador(){
    if(!this.cpfAprovadorControl.value)
      return;
    if(this.cpfAprovadorControl.value.replace(/_/g, '').length > 11){
      this.cpfAprovadorControl.setValue(this.utils.padLeftWithZeros(this.cpfAprovadorControl.value, 14));
    } else {
      this.cpfAprovadorControl.setValue(this.utils.padLeftWithZeros(this.cpfAprovadorControl.value, 11));
    }
  }

}
