import { ActivatedRoute, Router } from '@angular/router';
import { Token } from './../auth/token.model';
import { MfaService } from './mfa.service';
import { JWTService } from '../auth/jwt.service';
import { LoaderService } from '../shared/loader.service';
import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from '../shared/event-emiter-service.service';
import { MyErrorStateMatcher } from '../shared/error-matcher-input/error-matcher-input.model';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { UtilsService } from '../shared/utils.service';
import { MESSAGES } from '../shared/constants/constants-swal.model';
import { SenhaService } from '../senha/shared/senha.service';
import { EsqueciSenha } from '../senha/shared/senha.model';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss'],
  providers: [MfaService, JWTService, SenhaService]
})

export class MfaComponent implements OnInit {

  tokenJwt: Token;
  tentativas: number;  
  cronometro: number;
  intervalo: any;
  mfaForm: FormGroup;
  tokenCodigoControl = new FormControl('', [ Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/^[0-9]\d*$/) ]);
  matcher = new MyErrorStateMatcher();

  constructor(private formBuilder: FormBuilder,
    private mfaService: MfaService,
    private jwtService: JWTService,
    private router: Router,
    private loaderService: LoaderService,
    private emitterService: EventEmitterService,
    private senhaService: SenhaService,
  ) {}

  ngOnInit() {
    this.tokenJwt = this.jwtService.getObject('auth_token');  
    
    this.mfaForm = this.formBuilder.group({
      tokenCodigo: this.tokenCodigoControl,
    });

    this.emitterService.display(false);
    this.loaderService.display(false);
    this.tentativas = 0;

    $(".breadcrumb").css({'display': 'none'})    
    $('.content-wraper').addClass('col-6')
    $('.content-wraper').addClass('float-right')
    $('.content-wraper').addClass('pl-3')
    $('.content-wraper').addClass('ml-0')
    
    $(".container-fluid").removeClass('wraper')   
    $(".content-wraper").removeClass('ml-5')    
    $('.content-wraper').removeClass('col-12')
    $('.content-wraper').removeClass('pl-5')
    $('.content-wraper').removeClass('pb-3') 

    this.inicializaCronometro();
  }

  inicializaCronometro() {
    this.cronometro = 300;
    this.startTimer();
  }

  validarTokenMFA() {
    this.loaderService.display(true);

    this.mfaService.validarToken(this.tokenJwt.hash, this.tokenCodigoControl.value)
      .subscribe(
        data => {
          this.loaderService.display(false);
          if (data.access_token) {
            this.tokenJwt = data;
            
            var dt = new Date();
            dt.setSeconds(dt.getSeconds() + this.tokenJwt.expires_in);
            this.tokenJwt.expires_in = dt;
            this.jwtService.putObject('auth_token', this.tokenJwt, dt);

            this.router.navigate(['home']);
            $(".container-fluid").addClass('wraper');
          }
        },
        errors => {
          Swal(errors.error.message, "", "error");
          this.loaderService.display(false);
        }
    );
  }

  redefinirSenha() {
    const recuperarSenha: EsqueciSenha = new EsqueciSenha();
    const usuario = this.jwtService.getCookie('auth_token_mfa') as Token;
    recuperarSenha.cpf = Number(usuario.cpfCnpj);

    this.senhaService.recuperarSenha(recuperarSenha)
      .subscribe(
        data => {
          Swal("Você deverá redefinir a sua senha para acessar o sistema!", "", MESSAGES.TYPE_WARNING);
          this.router.navigate(['nova-senha']);
        },
        errors => {
          var dt = new Date();
          dt.setSeconds(dt.getSeconds() + this.tokenJwt.expires_in);
          this.tokenJwt.expires_in = dt;
          this.jwtService.putObject('auth_token', this.tokenJwt, dt);
          this.jwtService.removeCookie('auth_token_mfa');

          this.router.navigate(['home']);
          $(".container-fluid").addClass('wraper');
        }
      );
  }

  reenviarTokenMFA() {
    this.loaderService.display(true);
    this.mfaService.reenviarToken(this.tokenJwt.hash)
      .subscribe(
        data => {
          this.loaderService.display(false);
          if (data.status.codigo === 1) {
            Swal(data.status.statusItem.descricao, "", "success");
            this.inicializaCronometro();
          } else {
            Swal(data.status.statusItem.descricao, "", "error");
          }
        },
        errors => {
          this.loaderService.display(false);
          Swal(errors.error.message, "", "error");
        }
    );
  }

  startTimer() {
    this.intervalo = setInterval(() => {
      if(this.cronometro > 0) {
        this.cronometro--;
      } else {
        clearInterval(this.intervalo);
      }
    }, 1000)
  }

  exibeCronometroEmMinutos() {
    const minutos = ("0" + Math.floor(this.cronometro / 60)).slice(-2);
    const segundos = ("0" + (this.cronometro % 60)).slice(-2);
    return minutos + ":" + segundos;
  }

}
