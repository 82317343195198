export class GROUPS {
    static ADMIN_TI = 'Administrador TI';
    static ADMIN_NEGATIVACAO = 'Administrador Negativação';
    static OPERADOR_TI = 'Operador TI';
    static RETAGUARDA_GIC = 'Retaguarda GIC';
    static APROVADOR_USUARIOS_GIC = 'Aprovador Usuários GIC';
    static RETAGUARDA_MASTER_GIC = 'Retaguarda Master GIC';
    static RETAGUARDA_CONTENCIOSO_GIC = 'Retaguarda Contencioso GIC';
    static RETAGUARDA_FONTE = 'Retaguarda Fonte';
    static APROVADOR_USUARIOS_FONTE = 'Aprovador Usuários - Fonte';
    static APROVADOR_CONTESTACOES_FONTE = 'Aprovador Contestações Fonte';
    static APROVADOR_PEP_FONTE = 'Aprovador PEP Fonte';
    static APROVADOR_MENOR_FONTE = 'Aprovador MENOR Fonte';
    static CONSUMIDOR = 'Consumidor';
};

export class AUTHORITIES {
    static IDOBJETO                                                = "idobjeto";
    static BAIXAR_REGISTRO_PASSAGEM                                = "1";
    static INCLUSAO_USUARIO                                        = "2";
    static ALTERACAO_USUARIO                                       = "3";
    static CONSULTA_USUARIO                                        = "4";
    static INCLUSAO_PERFIL                                         = "5";
    static CONSULTAR_PERFIL                                        = "6";
    static ALTERAR_PERFIL                                          = "7";
    static CONSULTAR_FUNCIONALIDADE                                = "8";
    static ALTERAR_FUNCIONALIDADE                                  = "9";
    static ASSOCIAR_PERFIL_FUNCIONALIDADE                          = "10";
    static SOLICITAR_CADASTRAMENTO_USUARIO                         = "11";
    static SOLICITAR_ALTERACAO_USUARIO                             = "12";
    static CONSULTAR_SOLICITACAO_USUARIO                           = "14";
    static APROVAR_REJEITAR_SOLICITACAO_USUARIO                    = "13";
    static TRANSFERIR_SOLICITACOES_PENDENTES                       = "15";
    static CONSULTAR_NATUREZA_OPERACAO                             = "16";
    static INCLUIR_NATUREZA_OPERACOES                              = "17";
    static ALTERAR_NATUREZA_OPERACAO                               = "18";
    static EXCLUIR_NATUREZA_OPERACAO                               = "19";
    static CONSULTAR_CODIGO_RETORNO                                = "20";
    static INCLUIR_CODIGO_RETORNO                                  = "21";
    static ALTERAR_CODIGO_RETORNO                                  = "22";
    static EXCLUIR_CODIGO_RETORNO                                  = "23";
    static CONSULTAR_MOTIVOS_BAIXA                                 = "24";
    static INCLUIR_MOTIVOS_BAIXA                                   = "25";
    static ALTERAR_MOTIVOS                                         = "26";
    static EXCLUIR_MOTIVOS_BAIXA                                   = "27";
    static CONSULTAR_MODELO_CARTA_FONTE                            = "28";
    static INCLUIR_MODELO_CARTA_FONTE                              = "29";
    static ALTERAR_MODELO_CARTA_FONTE                              = "30";
    static EXCLURI_MODELO_CARTA_FONTE                              = "31";
    static CONSULTAR_CODIGO_RETORNO_REMESSA_INFORMACIONAL          = "32";
    static INCLUIR_CODIGO_RETORNO_REMESSA_INFORMACIONAL            = "33";
    static ALTERAR_CODIGO_RETORNO_REMESSA_INFORMACIONAL            = "34";
    static EXCLUIR_CODIGO_RETORNO_REMESSA_INFORMACIONAL            = "35";
    static CONSULTAR_INFORMACOES_FONTE                             = "36";
    static CONSULTAR_PRODUTO                                       = "37";
    static CONSULTAR_CONTRATO                                      = "38";
    static CONSULTAR_REGRAS_FONTE                                  = "39";
    static MANUTENCAO_PARAMETRO_LOCALIDADE                         = "40";
    static INCLUIR_NEGATIVACAO                                     = "41";
    static ALTERAR_NEGATIVACAO                                     = "42";
    static BAIXAR_NEGATIVACAO                                      = "43";
    static CONSULTAR_NEGATIVACAO_ATIVAS                            = "44";
    static CONSULTAR_HISTORICO_NEGATIVACAO                         = "45";
    static CONSULTAR_NEGATIVACAO_SUSPENSA                          = "46";
    static CONSULTAR_DETALHE_NEGATIVACAO                           = "47";
    static VISUALIZAR_CARTA                                        = "48";
    static EXPORTAR_CONSULTA_NEGATIVACAO_EXCEL                     = "49";
    static GERAR_INFORMACOES_NEGATIVACAO_PDF                       = "50";
    static CONSULTAR_CONTESTACAO                                   = "51";
    static SOLICITAR_CONTESTACAO                                   = "52";
    static APROVAR_REJEITAR_CONTESTACOES                           = "53";
    static APROVAR_REJEITAR_PEP                                    = "54";
    static APROVAR_REJEITAR_MENOR                                  = "55";
    static SUSPENDER_POR_OFICIO_JUDICIAL                           = "56";
    static REINCLUIR_NEGATIVACAO_OFICIO_JUDICIAL                   = "57";
    static SOLICITA_CARTA_AVULSA                                   = "58";
    static MONITORAMENTO_REMESSA                                   = "59";
    static CONSULTAR_HISTORICO_PROCESSAMENTO_REMESSA               = "60";
    static CONSULTAR_HISTORICO_PROCESSAMENTO_REMESSA_INFORMACIONAL = "61";
    static CONSULTAR_HISTORICO_PROCESSAMENTO_ATUALIZACAO_ONLINE    = "62";
    static CONSULTAR_DADOS_CONSUMIDOR                              = "63";
    static ALTERAR_DADOS_CONSUMIDOR                                = "64";
    static INCLUIR_DADOS_CONSUMIDOR                                = "65";
    static AGENDAMENTO_BASES                                       = "66";
    static CADASTRAR_USUARIO_API                                   = "67";
    static USUARIO_API                                             = "67";
    static CONSULTAR_IMAGEM                                        = "70";
    static PARAMETROS_COMUNICADO                                   = "73";
    static MANTER_USUARIO                                          = "76";
    static CONSULTAR_NEGATIVACAO                                   = "77";
    static CONSULTAR_LISTA_CONTESTACAO                             = "78";
    static CONSULTAR_TRILHA_APONTAMENTO                            = "79";
    static CADASTRAR_BLACKLIST                                     = "84";
    static CADASTRAR_ENRIQUECIMENTO                                = "85";
    static CONSULTAR_DASHBOARD                                     = "90";
    static RETAGUARDA_MULTICOBRANCA                                = "102";
    static CONSULTAR_COMUNICADO_TEMPLATE                           = "103";
    static CONSULTAR_COMUNICADOS                                   = "112";
    static AGENDAMENTO_CARTEIRA                                    = "118";
    static VISUALIZAR_CLIENTE                                      = "119";
}
