export class ConstantsAbrev {
    public SHORTENING = {};

    constructor(){
        this.SHORTENING["CONJUNTO"] = "CJ";
        this.SHORTENING["HABITACIONAL"] = "HAB";
        this.SHORTENING["ALAMEDA"] = "ALM";
        this.SHORTENING["AVENIDA"] = "AVN";
        this.SHORTENING["BOULEVARD"] = "BLV";
        this.SHORTENING["CAMINHO"] = "CAM";
        this.SHORTENING["CAMPO"] = "CMP";
        this.SHORTENING["ESCADA"] = "ESC";
        this.SHORTENING["ESTRADA"] = "ETR";
        this.SHORTENING["FAVELA"] = "FAV";
        this.SHORTENING["FAZENDA"] = "FAZ";
        this.SHORTENING["FLORESTA"] = "FLT";
        this.SHORTENING["JARDIM"] = "JRD";
        this.SHORTENING["LADEIRA"] = "LAD";
        this.SHORTENING["LOTEAMENTO"] = "LTM";
        this.SHORTENING["PARQUE"] = "PQE";
        this.SHORTENING["PASSEIO"] = "PAS";
        this.SHORTENING["PRAÇA"] = "PRC";
        this.SHORTENING["RECANTO"] = "REC";
        this.SHORTENING["RODOVIA"] = "ROD";
        this.SHORTENING["TRAVESSA"] = "TRV";
        this.SHORTENING["VILA"] = "VL";
    }
}