import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Overlay } from '@angular/cdk/overlay';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { LoaderService } from '../../../shared/loader.service';
import { ConstantsMaskModel } from '../../../shared/constants/constants-mask.model';
import { SharedService } from '../../../shared/shared.service';
import { AprovarRejeitarPepService } from './aprovar-rejeitar-pep.service';
import { NegativacaoPendenteAprovacaoModel } from '../../../shared/model/negativacao-pendente-aprovacao.model';
import { UtilsService } from '../../../shared/utils.service';
import { AprovarRejeitarPepDialog } from './aprovar-rejeitar-pep-dialog';
import { ConstantsNegativacaoPendenteAprovacaoModel } from '../../../shared/constants/constants-negativacao-pendente-aprovacao.model';
import { JWTService } from '../../../auth/jwt.service';
import { Token } from '../../../auth/token.model';
import { MESSAGES } from '../../../shared/constants/constants-swal.model';

@Component({
  selector: 'app-aprovar-rejeitar-pep',
  templateUrl: './aprovar-rejeitar-pep.component.html',
  styleUrls: ['./aprovar-rejeitar-pep.component.scss']
})
export class AprovarRejeitarPepComponent implements OnInit {
  nome: string;


  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private jwtService: JWTService,
    private loaderService: LoaderService,
    private service: AprovarRejeitarPepService,
    private sharedService: SharedService,
    public overlay: Overlay,
    private utilsService: UtilsService
  ) { }

  aprovarRejeitarPepForm: FormGroup;

  negPendAprovacaoControl = new FormArray([]);
  cnpjCredor: string = '';

  negativacoesPendeteAprovacao: NegativacaoPendenteAprovacaoModel[] = [];
  maskCpf = ConstantsMaskModel.MASK_CPF;
  maskCnpj = ConstantsMaskModel.MASK_CNPJ;
  maskDate = ConstantsMaskModel.MASK_DATE;

  contestacaoEncontrada: boolean = false;
  displayLoad: boolean = true;

  ngOnInit() {
    this.exibirNegativacaoPendentes();
    const userData = this.jwtService.getObject('auth_token') as Token;
    userData.numDocumento = userData.numDocumento;
    this.cnpjCredor = userData.numDocumento;
    this.getInformante(parseInt(userData.numDocumento));
  }

  getInformante(numDocumento: number) {
    const userData = this.jwtService.getObject('auth_token') as Token;
    userData.numDocumento = userData.numDocumento;
    numDocumento = parseInt(userData.numDocumento);

    this.sharedService.getInformante(numDocumento)
      .subscribe(
        informante => {
          this.nome = informante.nomeRazaoSocial;
        }
      ),
      error => {
        Swal(this.sharedService.tratarErro(error), "", MESSAGES.TYPE_ERROR);
      }
  }

  exibirNegativacaoPendentes() {
    this.loaderService.display(true);
    this.service.buscar(ConstantsNegativacaoPendenteAprovacaoModel.CONS_MOTIVO_PEP, ConstantsNegativacaoPendenteAprovacaoModel.CONS_STATUS_PENDENTE)
      .subscribe(
        negsPendAprovacao => {
          if(negsPendAprovacao.length > 0) {
            negsPendAprovacao.forEach(negPendAprovacao => {
              this.sharedService.getNegativacao(negPendAprovacao.idNegativacao).subscribe(
                negativacao => {
                  this.sharedService.getOcorrencias(negativacao.idNegativacao).subscribe(
                    ocorrencias => {
                      negativacao.ocorrencias = ocorrencias;
                      negPendAprovacao.negativacao = negativacao;
                      this.negativacoesPendeteAprovacao.push(negPendAprovacao);
  
                      this.loaderService.display(false);
                    },
                    errors => {
                      this.loaderService.display(false);
                    }
                  );
                },
                errors => {
                  this.loaderService.display(false);
                }
              );
            });
          } else {
            this.loaderService.display(false);
          }

          this.displayLoad = false;
          this.contestacaoEncontrada = true;
        },
        errors => {
          this.contestacaoEncontrada = false;
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  formatToNumber(value: number) {
    return this.sharedService.formatToNumber(value);
  }

  formatData(value: string) {
    return this.utilsService.formataData(value);
  }

  aprovar(negPendentAProvacao: NegativacaoPendenteAprovacaoModel) {
    let config = new MatDialogConfig();
    config.width = '42%';
    config.height = '50%';
    config.scrollStrategy = this.overlay.scrollStrategies.noop();
    config.data = {
      negPendentAProvacao: negPendentAProvacao,
      texto: 'Confirmar a aprovação de um apontamento para o PEP selecionado?',
      action: 'aprovar'
    }
    const dialogRef = this.dialog.open(AprovarRejeitarPepDialog, config);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.status == "ok") {
          Swal(MESSAGES.APROVACAO_PEP_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
          this.removerPendencia(negPendentAProvacao.idPendAutorizacao);
        } else {
          Swal(this.sharedService.tratarErro(result.errors), "", MESSAGES.TYPE_ERROR);
        }
      }
    });
  }

  rejeitar(negPendentAProvacao: NegativacaoPendenteAprovacaoModel) {
    let config = new MatDialogConfig();
    config.width = '42%';
    config.height = '60%';
    config.scrollStrategy = this.overlay.scrollStrategies.noop();
    config.data = {
      negPendentAProvacao: negPendentAProvacao,
      texto: 'Confirmar a recusa de um apontamento para o PEP selecionado?',
      action: 'rejeitar'
    }
    let dialogRef = this.dialog.open(AprovarRejeitarPepDialog, config);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.status == "ok") {
          this.removerPendencia(negPendentAProvacao.idPendAutorizacao);
        } else {
          Swal(this.sharedService.tratarErro(result.erros), "", MESSAGES.TYPE_ERROR);
        }
      }
    });
  }

  removerPendencia(id: number){
    let index: number;
    this.negativacoesPendeteAprovacao.forEach((pen, i)=>{
      if(pen.idPendAutorizacao == id){
        index = i;
      }
    })
    this.negativacoesPendeteAprovacao.splice(index);
  }

  maskACnpj(cnpj: number) {
    if (!cnpj)
      return;
    return this.sharedService.maskACnpj(cnpj.toString());
  }
}