import { Component, OnInit } from '@angular/core';
import { JWTService } from '../auth/jwt.service';
import { Token } from 'src/app/auth/token.model';
import { AUTHORITIES } from '../auth/groups.constants';
import { SharedService } from '../shared/shared.service';
import { ConsultaDashBoard, DashBoardIndicadorModel} from './shared/dashboard-indicador-model'
import { InformanteModel } from '../shared/model/informante.model';
import { MESSAGES } from '../shared/constants/constants-swal.model';
import { DashboardService } from './shared/dashboard.service';
import Swal from 'sweetalert2'
import { UtilsService } from 'src/app/shared/utils.service';
import { LoaderService } from '../shared/loader.service';
import { formatDate} from '@angular/common';
import { DatePipe } from '@angular/common'
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers:[DashboardService]
})
export class DashboardComponent implements OnInit {
  jwt: any;
  isAcessoPermitido: boolean;
  informanteSelecionado: InformanteModel[] = [];
  cnpj : string;
  cnpjControl =  new FormControl('', []);
  numDocumentoLogado: String;
  listaConglomerado: InformanteModel[] = [];
  informantes : any;
  razaoSocial : string;
  indicadoresMesVigente: DashBoardIndicadorModel[]; 
  model: ConsultaDashBoard;
  indicadoresConsolidado: DashBoardIndicadorModel[]; 
  quantidadeUltimosDias = 90;
  dtInicioMesVigente : string;
  dtFimMesVigente : string;
  dtInicioConsolidado : string;
  dtFimConsolidado : string 

  constructor(private sharedService: SharedService, 
    private jwtService: JWTService, 
    private service : DashboardService,
    private loaderService: LoaderService,
    private utilsService: UtilsService,
    public datepipe: DatePipe) { }
  
  ngOnInit() {
     this.jwt = this.jwtService.getObject('auth_token') as Token;
     this.verificarAcessoDashBoard();
     const userData = this.jwtService.getObject('auth_token') as Token;
     this.numDocumentoLogado = userData.numDocumento;
     let now =  new Date();
     let strdataFim = formatDate(now, 'yyyy-MM-dd', 'en-US');
     now.setDate(1);
     let strdataInicio = formatDate(now, 'yyyy-MM-dd', 'en-US');
     this.model = new ConsultaDashBoard(this.numDocumentoLogado.toString(),strdataInicio.toString(),strdataFim.toString(),'ACUMULADO');
     if(this.isAcessoPermitido){
      this.buscarIndicadoresMesVigente(this.model);
     }
    
     this.cnpj =  userData.numDocumento;
     this.getConglomerado(this.numDocumentoLogado);
     this.getInformante(this.numDocumentoLogado.toString());
  }
  verificarAcessoDashBoard(){
    this.isAcessoPermitido = this.sharedService.hasPermission(this.jwt, [AUTHORITIES.CONSULTAR_DASHBOARD]);
  }

  onTabChanged($event){
    if($event.index == 0){
      if(this.indicadoresMesVigente == null){
        let now =  new Date();
        let strdataFim = formatDate(now, 'yyyy-MM-dd', 'en-US');
        now.setDate(1);
        let strdataInicio = formatDate(now, 'yyyy-MM-dd', 'en-US');     
        this.model = new ConsultaDashBoard(this.numDocumentoLogado.toString(),strdataInicio.toString(),strdataFim.toString(),'ACUMULADO');
        this.buscarIndicadoresMesVigente(this.model);
      }
      
    }else{
      if(this.indicadoresConsolidado == null){
        let now =  new Date();
        let dataFim = new Date();
        now.setDate(dataFim.getDate() - this.quantidadeUltimosDias);
        let strdataInicio = formatDate(now, 'yyyy-MM-dd', 'en-US');
        let strdataFim = formatDate(dataFim, 'yyyy-MM-dd', 'en-US');
        this.model = new ConsultaDashBoard(this.numDocumentoLogado.toString(),strdataInicio.toString(),strdataFim.toString(),'CONSOLIDADO');
        this.buscarIndicadoresConsolidado(this.model);
      }
     
    }
  }

  buscarIndicadoresMesVigente(model : ConsultaDashBoard) {
    this.loaderService.display(true);
    this.service.listarIndicadoresByDocumentoAndDatas(model)
    .subscribe(
      retorno => {
        if(retorno){
          this.loaderService.display(false);
          this.indicadoresMesVigente = retorno.indicadores;
          this.cnpj = model.numDocumento;
          this.dtInicioMesVigente = retorno.dataInicio;
          this.dtFimMesVigente = retorno.dataFim;
          this.getInformante(this.cnpj);
        }else{
          this.loaderService.display(false);
          Swal('Consulta não retornou dados!', '', MESSAGES.TYPE_WARNING);
        }
      },
      error => {     
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(error), '', MESSAGES.TYPE_ERROR);      
      }
    );
}

  buscarIndicadoresConsolidado(model : ConsultaDashBoard) {
    this.loaderService.display(true); 
    this.service.listarIndicadoresByDocumentoAndDatas(model)
    .subscribe(
      retorno => {
        if(retorno){
          this.indicadoresConsolidado = retorno.indicadores;
          this.cnpj = model.numDocumento;
          this.dtInicioConsolidado = retorno.dataInicio;
          this.dtFimConsolidado = retorno.dataFim;               
          this.getInformante(this.cnpj);
          this.loaderService.display(false);    
        }else{
          this.loaderService.display(false); 
          Swal('Consulta não retornou dados!', '', MESSAGES.TYPE_WARNING);
        }
       
      },
      error => {     
          this.loaderService.display(false); 
          Swal(this.sharedService.tratarErro(error), '', MESSAGES.TYPE_ERROR); 
      }
    );
}

buscarMesVigentePorConglomerado(event : any){
  if (event.source.selected) {
    let doc = event.source.value;
    this.loaderService.display(true);
    let now =  new Date();
    let strdataFim = formatDate(now, 'yyyy-MM-dd', 'en-US');
    now.setDate(1);
    let strdataInicio = formatDate(now, 'yyyy-MM-dd', 'en-US');  
    let model = new ConsultaDashBoard(doc.toString(),strdataInicio.toString(),strdataFim.toString(),'ACUMULADO');   
    this.buscarIndicadoresMesVigente(model);
  }
}

buscarConsolidadoPorConglomerado(event : any){
  if (event.source.selected) {
    let doc = event.source.value;
    this.loaderService.display(true);
    let now = new Date();
    let dataFim = new Date();
    now.setDate(dataFim.getDate() - this.quantidadeUltimosDias);
    let strdataInicio = formatDate(now, 'yyyy-MM-dd', 'en-US');
    let strdataFim = formatDate(dataFim, 'yyyy-MM-dd', 'en-US');
    let model = new ConsultaDashBoard(doc.toString(),strdataInicio.toString(),strdataFim.toString(),'CONSOLIDADO');
    this.buscarIndicadoresMesVigente(model);
  }
}


  getConglomerado(numDocumento: String) {
    this.sharedService.getConglomerado(numDocumento)
      .subscribe(
        conglomerados => {
          this.listaConglomerado = conglomerados;
          if(this.listaConglomerado){
            this.informantes =  this.listaConglomerado.map( function (infor) {
              return {
                 value: infor.numDocumento,
                 obj: infor
              };
           });
          }
          
        },
        error => {
          Swal(this.sharedService.tratarErro(error), '', MESSAGES.TYPE_ERROR);
        }
      );
  }

  getInformante(numDocumento: string) {
    this.loaderService.display(true);
    numDocumento = this.zeroAEsquerda(numDocumento.toString());
    this.sharedService.getInformante(Number.parseInt(numDocumento))
      .subscribe(
        (informante: InformanteModel) => {
          this.razaoSocial = informante.nomeRazaoSocial;
          this.loaderService.display(false);
        },
        errors => {
          this.loaderService.display(false);
        }
      );
  }

  zeroAEsquerda(numDocumento: string): string {
    if (!numDocumento)
      return;
    return this.utilsService.padLeftWithZeros(numDocumento, 14);
  }
  
}



