import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-menor',
  templateUrl: './menor.component.html'
})
export class MenorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
