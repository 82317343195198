import { Injectable } from "@angular/core";
import { JWTService } from "../../../auth/jwt.service";
import { NegativacaoPendenteAprovacaoModel } from "../../../shared/model/negativacao-pendente-aprovacao.model";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { HttpService } from "../../../shared/http/httpclient.service";

@Injectable()
export class AprovarRejeitarPepService {
    constructor(
        private http: HttpService,
        private jwtService: JWTService) {

    }

    buscar(motivo: string, status?: string): Observable<NegativacaoPendenteAprovacaoModel[]> {
        return this.http.postParams(`${environment.settings.api}/negativacoesPendentesAutorizacao`, {}, this.jwtService.getApiHeaders([]),
            {
                motivo: motivo,
                status: status
            }
        )
    }

    alterar(negPendAutorizacao: NegativacaoPendenteAprovacaoModel) {
        return this.http.put(`${environment.settings.api}/negativacoesPendentesAutorizacao/atualizar`, negPendAutorizacao, this.jwtService.getApiHeaders([]))
    }
}