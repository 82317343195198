import { EnderecoModel } from "./endereco.model";
import { NegativacaoOcorrenciasModel } from "./negativacao-ocorrencias.model";
import { TelefoneModel } from "./telefone-model";
import { NegativacaoOpcionalModel } from "./negativacao-opcional.mode";
import { EmailModel } from "./email.model";
import { NegativacaoEnderecoModel } from "./negativacao-endereco.model";

export class NegativacaoModel {
    constructor(
        public idNegativacao?: string,
        public numDocumento?: number,
        public numContrato?: string,
        public codArea?: string,
        public datBaixa?: string,
        public tipoParticipante?: string,
        public tipPessoa?: string,
        public cpfCnpj?: number,
        public nomeRazaoSocial?: string,
        public datBaixaAnt?: string,
        public motBaixa?: string,
        public motBaixaAnt?: string,
        public sitNegativado?: string,
        public sitNegativadoAnt?: string,
        public natOperacao?: string,
        public datProcessamento?: string,
        public datDisponibilidade?: string,
        public datNascimento?: string,
        public enderecos?:NegativacaoEnderecoModel[],
        public ocorrencias?: NegativacaoOcorrenciasModel[],
        public telefones?: TelefoneModel[],
        public opcional?: NegativacaoOpcionalModel,
        public emails?: EmailModel[],
        public _links?: any

    ) {
        this.idNegativacao = idNegativacao == null ? '' : idNegativacao;
        this.numDocumento = numDocumento == null ? null : numDocumento;
        this.numContrato = numContrato == null ? '' : numContrato;
        this.codArea = codArea == null ? '' : codArea;
        this.datBaixa = datBaixa == null ? '' : datBaixa;
        this.tipoParticipante = tipoParticipante == null ? '' : tipoParticipante;
        this.tipPessoa = tipPessoa == null ? '' : tipPessoa;
        this.cpfCnpj = cpfCnpj == null ? null : cpfCnpj;
        this.nomeRazaoSocial = nomeRazaoSocial == null ? '' : nomeRazaoSocial;
        this.datBaixaAnt = datBaixaAnt == null ? '' : datBaixaAnt;
        this.motBaixa = motBaixa == null ? '' : motBaixa;
        this.motBaixaAnt = motBaixaAnt == null ? '' : motBaixaAnt;
        this.sitNegativado = sitNegativado == null ? '' : sitNegativado;
        this.sitNegativadoAnt = sitNegativadoAnt == null ? '' : sitNegativadoAnt;
        this.natOperacao = natOperacao == null ? '' : natOperacao;
        this.datProcessamento = datProcessamento == null ? '' : datProcessamento;
        this.datDisponibilidade = datDisponibilidade == null ? '' : datDisponibilidade;
        this.datNascimento = datNascimento == null ? '' : datNascimento;
        this.enderecos = enderecos == null || enderecos.length == 0 ? []:enderecos;
        this.ocorrencias = ocorrencias == null || ocorrencias.length == 0 ? []:ocorrencias;
        this.telefones = telefones == null || telefones.length == 0 ? []:telefones;
        this.emails = emails == null || emails.length == 0 ? []:emails;
        this.opcional = opcional == null ? new NegativacaoOpcionalModel():opcional;
    }
}