import { Injectable } from '@angular/core';

import {Observable} from 'rxjs/Observable';
import { InformanteModel } from '../../../../shared/model/informante.model';
import { SolicitarCadastroUsuarioModel } from '../solicitacao-usuario.model';
import { environment } from '../../../../../environments/environment';

import { JWTService } from '../../../../auth/jwt.service';
import { AUTHORITIES } from '../../../../auth/groups.constants';
import { HttpService } from '../../../../shared/http/httpclient.service';

@Injectable()
export class AlterarUsuarioService {

  constructor(private http: HttpService,
              private jwtService: JWTService) { }

  salvarUsuario(model):Observable<SolicitarCadastroUsuarioModel>{
    return this.http.post(`${environment.settings.api}/solicitacoes/usuarios`, model, this.jwtService.getApiHeaders([AUTHORITIES.INCLUSAO_USUARIO]));
  }

  getConglomerado(numDocumento: string):Observable<InformanteModel[]>{
    return this.http.get(`${environment.settings.api}/{numDocumento}/conglomerados`, this.jwtService.getApiHeaders([]), null);
  }
  
  alterarUsuario(model):Observable<SolicitarCadastroUsuarioModel>{
    return this.http.put(`${environment.settings.api}/solicitacoes/usuarios`, model, this.jwtService.getApiHeaders([AUTHORITIES.ALTERACAO_USUARIO]));
  }
}
