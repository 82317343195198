import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Solicitacao, SolicitacaoTransferencia } from './solicitacao.model';
import { Pessoa } from '../../../../shared/model/pessoa.model';
import { environment } from '../../../../../environments/environment';
import { JWTService } from '../../../../auth/jwt.service';
import { AUTHORITIES } from '../../../../auth/groups.constants';
import { HttpService } from '../../../../shared/http/httpclient.service';

@Injectable()
export class SolicitacaoService {

  constructor( private http: HttpService,
               private jwtService: JWTService ) { }

  obterAprovador(cpfAprovador: string): Observable<Pessoa> {
    return this.http.post(`${environment.settings.api}/pessoas/tipoPessoa/F/cpfCnpj/${cpfAprovador}`, {}, this.jwtService.getApiHeaders([]));
  }

  listar(cpfAprovador: string): Observable<Solicitacao[]> {
    return this.http.post(`${environment.settings.api}/solicitacoes/usuarios/cpfAprovador/${cpfAprovador}`, {}, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_SOLICITACAO_USUARIO]));
  }

  rejeitar(idSolicitacao : number, justificativa : string) {
    return this.http.patch(`${environment.settings.api}/solicitacoes/usuarios/idSolicitacao/${idSolicitacao}/justificativa/${justificativa}`, {}, this.jwtService.getApiHeaders([AUTHORITIES.APROVAR_REJEITAR_SOLICITACAO_USUARIO]));
  }

  aprovar(idSolicitacao : number, tipoSolicitacao : string) {
    return this.http.patch(`${environment.settings.api}/solicitacoes/usuarios/idSolicitacao/${idSolicitacao}/tipoSolicitacao/${tipoSolicitacao}`, {}, this.jwtService.getApiHeaders([AUTHORITIES.APROVAR_REJEITAR_SOLICITACAO_USUARIO]));
  }

  transferir(params : SolicitacaoTransferencia) {
    return this.http.patch(`${environment.settings.api}/solicitacoes/usuarios`, params, this.jwtService.getApiHeaders([AUTHORITIES.TRANSFERIR_SOLICITACOES_PENDENTES]));
  }
  
  buscarPorId(id: string): Observable<Solicitacao> {
    return this.http.post(`${environment.settings.api}/solicitacoes/usuarios/${id}`, this.jwtService.getApiHeaders([AUTHORITIES.CONSULTAR_SOLICITACAO_USUARIO]), null);
  }

}
