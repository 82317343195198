import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from '../../../shared/error-matcher-input/error-matcher-input.model';
import { LoaderService } from '../../../shared/loader.service';
import { JWTService } from '../../../auth/jwt.service';
import { Validator } from '../../../shared/validators/validator';
import { UtilsService } from '../../../shared/utils.service';
import { SharedService } from '../../../shared/shared.service';
import Swal from 'sweetalert2';
import { MESSAGES } from '../../../shared/constants/constants-swal.model';
import { ConstantsMaskModel } from '../../../shared/constants/constants-mask.model';
import { ContestacaoModel } from '../../../shared/model/contestacao.model';
import { ContestacaoService } from "../shared/contestacao.service"
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Overlay } from '@angular/cdk/overlay';
import { QuestionamentoDialogComponent } from "./questionamento-dialog/questionamento-dialog.component"
import { RejeicaoDialogComponent } from "./rejeicao-dialog/rejeicao-dialog.component"

@Component({
  selector: 'app-consultar-lista-contestacao',
  templateUrl: './consultar-lista-contestacao.component.html',
  styleUrls: ['./consultar-lista-contestacao.component.scss']
})
export class ConsultarListaContestacaoComponent implements OnInit {

  cpfCnpjControl = new FormControl('', [Validators.required, Validator.documentIsValid()]);
  nomeDevedorControl = new FormControl({ value: '', disabled: true }, []);
  consultarListaContestacaoForm: FormGroup;
  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();
  dialogRef: any;
  matcher = new MyErrorStateMatcher();

  //Lista Contestacao
  contestacoes: ContestacaoModel[] = [];

  constructor(
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private jwtService: JWTService,
    public utils: UtilsService,
    private sharedService: SharedService,
    private contestacaoService: ContestacaoService,
    public dialog: MatDialog,
    public overlay: Overlay,
  ) { }



  ngOnInit() {

    this.consultarListaContestacaoForm = this.fb.group({
      cpfCnpj: this.cpfCnpjControl,
      nomeDevedor: this.nomeDevedorControl
    });

  }


  buscarNomeDevedor() {
    this.zeroAEsquerda();
    if (this.cpfCnpjControl.value == 0) {
      this.nomeDevedorControl.setValue("");
      return;
    }
    let documentoSemMascara = this.cpfCnpjControl.value.replace(/_/g, "");
    let numDocumento = this.getNumber(this.cpfCnpjControl.value);
    let tipoPessoa = '';
    if (numDocumento === 11) {
      tipoPessoa = 'F';
    } else {
      tipoPessoa = 'J'
    }
    this.sharedService.getPessoa(documentoSemMascara, tipoPessoa)
      .subscribe(
        (success: any) => {
          this.nomeDevedorControl.setValue(success.nomeRazaoSocial);
        },
        (errors) => {
          if (errors.status == 404) {
            this.nomeDevedorControl.setValue('Nome não encontrado na base');
            return;
          }
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  limparDados() {
    this.nomeDevedorControl.setValue("")
    this.contestacoes = []
  }

  zeroAEsquerda() {
    if(!this.cpfCnpjControl.value)
      return;
    if(this.cpfCnpjControl.value.replace(/_/g, '').length > 11){
      this.cpfCnpjControl.setValue(this.utils.padLeftWithZeros(this.cpfCnpjControl.value, 14));
    } else {
      this.cpfCnpjControl.setValue(this.utils.padLeftWithZeros(this.cpfCnpjControl.value, 11));
    }
  }

  getNumber(cpfCnpjValue) {
    let cpfCnpj = cpfCnpjValue.match(/\d/g);
    let cpfCnpjLength = 0;
    if (cpfCnpj) {
      cpfCnpjLength = cpfCnpj.join('').length;
    }
    return cpfCnpjLength
  }

  consultarListaContestacao(dados: FormGroup) {
    this.loaderService.display(true);

    let cpfCnpjDevedor = dados.value.cpfCnpj
    let cnpjInformante = this.jwtService.getObject('auth_token').numDocumento

    this.contestacaoService.consultarListaContestacao(cpfCnpjDevedor, cnpjInformante)
      .subscribe(
        (success: ContestacaoModel[]) => {
          
          this.loaderService.display(false);
          
          if(success.length == 0)
            Swal("Não há questionamento(s) para este CPF/CNPJ", "", MESSAGES.TYPE_SUCCESS);
          else
            this.contestacoes = success
          
        },
        (errors: any) => {
          
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);

        }
    )

  }

  formatToCurrency(value: number) {
    return this.utils.formatToCurrency(value);
  }

  openDialogQuestionamento(contestacao: any) : void {

    let config =  new MatDialogConfig();
    config.width = '50%';
    config.height = '60%';
    config.data = contestacao;
    config.scrollStrategy = this.overlay.scrollStrategies.close();
    this.dialogRef = this.dialog.open(QuestionamentoDialogComponent, config);
    
  }

  
  openDialogRejeicao(contestacao: any) : void {

    let config =  new MatDialogConfig();
    config.width = '50%';
    config.height = '50%';
    config.data = contestacao;
    config.scrollStrategy = this.overlay.scrollStrategies.close();
    this.dialogRef = this.dialog.open(RejeicaoDialogComponent, config);

  }

}
