import { AUTHORITIES } from './groups.constants';

export class AUTH {
    static INCLUIR_USUARIO = {
        path: '/configuracoes/controleAcesso/usuario/incluirUsuario',
        groups: [
            AUTHORITIES.INCLUSAO_USUARIO
        ]
    };
    static ALTERAR_USUARIO = {
        path: 'configuracoes/controleAcesso/solicitacao/alterarUsuario',
        groups: [
            AUTHORITIES.ALTERACAO_USUARIO
        ]
    };
    static INCLUIR_PERFIL = {
        path: '/configuracoes/controleAcesso/perfil/incluirPerfil',
        groups: [
            AUTHORITIES.INCLUSAO_PERFIL
        ]
    };
    static CONSULTAR_PERFIL = {
        path: '/configuracoes/controleAcesso/perfil/consultarPerfil',
        groups: [
            AUTHORITIES.CONSULTAR_PERFIL
        ]
    };
    static ALTERAR_PERFIL = {
        path: '/configuracoes/controleAcesso/perfil/consultarPerfil',
        groups: [
            AUTHORITIES.ALTERACAO_USUARIO
        ]
    };
    static CONSULTAR_FUNCIONALIDADE = {
        path: '/configuracoes/controleAcesso/funcionalidade/consultarFuncionalidade',
        groups: [
            AUTHORITIES.CONSULTAR_FUNCIONALIDADE
        ]
    };
    static ALTERAR_FUNCIONALIDADE = {
        path: '/configuracoes/controleAcesso/funcionalidade/consultarFuncionalidade',
        groups: [
            AUTHORITIES.ALTERAR_FUNCIONALIDADE
        ]
    };
    static ASSOCIAR_PERFIL_FUNCIONALIDADE = {
        path: '/configuracoes/controleAcesso/perfil/assocPerfilFuncao',
        groups: [
            AUTHORITIES.ASSOCIAR_PERFIL_FUNCIONALIDADE
        ]
    };
    static SOLICITAR_CADASTRAMENTO_USUARIO = {
        path: '/configuracoes/controleAcesso/solicitacao/cadastroUsuario',
        groups: [
            AUTHORITIES.SOLICITAR_CADASTRAMENTO_USUARIO
        ]
    };
    static SOLICITAR_ALTERACAO_USUARIO = {
        path: '/configuracoes/controleAcesso/solicitacao/alterarUsuario',
        groups: [
            AUTHORITIES.SOLICITAR_ALTERACAO_USUARIO
        ]
    };
    static CONSULTAR_SOLICITACAO = {
        path: '/configuracoes/controleAcesso/solicitacao/aprovarUsuario',
        groups: [
            AUTHORITIES.APROVAR_REJEITAR_SOLICITACAO_USUARIO
        ]
    };
    static APROVAR_REJEITAR_SOLICITACAO = {
        path: '/configuracoes/controleAcesso/solicitacao/aprovarUsuario',
        groups: [
            AUTHORITIES.APROVAR_REJEITAR_SOLICITACAO_USUARIO
        ]
    };
    static TRANSFERIR_SOLICITACAO = {
        path: '/configuracoes/controleAcesso/solicitacao/transferirSolicitacao',
        groups: [
            AUTHORITIES.TRANSFERIR_SOLICITACOES_PENDENTES
        ]
    };
    static CONSULTAR_NATUREZA_OPERACAO = {
        path: '/cadastros/naturezaOperacao',
        groups: [
            AUTHORITIES.CONSULTAR_NATUREZA_OPERACAO
        ]
    };
    static INCLUIR_NATUREZA_OPERACAO = {
        path: '/cadastros/naturezaOperacao',
        groups: [
            AUTHORITIES.INCLUIR_NATUREZA_OPERACOES
        ]
    };
    static ALTERAR_NATUREZA_OPERACAO = {
        path: '/cadastros/naturezaOperacao',
        groups: [
            AUTHORITIES.ALTERAR_NATUREZA_OPERACAO
        ]
    };
    static EXCLUIR_NATUREZA_OPERACAO = {
        path: '/cadastros/naturezaOperacao',
        groups: [
            AUTHORITIES.EXCLUIR_NATUREZA_OPERACAO
        ]
    };
    static CONSULTAR_CODIGO_RETORNO = {
        path: '/cadastros/codigoRetorno',
        groups: [
            AUTHORITIES.CONSULTAR_CODIGO_RETORNO
        ]
    };
    static INCLUIR_CODIGO_RETORNO = {
        path: '/cadastros/codigoRetorno',
        groups: [
            AUTHORITIES.INCLUIR_CODIGO_RETORNO
        ]
    };
    static ALTERAR_CODIGO_RETORNO = {
        path: '/cadastros/codigoRetorno',
        groups: [
            AUTHORITIES.ALTERAR_CODIGO_RETORNO
        ]
    };
    static EXCLUIR_CODIGO_RETORNO = {
        path: '/cadastros/codigoRetorno',
        groups: [
            AUTHORITIES.EXCLUIR_CODIGO_RETORNO
        ]
    };
    static CONSULTAR_MOTIVOS_BAIXA = {
        path: '/cadastros/motivosBaixa',
        groups: [
            AUTHORITIES.CONSULTAR_MOTIVOS_BAIXA
        ]
    };
    static INCLUIR_MOTIVOS_BAIXA = {
        path: '/cadastros/motivosBaixa',
        groups: [
            AUTHORITIES.INCLUIR_MOTIVOS_BAIXA
        ]
    };
    static ALTERAR_MOTIVOS_BAIXA = {
        path: '/cadastros/motivosBaixa',
        groups: [
            AUTHORITIES.ALTERAR_MOTIVOS
        ]
    };
    static EXCLUIR_MOTIVOS_BAIXA = {
        path: '/cadastros/motivosBaixa',
        groups: [
            AUTHORITIES.EXCLUIR_MOTIVOS_BAIXA
        ]
    };
    static INCLUIR_MODELO_CARTA_FONTE = {
        path: '/cadastros/modelosCarta',
        groups: [
            AUTHORITIES.INCLUIR_MODELO_CARTA_FONTE
        ]
    };
    static ALTERAR_MODELO_CARTA_FONTE = {
        path: '/cadastros/modelosCarta',
        groups: [
            AUTHORITIES.ALTERAR_MODELO_CARTA_FONTE
        ]
    };
    static EXCLUIR_MODELO_CARTA_FONTE = {
        path: '/cadastros/modelosCarta',
        groups: [
            AUTHORITIES.EXCLURI_MODELO_CARTA_FONTE
        ]
    };
    static CONSULTAR_CODIGO_REMESSA = {
        path: '/cadastros/retornoRemessa',
        groups: [
            AUTHORITIES.MONITORAMENTO_REMESSA
        ]
    };
    static INCLUIR_CODIGO_REMESSA = {
        path: '/cadastros/retornoRemessa',
        groups: [
            AUTHORITIES.MONITORAMENTO_REMESSA
        ]
    };
    static ALTERAR_CODIGO_REMESSA = {
        path: '/cadastros/retornoRemessa',
        groups: [
            AUTHORITIES.MONITORAMENTO_REMESSA
        ]
    };
    static EXCLUIR_CODIGO_REMESSA = {
        path: '/cadastros/retornoRemessa',
        groups: [
            AUTHORITIES.MONITORAMENTO_REMESSA
        ]
    };
    static CONSULTAR_INFORMACAO_FONTE = {
        path: 'none',
        groups: [
            AUTHORITIES.CONSULTAR_INFORMACOES_FONTE
        ]
    };
    static CONSULTAR_PRODUTO = {
        path: '/cadastros/produto',
        groups: [
            AUTHORITIES.CONSULTAR_PRODUTO
        ]
    };
    static CONSULTAR_CONTRATO = {
        path: 'cadastros/contrato',
        groups: [
            AUTHORITIES.CONSULTAR_CONTRATO
        ]
    };
    static CONSULTAR_REGRAS_FONTE = {
        path: 'none',
        groups: [
            AUTHORITIES.CONSULTAR_REGRAS_FONTE
        ]
    };
    static MANUTENCAO_PARAMETRO_LOCALIDADE = {
        path: '/cadastros/localidade',
        groups: [
            AUTHORITIES.MANUTENCAO_PARAMETRO_LOCALIDADE
        ]
    };
    static INCLUIR_NEGATIVACAO = {
        path: '/servicos/apontamento/incluirApontamento',
        groups: [
            AUTHORITIES.INCLUIR_NEGATIVACAO
        ]
    };
    static ALTERAR_NEGATIVACAO = {
        path: '/servicos/apontamento/alterarApontamento',
        groups: [
            AUTHORITIES.ALTERAR_NEGATIVACAO
        ]
    };
    static BAIXAR_NEGATIVACAO = {
        path: '/servicos/apontamento/baixarApontamento',
        groups: [
            AUTHORITIES.BAIXAR_NEGATIVACAO
        ]
    };
    static CONSULTAR_APONTAMENTO = {
        path: '/servicos/apontamento/consultarApontamento',
        groups: [
            AUTHORITIES.CONSULTAR_HISTORICO_NEGATIVACAO,
            AUTHORITIES.CONSULTAR_NEGATIVACAO_ATIVAS,
            AUTHORITIES.CONSULTAR_NEGATIVACAO_SUSPENSA,
         //   AUTHORITIES.CONSULTAR_DETALHE_NEGATIVACAO,
         //   AUTHORITIES.EXPORTAR_CONSULTA_NEGATIVACAO_EXCEL,
         //   AUTHORITIES.GERAR_INFORMACOES_NEGATIVACAO_PDF
        ]
    };
    static VISUALIZAR_CARTA = {
        path: 'none',
        groups: [
            AUTHORITIES.VISUALIZAR_CARTA
        ]
    };
    static CONSULTAR_CONTESTACAO = {
        path: '/servicos/contestacao/consultaContestacao',
        groups: [
            AUTHORITIES.CONSULTAR_CONTESTACAO
        ]
    };
    static SOLICITAR_CONTESTACAO = {
        path: 'none',
        groups: [
            AUTHORITIES.SOLICITAR_CONTESTACAO
        ]
    };
    static APROVAR_REJEITAR_CONTESTACAO = {
        path: '/servicos/contestacao/aprovarRejeitarContestacao',
        groups: [
            AUTHORITIES.APROVAR_REJEITAR_CONTESTACOES
        ]
    };
    static APROVAR_REJEITAR_PEP = {
        path: '/servicos/excecao/aprovarRejeitarExcecoes',
        groups: [
            AUTHORITIES.APROVAR_REJEITAR_PEP
        ]
    };
    static APROVAR_REJEITAR_MENOR = {
        path: '/servicos/menor/aprovarRejeitarMenor',
        groups: [
            AUTHORITIES.APROVAR_REJEITAR_MENOR
        ]
    };
    static SUSPENDER_OFICIO_JUDICIAL = {
        path: '/servicos/oficioJudicial/suspensaoJudicial',
        groups: [
            AUTHORITIES.SUSPENDER_POR_OFICIO_JUDICIAL
        ]
    };
    static REINCLUIR_OFICIO_JUDICIAL = {
        path: '/servicos/oficioJudicial/reinclusaoJudicial',
        groups: [
            AUTHORITIES.REINCLUIR_NEGATIVACAO_OFICIO_JUDICIAL
        ]
    };
    static SOLICITACAO_CARTA_AVULSA = {
        path: 'none',
        groups: [
            AUTHORITIES.SOLICITA_CARTA_AVULSA
        ]
    };
    static MONITORAMENTO_REMESSA = {
        path: '/remessas/statusRemessaProcessada',
        groups: [
            AUTHORITIES.MONITORAMENTO_REMESSA
        ]
    };
    static CONSULTAR_HISTORICO_REMESSA = {
        path: '/remessas/historicoRemessa',
        groups: [
            AUTHORITIES.CONSULTAR_HISTORICO_PROCESSAMENTO_REMESSA
        ]
    };
    static CONSULTAR_HISTORICO_REMESSA_INFORMACIONAL = {
        path: '/remessas/historicoRemessa',
        groups: [
            AUTHORITIES.CONSULTAR_HISTORICO_PROCESSAMENTO_REMESSA
        ]
    };
    static CONSULTAR_HISTORICO_ATUALIZACAO_ONLINE = {
        path: 'none',
        groups: [
            AUTHORITIES.CONSULTAR_HISTORICO_PROCESSAMENTO_ATUALIZACAO_ONLINE
        ]
    };
    static CONSULTA_USUARIO = {
        path: '/configuracoes/controleAcesso/usuario/consultarUsuario',
        groups: [
            AUTHORITIES.CONSULTA_USUARIO
        ]
    };
    static ALTERACAO_USUARIO = {
        path: '/configuracoes/controleAcesso/alterarUsuario',
        groups: [
            AUTHORITIES.ALTERACAO_USUARIO
        ]
    };
    static INCLUSAO_USUARIO = {
        path: '/configuracoes/controleAcesso/solicitacao/cadastroUsuario',
        groups: [
            AUTHORITIES.INCLUSAO_USUARIO
        ]
    };
    static AGENDAMENTO_BASES = {
        path: 'none',
        groups: [
            AUTHORITIES.AGENDAMENTO_BASES
        ]
    };
    static BAIXAR_REGISTRO_PASSAGEM = {
        path: 'none',
        groups: [
            AUTHORITIES.BAIXAR_REGISTRO_PASSAGEM
        ]
    };
    static CONSULTAR_IMAGEM = {
        path: '/servicos/imagem/consultaImagem',
        groups: [
            AUTHORITIES.CONSULTAR_IMAGEM
        ]
    };
    static USUARIO_API = {
        path: '/configuracoes/controleAcesso/usuario/manterUsuarioApi',
        groups: [
            AUTHORITIES.USUARIO_API
        ]
    };
    static CONSULTAR_LISTA_CONTESTACAO = {
        path: '/servicos/contestacao/consultarListaContestacao',
        groups: [
            AUTHORITIES.CONSULTAR_LISTA_CONTESTACAO
        ]
    };
    static CONSULTAR_DASHBOARD = {
        path: '/dashboard/consultarDashboard',
        groups: [
            AUTHORITIES.CONSULTAR_DASHBOARD
        ]
    };
    static AGENDAMENTO_CARTEIRA = {
        path: '/cadastros/agendamentoCarteira/consulta',
        groups: [
            AUTHORITIES.AGENDAMENTO_CARTEIRA
        ]
    };
}