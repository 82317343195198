import { UsuarioModel } from "../../../shared/model/usuario.model";

export class SolicitarCadastroUsuarioModel extends UsuarioModel {
    constructor(
        public cpfAprovador?: number,
        public operacao?: string
    ) {
        super();
        this.cpfAprovador = cpfAprovador == null ? null:cpfAprovador;
        this.operacao = operacao == null ? '':operacao;
    }
}