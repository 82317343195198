import { Component, OnInit } from '@angular/core';
import { JWTService } from '../auth/jwt.service';
import $ from "jquery";
import { Token } from '@angular/compiler';
import { UsuarioService } from '../configuracoes/controle-acesso/usuario/usuario.service';
import { LoaderService } from '../shared/loader.service';
import { SolicitacaoService } from '../configuracoes/controle-acesso/solicitacao/shared/solicitacao.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  jwt: Token;

  constructor(private jwtService: JWTService, private usuarioService: UsuarioService, private loaderService: LoaderService, private solicitacaoService: SolicitacaoService) { }

  ngOnInit() {
    this.recuperarPerfilUsuarioAprovador();
    this.jwtService.checkJWT;
    $(".breadcrumb").css({ 'display': 'block' })
  }

  recuperarPerfilUsuarioAprovador() {
    const userData: any = this.jwtService.getObject('auth_token') as Token;
    this.listar(userData);
  }

  listar(userData) {
    this.loaderService.display(true);
    this.solicitacaoService.listar(userData.cpfCnpj)
      .subscribe(
        result => {
          this.loaderService.display(false);
          if (result.length > 0) {
            localStorage.setItem('pagina', 'home');
            window.location.href = '/configuracoes/controleAcesso/solicitacao/aprovarUsuario';
          }
        },
        errors => {
          this.loaderService.display(false);
        }
      );
  }

}
