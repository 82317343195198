import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { OnInit, Inject, Component } from "@angular/core";

@Component({
    selector: 'confirmar-exclusao-ocorrencia-avalista-dialog',
    templateUrl: './confirmar-exclusao-ocorrencia-avalista-dialog.html'
})
export class ConfirmarExclusaoOcorrenciaAvalistaDialog implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ConfirmarExclusaoOcorrenciaAvalistaDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(){

    }

    confirmar(){
        this.dialogRef.close(true);
    }

}
