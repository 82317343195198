import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parametros-sistema',
  templateUrl: './parametros-sistema.component.html',
})
export class ParametrosSistemaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
