import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracoes',
  templateUrl: './configuracoes.component.html',
})
export class ConfiguracoesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }
}
