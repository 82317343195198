import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imagem',
  templateUrl: './imagem.component.html',
})
export class ImagemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
