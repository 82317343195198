import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { JWTService } from '../../auth/jwt.service';
import { NovaSenha, EsqueciSenha, AcessoTemporario } from './senha.model';
import { HttpService } from '../../shared/http/httpclient.service';

@Injectable()
export class SenhaService {

  constructor( private http: HttpService, private jwtService : JWTService ) {}

  recuperarSenha(senha : EsqueciSenha) {
    return this.http.put(`${environment.settings.api}/usuarios/cpf/${senha.cpf}/tipoUsuario/${environment.settings.tipoUsuario}/senha`, null, this.jwtService.getSignInHeaders());
  }

  atualizarSenha(senha : NovaSenha) {
    return this.http.put(`${environment.settings.api}/usuarios/senha`, senha, this.jwtService.getSignInHeaders());
  }

  validaToken(token : string) {
    return this.http.get(`${environment.settings.api}/usuarios/validaToken/${token}`, this.jwtService.getSignInHeaders(), null);
  }

  acessoTemporario(senha : AcessoTemporario) {
    return this.http.post(`${environment.settings.api}/usuarios/acesso`, senha, this.jwtService.getSignInHeaders());
  }
}
