export class MESSAGES {
    
    //TYPE
    public static TYPE_SUCCESS: any = "success";
    public static TYPE_ERROR: any = "error";
    public static TYPE_WARNING: any = "warning";   
    
    //SUCCESS
    public static OPERACAO_REALIZADA_SUCESSO: string = "Operação realizada com sucesso!";
    public static NEGATIVACAO_REALIZADA_SUCESSO: string = "Negativação realizada com sucesso!";
    public static MOTIVO_BAIXA_INCLUIDO: string = "Motivo de Baixa incluído com sucesso!";
    public static SENHA_ATUALIZADA_SUCESSO: string = "Senha atualizada com sucesso!";
    public static OCORRENCIA_EXCLUIDA_SUCESSO: string = "Ocorrência excluída com sucesso!";
    public static OCORRENCIA_ATUALIZADA_SUCESSO: string = "Ocorrência atualizada com sucesso!";
    public static CONTESTACAO_APROVADA_SUCESSO: string = "Contestação aprovada com sucesso!";
    public static CONTESTACAO_REJEITADA_SUCESSO: string = "Contestação rejeitada com sucesso!";
    public static APROVACAO_PEP_SUCESSO: string = "Aprovação de prontamento para PEP foi processado com sucesso!";
    public static USUARIO_CADASTRADO_SUCESSO: string = "Usuário cadastrado com sucesso!";
    public static USUARIO_ALTERADO_SUCESSO: string = "Usuário alterado com sucesso!";
    public static NAO_HA_CONTESTACOES: string = "Não há contestações";
    public static NAO_HA_QUESTIONAMENTO: string = "Não há questionamento(s) para esse CPF/CNPJ";
    public static SOLICITACAO_CRIADA_SUCESSO: string = "Solicitação criada com sucesso!";
    public static AGENDAMENTO_INSERIDO_SUCESSO: string = "Agendamento inserido com sucesso!";
    public static NATUREZA_OPERACAO_INCLUIDA_SUCESSO: string = "Natureza da Operação incluída com sucesso!";
    public static LOCALIDADE_INSERIDA_SUCESSO: string = "Localização inserida com sucesso!";
    public static CODIGO_RETORNO_INCLUIDO_SUCESSO: string = "Código de Retorno incluído com sucesso!";
    public static CODIGO_RETORNO_REMESSA_INCLUIDO_SUCESSO: string = "Código de Retorno de Remessa incluído com sucesso!";
    public static PERFIL_ALTERADO_SUCESSO: string = "Perfil alterado com sucesso!";
    public static CLIENTE_ATUALIZADO_SUCESSO: string = "Cliente atualizado com sucesso!";
    public static CLIENTE_SALVO_SUCESSO: string = "Cliente salvo com sucesso!";
    public static PERFIL_FUNCOES_ATUALIZADAS_SUCESSO: string = "O Perfil e as Funções associadas a ele foram atualizadas com sucesso!";
    public static FUNCAO_ATUALIZADA_SUCESSO: string = "Dados da Funcionalidade atualizados com sucesso!";
    public static CONGLOMERADO_ALTERADO_SUCESSO: string = "Conglomerado alterado com sucesso!";
    public static NAO_HA_SOLICITACOES: string = "Não há solicitações pendentes para este aprovador";
    public static AVALISTA_EXCLUIDO_SUCESSO: string = "Avalista excluído com sucesso!";
    public static NAO_ENCONTRADO_APONTAMENTO: string = "Não encontrado nenhum apontamento para o filtro informado";

    //ERROR / INFO
    public static SESSAO_EXPIRADA: string = "Sessão expirada! Por favor, faça o login novamente";
    public static ERRO_DESCONHECIDO: string = "Ocorreu um erro inesperado. Por favor, tente novamente.";
    public static SERVICO_INDISPONIVEL: string = "O serviço está temporariamente indisponível. Tente novamente mais tarde.";
    public static OCORREU_SEGUINTES_ERROS: string = "Ocorreu o(s) seguinte(s) erro(s) na inclusão: ";
    public static USUARIO_SENHA_INVALIDOS: string = "Usuário ou Senha inválidos";
    public static NAO_POSSUI_ACESSO: string = "Você não tem acesso";
    public static USUARIO_NAO_CADASTRADO: string = "Usuário não cadastrado";
    public static NAO_FOI_POSSIVEL_DESCONECTAR: string = "Não foi possível desconectar";
    public static EXISTEM_CAMPOS_FALTANDO: string = "Existem 1 ou mais campos faltando ou com informações inválidas";
    public static DATA_INVALIDA: string = "Data inválida!";
    public static NECESSARIO_SELECIONAR_SOLICITACAO: string = "É necessário selecionar pelo menos uma solicitação";
    public static NOVA_SENHA_ENVIADA_EMAIL: string = "Nova senha enviada no email!";
    public static NAO_RETORNOU_RESULTADOS: string = "Sua busca não retornou nenhum resultado";
    public static DIVIDA_JA_CADASTRADA: string = "Dívida já foi cadastrada";
    public static CONTRATO_JA_CADASTRADO: string = "Contrato já cadastrado para outro CPF/CNPJ";
    public static DIVIDA_PARTICIPANTE_PRINCIPAL_NAO_ENCONTRADA: string = "Dívida do participante principal não foi encontrada";
    public static DIVIDA_PARTICIPANTE_PRINCIPAL_INATIVA: string = "Dívida do participante principal não esta ativa";
    public static SENHAS_NAO_PODEM_SER_DIFERENTES: string = "Senhas não podem ser diferentes";
    public static SENHA_CONFIRMA_DEVEM_SER_IGUAIS: string = "Campo Senha e Confirma Senha devem ser iguais";
    public static DDD_INVALIDO: string = "DDD inválido";
    public static TELEFONE_JA_ADICIONADO: string = "Esse telefone já foi adicionado";
    public static EMAIL_JA_ADICIONADO: string = "Esse email já foi adicionado";
    public static NOVA_DATA_DIVIDA_NAO_PODE_SER_MAIOR: string = "Nova data de dívida não pode ser maior que a atual";
    public static NOVA_DATA_DIVIDA_INVALIDA: string = "Nova data da dívida inválida";
    public static NAO_HA_OCORRENCIAS_ATIVAS: string = "Não há ocorrências ativas";
    public static COMUNICADO_NAO_ENCONTRADO: string = "Comunicado não encontrado para essa negativação!";
    public static ERRO_GERACAO_COMUNICADO: string = "Não foi possível gerar o comunicado!";
    public static DATA_INICIAL_MAIOR_FINAL: string = "Data inicial maior que a final!";
    public static DATA_FINAL_MENOR_INICIAL: string = "Data final menor que a inicial!";
    public static DATA_FINAL_NAO_PODE_SER_MAIOR_HOJE: string = "Data final não pode ser maior que hoje!";
    public static RELATORIO_NAO_GERADO: string = "Relatório não foi gerado";
    public static PELO_MENOS_UMA_OPCAO_SELECIONADA: string = "Pelo menos uma das opções de envio deve ser selecionada (E-mail, SMS ou Carta)";
    public static PELO_MENOS_UMA_FUNCAO_POR_PERFIL: string = "Pelo menos uma função deve ser selecionada para o perfil";
    public static NENHUMA_OCORRENCIA_ENCONTRADA: string = "Nenhuma ocorrência encontrada";
    public static NENHUMA_REMESSA_ENCONTRADA: string = "Nenhuma remessa encontrada";
    public static SEQUENCIA_INICIAL_MAIOR_FINAL: string = "Data inicial maior que a final!";
    public static SEQUENCIA_FINAL_MENOR_INICIAL: string = "Sequência inicial não pode ser nula";
    public static SEQUENCIA_INICIAL_NAO_PODE_SER_NULA: string = "Sequência inicial não pode ser nula";
    public static SEQUENCIA_FINAL_NAO_PODE_SER_NULA: string = "Sequência final não pode ser nula";
    public static NECESSARIO_UM_MODELO_COMUNICADO: string = "Deve ser adicionado pelo menos um modelo de comunicado";
    public static NECESSARIO_UMA_AREA: string = "Deve ser adicionado pelo menos uma área";
    public static EXISTE_UMA_AREA_CODIGO: string = "Já existe uma área com esse código";
    public static JA_EXISTE_MODELO_COMUNICADO: string = "Já existe um modelo de comunicado com esse código";
    public static INFORMANTE_NAO_EXISTE: string = "Informante não existe";
    public static NECESSARIO_PELO_MENOS_UM_INFORMANTE: string = "É preciso inserir pelo menos um informante";
    public static INFORMANTE_PAI_NAO_PODE_SER_FILHO: string = "Informante pai não pode ser um informante filho";
    public static INFORMANTE_JA_PERTENCE_CONGLOMERADO: string = "Informante já faz parte do conglomerado";
    public static CNPJ_INVALIDO: string = "CNPJ Inválido";
    public static NAO_RETORNOU_COMUNICADO: string = "Não foi encontrado nenhum Comunicado para os dados informados";
    public static PERIODO_MAIOR_QUE_31_DIAS: string = "O período para a pesquisa não pode ultrapassar 31 dias";
    public static CPFCNPJ_DEVEDOR_NAO_ENCONTRADO: string = "CPF/CNPJ devedor não encontrado no cadastro";
    public static CREDOR_NAO_ENCONTRADO: string = "CNPJ credor não encontrado no cadastro";
    public static CPFCNPJ_DEVEDOR_CREDOR_NAO_INFORMADO: string = "Filtro de busca não informado";
    public static NECESSARIO_SELECIONAR_ITEM: string = "É necessário selecionar pelo menos um item";
    public static NECESSARIO_SELECIONAR_APENAS_UM_CPF: string = "A geração de relatório de PDF somente pode ser executada para um CPF";
    public static DOCUMENTO_CONFIDENCIAL: string =  "Documento confidencial";
    public static REDIRECIONAMENTO_CADASTRO_CREDOR: string = "Por favor, cadastre o credor";
    public static DADO_INVALIDO: string = "Dado inválido detectado. Por favor, verifique.";
    public static CONFIRME_RAZAO_SOCIAL: string = "Por favor, verifique a validade do nome/razão social antes de confirmar a operação.";
    public static NAO_POSSUI_ACESSO_FUNCIONALIDADE: string = "Você não possui acesso para esta funcionalidade!";
    public static ERRO_GERACAO_RELATORIO: string = "Não foi possível gerar relatório!";
}
