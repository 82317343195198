import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SharedService } from '../../../shared/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NegativacaoModel } from '../../../shared/model/negativacao.model';
import { HttpClient } from '@angular/common/http';
import { NegativacaoOpcionalModel } from '../../../shared/model/negativacao-opcional.mode';
import { TelefoneModel } from '../../../shared/model/telefone-model';
import { EmailModel } from '../../../shared/model/email.model';
import { NegativacaoOcorrenciasModel, AtualizarOcorrenciaModel } from '../../../shared/model/negativacao-ocorrencias.model';
import { ConstantsMaskModel } from '../../../shared/constants/constants-mask.model';
import { NegativacaoEnderecoModel } from '../../../shared/model/negativacao-endereco.model';
import { JWTService } from '../../../auth/jwt.service';
import { ApontamentoService } from '../shared/apontamento.service';
import { MatDialog } from '@angular/material';
import { ExcluirNegativacaoDialog } from './excluir-negativacao-dialog.component';
import { Overlay } from '@angular/cdk/overlay';
import Swal from 'sweetalert2'
import { MotivosBaixaModel } from '../../../cadastros/motivos-baixa/motivos-baixa.model';
import { Token } from '../../../auth/token.model';
import { UtilsService } from '../../../shared/utils.service';
import { InformanteAreaModel } from '../../../shared/model/informante-area.model';
import { NaturezaOperacaoModel } from '../../../shared/model/natureza-operacao.model';
import { LoaderService } from '../../../shared/loader.service';
import { AtualizarOcorrenciaDialog } from './atualizar-ocorrencia-dialog.component';
import { MESSAGES } from '../../../shared/constants/constants-swal.model';
import { AUTHORITIES } from '../../../auth/groups.constants';
import { ConfirmarExclusaoOcorrenciaPrincipalDialog } from './confirmar-exclusao-ocorrencia-principal-dialog.component';
import { ExcluirAvalistaDialog } from './excluir-avalista-dialog.component';


@Component({
  selector: 'app-detalhe',
  templateUrl: './detalhe.component.html',
  providers: [ApontamentoService]
})
export class DetalheComponent implements OnInit {

  detalheApontamentoForm: FormGroup;
  idNegativacao: string;
  datOcorrencia: string;
  valor: number;
  dialogRef: any;

  negativacao: NegativacaoModel = new NegativacaoModel();
  informanteArea: InformanteAreaModel = new InformanteAreaModel();
  enderecos: NegativacaoEnderecoModel[] = [];
  opcional: NegativacaoOpcionalModel = new NegativacaoOpcionalModel();
  telefones: TelefoneModel[] = [];
  emails: EmailModel[] = [];
  ocorrencias: NegativacaoOcorrenciasModel[] = [];
  avalistas: NegativacaoModel[] = [];
  naturezaOperacao: NaturezaOperacaoModel;
  motivosBaixa: MotivosBaixaModel[];

  msgNegativacao = '';
  msgInformanteArea = '';
  msgEnderecos: string = '';
  msgOpcional: string = '';
  msgTelefones: string = '';
  msgEmails: string = '';
  msgOcorrencias: string = '';
  msgAvalistas: string = '';

  msgEnderecosAvalista: string = '';
  msgOpcionalAvalista: string = '';
  msgTelefonesAvalista: string = '';
  msgEmailsAvalista: string = '';
  msgOcorrenciasAvalista: string = '';
  msgAvalistasAvalista: string = '';

  nomeInformante: string = '';

  maskCnpj = ConstantsMaskModel.MASK_CNPJ;
  maskCpf = ConstantsMaskModel.MASK_CPF;
  sitNegativadoDivida : string = '';
  jwt: Token;

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private jwtService: JWTService,
    private service: ApontamentoService,
    public dialog: MatDialog,
    public overlay: Overlay,
    public router: Router,
    public utilsService: UtilsService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.detalheApontamentoForm = this.formBuilder.group({});
    this.route.params.subscribe(params => {
    this.idNegativacao = params['id'];
    });

    this.route.queryParams.subscribe(params => {
      this.datOcorrencia = params['datOcorrencia'];
      this.valor = params['valor'];
      this.sitNegativadoDivida = params['sitNegativado'];
    });

    this.jwt = this.jwtService.getObject('auth_token');
    this.getNegativacao(this.idNegativacao);
    this.naturezaOperacao = new NaturezaOperacaoModel();

    this.getMotivosBaixa();
  }

  getInformanteArea(numDocumento: string, codArea: string) {
    this.sharedService.getInformanteArea(numDocumento, codArea)
      .subscribe(
        informanteArea => {
          this.informanteArea = informanteArea;
        },
        errors => {

        }
      );
  }

  getInformanteAreaByIdNegativacao(numDocumento: string, codArea: string, idNegativacao: string) {
    this.sharedService.getInformanteAreaByIdNegativacao(numDocumento, codArea, idNegativacao)
      .subscribe(
        informanteArea => {
          this.informanteArea = informanteArea;
        },
        errors => {

        }
      );
  }

  formatarDocumento(numDocumento: number, tipPessoa: string): string {
    if (numDocumento)
      return this.utilsService.formatarDocumento(numDocumento.toString(), tipPessoa);
    return "";
  }

  maskACpf(cpf: number) {
    if (!cpf)
      return;
    return this.sharedService.maskACpf(cpf.toString());
  }

  maskACnpj(cnpj: number) {
    if (!cnpj)
      return;
    return this.sharedService.maskACnpj(cnpj.toString());
  }

  isEmpty(value: string): boolean {
    return value == null || value.toString().trim() == '';
  }

  objectIsEmpty(object: Object) {
    if (object == null)
      return true;
    let value: boolean = true;
    Object.keys(object).forEach(key => {
      if (object[key] != null && object[key].toString().trim() != '') {
        value = false;
        return;
      }
    });
    return value;
  }

  converterTipoTelefone(tipo: string): string {
    switch (tipo) {
      case "C":
        return "Comercial";
      case "M":
        return "Movel";
      case "R":
        return "Residencial";
      default:
        return tipo;
    }
  }

  converterTipoEmail(tipo: string): string {
    switch (tipo) {
      case "C":
        return "Comercial";
      case "P":
        return "Pessoal";
      default:
        return tipo;
    }
  }

  getNegativacaoInformacoesAdicionais() {
    Object.keys(this.negativacao._links).forEach(key => {
      let url = this.utilsService.converterLinkDto(this.negativacao._links[key].href);
      switch (key) {
        case "Endereços":
          this.getEnderecos(url);
          break;
        case "Telefones":
          this.getTelefones(url);
          break;
        case "Opcional":
          this.getOpcional(url);
          break;
        case "E-mails":
          this.getEmails(url);
          break;
        case "Avalistas":
          this.getAvalistas(url);
          break;
        case "Ocorrencias":
          this.getOcorrencias(url);
          break;
      }
    });
  }

  getNegativacao(id: string) {
    this.loaderService.display(true);
    this.sharedService.getNegativacaoPrincipalDivida(id)
      .subscribe(
        (negativacao: any) => {

          this.loaderService.display(false);
          this.negativacao = negativacao;

          if (negativacao.datDisponibilidade == "01/01/1900")
            this.negativacao.datDisponibilidade = " ";

          if (this.negativacao._links) {
            this.getNegativacaoInformacoesAdicionais();
          }

          this.getNomeInformante(this.negativacao.numDocumento);
          this.getDescricaoNaturezaOperacao(negativacao.natOperacao);
          this.getInformanteAreaByIdNegativacao(this.negativacao.numDocumento.toString(), this.negativacao.codArea, this.negativacao.idNegativacao)
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
          this.router.navigate(["/servicos/apontamento/consultarApontamento"]);
        }
      );
  }

  getMotivosBaixa() {
    this.sharedService.getMotivosBaixa()
      .subscribe(
        (motivos: MotivosBaixaModel[]) => {
          this.motivosBaixa = motivos;
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
          this.router.navigate(["/servicos/apontamento/consultarApontamento"]);
        }
      );
  }

  getMotivo(codMotivo: string): string {
    if(this.motivosBaixa && codMotivo && codMotivo.trim().length > 0) {
      let motivoTemp = this.motivosBaixa.filter(motivo => motivo.codMotivoBaixa == codMotivo)[0];
      return motivoTemp.descMotivoBaixa;
    }
    return "";
  }


  getNomeInformante(numDocumento: number) {
    this.sharedService.getInformante(numDocumento)
      .subscribe(
        (informante: any) => {
          this.nomeInformante = informante.nomeRazaoSocial;
        },
        errors => { }
      );
  }

  getEnderecos(link: string, avalistaIndex?: number) {
    this.http.get(this.utilsService.converterLinkDto(link),
      {
        headers: this.jwtService.getApiHeaders([])
      }
    )
      .subscribe(
        (enderecos: NegativacaoEnderecoModel[]) => {
          if (!avalistaIndex && avalistaIndex !== 0) {
            this.enderecos = enderecos;
          } else {
            this.avalistas[avalistaIndex].enderecos = enderecos;
          }
        },
        errors => {
          this.msgEnderecos = this.sharedService.tratarErro(errors);
        }
      );
  }

  formatToCurrency(value: number): string {
    return this.sharedService.formatToCurrency(value);
  }

  getOpcional(link: string, avalistaIndex?: number) {
    this.http.get(this.utilsService.converterLinkDto(link),
      {
        headers: this.jwtService.getApiHeaders([])
      }
    )
      .subscribe(
        (opcional: NegativacaoOpcionalModel) => {
          if (!avalistaIndex && avalistaIndex !== 0) {
            this.opcional = opcional;
          } else {
            this.avalistas[avalistaIndex].opcional = opcional;
          }
        },
        errors => {
          this.msgOpcional = this.sharedService.tratarErro(errors);
        }
      );
  }

  getTelefones(link: string, avalistaIndex?: number) {
    this.http.get(this.utilsService.converterLinkDto(link),
      {
        headers: this.jwtService.getApiHeaders([])
      }
    )
      .subscribe(
        (telefones: TelefoneModel[]) => {
          if (!avalistaIndex && avalistaIndex !== 0) {
            this.telefones = telefones;
          } else {
            this.avalistas[avalistaIndex].telefones = telefones;
          }
        },
        errors => {
          this.msgTelefones = this.sharedService.tratarErro(errors);
        }
      );
  }

  getAvalistas(link: string) {
    this.http.post(link, {},
      {
        headers: this.jwtService.getApiHeaders([])
      }
    )
      .subscribe(
        (avalistas: any[]) => {
          this.avalistas = avalistas;
          this.avalistas.forEach((a: any, index) => {

            if (a.motBaixa)
              a.descBaixa = this.getMotivo(a.motBaixa);
            else
              a.descBaixa = "";

            a.links.forEach(link => {
              switch (link.rel) {
                case "Endereços":
                  this.getEnderecos(this.utilsService.converterLinkDto(link.href), index);
                  break;
                case "Telefones":
                  this.getTelefones(this.utilsService.converterLinkDto(link.href), index);
                  break;
                case "Opcional":
                  this.getOpcional(this.utilsService.converterLinkDto(link.href), index);

                  break;
                case "E-mails":
                  this.getEmails(this.utilsService.converterLinkDto(link.href), index);
                  break;
              }
            });
          });
        },
        errors => {
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  getEmails(link: string, avalistaIndex?: number) {
    this.http.get(link,
      {
        headers: this.jwtService.getApiHeaders([])
      }
    )
      .subscribe(
        (emails: EmailModel[]) => {
          if (!avalistaIndex && avalistaIndex !== 0) {
            this.emails = emails;
          } else {
            this.avalistas[avalistaIndex].emails = emails;
          }
        },
        errors => {
          this.msgEmails = this.sharedService.tratarErro(errors);
        }
      )
  }

  getOcorrencias(link: string) {
    this.http.get(link,
      {
        headers: this.jwtService.getApiHeaders([])
      }
    ).subscribe(
      (ocorrencias: NegativacaoOcorrenciasModel[]) => {
        if (ocorrencias) {
          for (let i = 0; i < ocorrencias.length; i++) {
            ocorrencias[i].descBaixa = this.getMotivo(ocorrencias[i].motBaixa);
          }
          this.ocorrencias = ocorrencias.filter(o => {
            if (o.datOcorrencia == this.datOcorrencia && o.valor == this.valor)
              return o;
          });
        }
      },
      errors => {
        Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
      }
    )
  }

  getDescricaoNaturezaOperacao(codNatOper: string) {
    this.sharedService.getNaturezaOperacao(codNatOper)
      .subscribe(
        naturezaOperacao => {
          this.naturezaOperacao = naturezaOperacao;
        }
      );
  }


  verificarSePossuiAvalistas(): boolean {
    let ocorrenciasAtivas = this.ocorrencias.filter(ocorrencia => ocorrencia.sitNegativado == 'A');
    if (this.avalistas.length > 0 && ocorrenciasAtivas && ocorrenciasAtivas.length <= 1) {
      return true;
    }
    return false;
  }

  verificcaTipoParticipante() {
    if (this.negativacao.tipoParticipante == 'P') {
      return true;
    } else if (this.negativacao.tipoParticipante == 'A') {
      return true;
    }
  }

  verificaParticipantePrincipal() {
    if (this.negativacao.tipoParticipante == 'P') {
      return true;
    } else {
      return false;
    }
  }

  openDialogExcluirNegativacao(row: any) {
    this.dialogRef = this.dialog.open(ExcluirNegativacaoDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '50%'
    });

    this.dialogRef.afterClosed().subscribe(
      (result: MotivosBaixaModel) => {
        if (result && result.codMotivoBaixa) {
          this.excluir(row, result.codMotivoBaixa);
        }
      });
  }

  excluir(row: any, motivoBaixa: string) {
    let datOcorrencia, sequenciaOcorr;
    if (this.ocorrencias.length > 0) {
      datOcorrencia = row.datOcorrencia.replace(/[\\/.]/gi, "");
      sequenciaOcorr = row.sequenciaOcorr;
    }

    this.loaderService.display(true);
    this.service.excluir(this.idNegativacao, datOcorrencia, sequenciaOcorr, motivoBaixa, this.jwt.numDocumento)
      .subscribe(
        result => {
          this.loaderService.display(false);
          Swal(MESSAGES.OCORRENCIA_EXCLUIDA_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
          this.router.navigate(['/servicos/apontamento/consultarApontamento']);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
          this.msgNegativacao = this.sharedService.tratarErro(errors);
        }
      );
  }

  openDialogConfirmarExclusaoOcorrencia(row: any) {
    this.dialogRef = this.dialog.open(ConfirmarExclusaoOcorrenciaPrincipalDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '50%'
    });

    this.dialogRef.afterClosed().subscribe(
      (result: boolean) => {
        if (result) {
          this.openDialogExcluirNegativacao(row);
        }
      }
    );
  }

  excluirOcorrencia(row: any) {
    this.sharedService.getNegativacao(this.idNegativacao)
    .subscribe(
      (negativacao: any) => {
        this.loaderService.display(false);
        this.negativacao = negativacao;

        if(this.verificarSePossuiAvalistas()&&
          this.negativacao.tipoParticipante == 'P'){
          this.openDialogConfirmarExclusaoOcorrencia(row);
        } else if (this.verificarSePossuiAvalistas()&&
          this.negativacao.tipoParticipante == 'A'){
          this.openDialogConfirmarExclusaoAvalista(negativacao);
        } else{
          this.openDialogExcluirNegativacao(row);
        }
          
          },
          errors => {
            Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
            this.router.navigate(["/servicos/apontamento/consultarApontamento"]);
          }
        );

  }

  openDialogConfirmarExclusaoAvalista(row: NegativacaoModel) {
    this.dialogRef = this.dialog.open(ExcluirAvalistaDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '50%'
    });

    this.dialogRef.afterClosed().subscribe(
      (result: MotivosBaixaModel) => {
        if (result) {
          this.excluirAvalista(row, result.codMotivoBaixa);
        }
      }
    );
  }

  excluirAvalista(row: NegativacaoModel, motivoBaixa: string) {
    this.loaderService.display(true);
    this.service.excluirAvalista(row.idNegativacao, motivoBaixa, row.numDocumento)
      .subscribe(
        result => {
          this.loaderService.display(false);
          Swal(MESSAGES.AVALISTA_EXCLUIDO_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
          this.router.navigate(['/servicos/apontamento/consultarApontamento']);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
          this.msgNegativacao = this.sharedService.tratarErro(errors);
        }
      );
  }

  openDialogAlterarOcorrenciaAvalista(row: any) {
    this.dialogRef = this.dialog.open(AtualizarOcorrenciaDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '50%',
      data: row
    });

    this.dialogRef.afterClosed().subscribe(
      (result: any) => {
        if (result) {
          this.atualizar(new AtualizarOcorrenciaModel(row, row.datOcorrencia, result.datOcorrencia, row.valor, result.valor));
        }
      });
  }

  openDialogAlterarOcorrencia(row: any) {
    this.dialogRef = this.dialog.open(AtualizarOcorrenciaDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '50%',
      data: row
    });

    this.dialogRef.afterClosed().subscribe(
      (result: any) => {
        if (result) {
          this.atualizar(new AtualizarOcorrenciaModel(row, row.datOcorrencia, result.datOcorrencia, row.valor, result.valor));
        }
      });
  }

  atualizar(row: AtualizarOcorrenciaModel) {
    this.loaderService.display(true);
    const id = row.ocorrenciaOriginal.idNegativacao;
    const seqOcorr = row.ocorrenciaOriginal.sequenciaOcorr;
    let datOcorr = row.ocorrenciaOriginal.datOcorrencia;
    let data = row.datOcorrenciaModificada;
    let valor = row.valorModificado;
    datOcorr = datOcorr.replace(/\//g, '');
    data = data.replace(/\//g, '');

    this.service.atualizar(id, datOcorr, seqOcorr, data, valor)
      .subscribe(
        result => {
          this.loaderService.display(false);
          Swal(MESSAGES.OCORRENCIA_ATUALIZADA_SUCESSO, "", MESSAGES.TYPE_SUCCESS);
          this.router.navigate(['servicos/apontamento/consultarApontamento']);
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.sharedService.tratarErro(errors), "", MESSAGES.TYPE_ERROR);
        }
      );
  }

  disableBtnAlterar() {
    return !this.sharedService.hasPermission(this.jwt, [AUTHORITIES.ALTERAR_NEGATIVACAO]);
  }

  disableBtnExcluir() {
    return !this.sharedService.hasPermission(this.jwt, [AUTHORITIES.BAIXAR_NEGATIVACAO]);
  }

  disableBtnExcluirAvalista() {
    return !this.sharedService.hasPermission(this.jwt, [AUTHORITIES.BAIXAR_NEGATIVACAO]);
  }

  disableBotaoExcluirOcorrencia(ocorrencia : any){
    let isDisable = false;
    if(this.verificaParticipantePrincipal() ||  (!this.verificaParticipantePrincipal() && this.sitNegativadoDivida =='A')){
      if(ocorrencia.sitNegativado == 'I' || this.disableBtnExcluir()){
        isDisable =  true;
      }     
    }else{
      isDisable =  true;
    }

    if(ocorrencia.tipoApontamento == 'AC'){
      isDisable =  true;
    }

   return isDisable;
  }

  disableBotaoAlterarOcorrencia(ocorrencia : any){
    let isDisable = false;
    if(this.verificaParticipantePrincipal() ||  ( !this.verificaParticipantePrincipal() && this.sitNegativadoDivida =='A')){
      if(ocorrencia.sitNegativado == 'I' || this.disableBtnAlterar()){
        isDisable =  true;
      }     
    }else{
      isDisable =  true;
    }

    if(ocorrencia.tipoApontamento == 'AC'){
      isDisable =  true;
    }
    
   return isDisable;
  }
  
}
