import { Component, OnInit } from '@angular/core';
import { JWTService } from '../auth/jwt.service';
import { Token } from '../auth/token.model';
import Swal from 'sweetalert2';
import { UtilsService } from '../shared/utils.service';
import { LoaderService } from '../shared/loader.service';
import { EventEmitterService } from '../shared/event-emiter-service.service';
import $ from "jquery";
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../login/shared/login.service';
import { MESSAGES } from '../shared/constants/constants-swal.model';
import { SharedService } from '../shared/shared.service';
import { DominioModel } from '../shared/model/dominio.model';

@Component({
  selector: 'acesso-itau',
  templateUrl: './acesso-itau.component.html'
})
 

export class AcessoItauComponent implements OnInit {

  constructor(private jwtService : JWTService,
              private utils: UtilsService,
              private sharedService: SharedService,
              private loaderService: LoaderService,
              private eventEmitterService: EventEmitterService,
              private router: Router,
              private route: ActivatedRoute,
              private loginService : LoginService) { 
  }

  ngOnInit() {
    this.jwtService.authCheck(true);
    this.eventEmitterService.display(false);
      
    $(".breadcrumb").css({'display': 'none'})    
    $('.content-wraper').addClass('col-6')
    $('.content-wraper').addClass('float-right')
    $('.content-wraper').addClass('pl-3')
    $('.content-wraper').addClass('ml-0')
    
    $(".container-fluid").removeClass('wraper')   
    $(".content-wraper").removeClass('ml-5')    
    $('.content-wraper').removeClass('col-12')
    $('.content-wraper').removeClass('pl-5')
    $('.content-wraper').removeClass('pb-3')

    this.iniciarSessao();
  }

  iniciarSessao(){
    this.loaderService.display(true);
    const username = this.route.snapshot.queryParamMap.get("username");
    const key = this.route.snapshot.queryParamMap.get("password");

    this.sharedService
          .getDominioByCodDominioAndCodigo(username.toUpperCase(), key.substring(0,4))
          .subscribe((dominio : DominioModel) => {
            if(dominio) {
              let desc : string[] = dominio.descricao.split(':');
              if(desc.length == 2){
                this.loginService.loginItau(desc[0], desc[1], 'local')
                .subscribe(
                  (login: Token) => {
                    this.loaderService.display(false);
                    this.storeAuthToken(login);
                    return;
                  },
                  errors => {
                    this.throwError(this.utils.tratarErro(errors));
                    return;
                  }
                );
              } else this.throwError(MESSAGES.USUARIO_SENHA_INVALIDOS);
            } else this.throwError(MESSAGES.USUARIO_SENHA_INVALIDOS);         
          });
  }

  throwError(message) {
    this.loaderService.display(false);
    Swal(message, "", MESSAGES.TYPE_ERROR);
    return;
  }
  
  storeAuthToken(token: Token) {
    var dt = new Date();
    dt.setSeconds(dt.getSeconds() + token.expires_in);
    token.expires_in = dt;
    let tempRefreshToken = token.refresh_token;
    token.refresh_token = null;
    this.jwtService.putObject('auth_token', token, dt);
    this.jwtService.putObjectLocalStorage('ref', tempRefreshToken);   
    this.jwtService.putObject('_EPS', 1, dt);
    const route = this.jwtService.getObjectLocalStorage('route');
    this.router.navigate(['/home']);
    $(".container-fluid").addClass('wraper');
  }

}
