export class UsuarioModel {
    constructor(
        public apelido?: string,
        public codArea?: string,
        public codDdd?: number,
        public codPerfil?: number,
        public cpf?: number,
        public cnpj?: number,
        public email?: string,
        public emailConfirmar?: string,
        public idLogin?: number,
        public nome?: string,
        public numDocumento?: number,
        public numTelefone?: string,
        public status?: string,
        public tipoUsuario?: string,
        public nomeFantasia?: string) {
        this.apelido = apelido == null ? '' : apelido;
        this.codArea = codArea == null ? '' : codArea;
        this.codDdd = codDdd == null ? null : codDdd;
        this.codPerfil = codPerfil == null ? null : codPerfil;
        this.cpf = cpf == null ? null : cpf;
        this.email = email == null ? '' : email;
        this.emailConfirmar = emailConfirmar == null ? '' : emailConfirmar;
        this.idLogin = idLogin == null ? null : idLogin;
        this.nome = nome == null ? '' : nome;
        this.numDocumento = numDocumento == null ? null : numDocumento;
        this.numTelefone = numTelefone == null ? '' : numTelefone;
        this.status = status == null ? '' : status;
        this.tipoUsuario = tipoUsuario == null ? '' : tipoUsuario;
        this.nomeFantasia = nomeFantasia == null ? '' : nomeFantasia;
    }
}