import { Component, OnInit } from '@angular/core';
import { JWTService } from '../auth/jwt.service';
import { UtilsService } from '../shared/utils.service';
import $ from "jquery"
import { EventEmitterService } from '../shared/event-emiter-service.service';
import { LoaderService } from '../shared/loader.service';
@Component({
  selector: 'app-instrucoesnovasenha',
  templateUrl: './reenviosenha.component.html',
  providers: [JWTService, UtilsService]
})

export class ReenvioSenhaComponent implements OnInit {
  constructor(
    private jwtService: JWTService,
    private loaderService: LoaderService,
    private emitterService: EventEmitterService) { }

  ngOnInit() {
    this.jwtService.authCheck(true);
    this.emitterService.display(false);
    this.loaderService.display(false);
    $(".container-fluid").removeClass('wraper')      
   }

  
}
