import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AUTHORITIES } from '../../auth/groups.constants';
import { JWTService } from '../../auth/jwt.service';
import { HttpService } from '../../shared/http/httpclient.service';

@Injectable()
export class MotivosBaixaService {
  constructor(private http: HttpService,
    private jwtService: JWTService) { }

  getMotivosBaixa() {
    return this.http.get(`${environment.settings.api}/motivosBaixa/todos`, this.jwtService.getApiHeaders([]), null);
  }

  getMotivosBaixaDesc() {
    return this.http.get(`${environment.settings.api}/motivosBaixa/todosDecrescente`, this.jwtService.getApiHeaders([]), null);
  }

  getMotivosBaixaAsc() {
    return this.http.get(`${environment.settings.api}/motivosBaixa/todosDescricaoAsc`, this.jwtService.getApiHeaders([]), null);
  }

  inserirMotivoBaixa(model) {
    return this.http.post(`${environment.settings.api}/motivosBaixa/`, model, this.jwtService.getApiHeaders([AUTHORITIES.INCLUIR_MOTIVOS_BAIXA]));
  }

}
